import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import BusMultipleIcon from 'mdi-react/BusMultipleIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import CityIcon from 'mdi-react/CityIcon';
import CityVariantIcon from 'mdi-react/CityVariantIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { registerDepotValidate } from '@/utils/validate.js';
import getFleetList from '../../../../redux/actions/FleetActions/getFleetListActions';
import registerDepot, {
  registerDepotReset,
} from '../../../../redux/actions/DepotActions/registerDepotActions';
import getRegisteredDepot from '../../../../redux/actions/DepotActions/getRegisteredDepotActions';
import updateDepot, {
  updateDepotReset,
} from '../../../../redux/actions/DepotActions/updateDepotActions';
import { resetDepotList } from '../../../../redux/actions/DepotActions/getDepotListActions';

const DepotForm = ({ setGoBack, editMode, setShowDepotEditForm, fleetId, depotId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [depotUpdateBtnClick, setDepotUpdateBtnClick] = useState(false);

  const getFleetListReducerLoading = useSelector((state) => state.getFleetListReducer.loading);
  const getFleetListReducerInfo = useSelector((state) => state.getFleetListReducer.info);
  const registerDepotReducerLoading = useSelector((state) => state.registerDepotReducer.loading);
  const registerDepotReducerInfo = useSelector((state) => state.registerDepotReducer.info);
  const registerDepotReducerError = useSelector((state) => state.registerDepotReducer.error);
  const getRegisteredDepotReducerLoading = useSelector(
    (state) => state.getRegisteredDepotReducer.loading
  );
  const getRegisteredDepotReducerInfo = useSelector(
    (state) => state.getRegisteredDepotReducer.info
  );
  const getRegisteredDepotReducerError = useSelector(
    (state) => state.getRegisteredDepotReducer.error
  );
  const updateDepotReducerLoading = useSelector((state) => state.updateDepotReducer.loading);
  const updateDepotReducerInfo = useSelector((state) => state.updateDepotReducer.info);
  const updateDepotReducerError = useSelector((state) => state.updateDepotReducer.error);
  const companyId = localStorage.getItem('companyId');

  const handleCancel = () => {
    setGoBack(true);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getFleetList(companyId));
    }
    if (companyId && fleetId && depotId) {
      dispatch(getRegisteredDepot(companyId, fleetId, depotId));
      dispatch(resetDepotList());
    }
    return () => {
      setBtnClicked(false);
      setDepotUpdateBtnClick(false);
    };
  }, [depotId, fleetId]);

  const handleRegisterDepot = (values) => {
    if (Object.keys(values).length !== 0) {
      dispatch(registerDepot(companyId, values));
      setBtnClicked(true);
    }
  };

  if (btnClicked === true && registerDepotReducerInfo !== '') {
    setTimeout(() => {
      handleCancel();
      dispatch(registerDepotReset());
      setBtnClicked(false);
    }, 1000);
  }

  const handleUpdateDepot = (values) => {
    if (Object.keys(values).length !== 0 && companyId && fleetId && depotId) {
      dispatch(updateDepot(companyId, depotId, fleetId, values));
      setDepotUpdateBtnClick(true);
    }
  };

  if (depotUpdateBtnClick === true && updateDepotReducerInfo !== '') {
    setTimeout(() => {
      setShowDepotEditForm(false);
      dispatch(updateDepotReset());
      setDepotUpdateBtnClick(false);
    }, 1000);
  }

  const dropdownValue = [
    {
      id: getRegisteredDepotReducerInfo ? getRegisteredDepotReducerInfo.depot.fleet_name : '',
      label: getRegisteredDepotReducerInfo ? getRegisteredDepotReducerInfo.depot.fleet_name : '',
      value: getRegisteredDepotReducerInfo ? getRegisteredDepotReducerInfo.depot.fleet_name : '',
    },
  ];

  return (
    <Card height="auto">
      <CardBody>
        <Form
          onSubmit={editMode === false ? handleRegisterDepot : handleUpdateDepot}
          initialValues={
            editMode === true && getRegisteredDepotReducerInfo !== ''
              ? getRegisteredDepotReducerInfo.depot
              : ''
          }
          validate={registerDepotValidate}
        >
          {({ handleSubmit }) => (
            <FormContainer onSubmit={handleSubmit}>
              {getRegisteredDepotReducerLoading || getFleetListReducerLoading ? (
                <DotDotLoader
                  loadingState={getRegisteredDepotReducerLoading || getFleetListReducerLoading}
                />
              ) : (
                ''
              )}
              <FormGroup>
                {registerDepotReducerLoading || updateDepotReducerLoading ? (
                  <DotDotLoader
                    loadingState={registerDepotReducerLoading || updateDepotReducerLoading}
                  />
                ) : (
                  ''
                )}
                <FormGroupLabel>Fleet Name</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <BusMultipleIcon />
                  </FormGroupIcon>
                  <Field
                    name="fleet_name"
                    component={editMode === true ? FormField : renderSelectField}
                    type="text"
                    options={
                      getFleetListReducerInfo
                        ? getFleetListReducerInfo.fleet_list.map(({ id, name }) => ({
                            id: id,
                            value: name,
                            label: name,
                          }))
                        : []
                    }
                    placeholder="Select fleet"
                    disabled={editMode === true ? true : false}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Depot Name</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <DomainIcon />
                  </FormGroupIcon>
                  <Field
                    name="name"
                    component={FormField}
                    type="text"
                    placeholder="Depot"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Address</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <CityIcon />
                  </FormGroupIcon>
                  <Field
                    name="address"
                    component={FormField}
                    type="text"
                    placeholder="Address"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>City</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <CityVariantIcon />
                  </FormGroupIcon>
                  <Field
                    name="city"
                    component={FormField}
                    type="text"
                    placeholder="City"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Country</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <CityVariantIcon />
                  </FormGroupIcon>
                  <Field
                    name="country"
                    component={FormField}
                    type="text"
                    placeholder="Country"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Latitude</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <MapMarkerIcon />
                  </FormGroupIcon>
                  <Field
                    name="lat"
                    component={FormField}
                    type="text"
                    placeholder="Latitude"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Longitude</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <MapMarkerIcon />
                  </FormGroupIcon>
                  <Field
                    name="long"
                    component={FormField}
                    type="text"
                    placeholder="Longitude"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              {editMode === false ? (
                <FormButtonToolbar>
                  <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Register
                  </Button>
                </FormButtonToolbar>
              ) : (
                <FormButtonToolbar>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => setShowDepotEditForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Update
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default DepotForm;

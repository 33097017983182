import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@/shared/components/Button';
import RegisteredDevicesContainer from './RegisteredDevicesContainer';
import ComponentsEdit from './ComponentsEdit';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import getComps from '../../../../redux/actions/getCompsActions';
import data from '../../../App/Router/data';

const ComponentsRegistered = ({ compName, setAddNew, setSwitchComp }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Ava3 = `${process.env.PUBLIC_URL}/img/14.png`;

  const getCompsReducer = useSelector((state) => state.getCompsReducer.info);

  const getCompsReducerLoading = useSelector((state) => state.getCompsReducer.loading);

  const addHvacCompReducer = useSelector((state) => state.addHvacCompReducer);
  const deleteHvacCompReducer = useSelector((state) => state.deleteHvacCompReducer);
  const editHvacCompReducer = useSelector((state) => state.editHvacCompReducer);

  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  useEffect(() => {
    const getVehicleInfo = JSON.parse(localStorage.getItem('vehicleDetails'));
    const hvacID = getVehicleInfo.hvac_id;
    if (getVehicleInfo) {
      dispatch(getComps(hvacID));
    }
  }, [addHvacCompReducer.info, deleteHvacCompReducer.info, editHvacCompReducer.info]);

  const [showEditComp, setShowEditComp] = useState(false);
  const [serialNumber, setSerialNumber] = useState();
  const [manufacturerId, setManufacturerId] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [deviceName, setDeviceName] = useState();
  const [compId, setCompId] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleClick = (serial, man_name, man_id, device_name, compId) => {
    setSerialNumber(serial);
    setManufacturerName(man_name);
    setManufacturerId(man_id);
    setDeviceName(device_name);
    setCompId(compId);
    setShowEditComp(true);
    setShowSuccessMessage(true);
  };

  return (
    <>
      {addHvacCompReducer.status === 'success' && showSuccessMessage === true ? <></> : ''}
      {showEditComp === true ? (
        <ComponentsEdit
          serialNum={serialNumber}
          manId={manufacturerId}
          manName={manufacturerName}
          deviceName={deviceName}
          componentId={compId}
          setShowEditComp={setShowEditComp}
        />
      ) : getCompsReducerLoading === true || !getCompsReducer ? (
        <SimpleLoader />
      ) : (
        getCompsReducer.map((comp) =>
          comp.component_type_object.name === compName ? (
            <>
              <div
                onClick={() =>
                  !viewRole.includes(userRole)
                    ? handleClick(
                        comp.serial_num,
                        comp.supplier_detail_object.name,
                        comp.supplier_detail_object.id,
                        comp.supplier_detail_object.unit_type,
                        comp.id
                      )
                    : null
                }
                id={comp.id}
              >
                <RegisteredDevicesContainer
                  key={comp.id}
                  manufacturer={comp.supplier_detail_object.name}
                  deviceType={comp.supplier_detail_object.unit_type}
                  serialNum={comp.supplier_detail_object.supplier ? comp.serial_num : ''}
                  img={Ava3}
                  showEdit={!viewRole.includes(userRole) ? true : ''}
                />
              </div>
            </>
          ) : (
            ''
          )
        )
      )}
      {showEditComp === false ? (
        <>
          <Button
            variant="secondary"
            style={{
              width: '100%',
              marginTop: '40px',
              marginBottom: '10px',
            }}
            onClick={() => {
              setAddNew(true);
              setSwitchComp(false);
            }}
          >
            + Add More
          </Button>
          <Button
            variant="primary"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={() => {
              history.goBack();
            }}
          >
            Done
          </Button>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ComponentsRegistered;

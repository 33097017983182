import {
  UPLOAD_PACKAGE_FILE_CHECK_LOADING,
  UPLOAD_PACKAGE_FILE_CHECK_SUCCESS,
  UPLOAD_PACKAGE_FILE_CHECK_FAIL,
  UPLOAD_PACKAGE_FILE_CHECK_RESET
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const uploadPackageFileCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_PACKAGE_FILE_CHECK_LOADING:
      return { ...state, loading: true };
    case UPLOAD_PACKAGE_FILE_CHECK_SUCCESS:
      return {
        // ...state,
        loading: false,
        info: action.payload,
        error:''
      };
    case UPLOAD_PACKAGE_FILE_CHECK_FAIL:
      return {
        // ...state,
        info:'',
        loading: false,
        error: action.payload,
      };
    case UPLOAD_PACKAGE_FILE_CHECK_RESET:
      return initialState;
    default:
      return state;
  }
};

export default uploadPackageFileCheckReducer;

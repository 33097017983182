import axios from "axios";
import { ApiURL } from "@/config/GlobalUrl";
import {
  GET_DEPOT_VEHICLES_LOADING,
  GET_DEPOT_VEHICLES_SUCCESS,
  GET_DEPOT_VEHICLES_FAIL,
  GET_DEPOT_VEHICLES_RESET,
} from "@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants";
import { getConfig } from "@/shared/helpers/apiConfig.js";

const getDepotVehicles = (company_id, depot_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_VEHICLES_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_DEPOT_VEHICLES +
        company_id +
        "/depots/" +
        depot_id +
        "/vehicles",
      getConfig()
    );

    const getDepotVehicles = data?.data?.vehicles?.map(({ vin, bus_num }) => ({
      id: vin,
      value: vin,
      label: `${vin}, ${bus_num}`,
    }));

    dispatch({
      type: GET_DEPOT_VEHICLES_SUCCESS,
      payload: getDepotVehicles,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_VEHICLES_FAIL,
        payload: "network error",
      });
    } else {
      dispatch({
        type: GET_DEPOT_VEHICLES_FAIL,
        payload: error.response,
      });
    }
  }
};

export const resetDepotVehicles = () => async (dispatch) => {
  dispatch({
    type: GET_DEPOT_VEHICLES_RESET,
  });
};

export default getDepotVehicles;

// OEM comapny type check
export const companyNameCheck = (companyName) => {
  return companyName.split(' ')[0] === 'OEM';
};

// Get user details from local storage
export const getUserDetails = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  if(userDetails){
    return userDetails;
  }
  return;
};

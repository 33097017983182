import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_DEPOT_HVAC_LOADING,
  GET_DEPOT_HVAC_SUCCESS,
  GET_DEPOT_HVAC_FAIL,
} from '@/shared/constants/DepotDashboardConstants/depotDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getDepotHvacByBrand = (companyId, fleetId, depotId, tag) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_HVAC_LOADING,
    });

    const params = {
      tag_id: tag ? tag : null,
    };

    const { data } = await axios.get(
      ApiURL.GET_HVAC_UNITS_BY_BRAND +
        companyId +
        '/fleets/' +
        fleetId +
        '/depots/' +
        depotId +
        '/counts/hvac_units_by_brand',
      { params },
      getConfig()
    );

    dispatch({
      type: GET_DEPOT_HVAC_SUCCESS,
      payload: data.data.counts,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_HVAC_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_HVAC_FAIL,
        payload: error.response.data,
      });
    }
  }
};

export default getDepotHvacByBrand;

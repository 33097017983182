import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import {
  getCompSerialNumber,
  resetCompSerialNumber,
} from '../../../../redux/actions/getCompSerialNumberActions';
import ComponentsAddForm from './ComponentsAddForm';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import ComponentsRegistered from './ComponentsRegistered';
import addHvacComp from '../../../../redux/actions/addHvacCompActions';

const ComponentsAdd = ({ componentName, componentId, showComponent, setShowComponent }) => {
  const dispatch = useDispatch();

  const getVehicleInfo = JSON.parse(localStorage.getItem('vehicleDetails'));
  const hvacID = getVehicleInfo.hvac_id;

  const getHvacCountReducer = useSelector((state) => state.getHvacCountReducer);

  const getCompSerialNumberReducer = useSelector((state) => state.getCompSerialNumberReducer);
  const getCompsReducer = useSelector((state) => state.getCompsReducer);

  const [addNew, setAddNew] = useState(false);
  const [switchComp, setSwitchComp] = useState(false);
  const [serialCheck, setSerialCheck] = useState(false);
  const [serialValue, setSerialValue] = useState();
  const [manufacturerId, setManufacturerId] = useState();

  useEffect(() => {
    return () => {
      dispatch(resetCompSerialNumber());
    };
  }, []);

  const handleClick = (values) => {
    if (Object.keys(values).length !== 0) {
      if (values.select && values.select.id === 1 && values.input) {
        dispatch(getCompSerialNumber(values.input));
        setSerialCheck(true);
        setSerialValue(values.input);
        setManufacturerId(values.select.id);
      }
      if (values.select && values.select.id !== 1 && values.selectcomponents && !values.input) {
        dispatch(addHvacComp(hvacID, componentId, '', values.selectcomponents.id));
        setSwitchComp(true);
      }
    }
  };

  if (getCompSerialNumberReducer.info && serialCheck === true) {
    if (manufacturerId && manufacturerId === 1) {
      dispatch(addHvacComp(hvacID, componentId, serialValue, manufacturerId));
      dispatch(resetCompSerialNumber());
      setSwitchComp(true);
      setSerialCheck(false);
    }
  }

  return (
    <>
      {switchComp === true ? (
        <Col xs={12} sm={12} md={6}>
          <Card height="auto">
            <CardBody>
              <ComponentsRegistered
                compName={componentName}
                setAddNew={setAddNew}
                setSwitchComp={setSwitchComp}
              />
            </CardBody>
          </Card>
        </Col>
      ) : (
        <>
          <Row className="mb-2">
            <Col md={6}>
              <h3 className="page-title">Add {componentName}</h3>
              <h6 style={{ paddingBottom: '20px' }}>Edit Assets</h6>
            </Col>
          </Row>
          {!getHvacCountReducer || getHvacCountReducer.loading === true ? (
            <SimpleLoader />
          ) : getHvacCountReducer.filtered[componentName] && addNew === false ? (
            <Col xs={12} sm={12} md={6}>
              <Card height="auto">
                <CardBody>
                  <ComponentsRegistered
                    compName={componentName}
                    setAddNew={setAddNew}
                    setSwitchComp={setSwitchComp}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : addNew === true ||
            !getHvacCountReducer.filtered[componentName] ||
            switchComp === false ? (
            <Col xs={12} sm={12} md={6}>
              <ComponentsAddForm
                onSubmit={handleClick}
                errorMsg={getCompSerialNumberReducer.error}
                addComp={showComponent}
                setAddComp={setShowComponent}
              />
            </Col>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default ComponentsAdd;

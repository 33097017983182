import {
  GET_HEALTH_STATUS_LOADING,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_FAIL,
  SET_FILTER_VALUE,
  RESET_FILTER_VALUE,
} from '../../../shared/constants/HealthStatusDashboardConstants/getHealthStatusDetailConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
  filter: {},
};

const getHealthStatusDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEALTH_STATUS_LOADING:
      return { ...state, loading: true };
    case GET_HEALTH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_HEALTH_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_FILTER_VALUE:
      return {
        ...state,
        filter: action.payload,
      };
    case RESET_FILTER_VALUE:
      return {
        ...state,
        filter: {},
      };
    default:
      return state;
  }
};

export default getHealthStatusDetailReducer;

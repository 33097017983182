import React from 'react';
import PropTypes from 'prop-types';

const VehicleDetailsImage = ({ colorCode }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='#A7AEB4'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M39.4554 22.9062V30.5424H47.0915V27.9966H42.0011V22.9062H39.4554ZM7.63672 22.9062V29.2729H35.6367V22.9062H33.091V26.7271H29.2734V22.9085H26.7277V26.7271H22.9102V22.9085H20.3644V26.7271H16.5458V22.9085H14V26.7271H10.1825V22.9062H7.63672Z'
        fill={`#${colorCode}`}
      />
      <path
        d='M50.5967 17.8164L2.54492 17.8186L2.54604 34.3639L5.0918 35.0003L5.09068 20.3633L48.6762 20.361L50.9088 28.1757V36.9062H47.6783C46.7972 35.3864 45.1527 34.3605 43.2726 34.3605C41.3925 34.3605 39.748 35.3853 38.8669 36.9062H17.1337C16.2525 35.3864 14.6081 34.3605 12.728 34.3605C10.8478 34.3605 9.20341 35.3853 8.32227 36.9062H5.45773V36.9085H2.54604V39.452H7.63644C7.63644 42.2593 9.92067 44.5424 12.728 44.5424C15.5353 44.5424 17.8195 42.2582 17.8195 39.452H38.1833C38.1833 42.2593 40.4675 44.5424 43.2748 44.5424C46.0821 44.5424 48.3664 42.2582 48.3664 39.452H53.4568V27.821L50.5967 17.8164ZM12.728 41.9989C11.3249 41.9989 10.1822 40.8573 10.1822 39.4531C10.1822 38.05 11.3238 36.9074 12.728 36.9074C14.1322 36.9074 15.2737 38.0489 15.2737 39.4531C15.2726 40.8573 14.1311 41.9989 12.728 41.9989V41.9989ZM43.2737 41.9989C41.8706 41.9989 40.728 40.8573 40.728 39.4531C40.728 38.05 41.8695 36.9074 43.2737 36.9074C44.6779 36.9074 45.8195 38.0489 45.8195 39.4531C45.8184 40.8573 44.6768 41.9989 43.2737 41.9989Z'
        fill={`#${colorCode}`}
      />
    </svg>
  );
};

VehicleDetailsImage.propTypes = {
  colorCode: PropTypes.string,
};

VehicleDetailsImage.defaultProps = {
  colorCode: '#A7AEB4',
};

export default VehicleDetailsImage;

import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  GET_ALARM_CHECKLIST_LOADING,
  GET_ALARM_CHECKLIST_SUCCESS,
  GET_ALARM_CHECKLIST_FAIL,
} from '../../../shared/constants/HealthStatusDashboardConstants/getAlarmChecklistConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getAlarmChecklist = (vin, alarmId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALARM_CHECKLIST_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_ALARM_CHECKLIST + vin + '/alarm/' + alarmId + '/checkpoints',
      getConfig()
    );

    dispatch({
      type: GET_ALARM_CHECKLIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_ALARM_CHECKLIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_ALARM_CHECKLIST_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getAlarmChecklist;

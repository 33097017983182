import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { colorText } from '@/utils/palette';
import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import InputOption from './InputOption';

const NotifcationForm = ({ vinOptions, usersOptions }) => {
  const getUsersListNotiLoading = useSelector((state) => state.getUsersListNotiReducer.loading);
  const getDepotVehiclesReducerLoading = useSelector(
    (state) => state.getDepotVehiclesReducer.loading
  );
  const getSelectedUserVehiclesReducerLoading = useSelector(
    (state) => state.getSelectedUserVehiclesReducer.loading
  );

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: 'static',
    }),

    placeholder(base) {
      return {
        ...base,
        color: `${colorText}`,
      };
    },
    option: (provided) => ({
      ...provided,
    }),
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={6}>
        <FormGroup>
          <FormGroupLabel>Users</FormGroupLabel>
          <FormGroupField>
            <Field
              name="user_list"
              component={renderSelectField}
              options={usersOptions}
              placeholder="Select users"
              menuIsOpen
              isMulti
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: InputOption,
              }}
              styles={customStyles}
              isLoading={getUsersListNotiLoading}
            />
          </FormGroupField>
        </FormGroup>
      </Col>
      <Col xs={12} sm={12} md={6}>
        <FormGroup>
          <FormGroupLabel>Vehicles</FormGroupLabel>
          <FormGroupField>
            <Field
              name="vehicle_list"
              component={renderSelectField}
              options={vinOptions}
              placeholder="Select vehicles"
              menuIsOpen
              isMulti
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: InputOption,
              }}
              styles={customStyles}
              isLoading={getDepotVehiclesReducerLoading || getSelectedUserVehiclesReducerLoading}
            />
          </FormGroupField>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default NotifcationForm;

import styled from 'styled-components';
import { FormButtonToolbar, FormContainer } from '@/shared/components/form/FormElements';
import { CardBody } from '@/shared/components/Card';
import {
  colorAdditional,
  colorBlue,
  colorBorder,
  colorHover,
  colorWhite,
  colorBackground,
  colorText,
} from '@/utils/palette';

export const WizardWrap = styled(CardBody)`
  background-color: ${colorBackground};
`;

export const WizardFormContainer = styled(FormContainer)`
  // max-width: 610px; //original
  max-width: 800px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 0 25px;
`;

export const WizardButtonToolbar = styled(FormButtonToolbar)`
  margin-left: auto;
  margin-right: auto;
`;

export const WizardSteps = styled.div`
  display: flex;
`;

export const WizardStep = styled.div`
  width: 100%;
  text-align: center;
  height: 55px;
  text-transform: uppercase;
  display: flex;
  transition: background 0.3s;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.active ? colorBlue : colorBorder)};
  background: ${(props) => (props.active ? colorBlue : colorHover)};

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
    color: ${(props) => (props.active ? colorWhite : colorText)};
  }
`;

export const WizardFormWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const WizardTitle = styled.h3`
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
`;

export const WizardDescription = styled.p`
  color: ${colorAdditional};
  margin: 0;
  max-width: 410px;
`;
export const MainContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  :before {
    content: '';
    position: absolute;
    background: ${colorHover};
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: ${colorBlue};
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: all 0.4s;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.active || props.page > props.step ? colorBlue : colorHover)};
  background: ${(props) => (props.page > props.step ? colorBlue : colorHover)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    color: ${colorText};
    svg {
      fill: #fff;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from '@/shared/components/Card';
import { colorBorder, colorText } from '@/utils/palette';
import { ToggleButtonField } from '@/shared/components/form/ToggleButton';
import getUserProfile from '@/redux/actions/UserProfileActions/getUserProfile';
import updateUserProfile from '../../../../redux/actions/UserProfileActions/updateUserProfile';
import renderImageUploadField from '../../../../shared/components/form/ImageUpload';
import { Field, Form } from 'react-final-form';
import { FormGroup, FormGroupField, FormContainer } from '@/shared/components/form/FormElements';
import { handleProfilePhotoValidation } from '@/utils/validate';

const ProfileMain = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');

  const profile = useSelector((state) => state.getUserProfileReducer.info);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (profile) {
      if (checked !== profile.is_notification_active) {
        setChecked(profile.is_notification_active);
      }
    }
  }, [profile]);

  const handleUpdateProfile = () => {
    dispatch(updateUserProfile({ notify: !checked })).then((res) => {
      if (res?.data?.status === 'success') {
        setChecked((pre) => !pre);
        dispatch(getUserProfile());
      }
    });
  };

  return (
    <Card>
      <CardBody>
        <ProfileInformation>
          <Form onSubmit={() => {}} validate={handleProfilePhotoValidation}>
            {({ handleSubmit, errors }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupField>
                    <Field
                      name="file"
                      component={renderImageUploadField}
                      fileType=".png,.jpeg,.jpg"
                      parse={(event) => {
                        setError('');
                        if (event && event.fileSize > 700000) {
                          setError('Maximum size allowed: 700KB');
                        }
                        return event;
                      }}
                    />
                  </FormGroupField>
                </FormGroup>
              </FormContainer>
            )}
          </Form>
          {error && <p style={{ color: 'red', marginTop: -15, textAlign: 'center' }}>{error}</p>}
          <ProfileData>
            <ProfileName>{profile?.username}</ProfileName>
            <ProfileWork>{profile?.designation}</ProfileWork>
            <ProfileContact>{profile?.email}</ProfileContact>
            <ProfileContact>{profile?.contact}</ProfileContact>
          </ProfileData>
        </ProfileInformation>
        <ProfileStats>
          <ProfileStatTitle>Notifications</ProfileStatTitle>
          <StyledToggle>
            <ToggleButtonField
              name={'notifications'}
              id={'notifications'}
              value={checked ? true : false}
              onChange={handleUpdateProfile}
            />
          </StyledToggle>
        </ProfileStats>
      </CardBody>
    </Card>
  );
};

export default ProfileMain;

// region STYLES

export const StyledNotifications = styled.span`
  display: flex;
  justify-content: ${(props) => (props.iconRight ? 'space-between' : 'left')};
  padding: 9px 0px 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: ${colorText};
`;

export const StyledToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

export const TopbarProfileWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  margin-left: 0;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${colorBorder};
`;

const ProfileData = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const ProfileName = styled.p`
  font-weight: 900;
  font-size: 20px;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
`;

const ProfileStatTitle = styled.h5`
  text-align: center;
`;

// endregion

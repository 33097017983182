import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  signInReducer,
  resetPwdReducer,
  forgotPwdReducer,
  resendPwdReducer,
  loginReducer,
  validateOTPReducer,
  vinNumReducer,
  getCompListReducer,
  vehicleRegisterReducer,
  getVinReducer,
  editVehicleReducer,
  registerGatewayReducer,
  getGatewayReducer,
  registerSimReducer,
  editGatewayReducer,
  getHvacCountReducer,
  getCompSerialNumberReducer,
  getSuppliersReducer,
  addHvacCompReducer,
  getCompsReducer,
  deleteHvacCompReducer,
  editHvacCompReducer,
  getRolesReducer,
  //Manage Settings - Manage Notifications
  getDepotListNotiReducer,
  getDepotVehiclesReducer,
  getUsersListNotiReducer,
  getNotificationListReducer,
  getDepotNotificationTypesReducer,
  getSelectedUserVehiclesReducer,
  getFleetsReducer,
  getDepotsReducer,
  getTemperatureReducer,
  getCompStatusReducer,
  getVehicleLocationReducer,
  getLastTimestampReducer,
  getRegisteredHvacReducer,
  getVehicleTagsReducer,
  //Depot Dashboard
  getTotalVehiclesReducer,
  getDepotHvacByBrandReducer,
  getHealthStatusSummaryReducer,
  getVehiclesWithGatewayReducer,
  getDepotVehicleStatusReducer,
  addVehicleTagsReducer,
  removeVehicleTagReducer,
  getVehicleAlarmsReducer,
  getCompanyTagsReducer,
  closeVehicleAlarmReducer,
  searchVinNumberReducer,

  //Global Dashboard
  getTotalCompanyVehiclesReducer,
  getGlobalHealthSummaryReducer,
  getGlobalHealthDetailReducer,
  getGlobalDepotLocationReducer,

  //Health Status Dashboard
  getAlarmCountReducer,
  getAssetsCountReducer,
  getHealthStatusDetailReducer,
  getAlarmChecklistReducer,
  closeAlarmReducer,
  getRegisteredCompanyReducer,
  getCompanyListReducer,
  addCompanyReducer,
  getCompanyRelationReducer,
  registerCompanyRelationReducer,
  updateCompanyReducer,
  registerFleetReducer,
  getRegisteredFleetReducer,
  getFleetListReducer,
  updateFleetReducer,
  getSysSettingsCountReducer,
  getCompanySysSettingsCountReducer,
  getDepotListReducer,
  getDepotListByCompanyReducer,
  registerDepotReducer,
  getRegisteredDepotReducer,
  updateDepotReducer,
  getUserListReducer,
  getRegisteredUserReducer,
  updateUserReducer,
  registerUserReducer,
  getRoleListReducer,
  getSignalsReducer,
  getDiagnosticsFileReducer,
  sendCanIntervalReducer,
  //Retrieve Dats
  getCanDataListingReducer,
  deleteCanDumpReducer,
  getMapFiltersReducer,
  getMaintenanceMetaReducer,
  addMaintenanceMetaReducer,

  //Gateway Master
  registerCpuNumberReducer,
  getGatewayMasterListReducer,
  //Sim Master
  registerSimMasterReducer,
  getSimMasterListReducer,
  getVehicleGatewayDetailsReducer,
  downloadIndexErrorCodeReducer,
  getOpFleetsReducer,
  getDepotTagsReducer,
  //Gateway Diagnostics Reducers
  getGatewayDiagnosticsListReducer,
  getGatewayDiagnosticsListingReducer,
  getGatewayDiagnosticsMonthReducer,
  //OTA Update - Package Management Reducers
  getCompanyDataReducer,
  getGroupListReducer,
  uploadPackageFileCheckReducer,
  createPackageReducer,
  getPackageListingReducer,

  //OTA Update - Package Management - Gateway Package Creation
  getBaseFirmwareVersionReducer,
  createGatewayPackageReducer,
  getGatewayConfigReducer,

  //OTA Update - Package Management
  downloadPackageReducer,

  //OTA Update - Campaign Management Reducers
  getCampaignListingReducer,
  getCampaignDeviceStatusListingReducer,
  getHvacListReducer,
  createCampaignReducer,
  getMetadataReducer,
  serviceModeEnableReducer,
  getServiceModeDeviceStatusReducer,

  //OTA Update - Group Management Reducers
  getHvacModelsReducer,
  getGroupDeviceListByModalReducer,
  getGroupDeviceListByOthersReducer,
  createGroupReducer,
  updateGroupReducer,
  getGroupDetailsReducer,

  //OTA Update - OTA Dashboard Reducers
  updateOtaRowReducer,
  getGatewayUnitsByBrandReducer,
  getGatewayListingReducer,
  getPackageCountReducer,
  getPackageListingKpiReducer,

  //OTA Update - Group Management - Gateway
  getGatewayDeviceListReducer,
  createGatewayGroupReducer,
  updateGatewayGroupReducer,

  // initial depot filters
  setInitialDepotFilterreducers,

  //User Profile
  getUserProfileReducer,
  updateUserProfileReducer,
} from '@/redux/reducers/index';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
  signInReducer: signInReducer,
  resetPwdReducer: resetPwdReducer,
  forgotPwdReducer: forgotPwdReducer,
  resendPwdReducer: resendPwdReducer,
  loginReducer,
  validateOTPReducer,
  vinNumReducer,
  getCompListReducer: getCompListReducer,
  vehicleRegisterReducer: vehicleRegisterReducer,
  getVinReducer: getVinReducer,
  editVehicleReducer: editVehicleReducer,
  registerGatewayReducer: registerGatewayReducer,
  getGatewayReducer: getGatewayReducer,
  registerSimReducer: registerSimReducer,
  editGatewayReducer: editGatewayReducer,
  getHvacCountReducer: getHvacCountReducer,
  getCompSerialNumberReducer: getCompSerialNumberReducer,
  getSuppliersReducer: getSuppliersReducer,
  addHvacCompReducer: addHvacCompReducer,
  getCompsReducer: getCompsReducer,
  deleteHvacCompReducer: deleteHvacCompReducer,
  editHvacCompReducer: editHvacCompReducer,
  getRolesReducer: getRolesReducer,
  getFleetsReducer: getFleetsReducer,
  getDepotsReducer: getDepotsReducer,
  getTemperatureReducer: getTemperatureReducer,
  getCompStatusReducer: getCompStatusReducer,
  getVehicleLocationReducer: getVehicleLocationReducer,
  getLastTimestampReducer: getLastTimestampReducer,
  getRegisteredHvacReducer: getRegisteredHvacReducer,
  getVehicleTagsReducer: getVehicleTagsReducer,
  //Depot Dashboard
  getTotalVehiclesReducer: getTotalVehiclesReducer,
  getDepotHvacByBrandReducer: getDepotHvacByBrandReducer,
  getHealthStatusSummaryReducer: getHealthStatusSummaryReducer,
  getVehiclesWithGatewayReducer: getVehiclesWithGatewayReducer,
  getDepotVehicleStatusReducer: getDepotVehicleStatusReducer,

  addVehicleTagsReducer: addVehicleTagsReducer,
  removeVehicleTagReducer: removeVehicleTagReducer,
  getVehicleAlarmsReducer: getVehicleAlarmsReducer,
  getCompanyTagsReducer: getCompanyTagsReducer,
  closeVehicleAlarmReducer: closeVehicleAlarmReducer,
  searchVinNumberReducer: searchVinNumberReducer,

  //Global Dashboard
  getTotalCompanyVehiclesReducer: getTotalCompanyVehiclesReducer,
  getGlobalHealthSummaryReducer: getGlobalHealthSummaryReducer,
  getGlobalHealthDetailReducer: getGlobalHealthDetailReducer,
  getGlobalDepotLocationReducer: getGlobalDepotLocationReducer,

  //Depot Dashboard

  //Health Status Dashboard
  getAlarmCountReducer: getAlarmCountReducer,

  getAssetsCountReducer: getAssetsCountReducer,
  getHealthStatusDetailReducer: getHealthStatusDetailReducer,
  getAlarmChecklistReducer: getAlarmChecklistReducer,
  closeAlarmReducer: closeAlarmReducer,
  getRegisteredCompanyReducer: getRegisteredCompanyReducer,
  getCompanyListReducer: getCompanyListReducer,
  addCompanyReducer: addCompanyReducer,
  getCompanyRelationReducer: getCompanyRelationReducer,
  registerCompanyRelationReducer: registerCompanyRelationReducer,
  updateCompanyReducer: updateCompanyReducer,
  registerFleetReducer: registerFleetReducer,
  getRegisteredFleetReducer: getRegisteredFleetReducer,
  updateFleetReducer: updateFleetReducer,
  getFleetListReducer: getFleetListReducer,
  getSysSettingsCountReducer: getSysSettingsCountReducer,
  getCompanySysSettingsCountReducer: getCompanySysSettingsCountReducer,
  getDepotListReducer: getDepotListReducer,
  getDepotListByCompanyReducer: getDepotListByCompanyReducer,
  registerDepotReducer: registerDepotReducer,
  getRegisteredDepotReducer: getRegisteredDepotReducer,
  updateDepotReducer: updateDepotReducer,
  getUserListReducer: getUserListReducer,
  getRegisteredUserReducer: getRegisteredUserReducer,
  updateUserReducer: updateUserReducer,
  registerUserReducer: registerUserReducer,
  getRoleListReducer: getRoleListReducer,
  //Manage Settings - Manage Notifications
  getDepotListNotiReducer,
  getDepotVehiclesReducer,
  getUsersListNotiReducer,
  getNotificationListReducer,
  getDepotNotificationTypesReducer,
  getSelectedUserVehiclesReducer,
  getSignalsReducer: getSignalsReducer,
  getDiagnosticsFileReducer: getDiagnosticsFileReducer,
  sendCanIntervalReducer: sendCanIntervalReducer,
  //Retrieve Data
  getCanDataListingReducer: getCanDataListingReducer,
  deleteCanDumpReducer: deleteCanDumpReducer,
  getMapFiltersReducer: getMapFiltersReducer,
  getMaintenanceMetaReducer: getMaintenanceMetaReducer,
  addMaintenanceMetaReducer: addMaintenanceMetaReducer,

  //Gateway Master
  registerCpuNumberReducer: registerCpuNumberReducer,
  getGatewayMasterListReducer: getGatewayMasterListReducer,

  //Sim Master
  registerSimMasterReducer: registerSimMasterReducer,
  getSimMasterListReducer: getSimMasterListReducer,

  getVehicleGatewayDetailsReducer: getVehicleGatewayDetailsReducer,
  downloadIndexErrorCodeReducer: downloadIndexErrorCodeReducer,
  getOpFleetsReducer: getOpFleetsReducer,
  getDepotTagsReducer: getDepotTagsReducer,
  //Gateway Diagnostics Reducers
  getGatewayDiagnosticsListReducer: getGatewayDiagnosticsListReducer,
  getGatewayDiagnosticsListingReducer: getGatewayDiagnosticsListingReducer,
  getGatewayDiagnosticsMonthReducer: getGatewayDiagnosticsMonthReducer,

  //OTA Update - Package Management Reducers
  getCompanyDataReducer: getCompanyDataReducer,
  getGroupListReducer: getGroupListReducer,
  uploadPackageFileCheckReducer: uploadPackageFileCheckReducer,
  createPackageReducer: createPackageReducer,
  getPackageListingReducer: getPackageListingReducer,

  //OTA Update - Package Management - Gateway Package Creation
  getBaseFirmwareVersionReducer,
  createGatewayPackageReducer,
  getGatewayConfigReducer,

  //OTA Update - Campaign Management Reducers
  getCampaignListingReducer: getCampaignListingReducer,
  getCampaignDeviceStatusListingReducer: getCampaignDeviceStatusListingReducer,
  getHvacListReducer: getHvacListReducer,
  createCampaignReducer: createCampaignReducer,
  getMetadataReducer,
  updateOtaRowReducer,
  getGatewayUnitsByBrandReducer,
  getGatewayListingReducer,
  getPackageCountReducer,
  getPackageListingKpiReducer,
  serviceModeEnableReducer: serviceModeEnableReducer,
  getServiceModeDeviceStatusReducer: getServiceModeDeviceStatusReducer,

  //OTA Update - Group Management Reducers
  getHvacModelsReducer,
  getGroupDeviceListByModalReducer,
  getGroupDeviceListByOthersReducer,
  createGroupReducer,
  updateGroupReducer,
  getGroupDetailsReducer,

  //OTA Update - Group Management - Gateway
  getGatewayDeviceListReducer,
  createGatewayGroupReducer,
  updateGatewayGroupReducer,

  //OTA Update - Package Management
  downloadPackageReducer,

  // initial depot reducers
  setInitialDepotFilterreducers,

  //User Profile
  getUserProfileReducer,
  updateUserProfileReducer,
});
/* eslint-disable no-underscore-dangle */
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
/* eslint-enable */
// console.log(store.getState());
export default store;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { dashColor } from '@/utils/palette';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import DotDotLoader from '@/shared/components/DotDotLoader';
import BasicTableData from './BasicTableData';
import moment from 'moment';

const { tableHeaderData } = BasicTableData();
const VehicleHvacCompStatus = ({ vin }) => {
  const { loading, info, error } = useSelector((state) => state.getVehicleGatewayDetailsReducer);
  const greyColor = 'A7AEB4';

  const { ambientLoading, returnLoading, ambientInfo, returnInfo, ambientError, returnError } =
    useSelector((state) => state.getTemperatureReducer);

  const convertTime = (minutes) => {
    var duration = moment.duration(minutes, 'minutes');
    var hours = Math.floor(duration.asHours());
    var remainingMinutes = duration.minutes();

    return hours + ' hours ' + remainingMinutes + ' minutes';
  };

  const timeDiff = () => {
    const utcTimestamps = ambientInfo
      ? Object.entries(ambientInfo.COMPONENT_ON_OFF)
          .map(([key, val]) => ({ key: val.display_name, val: val.__time }))
          .map((item) => item.val)
      : '';

    const moments = utcTimestamps.map((timestamp) => moment.utc(timestamp));

    const latestMoment = moment.max(moments);
    const latestTimestamp = latestMoment;
    const currentUTC = moment.utc(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
    const timeDifference = moment(currentUTC).diff(latestTimestamp, 'minutes');
    return convertTime(timeDifference);
  };

  return (
    <Card style={{ height: '483px' }}>
      <CardBody>
        <CardTitleWrap style={{ marginBottom: '12px' }}>
          <CardTitle>HVAC Component status</CardTitle>
          <CardSubhead>{ambientLoading ? '' : ambientInfo ? `${timeDiff()} old` : ''}</CardSubhead>
        </CardTitleWrap>
        <div style={{ overflowY: 'auto', height: '85%' }}>
          <Table striped responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            {ambientLoading ? (
              <CustomBody>
                <tr style={{ display: 'none' }}>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <DotDotLoader loadingState={ambientLoading} />
                  </td>
                </tr>
              </CustomBody>
            ) : (
              <tbody>
                {ambientInfo && info ? (
                  ambientInfo.COMPONENT_ON_OFF.Can0Active &&
                  ambientInfo.COMPONENT_ON_OFF.Can0Active.value ? (
                    Object.entries(ambientInfo.COMPONENT_ON_OFF)
                      .map(([key, val]) => ({ key: val.display_name, val: val.value }))
                      .map((item) => (
                        <tr key={`${item.key}-${item.val}`}>
                          <td>{item.key}</td>
                          {item.val > 0 && info[0].vehicle[0].color_code !== greyColor ? (
                            <td style={{ color: '#008B46' }}>{item.val > 0 && 'On'}</td>
                          ) : item.val < 1 && info[0].vehicle[0].color_code !== greyColor ? (
                            <td style={{ color: '#DC2323' }}>{item.val === 0 && 'OFF'}</td>
                          ) : item.val > 0 && info[0].vehicle[0].color_code === greyColor ? (
                            <td style={{ color: `#${greyColor}` }}>{item.val > 0 && 'On'}</td>
                          ) : item.val < 1 && info[0].vehicle[0].color_code === greyColor ? (
                            <td style={{ color: `#${greyColor}` }}>{item.val === 0 && 'OFF'}</td>
                          ) : (
                            ''
                          )}
                        </tr>
                      ))
                  ) : (
                    Object.entries(ambientInfo.COMPONENT_ON_OFF)
                      .map(([key, val]) => ({ key: val.display_name, val: val.value }))
                      .map((item) => (
                        <tr key={`${item.key}-${item.val}`}>
                          <td>{item.key}</td>
                          <td style={{ color: '#DC2323' }}>{'OFF'}</td>
                        </tr>
                      ))
                  )
                ) : (
                  <>
                    <tr style={{ display: 'none' }}>
                      <td></td>
                    </tr>
                    <CustomTr>
                      <td colSpan="2">
                        <ErrorSpan>{'No component status available'}</ErrorSpan>
                      </td>
                    </CustomTr>
                  </>
                )}
              </tbody>
            )}
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default VehicleHvacCompStatus;

const ErrorSpan = styled.span`
  color: ${(props) => dashColor(props)};
  text-align: center;
`;

const CustomBody = styled.tbody`
  height: 200px;
`;

const CustomTr = styled.tr`
  height: 200px;
  text-align: center;
`;

import axios from 'axios';
import {
  VIN_DETAILS_LOADING,
  VIN_DETAILS_SUCCESS,
  VIN_DETAILS_FAIL,
} from '@/shared/constants/VehicleDashboardConstants/getVehicleGatewayDetailsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '@/config/GlobalUrl';

const getVehicleGatewayDetails = (vin) => async (dispatch) => {
  try {
    dispatch({
      type: VIN_DETAILS_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_VEHICLE_DETAILS + vin + '/hvac_vin_gateway_details',
      getConfig()
    );

    dispatch({
      type: VIN_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: VIN_DETAILS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: VIN_DETAILS_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getVehicleGatewayDetails;

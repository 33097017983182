import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import UserListContainer from '../UserListContainer';
import resendPwd, {
  resendPwdReset,
} from '../../../../redux/actions/SignInActions/resendPwdActions';
import getUserList from '../../../../redux/actions/UserActions/getUserListActions';

const ViewUser = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const Ava = `${process.env.PUBLIC_URL}/img/Users.jpg`;

  const { loading, info, error } = useSelector((state) => state.getUserListReducer);
  const resendPwdReducerInfo = useSelector((state) => state.resendPwdReducer.info);
  const resendPwdReducerLoading = useSelector((state) => state.resendPwdReducer.loading);

  const [btnClicked, setBtnClicked] = useState(false);
  const [resendBtnClicked, setResendBtnClicked] = useState(false);
  const companyId = localStorage.getItem('companyId');

  const handleCancel = () => {
    setGoBack(true);
  };

  const handleResendPwd = (item) => {
    if (item && item.email && companyId) {
      dispatch(resendPwd(companyId, item.email));
      setBtnClicked(true);
      setResendBtnClicked(true);
    }
  };

  if (btnClicked && resendPwdReducerInfo) {
    setTimeout(() => {
      if (companyId) {
        dispatch(getUserList(companyId));
        dispatch(resendPwdReset());
        setBtnClicked(false);
        setResendBtnClicked(false);
      }
    }, 100);
  }

  useEffect(() => {
    return () => {
      dispatch(resendPwdReset());
      setResendBtnClicked(false);
    };
  }, []);

  return (
    <Card height='auto'>
      <CardBody>
        {loading || resendPwdReducerLoading ? (
          <DotDotLoader loadingState={loading || resendPwdReducerLoading} />
        ) : info && info.users.length > 0 ? (
          info.users.map((item) => (
            <UserListContainer
              img={Ava}
              userName={item.username}
              companyName={item.company_name}
              userEmail={item.email}
              userDesignation={item.designation}
              key={`${item.username}_${item.id}`}
              userRole={item.role_code}
              status={item.password_expired}
              onClick={() => handleResendPwd(item)}
              resendBtnClicked={resendBtnClicked}
            />
          ))
        ) : info && info.users.length === 0 ? (
          <UserListContainer error={'No user added'} />
        ) : (
          error && <UserListContainer error={'Error getting user data'} />
        )}
        <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
          <Button
            variant='secondary'
            type='button'
            onClick={() => handleCancel()}
            style={{ width: '100%' }}
          >
            Back
          </Button>
        </FormButtonToolbar>
      </CardBody>
    </Card>
  );
};

export default ViewUser;

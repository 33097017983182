import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colorText, colorHover } from '@/utils/palette';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getCampaignDeviceStatusListing, {
  getCampaignDeviceStatusListingReset,
} from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getCampaignDeviceStatusListingAction';
import SharedModal from '../../../SharedElements/SharedModal';
import { StyledPreTag } from '@/shared/components/form/FormElements';

const CampaignDeviceStatusListing = ({ reactTableData, reactTableDataDeviceStatus }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  useEffect(() => {
    dispatch(
      getCampaignDeviceStatusListing(
        reactTableData.deviceId.id,
        pageNumber,
        numberofRecords,
        reactTableDataDeviceStatus.sort
      )
    );
    // return () => {
    //   dispatch(getCampaignDeviceStatusListingReset());
    // };
  }, [reactTableData.deviceId.id, reactTableDataDeviceStatus.sort]);

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getCampaignDeviceStatusListing(
        reactTableData.deviceId.id,
        pageIndex + 1,
        pageSize,
        reactTableDataDeviceStatus.sort
      )
    );
  };

  const { loading, info, error, pagination } = useSelector(
    (state) => state.getCampaignDeviceStatusListingReducer
  );

  const handleClose = () => {
    reactTableDataDeviceStatus.setShowModal((prev) => !prev);
  };

  return (
    <Card>
      {reactTableDataDeviceStatus.showModal ? (
        <SharedModal show={reactTableDataDeviceStatus.showModal} handleClose={handleClose}>
          <div>
            <h4 style={{ paddingBottom: '20px' }}>Status Details:</h4>
            {Object.entries(reactTableDataDeviceStatus?.rowData?.last_reply_from_device).map(
              ([key, value]) => (
                <div key={key} style={{ textAlign: 'left' }}>
                  {key}: <strong>{JSON.stringify(value)}</strong>
                </div>
              )
            )}
          </div>
        </SharedModal>
      ) : (
        ''
      )}
      <CardBody>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardTitleWrap>
            <CardTitle>Campaign Device Status</CardTitle>
            <CardSubhead>{`${reactTableData.deviceId.name}, ${reactTableData.deviceId.software_version}, ${reactTableData.deviceId.status}, ${reactTableData.deviceId.scheduled_at}`}</CardSubhead>
          </CardTitleWrap>
          <BackBtnContainer>
            <BackBtn
              onClick={() => reactTableData.setGoToDetails(false)}
              style={{ paddingRight: '8px' }}
            >
              <ArrowBackIcon
                style={{
                  padding: '0',
                }}
              />
            </BackBtn>
            <BackBtn
              onClick={() =>
                dispatch(
                  getCampaignDeviceStatusListing(
                    reactTableData.deviceId.id,
                    pageNumber,
                    numberofRecords
                  )
                )
              }
            >
              <RefreshIcon
                style={{
                  padding: '0',
                }}
              />
            </BackBtn>
          </BackBtnContainer>
        </div>
        <ReactTableBase
          columns={reactTableDataDeviceStatus.tableHeaderData}
          data={info && info.device_status ? info.device_status : []}
          tableConfig={tableConfig}
          paginationDetail={
            pagination
              ? pagination
              : {
                  totalPages: 0,
                  totalRecords: 0,
                }
          }
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Diagnostics'}
        />

        {loading ? (
          ''
        ) : info && info.device_status ? (
          info.device_status.length === 0 ? (
            <TableNoData errorText={'No Data Found'} />
          ) : (
            ''
          )
        ) : (
          <TableNoData errorText={'No Data Found'} />
        )}
      </CardBody>
    </Card>
  );
};

export default CampaignDeviceStatusListing;

const BackBtnContainer = styled.div`
  color: ${colorText};
`;

const BackBtn = styled.span`
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
  }
`;

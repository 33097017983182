import axios from 'axios';
import {
  EDIT_HVAC_COMP_LOADING,
  EDIT_HVAC_COMP_SUCCESS,
  EDIT_HVAC_COMP_FAIL,
  RESET_ERROR,
} from '@/shared/constants/editHvacCompConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { manage_assets } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../config/GlobalUrl';

const editHvacComp =
  (hvac_id, oldComponentId, newComponentId, serialNumber) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_HVAC_COMP_LOADING,
      });

      const response = await axios.post(
        ApiURL.EDIT_HVAC_COMP + hvac_id + '/components/' + oldComponentId + '/update',
        {
          serial_num: serialNumber,
          supplier_detail: newComponentId,
        },
        getConfig()
      );

      successMessage(manage_assets.update_hvac);

      dispatch({
        type: EDIT_HVAC_COMP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.response === undefined) {
        errorFunction(error);
        dispatch({
          type: EDIT_HVAC_COMP_FAIL,
          payload: 'network error',
        });
      } else {
        errorFunction(error.response.data.data);
        dispatch({
          type: EDIT_HVAC_COMP_FAIL,
          payload: error.response,
        });
      }
      setTimeout(() => {
        dispatch({
          type: RESET_ERROR,
        });
      }, 5000);
    }
  };

export default editHvacComp;

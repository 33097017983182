import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_VEHICLE_ALARMS_LOADING,
  GET_VEHICLE_ALARMS_SUCCESS,
  GET_VEHICLE_ALARMS_FAIL,
} from '@/shared/constants/VehicleDashboardConstants/vehicleAlarmsConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { sortingFunc } from '@/utils/helpers.js';

const getVehicleAlarms =
  (vinNumber, pageNumber, numberofRecords, sortData, filter) => async (dispatch) => {
    try {
      dispatch({
        type: GET_VEHICLE_ALARMS_LOADING,
      });

      const response = await axios.get(
        ApiURL.GET_VEHICLE_ALARMS +
          vinNumber +
          `/alarms?sort=${sortingFunc(sortData)}&p=${pageNumber}&page_size=${numberofRecords}${
            filter ? '&filter-' + filter.split('-')[0] + '=' + filter.split('-')[1] : '&filter-status=open'
          }`,
        getConfig()
      );

      dispatch({
        type: GET_VEHICLE_ALARMS_SUCCESS,
        payload: { alarms: response.data.data.alarms, pagination: response.data.pagination },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_VEHICLE_ALARMS_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_VEHICLE_ALARMS_FAIL,
          payload: error.response,
        });
      }
    }
  };

export default getVehicleAlarms;

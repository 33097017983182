import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import { getCpuIdMasterList } from '../../../../redux/actions/GatewayMasterActions/getGatewayMasterList';

const GatewayFilter = ({ setCpuId, cpuId }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e) {
      setCpuId(e.value);
    }
    if (!e) {
      setCpuId('');
    }
  };

  useEffect(() => {
    dispatch(getCpuIdMasterList());
  }, []);

  const { loading, info, pagination, error, info_list, error_list } = useSelector(
    (state) => state.getGatewayMasterListReducer
  );

  return (
    <div style={{ width: '200px' }}>
      <Form onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <FormContainer onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
              <FormGroupField>
                <Field
                  name="gateway_filter"
                  component={renderSelectField}
                  type="text"
                  placeholder="Please select a cpu id"
                  options={info_list ? info_list : []}
                  parse={(e) => {
                    handleChange(e);
                    return e;
                  }}
                  isClearable
                />
              </FormGroupField>
            </FormGroup>
          </FormContainer>
        )}
      </Form>
    </div>
  );
};

export default GatewayFilter;

import axios from 'axios';
import {
  GATEWAY_LOADING,
  GATEWAY_SUCCESS,
  GATEWAY_FAIL,
  RESTORE_INITIAL_VALUES,
  RESET_ERROR,
} from '../../shared/constants/gatewayConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { manage_assets } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../config/GlobalUrl';

export const gatewaySerialNumber = (gatewayNumber, vinNumber) => async (dispatch) => {
  try {
    dispatch({
      type: GATEWAY_LOADING,
    });

    const response = await axios.post(
      ApiURL.GATEWAY_REGISTER,
      {
        vin: vinNumber,
        serial_number: gatewayNumber,
      },
      getConfig()
    );
    successMessage(manage_assets.register_gateway);

    dispatch({
      type: GATEWAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: GATEWAY_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: GATEWAY_FAIL,
        //   payload: error.response.data.data.message,
        payload: error.response.data.data.extra
          ? error.response.data.data.extra.fields[0]
          : error.response.data.data.message,
      });
    }
    setTimeout(() => {
      dispatch({
        type: RESET_ERROR,
      });
    }, 5000);
  }
};

export const resetValues = () => (dispatch) => {
  dispatch({
    type: RESTORE_INITIAL_VALUES,
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import PasswordField from '@/shared/components/form/Password';
import { AccountButton } from '../AccountElements';
import { resetPwdValidate } from '../../../../utils/validate';

const ResetPasswordForm = ({ onSubmit }) => {
  return (
    <Form onSubmit={onSubmit} validate={resetPwdValidate}>
      {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>Enter New Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="new_password"
                component={PasswordField}
                type="password"
                placeholder="Enter New Password"
                className="input-without-border-radius"
                keyIcon
                required
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Confirm New Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="new_password_confirm"
                component={PasswordField}
                type="password"
                placeholder="Confirm New Password"
                className="input-without-border-radius"
                keyIcon
                required
              />
            </FormGroupField>
          </FormGroup>
          <AccountButton
            variant="primary"
            type="submit"
            disabled={pristine || submitting || hasValidationErrors}
          >
            Reset Password
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default ResetPasswordForm;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { registerBusinessRelationValidate } from '@/utils/validate.js';
import registerCompanyRelation, {
  registerCompanyRelationReset,
} from '../../../../redux/actions/CompanyActions/registerCompanyRelationActions';
import getCompanyRelation from '@/redux/actions/CompanyActions/getCompanyRelationActions';
import { addCompanyReset } from '@/redux/actions/CompanyActions/addCompanyActions';

const BusinessNetworkForm = ({ setShowBusinessNetworkForm, mode, viewMode, disableContent }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [btnClicked, setBtnClicked] = useState(false);
  const [skipForm, setSkipForm] = useState(false);
  const companyBaseIdParsed = JSON.parse(localStorage.getItem('companyId'));

  const getCompanyRelationReducerLoading = useSelector(
    (state) => state.getCompanyRelationReducer.loading
  );
  const getCompanyRelationReducerInfo = useSelector(
    (state) => state.getCompanyRelationReducer.info
  );
  const registerCompanyRelationReducerLoading = useSelector(
    (state) => state.registerCompanyRelationReducer.loading
  );
  const registerCompanyRelationReducerInfo = useSelector(
    (state) => state.registerCompanyRelationReducer.info
  );
  const getRolesReducerInfo = useSelector((state) => state.getRolesReducer.info);
  const getRolesReducerLoading = useSelector((state) => state.getRolesReducer.loading);
  const getRegisteredCompanyReducerInfo = useSelector(
    (state) => state.getRegisteredCompanyReducer.info
  );
  const getRegisteredCompanyReducerLoading = useSelector(
    (state) => state.getRegisteredCompanyReducer.loading
  );

  const addCompanyReducerInfo = useSelector((state) => state.addCompanyReducer.info);

  const handleSubmit = (values) => {
    if (Object.keys(values).length !== 0) {
      var allId = [];

      const ids = Object.values(values).flatMap((item) =>
        item
          ? item.filter((newItem) => newItem.id !== companyBaseIdParsed).map(({ id }) => id)
          : null
      );
      allId.push(...ids);
      dispatch(registerCompanyRelation(allId.length === 0 ? [] : allId, companyBaseIdParsed));
      setBtnClicked(true);
    }
  };

  if (skipForm === true) {
    history.replace('/settings/company-settings/select/manage');
  }

  useEffect(() => {
    return () => {
      setBtnClicked(false);
    };
  }, []);

  const handleCancel = () => {
    setShowBusinessNetworkForm(false);
  };

  if (btnClicked === true && registerCompanyRelationReducerInfo === '') {
    setTimeout(() => {
      dispatch(registerCompanyRelationReset());
      if (!viewMode) {
        history.push('/settings/company-settings/select/manage');
      }
      if (viewMode) {
        handleCancel();
      }
      setBtnClicked(false);
    }, 1000);
  }

  const ptaVals =
    getRolesReducerInfo && getRegisteredCompanyReducerInfo
      ? getRolesReducerInfo.associations
          .filter((newItem) => newItem.role === 'pta')
          .flatMap(({ companies }) =>
            companies.flatMap(({ name, id }) => [{ id: id, value: name, label: name }])
          )
      : '';

  const ptoVals =
    getRolesReducerInfo && getRegisteredCompanyReducerInfo
      ? getRolesReducerInfo.associations
          .filter((newItem) => newItem.role === 'operator')
          .flatMap(({ companies }) =>
            companies.flatMap(({ name, id }) => [{ id: id, value: name, label: name }])
          )
      : '';

  const serviceProviderVals =
    getRolesReducerInfo && getRegisteredCompanyReducerInfo
      ? getRolesReducerInfo.associations
          .filter((newItem) => newItem.role === 'service_provider')
          .flatMap(({ companies }) =>
            companies.flatMap(({ name, id }) => [{ id: id, value: name, label: name }])
          )
      : '';

  const ptaVals2 =
    getCompanyRelationReducerInfo && addCompanyReducerInfo
      ? getCompanyRelationReducerInfo.pta
          ?.filter((newItem) => newItem.id === Number(addCompanyReducerInfo.company_id))
          .map((item) => ({ id: item.id, value: item.name, label: item.name }))
      : '';

  const ptoVals2 =
    getCompanyRelationReducerInfo && addCompanyReducerInfo
      ? getCompanyRelationReducerInfo.operator
          ?.filter((newItem) => newItem.id === Number(addCompanyReducerInfo.company_id))
          .map((item) => ({ id: item.id, value: item.name, label: item.name }))
      : '';

  const serviceProviderVals2 =
    getCompanyRelationReducerInfo && addCompanyReducerInfo
      ? getCompanyRelationReducerInfo.service_provider
          ?.filter((newItem) => newItem.id === Number(addCompanyReducerInfo.company_id))
          .map((item) => ({ id: item.id, value: item.name, label: item.name }))
      : '';

  useEffect(() => {
    dispatch(getCompanyRelation());
    return () => {
      dispatch(registerCompanyRelationReset());
      dispatch(addCompanyReset());
    };
  }, []);

  return (
    <Card height="auto">
      <CardBody>
        {getCompanyRelationReducerLoading ||
        getRolesReducerLoading ||
        getRegisteredCompanyReducerLoading ? (
          <DotDotLoader
            loadingState={
              getCompanyRelationReducerLoading ||
              getRolesReducerLoading ||
              getRegisteredCompanyReducerLoading
            }
          />
        ) : (
          <Form onSubmit={handleSubmit} validate={registerBusinessRelationValidate}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                {registerCompanyRelationReducerLoading ? (
                  <DotDotLoader loadingState={registerCompanyRelationReducerLoading} />
                ) : (
                  ''
                )}
                <FormGroup>
                  <FormGroupLabel>Transport Authority</FormGroupLabel>
                  <Field
                    name="pta"
                    component={renderSelectField}
                    isMulti
                    options={
                      getCompanyRelationReducerInfo
                        ? getCompanyRelationReducerInfo.pta?.map(({ id, name }) => ({
                            id: id,
                            value: name,
                            label: name,
                          }))
                        : []
                    }
                    initialValue={
                      viewMode && ptaVals.length > 0
                        ? ptaVals
                        : mode && addCompanyReducerInfo
                        ? ptaVals2
                        : ''
                    }
                    isDisabled={
                      (!mode &&
                        ptaVals.length > 0 &&
                        ptaVals[0] &&
                        ptaVals[0].id === getRegisteredCompanyReducerInfo.company.id) ||
                      (mode && ptaVals2.length > 0) ||
                      disableContent
                        ? true
                        : false
                    }
                    placeholder={'Select Transport Authority'}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Fleet Operator</FormGroupLabel>
                  <Field
                    name="pto"
                    component={renderSelectField}
                    isMulti
                    options={
                      getCompanyRelationReducerInfo
                        ? getCompanyRelationReducerInfo.operator?.map(({ id, name }) => ({
                            id: id,
                            value: name,
                            label: name,
                          }))
                        : []
                    }
                    initialValue={
                      viewMode && ptoVals.length > 0
                        ? ptoVals
                        : mode && addCompanyReducerInfo
                        ? ptoVals2
                        : ''
                    }
                    isDisabled={
                      (!mode &&
                        ptoVals.length > 0 &&
                        ptoVals[0] &&
                        ptoVals[0].id === getRegisteredCompanyReducerInfo.company.id) ||
                      (mode && ptoVals2.length > 0) ||
                      disableContent
                        ? true
                        : false
                    }
                    placeholder={'Select Fleet Operator'}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>HVAC Service Partner</FormGroupLabel>
                  <Field
                    name="serviceprovider"
                    component={renderSelectField}
                    isMulti
                    options={
                      getCompanyRelationReducerInfo
                        ? getCompanyRelationReducerInfo.service_provider?.map(({ id, name }) => ({
                            id: id,
                            value: name,
                            label: name,
                          }))
                        : []
                    }
                    initialValue={
                      viewMode && serviceProviderVals.length > 0
                        ? serviceProviderVals
                        : mode && addCompanyReducerInfo
                        ? serviceProviderVals2
                        : ''
                    }
                    isDisabled={
                      (!mode &&
                        serviceProviderVals.length > 0 &&
                        serviceProviderVals[0] &&
                        serviceProviderVals[0].id === getRegisteredCompanyReducerInfo.company.id) ||
                      (mode && serviceProviderVals2.length > 0) ||
                      disableContent
                        ? true
                        : false
                    }
                    placeholder={'Select HVAC Service Partner'}
                    menmenuIsOpen={true}
                  />
                </FormGroup>
                {mode === false ? (
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    {!disableContent && (
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    )}
                  </FormButtonToolbar>
                ) : (
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => setSkipForm(true)}>
                      Skip
                    </Button>
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </FormButtonToolbar>
                )}
              </FormContainer>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
BusinessNetworkForm.propTypes = {
  mode: PropTypes.bool,
};

BusinessNetworkForm.defaultProps = {
  mode: false,
};
export default BusinessNetworkForm;

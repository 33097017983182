import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import DepotHealthStatusSummary from './components/DepotHealthStatusSummary';
import DepotHvacByBrand from './components/DepotHvacByBrand';
import DepotHvacByType from './components/DepotHvacByType';
import VehiclesWithGateway from './components/VehiclesWithGateway';
import DepotVehicleStatus from './components/DepotVehicleStatus';
import CreateTableData from './components/CreateData';
import VinSearch from '../VinSearch';
import DepotVehicleCount from './components/DepotVehicleCount';

const DepotDashboard = () => {
  const reactTableData = CreateTableData();

  const dashboardDetails = JSON.parse(localStorage.getItem('dashboardDetails'));
  const fleetId = dashboardDetails ? dashboardDetails.fleet : '';
  const depotId = dashboardDetails ? dashboardDetails.depot : '';
  const fleetName = dashboardDetails ? dashboardDetails.fleetName : '';
  const depotName = dashboardDetails ? dashboardDetails.depotName : '';

  const [localValuesUpdated, setLocalValuesUpdated] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const compId = userDetails.company_id;

  return (
    <Container>
      <DashboardDetails>
        <DashboardDetailsText>
          {`Fleet: ${fleetName ? fleetName : ''}, Depot: ${depotName ? depotName : ''}`}
        </DashboardDetailsText>
      </DashboardDetails>
      <Row
        className={'d-flex justify-content-end'}
        style={{ marginTop: '-66px', paddingBottom: '30px' }}
      >
        <VinSearch />
      </Row>
      <Row>
        <DepotVehicleCount
          fleet_id={fleetId}
          depot_id={localValuesUpdated ? depotId : depotId}
          changeComp={localValuesUpdated}
          setChangeComp={setLocalValuesUpdated}
          companyId={compId}
          tag={selectedTag}
        />
      </Row>
      <Row>
        <Col lg={6} xl={3} md={12} className="d-flex align-items-stretch">
          <DepotHealthStatusSummary
            fleet_id={fleetId}
            depot_id={localValuesUpdated ? depotId : depotId}
            changeComp={localValuesUpdated}
            setChangeComp={setLocalValuesUpdated}
            companyId={compId}
            tag={selectedTag}
          />
        </Col>
        <Col lg={6} xl={3} md={12} className="d-flex align-items-stretch">
          <DepotHvacByBrand
            fleet_id={fleetId}
            depot_id={localValuesUpdated ? depotId : depotId}
            changeComp={localValuesUpdated}
            setChangeComp={setLocalValuesUpdated}
            companyId={compId}
            tag={selectedTag}
          />
        </Col>
        <Col lg={6} xl={3} md={12} className="d-flex align-items-stretch">
          <DepotHvacByType
            fleet_id={fleetId}
            depot_id={localValuesUpdated ? depotId : depotId}
            changeComp={localValuesUpdated}
            setChangeComp={setLocalValuesUpdated}
          />
        </Col>
        <Col lg={6} xl={3} md={12} className="d-flex align-items-stretch">
          <VehiclesWithGateway
            fleet_id={fleetId}
            depot_id={localValuesUpdated ? depotId : depotId}
            changeComp={localValuesUpdated}
            setChangeComp={setLocalValuesUpdated}
            companyId={compId}
            tag={selectedTag}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <DepotVehicleStatus
            reactTableData={reactTableData}
            fleet_id={fleetId}
            depot_id={depotId}
            changeComp={localValuesUpdated}
            setChangeComp={setLocalValuesUpdated}
            companyId={compId}
            tag={selectedTag}
            setSelectedTag={setSelectedTag}
            showDepot={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DepotDashboard;

const DashboardDetails = styled.div`
  padding: 5px 0 0 3px;
`;

const DashboardDetailsText = styled.p``;

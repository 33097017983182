import { ApiURL } from '../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_DEPOT_LOADING,
  GET_DEPOT_SUCCESS,
  GET_DEPOT_FAIL,
  GET_DEPOT_RESET,
} from '../../shared/constants/getAssociationsConstants';

const getDepots = (companyId, fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(ApiURL.GET_DEPOT + companyId + '/fleets/' + fleetId, config);

    dispatch({
      type: GET_DEPOT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const getDepotsReset = () => async (dispatch) => {
  dispatch({ type: GET_DEPOT_RESET });
};

export default getDepots;

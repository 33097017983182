import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Field, Form } from 'react-final-form';
import AccountIcon from 'mdi-react/AccountIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import AccountCogIcon from 'mdi-react/AccountCogIcon';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { registerUserValidate } from '@/utils/validate.js';
import registerUser, {
  registerUserReset,
} from '../../../../redux/actions/UserActions/registerUserAction';
import updateUser, {
  updateUserReset,
} from '../../../../redux/actions/UserActions/updateUserAction';
import getRegisteredUser from '../../../../redux/actions/UserActions/getRegisteredUserActions';
import getRoleList from '../../../../redux/actions/RolesActions/getRoleListActions';

const UserForm = ({ setGoBack, editMode, setShowUserEditForm, userId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [userUpdateBtnClick, setUserUpdateBtnClick] = useState(false);

  const registerUserReducerLoading = useSelector((state) => state.registerUserReducer.loading);
  const registerUserReducerInfo = useSelector((state) => state.registerUserReducer.info);
  const registerUserReducerError = useSelector((state) => state.registerUserReducer.error);
  const getRegisteredUserReducerLoading = useSelector(
    (state) => state.getRegisteredUserReducer.loading
  );
  const getRegisteredUserReducerInfo = useSelector((state) => state.getRegisteredUserReducer.info);
  const getRegisteredUserReducerError = useSelector(
    (state) => state.getRegisteredUserReducer.error
  );
  const getRoleListReducerInfo = useSelector((state) => state.getRoleListReducer.info);
  const updateUserReducerLoading = useSelector((state) => state.updateUserReducer.loading);
  const updateUserReducerInfo = useSelector((state) => state.updateUserReducer.info);
  const updateUserReducerError = useSelector((state) => state.updateUserReducer.error);

  const companyId = localStorage.getItem('companyId');

  useEffect(() => {
    if (companyId && userId) {
      dispatch(getRegisteredUser(companyId, userId));
    }
    dispatch(getRoleList('USER'));
    return () => {
      setBtnClicked(false);
      setUserUpdateBtnClick(false);
    };
  }, [userId]);

  const handleRegisterUser = (values) => {
    if (Object.keys(values).length !== 0) {
      dispatch(registerUser(companyId, values));
      setBtnClicked(true);
    }
  };

  if (btnClicked === true && registerUserReducerInfo !== '') {
    setTimeout(() => {
      handleCancel();
      dispatch(registerUserReset());
      setBtnClicked(false);
    }, 1000);
  }

  const handleUpdateUser = (values) => {
    if (Object.keys(values).length !== 0 && companyId && userId) {
      dispatch(updateUser(companyId, userId, values));
      setUserUpdateBtnClick(true);
    }
  };

  if (userUpdateBtnClick === true && updateUserReducerInfo !== '') {
    setTimeout(() => {
      setShowUserEditForm(false);
      dispatch(updateUserReset());
      setUserUpdateBtnClick(false);
    }, 1000);
  }

  const handleCancel = () => {
    setGoBack(true);
  };

  const dropdownValue = [
    {
      // id:
      // getRegisteredUserReducerInfo &&
      // getRegisteredUserReducerInfo.user_detail.role_code, // Need role_code id
      value: getRegisteredUserReducerInfo && getRegisteredUserReducerInfo.user_detail.role_code,
      label: getRegisteredUserReducerInfo && getRegisteredUserReducerInfo.user_detail.role_code,
    },
  ];

  return (
    <Card height="auto">
      <CardBody>
        {getRegisteredUserReducerLoading ? (
          <DotDotLoader loadingState={getRegisteredUserReducerLoading} />
        ) : (
          <Form
            onSubmit={editMode === false ? handleRegisterUser : handleUpdateUser}
            initialValues={
              editMode && getRegisteredUserReducerInfo !== ''
                ? getRegisteredUserReducerInfo.user_detail
                : ''
            }
            validate={registerUserValidate}
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                {registerUserReducerLoading || updateUserReducerLoading ? (
                  <DotDotLoader
                    loadingState={registerUserReducerLoading || updateUserReducerLoading}
                  />
                ) : (
                  ''
                )}
                <FormGroup>
                  <FormGroupLabel>User Name</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <AccountIcon />
                    </FormGroupIcon>
                    <Field
                      name="username"
                      component={FormField}
                      type="text"
                      placeholder="User Name"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Email Address</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <EmailIcon />
                    </FormGroupIcon>
                    <Field
                      name="email"
                      component={FormField}
                      type="email"
                      placeholder="Email Address"
                      disabled={editMode && true}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Mobile Number</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <CellphoneIcon />
                    </FormGroupIcon>
                    <Field
                      name="contact"
                      component={FormField}
                      type="text"
                      placeholder="Mobile Number"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Designation</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <TextBoxIcon />
                    </FormGroupIcon>
                    <Field
                      name="designation"
                      component={FormField}
                      type="text"
                      placeholder="Designation"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Role</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <AccountCogIcon />
                    </FormGroupIcon>
                    <Field
                      name="role"
                      component={renderSelectField}
                      type="text"
                      options={
                        getRoleListReducerInfo
                          ? getRoleListReducerInfo.roles.map(({ role_code, description }) => ({
                              value: role_code,
                              label: description,
                            }))
                          : []
                      }
                      placeholder="Select role"
                      initialValue={editMode && dropdownValue ? dropdownValue : ''}
                    />
                  </FormGroupField>
                </FormGroup>
                {editMode === false ? (
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Register
                    </Button>
                  </FormButtonToolbar>
                ) : (
                  <FormButtonToolbar>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => setShowUserEditForm(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </FormButtonToolbar>
                )}
              </FormContainer>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default UserForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ManageAssetsContainer from './ManageAssetsContainer';
import getAssetsCount from '../../../../redux/actions/getAssetsCountAction';
import { dataObject } from '../../../App/Router/data';

const Ava1 = `${process.env.PUBLIC_URL}/img/Vehicle.jpg`;
const Ava2 = `${process.env.PUBLIC_URL}/img/HVACs.jpg`;
const Ava3 = `${process.env.PUBLIC_URL}/img/Gateways.jpg`;

const ManageAssetsData = () => {
  const { viewRole } = dataObject;

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  const dispatch = useDispatch();

  const getAssetsCountReducer = useSelector((state) => state.getAssetsCountReducer.info);

  const getAssetTypes = (assetType) => {
    localStorage.setItem('asset', assetType);
  };

  useEffect(() => {
    dispatch(getAssetsCount());
  }, []);

  const data = [
    {
      id: 1,
      image: Ava1,
      assetType: 'Vehicles',
      link: '/app/select_vehicles',
      count: getAssetsCountReducer ? getAssetsCountReducer.assest_count[0].vehicle_count : '-',
    },
    {
      id: 2,
      image: Ava2,
      assetType: 'HVACs',
      link: '/app/select_vehicles',
      count: getAssetsCountReducer ? getAssetsCountReducer.assest_count[0].hvac_count : '-',
    },
    {
      id: 3,
      image: Ava3,
      assetType: 'Gateways',
      link: '/app/select_vehicles',
      count: getAssetsCountReducer ? getAssetsCountReducer.assest_count[0].gateway_count : '-',
    },
    {
      id: 4,
      image: Ava3,
      assetType: 'Maintenance Set Up',
      link: '/app/select_vehicles',
      count: getAssetsCountReducer
        ? getAssetsCountReducer.assest_count[0].maintanence_setup_count
        : '-',
    },
  ];

  return (
    <Col md={12} lg={12} sm={12}>
      <Card height="auto">
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Asset Types</CardTitle>
          </CardTitleWrap>
          {data
            .filter((item) => (!viewRole.includes(userRole) ? item : item.id !== 4 && item))
            .map(({ id, image, assetType, link, count }) => (
              <div
                onClick={() => {
                  getAssetTypes(assetType);
                }}
                key={id}
              >
                <ManageAssetsContainer
                  key={id}
                  img={image}
                  assettype={assetType}
                  link={link}
                  totalnumber={count}
                />
              </div>
            ))}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ManageAssetsData;

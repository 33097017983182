import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import PreventiveForm from './PreventiveForm';
import MaintenanceTypesContainer from '../MaintenanceTypesContainer';
import RegisteredVehicleDetailsImage from '../../Hvac/components/RegisteredVehicleDetailsImage';

const MaintenanceTypes = () => {
  const Ava1 = `${process.env.PUBLIC_URL}/img/Companies.jpg`;

  const [changeComp, setChangeComp] = useState(false);
  const [asset, setAsset] = useState('');

  const data = [
    {
      id: 1,
      img: Ava1,
      maintenanceType: 'Preventive Maintenance',
      totalnumber: 0,
    },
    // {
    //   id: 2,
    //   img: Ava1,
    //   maintenanceType: 'Condition Based Maintenance',
    //   totalnumber: 0,
    // },
    // {
    //   id: 3,
    //   img: Ava1,
    //   maintenanceType: 'Data Monitoring Alerts ',
    //   totalnumber: 0,
    // },
    // {
    //   id: 4,
    //   img: Ava1,
    //   maintenanceType: 'Connectivity Alerts',
    //   totalnumber: 0,
    // },
  ];

  const assets = [
    'Preventive Maintenance',
    'Condition Based Maintenance',
    'Data Monitoring Alerts',
    'Connectivity Alerts',
  ];

  const handleChangeComp = (maintenanceType) => {
    if (maintenanceType === 'Preventive Maintenance') {
      setAsset(maintenanceType);
      setChangeComp(true);
    }
  };

  return (
    <Container>
      <Row>
        <RegisteredVehicleDetailsImage />
      </Row>
      <Row>
        {!changeComp && asset === '' ? (
          <Col sm={12} md={6} lg={6}>
            <Card height="auto">
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Maintenance types</CardTitle>
                </CardTitleWrap>
                {data.map(({ id, img, maintenanceType, totalnumber }) => (
                  <div
                    key={id}
                    onClick={() => {
                      handleChangeComp(maintenanceType);
                    }}
                  >
                    <MaintenanceTypesContainer
                      key={maintenanceType}
                      img={img}
                      assettype={maintenanceType}
                      // totalnumber={totalnumber}
                    />
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        ) : changeComp && asset === assets[0] ? (
          <Col sm={12} md={6} lg={6}>
            <Card height="auto">
              <CardBody>
                {/* <CardTitleWrap>
                  <CardTitle>Maintenance types</CardTitle>
                </CardTitleWrap> */}
                <PreventiveForm setChangeComp={setChangeComp} setAsset={setAsset} />
              </CardBody>
            </Card>
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Container>
  );
};

export default MaintenanceTypes;

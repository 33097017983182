import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GATEWAY_DEVICE_LIST_LOADING,
  GET_GATEWAY_DEVICE_LIST_SUCCESS,
  GET_GATEWAY_DEVICE_LIST_FAIL,
  GET_GATEWAY_DEVICE_LIST_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getGatewayDeviceList = (depotId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GATEWAY_DEVICE_LIST_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_GATEWAY_DEVICE_LIST + `?depot_id=${depotId}`,
      getConfig()
    );

    dispatch({
      type: GET_GATEWAY_DEVICE_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GATEWAY_DEVICE_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GATEWAY_DEVICE_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGatewayDeviceListReset = () => async (dispatch) => {
  dispatch({
    type: GET_GATEWAY_DEVICE_LIST_RESET,
  });
};

export default getGatewayDeviceList;

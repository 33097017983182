const BasicTableData = () => {
  const header = [
    { id: 1, title: 'Choose Status' },
    { id: 2, title: 'Task#' },
    { id: 3, title: 'Sub component type' },
    { id: 4, title: 'Maintenance check point' },
    { id: 5, title: 'Job sheet' },
    { id: 6, title: 'Remarks' },
    { id: 7, title: 'Comments' },
  ];

  const headerResponsive = [
    { id: 1, title: 'Choose Status' },
    { id: 2, title: 'Task#' },
    { id: 3, title: 'Sub component type' },
    { id: 4, title: 'Maintenance check point' },
    { id: 5, title: 'Job sheet' },
    { id: 6, title: 'Remarks' },
    { id: 7, title: 'Comments' },
  ];

  const conditionalHeader = [
    { id: 1, title: 'Choose Status' },
    { id: 2, title: 'Task#' },
    { id: 3, title: 'Condition' },
    { id: 4, title: 'Trigger Value' },
    { id: 5, title: 'Possible Reasons' },
    { id: 6, title: 'Comments' },
  ];

  const conditionalHeaderResponsive = [
    { id: 1, title: 'Choose Status' },
    { id: 2, title: 'Task#' },
    { id: 3, title: 'Condition' },
    { id: 4, title: 'Trigger Value' },
    { id: 5, title: 'Possible Reasons' },
    { id: 6, title: 'Comments' },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
    tableHeaderDataConditional: conditionalHeader,
    tableHeaderResponsiveDataConditional: conditionalHeaderResponsive,
  };
  return basicTableData;
};

export default BasicTableData;

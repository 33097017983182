import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Path from '@/utils/routes/routePathNames';
import Layout from '../../../Layout/index';
import AppDashboard from '../../../Dashboards/App';
import HvacDashboard from '../../../Dashboards/Hvac';
import SelectVehicles from '../../../Dashboards/SelectVehicles';
import ManageAssetsData from '../../../Dashboards/ManageAssets/components/ManageAssetsData';
import VehicleRegisteration from '../../../Dashboards/VehicleRegisteration';
import Gateway from '../../../Dashboards/Gateway';
import GatewayRegistered from '../../../Dashboards/Gateway/components/GatewayRegistered';
import HvacDevices from '../../../Dashboards/HvacDevices';
import VehicleDashboard from '../../../Dashboards/VehicleDashboard';
import DepotDashboard from '../../../Dashboards/DepotDashboard';
import GlobalDashboard from '../../../Dashboards/GlobalDashboard';
import Breadcrumbs from './Breadcrumbs';
import HealthStatus from '../../../Dashboards/HealthStatus';
import AutoLogout from './AutoLogout';
import ManageSettings from '../../../Dashboards/ManageCompany';
import ManageCompany from '../../../Dashboards/ManageCompany/components/ManageCompany';
import CompanySelect from '../../../Dashboards/ManageCompany/components/CompanySelect';
import ManageCompanySettings from '../../../Dashboards/ManageCompany/components/ManageCompanySettings';
import ManageFleet from '../../../Dashboards/ManageFleet';
import ManageDepot from '../../../Dashboards/ManageDepot';
import ManageUser from '../../../Dashboards/ManageUser';
import ManageRole from '../../../Dashboards/ManageRole';
import NotFound404 from '../../../DefaultPage/404';
import DiagnosticsOne from '../../../Dashboards/DiagnosticsDashboard';
import D2 from '../../../Dashboards/DiagnosticsDashboard/components/D2';
import data from '../data';
import ErrorFallback from '../../ErrorFallback';
import MaintenanceSetup from '../../../Dashboards/MaintenanceSetup';
import GatewayMaster from '../../../Dashboards/GatewayMaster';
import SimMaster from '../../../Dashboards/SimMaster';
import { companyNameCheck } from '../../../../shared/helpers/storage';
import GatewayDiagnostics from '../../../Dashboards/GatewayDiagnostics';
import PackageManagement from '../../../Dashboards/OtaUpdate/PackageManagement';
import CampaignManagement from '../../../Dashboards/OtaUpdate/CampaignManagement';
import GroupDashboard from '../../../Dashboards/OtaUpdate/GroupDashboard';
import ManageNotifications from '../../../Dashboards/ManageNotifications';
import NotificationListing from '../../../Dashboards/ManageNotifications/components/NotificationListing';
import Profile from '../../../../containers/Account/Profile';
import newfour from '../../../DefaultPage/404/newfour';

export default () => {
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyRoleOfUser = userDetails ? userDetails.company_role_name : '';
  const companyName = userDetails ? userDetails.company_name : '';
  const pathforCompanySettings = companyRole.includes(companyRoleOfUser)
    ? '/company'
    : '/settings/company-settings/select/manage';

  const routes = [
    {
      path: ['/app'],
      name: 'Manage Assets',
      Component: AppDashboard,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/wform'],
      name: 'Register Vehicle',
      Component: VehicleRegisteration,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/manage_assets'],
      name: 'Manage Assets Data',
      Component: ManageAssetsData,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/HvacDevices'],
      name: 'Hvac Dashboard',
      Component: HvacDevices,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/app/hvac_dashboard'],
      name: 'Hvac Dashboard',
      Component: HvacDashboard,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/app/select_vehicles'],
      name: 'HVAC',
      Component: SelectVehicles,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },

    {
      path: ['/maintenance'],
      name: 'Maintenance',
      Component: MaintenanceSetup,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/gateway'],
      name: 'Edit Gateway',
      Component: Gateway,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/gateway/list'],
      name: 'Edit Gateway',
      Component: GatewayRegistered,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/cpu'],
      name: '',
      Component: GatewayMaster,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: ['/sim'],
      name: '',
      Component: SimMaster,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: ['/global'],
      name: 'Global',
      Component: GlobalDashboard,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/global/depot'],
      name: 'Depot',
      Component: DepotDashboard,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/global/depot/vehicle'],
      name: 'Vehicle',
      Component: VehicleDashboard,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/healthstatus'],
      name: 'Health Status',
      Component: HealthStatus,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: ['/settings'],
      name: 'System Settings',
      Component: ManageSettings,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: ['/settings/company-settings'],
      name: 'Company',
      Component: ManageCompany,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: ['/settings/company-settings/select'],
      name: 'Select Company',
      Component: CompanySelect,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: [pathforCompanySettings],
      name: 'Manage',
      Component: ManageCompanySettings,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/settings/company-settings/select/manage/fleets'],
      name: 'Fleets',
      Component: ManageFleet,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/settings/company-settings/select/manage/depots'],
      name: 'Depots',
      Component: ManageDepot,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/settings/company-settings/select/manage/users'],
      name: 'Users',
      Component: ManageUser,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/settings/company-settings/select/manage/roles'],
      name: 'Roles',
      Component: ManageRole,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: [Path.NOTIFICATION],
      name: 'Manage Notifications',
      Component: NotificationListing,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: [Path.NOTIFICATION_FORM],
      name: 'Create',
      Component: ManageNotifications,
      exact: true,
      userRole: companyAdmin,
      companyRole: companyRole,
    },
    {
      path: ['/diagnostic'],
      name: '',
      Component: DiagnosticsOne,
      exact: true,
      userRole: companyAdmin,
      // OEM comapny type check
      companyRole: companyNameCheck(companyName) ? companyRole : adminRole,
    },
    {
      path: ['/data-collection'],
      name: '',
      Component: D2,
      exact: true,
      userRole: companyAdmin,
      // OEM comapny type check
      companyRole: companyNameCheck(companyName) ? companyRole : adminRole,
    },
    {
      path: ['/gate-diagnostics'],
      name: '',
      Component: GatewayDiagnostics,
      exact: true,
      userRole: companyAdmin,
      // OEM comapny type check
      companyRole: companyNameCheck(companyName) ? companyRole : adminRole,
    },
    {
      path: [Path.PACKAGE_CREATION, Path.PACKAGE_LISTING],
      name: '',
      Component: PackageManagement,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: [Path.CAMPAIGN_CREATION, Path.CAMPAIGN_LISTING],
      name: '',
      Component: CampaignManagement,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: [Path.OTA_DASHBOARD],
      name: 'Group Dashboard',
      Component: GroupDashboard,
      exact: true,
      userRole: companyAdmin,
      companyRole: adminRole,
    },
    {
      path: '/profile',
      name: 'Profile Settings',
      Component: Profile,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
    {
      path: '/404',
      name: '',
      Component: newfour,
      exact: true,
      userRole: all,
      companyRole: companyRole,
    },
  ];

  const userRoleState = useSelector((state) => state.validateOTPReducer.info[1]);
  const companyRoleState = useSelector((state) => state.validateOTPReducer.info[2]);

  const getUserDetails = JSON.parse(localStorage.getItem('user'));
  const getUserRole = getUserDetails ? getUserDetails.user_role : '';
  const getCompanyRole = getUserDetails ? getUserDetails.company_role_name : '';

  return (
    <AutoLogout>
      <Layout />
      <ContainerWrap>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>
            {routes.map(({ path, Component, userRole, companyRole }, key) => (
              <Route
                exact
                path={path}
                key={key}
                render={(props) => {
                  const crumbs = routes
                    .filter(({ path }) => props.match.path.includes(path))
                    .map(({ path, ...rest }) => ({
                      path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce((path, param) => path)
                        : path,
                      ...rest,
                    }));
                  return (
                    <div className="p-8">
                      {userRole.includes(getUserRole || userRoleState) &&
                      companyRole.includes(getCompanyRole || companyRoleState) ? (
                        <>
                          <Breadcrumbs crumbs={crumbs} />
                          <Component {...props} />
                        </>
                      ) : getCompanyRole === adminRole[0] || companyRoleState === adminRole[0] ? (
                        <>
                          <Breadcrumbs crumbs={crumbs} />
                          <Component {...props} />
                        </>
                      ) : userRole.includes(all[0]) ? (
                        <>
                          <Breadcrumbs crumbs={crumbs} />
                          <Component {...props} />
                        </>
                      ) : (
                        <NotFound404 />
                      )}
                    </div>
                  );
                }}
              />
            ))}
            <Redirect to={{ pathname: '/404' }} />
          </Switch>
        </ErrorBoundary>
      </ContainerWrap>
    </AutoLogout>
  );
};

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getPackageListing, {
  getPackageListingReset,
} from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getPackageListing';
import { createPackageReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/createPackageAction';
import Filters from './Filters';
import { getGroupListReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getGroupListAction';

const PackageListingTable = ({ reactTableData }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const { loading, info, error, pagination, filters } = useSelector(
    (state) => state.getPackageListingReducer
  );

  useEffect(() => {
    dispatch(createPackageReset());
    return () => {
      dispatch(getPackageListingReset());
      dispatch(getGroupListReset());
    };
  }, []);

  useEffect(() => {
    dispatch(getPackageListing('', '', pageNumber, numberofRecords, reactTableData.sort));
  }, [reactTableData.sort]);

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getPackageListing(filters.depot, filters.group, pageIndex + 1, pageSize, reactTableData.sort)
    );
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12} lg={4} sm={12}>
            <CardTitleWrap>
              <CardTitle>Package Management Listing</CardTitle>
            </CardTitleWrap>
          </Col>
          {/* <Col md={12} lg={8} sm={12}>
            <Filters />
          </Col> */}
        </Row>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={info ? info : []}
          tableConfig={tableConfig}
          paginationDetail={pagination}
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Diagnostics'}
        />

        {loading ? (
          ''
        ) : info && info.length === 0 ? (
          <TableNoData errorText={'No Data Found'} />
        ) : error ? (
          <TableNoData errorText={'Error'} />
        ) : (
          ''
        )}
      </CardBody>
    </Card>
  );
};

export default PackageListingTable;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import { colorText } from '@/utils/palette';
import { getGatewayDeviceListReset } from '@/redux/actions/OtaUpdateActions/GroupMgtActions/GatewayGroup/getGatewayDeviceList';
import GroupInputOption from '../../../../SharedElements/GroupInputOption';

const GatewayGroupCreationFrom = ({ form, initialValues, editMode }) => {
  const dispatch = useDispatch();

  const getAllRegisteredFields = form
    .getRegisteredFields()
    .filter((item) => !item.includes('company'))
    .filter((item) => !item.includes('fleet'))
    .filter((item) => !item.includes('depot'))
    .filter((item) => !item.includes('device_type'));

  const getGatewayDeviceListInfo = useSelector((state) => state.getGatewayDeviceListReducer.info);
  const getGatewayDeviceListLoading = useSelector(
    (state) => state.getGatewayDeviceListReducer.loading
  );

  const gatewayDeviceList =
    getGatewayDeviceListInfo &&
    getGatewayDeviceListInfo.group_wise_devices &&
    Object.keys(getGatewayDeviceListInfo.group_wise_devices)
      ? Object.keys(getGatewayDeviceListInfo.group_wise_devices).map((groupKey) => ({
          label: groupKey,
          options:
            getGatewayDeviceListInfo.group_wise_devices[groupKey].length > 0
              ? getGatewayDeviceListInfo.group_wise_devices[groupKey].map((device) => ({
                  id: device.device_id,
                  value: device.device_id,
                  label: (
                    <>
                      {device.device_id}
                      <br />
                      {/* {device.gateway_group_name} */}
                    </>
                  ),
                  dataObj: device.gateway_group_name,
                }))
              : [],
        }))
      : [];

  useEffect(() => {
    return () => {
      //reset fields after choose device type
      getAllRegisteredFields.map((item) => form.change(item));
      dispatch(getGatewayDeviceListReset());
    };
  }, []);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: 'static',
    }),

    placeholder(base) {
      return {
        ...base,
        color: `${colorText}`,
      };
    },
    option: (provided) => ({
      ...provided,
    }),
  };

  return (
    <>
      <FormGroup>
        <FormGroupLabel>Group Name</FormGroupLabel>
        <FormGroupField>
          <Field name="gateway_group_name" component={FormField} placeholder="Enter group name" />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Gateways</FormGroupLabel>
        <FormGroupField>
          <Field
            name="gateway_device_name"
            component={renderSelectField}
            placeholder="Select device"
            options={gatewayDeviceList}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: GroupInputOption,
            }}
            menuIsOpen
            isMulti
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            styles={customStyles}
            initialValue={editMode ? initialValues.gateway_device_name : null}
            isLoading={getGatewayDeviceListLoading}
          />
        </FormGroupField>
      </FormGroup>
    </>
  );
};

export default GatewayGroupCreationFrom;

import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import SystemSettingsContainer from '../SystemSettingsContainer';
import getSysSettingsCount from '../../../../redux/actions/SystemSettingsActions/getSystemSettingsCountActions';

const SystemSettings = () => {
  const Ava1 = `${process.env.PUBLIC_URL}/img/Companies.jpg`;
  const Ava2 = `${process.env.PUBLIC_URL}/img/Users.jpg`;
  const Ava3 = `${process.env.PUBLIC_URL}/img/Fleet.jpg`;
  const Ava4 = `${process.env.PUBLIC_URL}/img/Depot.jpg`;
  const Ava5 = `${process.env.PUBLIC_URL}/img/Roles.jpg`;

  const dispatch = useDispatch();

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails.company_id;

  useEffect(() => {
    if (companyId) {
      dispatch(getSysSettingsCount(companyId));
    }
  }, []);

  const getSysSettingsCountReducerInfo = useSelector(
    (state) => state.getSysSettingsCountReducer.info.system_settings_asset_counts
  );

  const data = [
    {
      id: 1,
      img: Ava1,
      assetType: 'Companies',
      link: '/settings/company-settings',
      totalnumber: getSysSettingsCountReducerInfo
        ? getSysSettingsCountReducerInfo[0].total_company
        : '-',
    },
    {
      id: 2,
      img: Ava2,
      assetType: 'Users',
      link: '/settings/company-settings/select',
      totalnumber: getSysSettingsCountReducerInfo
        ? getSysSettingsCountReducerInfo[3].total_user
        : '-',
    },
    {
      id: 3,
      img: Ava3,
      assetType: 'Fleets',
      link: '/settings/company-settings/select',
      totalnumber: getSysSettingsCountReducerInfo
        ? getSysSettingsCountReducerInfo[1].total_fleet
        : '-',
    },
    {
      id: 4,
      img: Ava4,
      assetType: 'Depots',
      link: '/settings/company-settings/select',
      totalnumber: getSysSettingsCountReducerInfo
        ? getSysSettingsCountReducerInfo[2].total_depot
        : '-',
    },
    {
      id: 5,
      img: Ava5,
      assetType: 'Roles',
      link: '/settings/company-settings/select',
      totalnumber: getSysSettingsCountReducerInfo ? getSysSettingsCountReducerInfo[4].user_role : '-',
    },
  ];

  const handleAsset = (asset) => {
    localStorage.setItem('systemAsset', asset.toLowerCase());
  };

  return (
    <Col md={12} lg={12} sm={12}>
      <Card height="auto">
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Setting Types</CardTitle>
          </CardTitleWrap>
          {data.map(({ id, img, assetType, link, totalnumber }) => (
            <div
              key={id}
              onClick={() => {
                handleAsset(assetType);
              }}
            >
              <SystemSettingsContainer
                key={id}
                img={img}
                assettype={assetType}
                link={link}
                totalnumber={totalnumber}
              />
            </div>
          ))}
        </CardBody>
      </Card>
    </Col>
  );
};

export default SystemSettings;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_PACKAGE_LISTING_LOADING,
  GET_PACKAGE_LISTING_SUCCESS,
  GET_PACKAGE_LISTING_FAIL,
  GET_PACKAGE_LISTING_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { sortingFunc } from '@/utils/helpers';

const getPackageListing =
  (depot, group, pageNumber, numberofRecords, sortData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PACKAGE_LISTING_LOADING,
      });

      const params = {
        group_name: group ? group : null,
        depot_id: depot ? depot : null,
        p: pageNumber ? pageNumber : null,
        page_size: numberofRecords ? numberofRecords : null,
        sort: sortData ? sortingFunc(sortData) : '-creation_time',
      };

      const { data } = await axios.get(ApiURL.GET_PACKAGE_LISTING, { params: params }, getConfig());

      dispatch({
        type: GET_PACKAGE_LISTING_SUCCESS,
        payload: {
          data: data.data.packages_list,
          pagination: data.pagination,
          filters: { depot, group },
        },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_PACKAGE_LISTING_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_PACKAGE_LISTING_FAIL,
          payload: error.response,
        });
      }
    }
  };

export const getPackageListingReset = () => (dispatch) => {
  dispatch({
    type: GET_PACKAGE_LISTING_RESET,
  });
};

export default getPackageListing;

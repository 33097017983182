import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import DotDotLoader from '@/shared/components/DotDotLoader';
import editGatewaySim, { editGatewaySimReset } from '../../../../redux/actions/editGatewayActions';
import data from '../../../App/Router/data';

const GatewayEdit = ({ editState, setEditState }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [btnClicked, setBtnClicked] = useState(false);

  const getGatewaySerialFromLocal = JSON.parse(localStorage.getItem('GatewayDetails'));
  const getSimFromLocal = JSON.parse(localStorage.getItem('SimDetails'));
  const getVinFromLocal = JSON.parse(localStorage.getItem('vehicleDetails'));
  const editGatewayReducer = useSelector((state) => state.editGatewayReducer);

  const loading = useSelector((state) => state.editGatewayReducer.loading);

  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  const onSubmit = (values) => {
    if (values && values.gateway_number && values.sim_number) {
      dispatch(editGatewaySim(values.gateway_number, values.sim_number, getVinFromLocal.vin));
      setBtnClicked(true);
    }
  };

  const defaultValue = {
    gatewayNumber: getGatewaySerialFromLocal,
    simNumber: getSimFromLocal,
  };

  if (editGatewayReducer.info && btnClicked) {
    setTimeout(() => {
      history.push('/app/hvac_dashboard');
      setBtnClicked(false);
    }, 100);
  }

  useEffect(() => {
    return () => {
      setBtnClicked(false);
      dispatch(editGatewaySimReset());
    };
  }, []);

  return (
    <Col md={6} lg={6} sm={12}>
      <Card height="auto">
        <CardBody>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                {loading && <DotDotLoader loadingState={loading} />}
                <FormGroup>
                  <FormGroupLabel>Gateway System Part Number</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="gateway_number"
                      component="input"
                      type="text"
                      placeholder="Enter gateway serial number"
                      initialValue={defaultValue.gatewayNumber}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Connect SIM to the Gateway</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="sim_number"
                      component="input"
                      type="text"
                      placeholder="Enter sim number"
                      initialValue={defaultValue.simNumber}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar
                  style={{
                    width: '100%',
                    marginTop: '0',
                    paddingTop: '50px',
                  }}
                >
                  <Button
                    variant="secondary"
                    type="submit"
                    style={{ width: '100%' }}
                    onClick={() => {
                      setEditState(false);
                    }}
                  >
                    Cancel
                  </Button>
                </FormButtonToolbar>
                {!viewRole.includes(userRole) ? (
                  <FormButtonToolbar
                    style={{
                      width: '100%',
                      marginTop: '0',
                    }}
                  >
                    <Button variant="primary" type="submit" style={{ width: '100%' }}>
                      Save
                    </Button>
                  </FormButtonToolbar>
                ) : (
                  ''
                )}
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GatewayEdit;

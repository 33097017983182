import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colorText, colorHover } from '@/utils/palette';
import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormContainer,
  FormQuarterContainer,
  FormOneFourthContainer,
} from '@/shared/components/form/FormElements';
import getPackageListing from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getPackageListing';
import getHvacList, {
  getHvacListReset,
} from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getHvacListAction';
import { campaignCreationValidateStepTwo } from '@/utils/validate';
import { WEBSOCKET_BASE_URL, WEBSOCKET_PORT } from '@/config/GlobalUrl';
import { StepPropTypes, StepDefaultProps } from './Props';
import InputOption from '../InputOption';

const StepTwo = ({ onSubmit, previousPage, defaultValues, initialValues, prefilledFormOne }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState([]);

  const { loading, info, error } = useSelector((state) => state.getPackageListingReducer);

  const getHvacListReducerInfo = useSelector((state) => state.getHvacListReducer.info);

  const getDevicesString =
    getHvacListReducerInfo && getHvacListReducerInfo.hvac_model_types.length > 0
      ? getHvacListReducerInfo.hvac_model_types.map((item) => item.gateway_id).join(',')
      : '';

  //Add clean up function to clear
  useEffect(() => {
    dispatch(
      getPackageListing(defaultValues.depot_name?.id, defaultValues.group_name?.value, 1, 99999)
    );
    dispatch(
      getHvacList(
        `?depot_id=${defaultValues.depot_name.id}`,
        `&group_name=${defaultValues.group_name.value}`
      )
    );
    return () => {
      dispatch(getHvacListReset());
    };
  }, []);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (getDevicesString !== '') {
      const socket = new WebSocket(`${WEBSOCKET_BASE_URL}:${WEBSOCKET_PORT}`);
      socket.onopen = () => {
        socket.send(token);
        socket.send(`gw_list: ${getDevicesString}`);
      };

      socket.onmessage = (event) => {
        setMessage((prev) => [...prev, event.data]);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };

      return () => {
        socket.close();
        setMessage('');
      };
    }
  }, [getDevicesString]);

  const getOnlineStatus = (gatewayId) => {
    if (message && message.length > 0) {
      const mess = message.find((msg) => msg.startsWith(`is_gateway_online:${gatewayId}:`));
      return mess ? mess.split(':')[2] : 'unknown';
    }
  };

  const defaultPackageVal = defaultValues.name
    ? typeof defaultValues.name === 'string'
      ? {
          id: defaultValues ? defaultValues.id : null,
          label: defaultValues ? defaultValues.name : null,
          value: defaultValues ? defaultValues.name : null,
          software_version: defaultValues ? defaultValues['software-version'].value : null,
        }
      : {
          id: defaultValues ? defaultValues.name.id : null,
          label: defaultValues ? defaultValues.name.value : null,
          value: defaultValues ? defaultValues.name.value : null,
          software_version: defaultValues ? defaultValues['software-version'].value : null,
        }
    : null;

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: 'static',
    }),

    placeholder(base) {
      return {
        ...base,
        color: `${colorText}`,
      };
    },
    option: (provided) => ({
      ...provided,
    }),
  };

  const packageOptions = info
    ? info.map(({ id, name, software_version }) => ({
        id: id,
        value: name,
        label: (
          <div style={{}}>
            <p style={{ fontSize: '11px' }}>{`Name: ${name}`}</p>
            <p style={{ fontSize: '11px' }}>{`Software Version: ${software_version}`}</p>
          </div>
        ),
        software_version: software_version,
      }))
    : [];

  const initialPackage = packageOptions.filter((item) => {
    return item.software_version === defaultValues['software-version']?.value;
  })?.[0];

  const initpackage = defaultValues['package-list']
    ? defaultValues['package-list']
    : initialPackage;

  const [packageSelected, setPackageSelected] = useState(initpackage);

  const svn = packageSelected ? packageSelected.software_version : initialPackage?.software_version;

  const hvacoptions =
    getHvacListReducerInfo && getHvacListReducerInfo.hvac_model_types.length > 0
      ? getHvacListReducerInfo.hvac_model_types.map(
          ({ gateway_id, unit, econtrol_version, model_type, serial_num, vin, in_process }) => ({
            id: gateway_id,
            value: unit,
            connectivityStatus: getOnlineStatus(gateway_id),
            label: (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginRight: '10px' }}>
                  {getOnlineStatus(gateway_id) === 'online' ? (
                    <PulseIcon style={{ backgroundColor: '#006B38' }}></PulseIcon>
                  ) : getOnlineStatus(gateway_id) === 'offline' ? (
                    <PulseIcon style={{ backgroundColor: '#A7AEB4' }}></PulseIcon>
                  ) : (
                    <PulseIcon style={{ backgroundColor: '#A7AEB4' }}></PulseIcon>
                  )}
                </div>
                <LabelDiv econtrol_version={econtrol_version}>
                  <div>
                    <p style={{ fontSize: '11px' }}>{`Unit: ${unit}`}</p>
                    <p style={{ fontSize: '11px' }}>{`Model Type: ${model_type}`}</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '11px' }}>{`${
                      !econtrol_version
                        ? 'Version not available'
                        : `Current version: ${econtrol_version}`
                    }`}</p>
                    <p style={{ fontSize: '11px' }}>
                      {in_process ? `In process: ${in_process}` : ''}
                    </p>
                  </div>
                </LabelDiv>
              </div>
            ),
            econtrol_version: econtrol_version,
            svn: svn,
          })
        )
      : [];

  const initialhvac = hvacoptions.filter((item) => {
    const svn = packageSelected
      ? packageSelected.software_version
      : initialPackage?.[0]?.software_version;
    return item.econtrol_version === svn;
  });

  const customFilter = (option, searchText) => {
    if (
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
      // option.data.svn.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={campaignCreationValidateStepTwo}
    >
      {({ handleSubmit, hasValidationErrors, values, form }) => (
        <FormContainer
          onSubmit={handleSubmit}
          style={{ width: '100%', marginTop: '25px', height: 'auto' }}
          vertical
        >
          <FormOneFourthContainer>
            <FormGroup>
              <FormGroupLabel>Package Listing</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="package-list"
                  component={renderSelectField}
                  options={packageOptions}
                  placeholder="Select Package"
                  initialValue={
                    defaultValues && defaultValues['package-list']
                      ? defaultValues['package-list']
                      : defaultValues && defaultValues.name && defaultPackageVal
                      ? defaultValues && defaultValues.name && defaultPackageVal
                      : initialPackage
                  }
                  parse={(e) => {
                    setPackageSelected(e);
                    form.change('hvac-list', []);
                    return e;
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option: InputOption,
                  }}
                  menuIsOpen
                  styles={customStyles}
                  isClearable
                />
              </FormGroupField>
            </FormGroup>
          </FormOneFourthContainer>
          <FormQuarterContainer>
            <FormGroup>
              <FormGroupLabel>HVAC Listing</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="hvac-list"
                  component={renderSelectField}
                  options={hvacoptions}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option: InputOption,
                  }}
                  placeholder="Select HVAC"
                  menuIsOpen
                  isMulti
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  styles={customStyles}
                  filterOption={customFilter}
                />
              </FormGroupField>
            </FormGroup>
          </FormQuarterContainer>
          <Button
            variant="secondary"
            type="button"
            className="previous"
            onClick={() => {
              previousPage(values);
            }}
          >
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="next"
            disabled={hasValidationErrors || hvacoptions.length === initialhvac.length}
          >
            Next
          </Button>
        </FormContainer>
      )}
    </Form>
  );
};

StepTwo.propTypes = StepPropTypes;
StepTwo.defaultProps = StepDefaultProps;

export default StepTwo;

const LabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  // background-color: ${(props) => (props.econtrol_version ? colorHover : '')};
  background-color: inherit;
`;

const PulseIcon = styled.span`
  position: relative;
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  top: 30%;
`;

import axios from 'axios';
import {
  GET_COMP_SERIAL_NUM_LOADING,
  GET_COMP_SERIAL_NUM_SUCCESS,
  GET_COMP_SERIAL_NUM_FAIL,
  RESET_COMP_SERIAL_NUMBER,
} from '@/shared/constants/getCompSerialNumberConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../config/GlobalUrl';

export const getCompSerialNumber = (serialNumber) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMP_SERIAL_NUM_LOADING,
    });

    const response = await axios.get(ApiURL.GET_COMP_SERIAL_NUMBER + serialNumber, getConfig());

    dispatch({
      type: GET_COMP_SERIAL_NUM_SUCCESS,
      payload: response.data,
    });
    // successMessage('Serial number found!');
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: GET_COMP_SERIAL_NUM_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: GET_COMP_SERIAL_NUM_FAIL,
        payload: error.response,
      });
    }
  }
};

export const resetCompSerialNumber = () => (dispatch) => {
  dispatch({
    type: RESET_COMP_SERIAL_NUMBER,
  });
};

// export default getCompSerialNumber;

import React, { useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tooltip from '@/shared/components/Tooltip.jsx';

const CustomProgressBar = ({ progressData }) => {
  const { total, pending, completed, failed } = progressData;
  const pendingPercentage = (pending / total) * 100;
  const completedPercentage = (completed / total) * 100;
  const failedPercentage = (failed / total) * 100;
  const [tooltipText, setTooltipText] = useState('');

  const handleHover = (e) => {
    setTooltipText(e);
  };

  return (
    <Tooltip dir={'rtl'} text={tooltipText}>
      <ProgressBar style={{ height: '5px' }}>
        {pending > 0 && (
          <ProgressBar
            variant="warning"
            now={pendingPercentage}
            key={1}
            onMouseEnter={(e) => handleHover(`Pending: ${pending}`)}
          />
        )}
        {completed > 0 && (
          <ProgressBar
            variant="success"
            now={completedPercentage}
            key={2}
            onMouseEnter={(e) => handleHover(`Completed: ${completed}`)}
          />
        )}
        {failed > 0 && (
          <ProgressBar
            variant="danger"
            now={failedPercentage}
            key={3}
            onMouseEnter={(e) => handleHover(`Failed: ${failed}`)}
          />
        )}
      </ProgressBar>
    </Tooltip>
  );
};

export default CustomProgressBar;

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  DOWNLOAD_INDEX_ERROR_CODE_LOADING,
  DOWNLOAD_INDEX_ERROR_CODE_SUCCESS,
  DOWNLOAD_INDEX_ERROR_CODE_FAIL,
} from '@/shared/constants/VehicleDashboardConstants/getCompanyTagsConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const downloadIndexErrorCode = (gatewayId, vin) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_INDEX_ERROR_CODE_LOADING,
    });

    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `${'ErrorCode'}_${vin}`;

    const response = await axios.post(
      ApiURL.DOWNLOAD_INDEX_ERROR_CODE,
      {
        gateway_id: gatewayId,
      },
      getConfig()
    );

    link.href = URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
    link.click();

    dispatch({
      type: DOWNLOAD_INDEX_ERROR_CODE_SUCCESS,
      payload: [],
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: DOWNLOAD_INDEX_ERROR_CODE_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: DOWNLOAD_INDEX_ERROR_CODE_FAIL,
        payload: error.response,
      });
    }
  }
};

export default downloadIndexErrorCode;

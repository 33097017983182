import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  REGISTER_COMPANY_RELATION_LOADING,
  REGISTER_COMPANY_RELATION_SUCCESS,
  REGISTER_COMPANY_RELATION_FAIL,
  REGISTER_COMPANY_RELATION_RESET,
} from '../../../shared/constants/CompanyConstants/registerCompanyRelationConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';

const registerCompanyRelation = (allCompaniesId, registeredCompanyId) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_COMPANY_RELATION_LOADING,
    });

    const response = await axios.post(
      ApiURL.REGISTER_COMPANY_RELATION,
      {
        data: {
          base_company: registeredCompanyId,
          all: allCompaniesId,
        },
      },
      getConfig()
    );
    successMessage(system_settings.register_company_relation);

    dispatch({
      type: REGISTER_COMPANY_RELATION_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: REGISTER_COMPANY_RELATION_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: REGISTER_COMPANY_RELATION_FAIL,
        payload: error.response,
      });
    }
  }
};

export const registerCompanyRelationReset = () => (dispatch) => {
  dispatch({
    type: REGISTER_COMPANY_RELATION_RESET,
  });
};

export default registerCompanyRelation;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';
import WarningIcon from 'mdi-react/AlertCircleOutlineIcon';
import styled from 'styled-components';
import { Button, ButtonToolbar } from '@/shared/components/Button';
import { colorWhite, colorIcon, colorBackground, colorText } from '@/utils/palette';
import { flexFlow, paddingRight, paddingLeft, right, left } from '@/utils/directions';

const Modal = ({ title, handleClose, handleSubmit, show, type, children }) => {
  return (
    <StyledModal onHide={handleClose} show={show} backdrop="static" keyboard={false}>
      <ModalHeader>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={handleClose}
        />
        {type === 'warning' && <WarningIcon style={{ height: '30px', width: '30px' }} />}
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Okay
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  header: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  message: '',
  header: false,
};

export default Modal;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 385px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  

  ${(props) =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }
  `}
`;

// endregion

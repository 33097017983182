import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import renderSelectField from '@/shared/components/form/Select';
import { Button } from '@/shared/components/Button';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Card, CardBody } from '@/shared/components/Card';
import FormField from '@/shared/components/form/FormField';
import { groupCreationValidate } from '@/utils/validate';
import { colorText } from '@/utils/palette';
import { slugify } from '@/utils/helpers';
import getHvacModels, {
  getHvacModelsReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/getHvacModels';
import getGroupDeviceListByModal, {
  getGroupDeviceListByModalReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/getGroupDeviceListByModal';
import getGroupDeviceListByOthers, {
  getGroupDeviceListByOthersReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/getGroupDeviceListByOthers';
import createGroup, {
  createGroupReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/createGroup';
import getGroupDetails, {
  getGroupDetailsReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/getGroupDetails';
import SharedRadio from '../../../SharedElements/SharedRadio';
import SharedForm from '../../../SharedElements/SharedForm';
import GroupInputOption from '../../../SharedElements/GroupInputOption';
import updateGroup, {
  updateGroupReset,
} from '@/redux/actions/OtaUpdateActions/GroupMgtActions/updateGroup';
import GatewayGroupCreationFrom from './components/GatewayGroupCreationForm';
import getGatewayDeviceList, {
  getGatewayDeviceListReset,
} from '../../../../../../redux/actions/OtaUpdateActions/GroupMgtActions/GatewayGroup/getGatewayDeviceList';
import createGatewayGroup, {
  createGatewayGroupReset,
} from '../../../../../../redux/actions/OtaUpdateActions/GroupMgtActions/GatewayGroup/createGatewayGroup';
import updateGatewayGroup, {
  updateGatewayGroupReset,
} from '../../../../../../redux/actions/OtaUpdateActions/GroupMgtActions/GatewayGroup/updateGatewayGroup';
import SharedModal from '../../../SharedElements/SharedModal';

const GroupCreationForm = ({ editMode, setEditMode, setShowForm, getRowDetails }) => {
  const dispatch = useDispatch();
  const radioValues = ['econtrol', 'gateway'];

  const [groupName, setGroupName] = useState('');
  const [disableFields, setDisableFields] = useState(false);
  const [depotId, setDepotId] = useState('');
  const [deviceTypeValue, setDeviceTypeValue] = useState(radioValues[0]);
  const [btnClicked, setBtnClicked] = useState(false);

  let arr = [];

  const getGroupDeviceListByModalReducerLoading = useSelector(
    (state) => state.getGroupDeviceListByModalReducer.loading
  );
  const getGroupDeviceListByModalReducerInfo = useSelector(
    (state) => state.getGroupDeviceListByModalReducer.info
  );
  const getGroupDeviceListByModalReducerError = useSelector(
    (state) => state.getGroupDeviceListByModalReducer.error
  );

  const getGroupDeviceListByOthersReducerInfo = useSelector(
    (state) => state.getGroupDeviceListByOthersReducer.info
  );
  const getGroupDeviceListByOthersReducerLoading = useSelector(
    (state) => state.getGroupDeviceListByOthersReducer.loading
  );

  const getHvacModelsReducerLoading = useSelector((state) => state.getHvacModelsReducer.loading);
  const getHvacModelsReducerInfo = useSelector((state) => state.getHvacModelsReducer.info);
  const getHvacModelsReducerError = useSelector((state) => state.getHvacModelsReducer.error);

  const getGroupDetailsLoading = useSelector((state) => state.getGroupDetailsReducer.loading);
  const getGroupDetailsInfo = useSelector((state) => state.getGroupDetailsReducer.info);
  const getGroupDetailsError = useSelector((state) => state.getGroupDetailsReducer.error);

  const createGroupReducerInfo = useSelector((state) => state.createGroupReducer.info);
  const createGatewayGroupInfo = useSelector((state) => state.createGatewayGroupReducer.info);
  const updateGroupReducerInfo = useSelector((state) => state.updateGroupReducer.info);
  const updateGatewayGroupInfo = useSelector((state) => state.updateGatewayGroupReducer.info);

  const getGatewayDeviceListInfo = useSelector((state) => state.getGatewayDeviceListReducer.info);

  const handleHvacModelChange = (e) => {
    if (e) {
      if (e.value !== 'Custom') {
        dispatch(getGroupDetails(`${slugify(e.value)}@econtrol@${depotId}`, depotId));
        dispatch(getGroupDeviceListByModal(depotId, e.value));
        dispatch(getGroupDeviceListByOthersReset());
        dispatch(getGroupDetailsReset());
      }
      if (e.value === 'Custom') {
        dispatch(getGroupDeviceListByOthers(depotId));
        dispatch(getGroupDeviceListByModalReset());
        dispatch(getGroupDetailsReset());
      }
      setGroupName(e.value);
    }
    if (!e) {
      setGroupName('');
      setDisableFields(false);
      dispatch(getGroupDeviceListByModalReset());
      dispatch(getGroupDeviceListByOthersReset());
    }
  };

  const handleGroupCreation = (values) => {
    if (values && values.device_type === 'gateway') {
      dispatch(createGatewayGroup(values));
      setBtnClicked(true);
    }
    if (values && values.device_type === 'econtrol') {
      dispatch(createGroup(values));
      setBtnClicked(true);
    }
  };

  if (btnClicked && createGroupReducerInfo) {
    setTimeout(() => {
      setShowForm(false);
      dispatch(createGroupReset());
    }, 100);
  }

  if (btnClicked && createGatewayGroupInfo) {
    setTimeout(() => {
      setShowForm(false);
      dispatch(createGatewayGroupReset());
    }, 100);
  }

  const handleUpdateGroup = (values) => {
    if (values && values.device_type === 'econtrol') {
      const addedVals = values.device_name.map((item) => item.value);
      const removedVals = prefilledVals
        .filter((item) => !addedVals.includes(item.value))
        .map((newItem) => newItem.id);

      arr = [
        {
          name:
            values.hvac_model.value && values.hvac_model.value !== 'Custom'
              ? `${slugify(values.hvac_model.value)}@econtrol@${values.depot.id}`
              : getRowDetails.name,
          depot_id: values.depot.id,
          added_device_ids: addedVals,
          removed_device_ids: removedVals,
          label: values.group_name,
        },
      ];
      if (arr.length > 0) {
        dispatch(updateGroup(arr));
        setBtnClicked(true);
      }
    }

    if (values && values.device_type === 'gateway') {
      const addedVals = values.gateway_device_name.map((item) => item.value);
      const removedVals = gatewayPrefilledVals
        .filter((item) => !addedVals.includes(item.value))
        .map((newItem) => newItem.id);

      arr = [
        {
          name: getRowDetails.name,
          depot_id: values.depot.id,
          added_device_ids: addedVals,
          removed_device_ids: removedVals,
          label: values.gateway_group_name,
        },
      ];
      if (arr.length > 0) {
        dispatch(updateGatewayGroup(arr));
        setBtnClicked(true);
      }
    }
  };

  if (btnClicked && updateGroupReducerInfo) {
    setTimeout(() => {
      setEditMode(false);
      setShowForm(false);
      dispatch(updateGroupReset());
    }, 100);
  }

  if (btnClicked && updateGatewayGroupInfo) {
    setTimeout(() => {
      setEditMode(false);
      setShowForm(false);
      dispatch(updateGatewayGroupReset());
    }, 100);
  }

  const goBack = () => {
    setShowForm(false);
    if (editMode) {
      setEditMode(false);
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: 'static',
    }),

    placeholder(base) {
      return {
        ...base,
        color: `${colorText}`,
      };
    },
    option: (provided) => ({
      ...provided,
    }),
  };

  const onDepotChange = (e, form) => {
    if (e) {
      dispatch(getHvacModels(e.id));
      setDepotId(e.id);
      form.change('device_type', radioValues[0]);
      setDeviceTypeValue(radioValues[0]);
    }
    if (!e) {
      setDepotId('');
      dispatch(getGroupDeviceListByModalReset());
      dispatch(getHvacModelsReset());
      dispatch(getGatewayDeviceListReset());
      setDisableFields(false);
      const getAllRegisteredFields = form
        .getRegisteredFields()
        .filter((item) => !item.includes('company'))
        .filter((item) => !item.includes('fleet'));
      // .filter((item) => !item.includes('device_type'));
      getAllRegisteredFields.map((item) => form.change(item));
      // form.change('device_type', radioValues[0]);
    }
  };

  const filteredDevices =
    getGroupDeviceListByModalReducerInfo?.length > 0
      ? getGroupDeviceListByModalReducerInfo.filter(
          (device) =>
            device.econrol_group_name ===
            `${slugify(
              getRowDetails && Object.keys(getRowDetails).length > 0
                ? getRowDetails.model
                : groupName
            )}@econtrol@${
              getRowDetails && Object.keys(getRowDetails).length > 0
                ? getRowDetails.depot_id
                : depotId
            }`
        )
      : getGroupDeviceListByOthersReducerInfo &&
        Object.keys(getGroupDeviceListByOthersReducerInfo).length > 0
      ? Object.keys(getGroupDeviceListByOthersReducerInfo).flatMap((groupKey) =>
          getGroupDeviceListByOthersReducerInfo[groupKey]
            .map((device) => ({
              hvac_id: device.device_id,
              value: device.device_id,
              econrol_group_name: device.econtrol_group_name,
            }))
            .filter((newdevice) =>
              newdevice.econtrol_group_name === getRowDetails &&
              Object.keys(getRowDetails).length > 0
                ? getRowDetails.name
                : `${slugify(groupName)}@econtrol@${depotId}`
            )
        )
      : [];

  const gatewayDeviceFilteredList =
    getGatewayDeviceListInfo &&
    getGatewayDeviceListInfo.group_wise_devices &&
    Object.keys(getGatewayDeviceListInfo.group_wise_devices).length > 0
      ? Object.keys(getGatewayDeviceListInfo.group_wise_devices).flatMap((groupKey) =>
          getGatewayDeviceListInfo.group_wise_devices[groupKey]
            .map((device) => ({
              gateway_device_id: device.device_id,
              value: device.device_id,
              gateway_group_name: device.gateway_group_name,
            }))
            .filter((newdevice) => newdevice.gateway_group_name === getRowDetails.name)
        )
      : [];

  const gatewayPrefilledVals =
    gatewayDeviceFilteredList && gatewayDeviceFilteredList.length > 0
      ? gatewayDeviceFilteredList.map((item) => ({
          id: item.gateway_device_id,
          value: item.gateway_device_id,
          label: (
            <>
              {item.gateway_device_id}
              <br />
              {/* {item.econrol_group_label} */}
            </>
          ),
          dataObj: item.gateway_group_name,
        }))
      : [];

  const initialGroupName =
    getGroupDetailsInfo && getGroupDetailsInfo.group
      ? getGroupDetailsInfo.group.econrol_group_label
      : '';

  const prefilledVals =
    filteredDevices && filteredDevices.length > 0
      ? filteredDevices.map((item) => ({
          id: item.hvac_id,
          value: item.hvac_id,
          label: (
            <>
              {item.dispaly_name}
              <br />
              {item.econrol_group_label}
            </>
          ),
          dataObj: item.econrol_group_name,
        }))
      : [];

  useEffect(() => {
    if (initialGroupName !== '') {
      setDisableFields(true);
    }
    return () => {
      setDisableFields(false);
    };
  }, [initialGroupName]);

  useEffect(() => {
    if (editMode && Object.keys(getRowDetails).length > 0) {
      if (getRowDetails.type === 'econtrol') {
        dispatch(getGroupDetails(getRowDetails.name, getRowDetails.depot_id));
      }
      if (getRowDetails.checks !== 'open' && getRowDetails.type === 'econtrol') {
        dispatch(getGroupDeviceListByModal(getRowDetails.depot_id, getRowDetails.model));
        dispatch(getGroupDeviceListByOthersReset());
      }
      if (getRowDetails.checks === 'open' && getRowDetails.type === 'econtrol') {
        dispatch(getGroupDeviceListByOthers(getRowDetails.depot_id));
        dispatch(getGroupDeviceListByModalReset());
      }
      if (getRowDetails.checks === 'open' && getRowDetails.type === 'gateway') {
        dispatch(getGatewayDeviceList(getRowDetails.depot_id));
      }
    }
  }, [editMode]);

  const deviceListByModel =
    getGroupDeviceListByModalReducerInfo?.length > 0
      ? getGroupDeviceListByModalReducerInfo.map((item) => ({
          id: item.hvac_id,
          value: item.hvac_id,
          label: (
            <>
              {item.dispaly_name}
              <br />
              {item.econrol_group_label}
            </>
          ),
          dataObj: item.econrol_group_name,
        }))
      : [];

  const deviceListByOther =
    getGroupDeviceListByOthersReducerInfo && Object.keys(getGroupDeviceListByOthersReducerInfo)
      ? Object.keys(getGroupDeviceListByOthersReducerInfo).map((groupKey) => ({
          label: groupKey,
          options:
            getGroupDeviceListByOthersReducerInfo[groupKey].length > 0
              ? getGroupDeviceListByOthersReducerInfo[groupKey].map((device) => ({
                  id: device.device_id,
                  value: device.device_id,
                  label: (
                    <>
                      {device.display_name}
                      <br />
                      {/* {device.econtrol_group_name} */}
                    </>
                  ),
                  dataObj: device.econtrol_group_name,
                }))
              : [],
        }))
      : [];

  const newOptions =
    editMode && getRowDetails.checks !== 'open'
      ? deviceListByModel
      : !editMode && disableFields && groupName !== 'Custom'
      ? deviceListByModel
      : !editMode && !disableFields && groupName !== 'Custom'
      ? deviceListByModel
      : groupName === 'Custom'
      ? deviceListByOther
      : deviceListByOther;

  useEffect(() => {
    return () => {
      dispatch(getHvacModelsReset());
      dispatch(getGroupDeviceListByModalReset());
      dispatch(getGroupDeviceListByOthersReset());
      dispatch(getGroupDetailsReset());
    };
  }, []);

  const initialVals =
    editMode && getRowDetails
      ? {
          company: {
            id: getRowDetails.operator_name,
            value: getRowDetails.operator_name,
            label: getRowDetails.operator_name,
          },
          fleet: {
            id: getRowDetails.fleet_id,
            value: getRowDetails.fleet_name,
            label: getRowDetails.fleet_name,
          },
          depot: {
            id: getRowDetails.depot_id,
            value: getRowDetails.depot_name,
            label: getRowDetails.depot_name,
          },
          device_type: getRowDetails.type,
          hvac_model:
            getRowDetails.checks !== 'open'
              ? {
                  id: getRowDetails.model,
                  value: getRowDetails.model,
                  label: getRowDetails.model,
                }
              : { id: 'Custom', value: 'Custom', label: 'Custom' },
          group_name: getRowDetails.label,
          device_name:
            getRowDetails.type === 'econtrol' && getGroupDetailsInfo && getGroupDetailsInfo.group
              ? getGroupDetailsInfo.group.devices.flatMap((item) => ({
                  id: item.hvac_id,
                  value: item.hvac_id,
                  label: (
                    <>
                      {item.display_name}
                      <br />
                      {item.model_details}
                    </>
                  ),
                  dataObj: getGroupDetailsInfo.group.econrol_group_name,
                }))
              : [],
          gateway_group_name: getRowDetails.type === 'gateway' && getRowDetails.label,
          gateway_device_name:
            getRowDetails.type === 'gateway' &&
            getGatewayDeviceListInfo &&
            getGatewayDeviceListInfo.group_wise_devices &&
            Object.keys(getGatewayDeviceListInfo.group_wise_devices).length > 0
              ? Object.keys(getGatewayDeviceListInfo.group_wise_devices).flatMap((groupKey) =>
                  getGatewayDeviceListInfo.group_wise_devices[groupKey]
                    .map((device) => ({
                      gateway_device_id: device.device_id,
                      value: device.device_id,
                      gateway_group_name: device.gateway_group_name,
                    }))
                    .filter((newdevice) => newdevice.gateway_group_name === getRowDetails?.name)
                )
              : [],
        }
      : null;

  const initialValuesByModel = newOptions
    .filter((item) => item.dataObj === `${slugify(groupName)}@econtrol@${depotId}`)
    .map((item) => item);

  const handleRadioChange = (e) => {
    if (e && e === 'econtrol' && depotId) {
      // dispatch();
      setDeviceTypeValue(radioValues[0]);
    }
    if (e && e === 'gateway' && depotId) {
      dispatch(getGatewayDeviceList(depotId));
      setDeviceTypeValue(radioValues[1]);
    }
  };

  //Add custom filter for device_name and group_name
  // const customFilter = (option, searchText) => {
  //   if (option.data.value.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  //SHOW MODAL LATER BASED ON STATE CHANGE

  // {btnClicked ? (
  //   <SharedModal show={btnClicked} handleClose={() => setBtnClicked(false)}>
  //     <div>
  //       <h4 style={{ paddingBottom: '20px' }}>Status Details:</h4>
  //       {/* {formValuesToBeSubmitted.map((item)=><p>{item}</p>)} */}
  //     </div>
  //   </SharedModal>
  // ) : (
  //   ''
  // )}

  return (
    <Col md={12} lg={12}>
      <Form
        onSubmit={editMode || disableFields ? handleUpdateGroup : handleGroupCreation}
        validate={groupCreationValidate}
        initialValues={initialVals}
      >
        {({ handleSubmit, form, pristine, submitting, hasValidationErrors, initialValues }) => (
          <FormContainer
            vertical
            onSubmit={handleSubmit}
            style={{ width: '100%', display: 'block' }}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={3}>
                <Card>
                  <CardBody>
                    <SharedForm
                      form={form}
                      onDepotChange={onDepotChange}
                      disableFields={editMode || disableFields}
                      editMode={editMode}
                      initialValues={initialValues}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={9}>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <FormGroupLabel>Choose device type</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="device_type"
                          render={SharedRadio}
                          label="HVAC ECUs"
                          radioValue={radioValues[0]}
                          value={radioValues[0]}
                          disabled={editMode || disableFields ? true : false}
                          parse={(e) => {
                            handleRadioChange(e);
                            return e;
                          }}
                          initialValue={deviceTypeValue}
                        />
                        <Field
                          name="device_type"
                          render={SharedRadio}
                          label="Gateway"
                          radioValue={radioValues[1]}
                          value={radioValues[1]}
                          disabled={editMode || disableFields ? true : false}
                          parse={(e) => {
                            handleRadioChange(e);
                            return e;
                          }}
                        />
                      </FormGroupField>
                    </FormGroup>
                    {deviceTypeValue === 'gateway' || initialVals?.device_type === 'gateway' ? (
                      <GatewayGroupCreationFrom
                        form={form}
                        initialValues={initialValues}
                        editMode={editMode}
                      />
                    ) : (
                      <>
                        <FormGroup>
                          <FormGroupLabel>Hvac Models</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="hvac_model"
                              component={renderSelectField}
                              placeholder="Select model"
                              options={
                                getHvacModelsReducerInfo &&
                                getHvacModelsReducerInfo.model_list.length > 0
                                  ? [
                                      ...getHvacModelsReducerInfo.model_list.map(
                                        (value, index) => ({
                                          id: index,
                                          value,
                                          label: value,
                                        })
                                      ),
                                      { id: 'Custom', label: 'Custom', value: 'Custom' },
                                    ]
                                  : []
                              }
                              parse={(e) => {
                                handleHvacModelChange(e);
                                form.change(
                                  'group_name',
                                  filteredDevices.length > 0
                                    ? filteredDevices[0].econrol_group_label
                                    : ''
                                );
                                return e;
                              }}
                              isClearable
                              isDisabled={editMode ? true : false}
                              isLoading={getHvacModelsReducerLoading}
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Group Name</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="group_name"
                              component={FormField}
                              placeholder="Enter group name"
                              initialValue={initialGroupName}
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Devices</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="device_name"
                              component={renderSelectField}
                              placeholder="Select device"
                              options={newOptions}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Option: GroupInputOption,
                              }}
                              menuIsOpen
                              isMulti
                              controlShouldRenderValue={false}
                              hideSelectedOptions={false}
                              styles={customStyles}
                              initialValue={initialValuesByModel}
                              isLoading={
                                getGroupDeviceListByModalReducerLoading ||
                                getGroupDeviceListByOthersReducerLoading
                              }
                              // filterOption={customFilter}
                            />
                          </FormGroupField>
                        </FormGroup>
                      </>
                    )}
                    <FormButtonToolbar>
                      <Button variant="secondary" onClick={goBack}>
                        Cancel
                      </Button>
                      {!editMode && !disableFields ? (
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={pristine || submitting || hasValidationErrors}
                        >
                          Create
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={pristine || submitting || hasValidationErrors}
                        >
                          Update
                        </Button>
                      )}
                    </FormButtonToolbar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </Col>
  );
};

export default GroupCreationForm;

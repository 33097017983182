import {
  GET_CAN_DATA_LISTING_LOADING,
  GET_CAN_DATA_LISTING_SUCCESS,
  GET_CAN_DATA_LISTING_FAIL,
} from '@/shared/constants/DiagnosticsConstants/diagnosticsConstants';

const initialState = {
  loading: false,
  info: [],
  pagination: {
    totalPages: 0,
    totalRecords: 0,
  },
  error: '',
};

const getCanDataListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAN_DATA_LISTING_LOADING:
      return { ...state, loading: true };
    case GET_CAN_DATA_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        pagination: action.payload.pagination,
      };
    case GET_CAN_DATA_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getCanDataListingReducer;

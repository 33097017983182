import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import BusMultipleIcon from 'mdi-react/BusMultipleIcon';
import CityVariantIcon from 'mdi-react/CityVariantIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import ThermometerIcon from 'mdi-react/ThermometerIcon';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { registerFleetValidate } from '@/utils/validate.js';
import registerFleet, {
  registerFleetReset,
} from '../../../../redux/actions/FleetActions/registerFleetActions';
import getRegisteredFleet from '../../../../redux/actions/FleetActions/getRegisteredFleetActions';
import updateFleet, {
  updateFleetReset,
} from '../../../../redux/actions/FleetActions/updateFleetActions';

const FleetForm = ({ setGoBack, editMode, setShowFleetEditForm, fleetId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [fleetUpdateBtnClick, setFleetUpdateBtnClick] = useState(false);
  const companyId = localStorage.getItem('companyId');

  const registerFleetReducerLoading = useSelector((state) => state.registerFleetReducer.loading);
  const registerFleetReducerInfo = useSelector((state) => state.registerFleetReducer.info);
  const registerFleetReducerError = useSelector((state) => state.registerFleetReducer.error);
  const getRegisteredFleetReducerLoading = useSelector(
    (state) => state.getRegisteredFleetReducer.loading
  );
  const getRegisteredFleetReducerInfo = useSelector(
    (state) => state.getRegisteredFleetReducer.info
  );
  const getRegisteredFleetReducerError = useSelector(
    (state) => state.getRegisteredFleetReducer.error
  );
  const updateFleetReducerLoading = useSelector((state) => state.updateFleetReducer.loading);
  const updateFleetReducerInfo = useSelector((state) => state.updateFleetReducer.info);
  const updateFleetReducerError = useSelector((state) => state.updateFleetReducer.error);

  const [apiData, setApiData] = useState(
    getRegisteredFleetReducerInfo ? getRegisteredFleetReducerInfo.fleet : ''
  );
  const handleCancel = () => {
    setGoBack(true);
  };

  useEffect(() => {
    if (companyId && fleetId) {
      dispatch(getRegisteredFleet(companyId, fleetId));
    }
    return () => {
      setBtnClicked(false);
      setFleetUpdateBtnClick(false);
    };
  }, [fleetId]);

  const handleRegisterFleet = (values) => {
    if (Object.keys(values).length !== 0) {
      dispatch(registerFleet(companyId, values));
      setBtnClicked(true);
    }
  };

  if (btnClicked === true && registerFleetReducerInfo !== '') {
    setTimeout(() => {
      handleCancel();
      dispatch(registerFleetReset());
      setBtnClicked(false);
    }, 1000);
  }

  const handleUpdateFleet = (values) => {
    if (Object.keys(values).length !== 0 && companyId && fleetId) {
      dispatch(updateFleet(companyId, fleetId, values));
      setFleetUpdateBtnClick(true);
    }
  };

  if (fleetUpdateBtnClick === true && updateFleetReducerInfo !== '') {
    setTimeout(() => {
      setShowFleetEditForm(false);
      dispatch(updateFleetReset());
      setFleetUpdateBtnClick(false);
    }, 1000);
  }
  const dropdownValue = [
    {
      id: getRegisteredFleetReducerInfo && getRegisteredFleetReducerInfo.fleet.climate_zone,
      value: getRegisteredFleetReducerInfo && getRegisteredFleetReducerInfo.fleet.climate_zone_name,
      label: getRegisteredFleetReducerInfo && getRegisteredFleetReducerInfo.fleet.climate_zone_name,
    },
  ];

  return (
    <Card height="auto">
      <CardBody>
        {getRegisteredFleetReducerLoading ? (
          <DotDotLoader loadingState={getRegisteredFleetReducerLoading} />
        ) : (
          <Form
            onSubmit={editMode === false ? handleRegisterFleet : handleUpdateFleet}
            initialValues={
              editMode && getRegisteredFleetReducerInfo !== ''
                ? getRegisteredFleetReducerInfo.fleet
                : ''
            }
            validate={registerFleetValidate}
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                {registerFleetReducerLoading || updateFleetReducerLoading ? (
                  <DotDotLoader
                    loadingState={registerFleetReducerLoading || updateFleetReducerLoading}
                  />
                ) : (
                  ''
                )}
                <FormGroup>
                  <FormGroupLabel>Fleet Name</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <BusMultipleIcon />
                    </FormGroupIcon>
                    <Field
                      name="name"
                      component={FormField}
                      type="text"
                      placeholder="Fleet Name"
                      required
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Region</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <CityVariantIcon />
                    </FormGroupIcon>
                    <Field
                      name="region"
                      component={FormField}
                      type="text"
                      placeholder="Region"
                      required
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Description</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <TextBoxIcon />
                    </FormGroupIcon>
                    <Field
                      name="description"
                      component={FormField}
                      type="text"
                      placeholder="Description"
                      required
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Climate Zone</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <ThermometerIcon />
                    </FormGroupIcon>
                    <Field
                      name="climateZone"
                      component={renderSelectField}
                      type="text"
                      options={[
                        { id: 1, value: 'moderate', label: 'Moderate' },
                        { id: 2, value: 'hot', label: 'Hot' },
                        { id: 3, value: 'tropic', label: 'Tropic' },
                      ]}
                      placeholder="Select climate zone"
                      //Add condition to get data from api for climatezone default value
                      initialValue={editMode && dropdownValue ? dropdownValue : ''}
                      // initialValue={
                      //   editMode === true && getRegisteredFleetReducerInfo
                      //     ? getRegisteredFleetReducerInfo.fleet
                      //         .climate_zone_name
                      //     : ''
                      // }
                      // defaultValue={[
                      //   {
                      //     value:
                      //       editMode === true && getRegisteredFleetReducerInfo
                      //         ? getRegisteredFleetReducerInfo.fleet
                      //             .climate_zone_name
                      //         : '',
                      //     label:
                      //       editMode === true && getRegisteredFleetReducerInfo
                      //         ? getRegisteredFleetReducerInfo.fleet
                      //             .climate_zone_name
                      //         : '',
                      //   },
                      // ]}
                    />
                  </FormGroupField>
                </FormGroup>
                {editMode === false ? (
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Register
                    </Button>
                  </FormButtonToolbar>
                ) : (
                  <FormButtonToolbar>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => setShowFleetEditForm(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </FormButtonToolbar>
                )}
              </FormContainer>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default FleetForm;

import React from 'react';
import { useHistory } from 'react-router';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { right, marginRight, left } from '@/utils/directions';
import { Card, CardBody } from '@/shared/components/Card';

const MaintenanceSetupContainer = () => {
  const Ava3 = `${process.env.PUBLIC_URL}/img/Gateways.jpg`;

  const history = useHistory();

  const handleClick = () => {
    setTimeout(() => {
      history.push('/maintenance');
    }, 200);
  };

  return (
    <Col xs={12} sm={12} md={6}>
      <h6 style={{ paddingBottom: '20px' }}>Maintenance Setup</h6>
      <Card>
        <CardBody>
          <GatewayList onClick={() => handleClick()}>
            <GatewayContainer style={{ width: '100%' }}>
              <GatewayImage>
                <img src={Ava3} alt="" />
              </GatewayImage>
              <GatewayAddContainer>
                <GatewayAdd>Define maintenance activities</GatewayAdd>
                <GatewayLink>
                  <span className="lnr lnr-chevron-right" />
                </GatewayLink>
              </GatewayAddContainer>
            </GatewayContainer>
          </GatewayList>
        </CardBody>
      </Card>
    </Col>
  );
};
export default MaintenanceSetupContainer;

// region STYLES

const GatewayContainer = styled.div`
  display: flex;
  position: relative;
  padding: 10px 10px;
  text-align: ${left};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const GatewayList = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};

  &:last-child {
    border-bottom: none;
  }
`;

const GatewayAddContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;
const GatewayAdd = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`;

const GatewayImage = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const GatewayLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 20px;
  line-height: 16px;
  color: #1368b1;
`;

// endregion

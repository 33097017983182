import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_REGISTERED_FLEET_LOADING,
  GET_REGISTERED_FLEET_SUCCESS,
  GET_REGISTERED_FLEET_FAIL,
} from '../../../shared/constants/FleetConstants/getRegisteredFleetConstants';

const getRegisteredFleet = (companyId, fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REGISTERED_FLEET_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.get(
      ApiURL.GET_REGISTERED_FLEET + companyId + '/fleet/' + fleetId + '/get',
      config
    );
    dispatch({
      type: GET_REGISTERED_FLEET_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_REGISTERED_FLEET_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_REGISTERED_FLEET_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getRegisteredFleet;

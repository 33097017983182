import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorAdditional, colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const ManageCompanyContainer = ({ img, assettype, link, totalnumber }) => (
  <ManageCompanyContainerWrap>
    <ManageCompanyContainerImageWrap>
      <img src={img} alt="" />
    </ManageCompanyContainerImageWrap>
    <div>
      <ManageCompanyContainerName>{assettype}</ManageCompanyContainerName>
      <ManageCompanyContainerCount>{totalnumber}</ManageCompanyContainerCount>
    </div>
    <ManageCompanyContainerLink>
      <span style={{ color: '#1368B1' }} className="lnr lnr-chevron-right" />
    </ManageCompanyContainerLink>
  </ManageCompanyContainerWrap>
);

ManageCompanyContainer.propTypes = {
  img: PropTypes.string.isRequired,
  assettype: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default ManageCompanyContainer;

// region STYLES

const ManageCompanyContainerWrap = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const ManageCompanyContainerLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;

  // svg {
  //   ${marginRight}: 0;
  //   margin-top: 2px;
  //   height: 16px;
  //   width: 16px;
  // }
`;

const ManageCompanyContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const ManageCompanyContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
`;

const ManageCompanyContainerCount = styled.p`
  color: ${colorAdditional};
  line-height: 15px;
  font-size: 11px;
  margin: 0;

  @media (max-width: 1100px) and (min-width: 990px) {
    font-size: 10px;
  }
`;

// endregion

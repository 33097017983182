import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  GET_COMPANY_RELATION_LOADING,
  GET_COMPANY_RELATION_SUCCESS,
  GET_COMPANY_RELATION_FAIL,
} from '../../../shared/constants/CompanyConstants/getCompanyRelationConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getCompanyRelation = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_RELATION_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_COMPANY_RELATION,
      getConfig()
    );

    dispatch({
      type: GET_COMPANY_RELATION_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPANY_RELATION_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMPANY_RELATION_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getCompanyRelation;

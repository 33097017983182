import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_COMPS_LOADING,
  GET_COMPS_SUCCESS,
  GET_COMPS_LIST_FAIL,
} from '../../shared/constants/getCompsConstants';
import axios from 'axios';

const getComps = hvacId => async dispatch => {
  try {
    dispatch({
      type: GET_COMPS_LOADING,
    });

    // setting up the config as headers content-type
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      ApiURL.GET_COMPS + hvacId + '/components',
      config
    );

    dispatch({
      type: GET_COMPS_SUCCESS,
      payload: data.data.components,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPS_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMPS_LIST_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export default getComps;

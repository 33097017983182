// Gateway Diagnostics List Constants

export const GET_GATEWAY_DIAGNOSTICS_LIST_LOADING = 'GET_GATEWAY_DIAGNOSTICS_LIST_LOADING';
export const GET_GATEWAY_DIAGNOSTICS_LIST_SUCCESS = 'GET_GATEWAY_DIAGNOSTICS_LIST_SUCCESS';
export const GET_GATEWAY_DIAGNOSTICS_LIST_FAIL = 'GET_GATEWAY_DIAGNOSTICS_LIST_FAIL';

// Gateway Diagnostics Listing Constants

export const GET_GATEWAY_DIAGNOSTICS_LISTING_LOADING = 'GET_GATEWAY_DIAGNOSTICS_LISTING_LOADING';
export const GET_GATEWAY_DIAGNOSTICS_LISTING_SUCCESS = 'GET_GATEWAY_DIAGNOSTICS_LISTING_SUCCESS';
export const GET_GATEWAY_DIAGNOSTICS_LISTING_FAIL = 'GET_GATEWAY_DIAGNOSTICS_LISTING_FAIL';
export const GET_GATEWAY_DIAGNOSTICS_LISTING_RESET = 'GET_GATEWAY_DIAGNOSTICS_LISTING_RESET';

// Gateway Diagnostics Month Constants

export const GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING = 'GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING';
export const GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS = 'GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS';
export const GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL = 'GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL';
export const GET_GATEWAY_DIAGNOSTICS_MONTH_RESET = 'GET_GATEWAY_DIAGNOSTICS_MONTH_RESET';

import {
  SET_OTA_ROW_ACTION,
  RESET_OTA_ROW_ACTION,
} from "@/shared/constants/OtaUpdateConstants/otaDashboardConstants";

const initialState = {
  info: "",
};

const updateOtaRowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OTA_ROW_ACTION:
      return {
        info: action.payload,
      };
    case RESET_OTA_ROW_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default updateOtaRowReducer;

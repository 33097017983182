import {
  GET_COMPANY_TAGS_LOADING,
  GET_COMPANY_TAGS_SUCCESS,
  GET_COMPANY_TAGS_FAIL,
} from '../../../shared/constants/VehicleDashboardConstants/getCompanyTagsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getCompanyTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_TAGS_LOADING:
      return { ...state, loading: true };
    case GET_COMPANY_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_COMPANY_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getCompanyTagsReducer;

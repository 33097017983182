import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import GatewayDiagnosticsForm from "./GatewayDiagnosticsForm";

const Filters = (props) => {
  return (
    <Row className={"d-flex justify-content-end"}>
      <Col md={6} lg={6} sm={12}>
        <GatewayDiagnosticsForm {...props} />
      </Col>
    </Row>
  );
};

export default Filters;

import { ApiURL } from '../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_VEHICLE_LOCATION_LOADING,
  GET_VEHICLE_LOCATION_SUCCESS,
  GET_VEHICLE_LOCATION_FAIL,
} from '../../shared/constants/getVehicleLocationConstants';

const getVehicleLocation = vin => async dispatch => {
  try {
    dispatch({
      type: GET_VEHICLE_LOCATION_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_VEHICLE_LOCATION + vin + '/gateway/gps',
      config
    );
    dispatch({
      type: GET_VEHICLE_LOCATION_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_VEHICLE_LOCATION_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_VEHICLE_LOCATION_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getVehicleLocation;

import {
  GET_REGISTERED_FLEET_LOADING,
  GET_REGISTERED_FLEET_SUCCESS,
  GET_REGISTERED_FLEET_FAIL,
  GET_REGISTERED_FLEET_RESET,
} from '../../../shared/constants/FleetConstants/getRegisteredFleetConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getRegisteredFleetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTERED_FLEET_LOADING:
      return { ...state, loading: true };
    case GET_REGISTERED_FLEET_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_REGISTERED_FLEET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REGISTERED_FLEET_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default getRegisteredFleetReducer;

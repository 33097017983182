import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_USER_LIST_LOADING,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
} from '../../../shared/constants/UserConstants/getUserListConstants';

const getUserList = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_LIST_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_USER_LIST + companyId + '/get_company_user_list',
      config
    );

    dispatch({
      type: GET_USER_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_USER_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_USER_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getUserList;

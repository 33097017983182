import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_SIGNALS_LOADING,
  GET_SIGNALS_SUCCESS,
  GET_SIGNALS_FAIL,
  GET_SIGNALS_RESET,
} from '../../../shared/constants/DiagnosticsConstants/diagnosticsConstants';
import { getConfig } from '../../../shared/helpers/apiConfig';

const getSignals = (vin, startTime, endTime) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIGNALS_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_SIGNALS +
        '?vin=' +
        vin +
        '&start_time=' +
        startTime +
        '&end_time=' +
        endTime,
      getConfig()
    );

    dispatch({
      type: GET_SIGNALS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_SIGNALS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_SIGNALS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getSignalsReset = () => (dispatch) => {
  dispatch({
    type: GET_SIGNALS_RESET,
  });
};

export default getSignals;

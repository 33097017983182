import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import KpiCard from '@/shared/components/KpiCard';
import getTotalVehicles from '../../../../redux/actions/DepotDashboardActions/getTotalVehiclesActions';

const DepotVehicleCount = ({ fleet_id, depot_id, changeComp, setChangeComp, companyId, tag }) => {
  const dispatch = useDispatch();

  const { loading, info, error } = useSelector((state) => state.getTotalVehiclesReducer);

  const GREEN_COLOR = '006B38';
  const ORANGE_COLOR = 'F57A08';
  const RED_COLOR = 'D21D26';
  const GREY_COLOR = 'A7AEB4';

  useEffect(() => {
    dispatch(getTotalVehicles(companyId, fleet_id, depot_id, tag));
    setChangeComp(false);
  }, [depot_id, tag]);

  return (
    <>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Green Vehicles'}
          color={'#006B38'}
          gradient={'green-vehicles'}
          count={info.total_vehicles && info.total_vehicles[1][GREEN_COLOR]}
          total={info.total_vehicles && info.total_vehicles[0].total_vehicle}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Orange Vehicles'}
          color={'#F57A08'}
          gradient={'orange-vehicles'}
          count={info.total_vehicles && info.total_vehicles[1][ORANGE_COLOR]}
          total={info.total_vehicles && info.total_vehicles[0].total_vehicle}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Red Vehicles'}
          color={'#D21D26'}
          gradient={'red-vehicles'}
          count={info.total_vehicles && info.total_vehicles[1][RED_COLOR]}
          total={info.total_vehicles && info.total_vehicles[0].total_vehicle}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Grey Vehicles'}
          color={'#A7AEB4'}
          gradient={'grey-vehicles'}
          count={info.total_vehicles && info.total_vehicles[1][GREY_COLOR]}
          total={info.total_vehicles && info.total_vehicles[0].total_vehicle}
          loading={loading}
          error={error}
        />
      </Col>
    </>
  );
};

export default DepotVehicleCount;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_HEALTH_STATUS_SUMMARY_SUMMARY_LOADING,
  GET_HEALTH_STATUS_SUMMARY_SUCCESS,
  GET_HEALTH_STATUS_SUMMARY_FAIL,
} from '@/shared/constants/DepotDashboardConstants/depotDashboardConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getDepotHealthStatusSummary = (companyId, fleetId, depotId, tag) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HEALTH_STATUS_SUMMARY_SUMMARY_LOADING,
    });

    const params = {
      tag_id: tag ? tag : null,
    };

    const { data } = await axios.get(
      ApiURL.GET_HEALTH_STATUS_SUMMARY +
        companyId +
        '/fleets/' +
        fleetId +
        '/depots/' +
        depotId +
        '/healthstatusvehicle',
      { params },
      getConfig()
    );

    const alarmData = data.data.health_status.alarms;
    const totalAlarms = data.data.health_status.total_count;

    const filteredData = Object.values(alarmData).map(({ alarm_type, color_code, count }) => ({
      name: alarm_type,
      fill: `#${color_code}`,
      value: count,
    }));

    dispatch({
      type: GET_HEALTH_STATUS_SUMMARY_SUCCESS,
      payload: { alarmData: filteredData, total: totalAlarms },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_HEALTH_STATUS_SUMMARY_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_HEALTH_STATUS_SUMMARY_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getDepotHealthStatusSummary;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import CompanyDetailsContainer from '../ManageCompany/CompanyDetailsContainer';
import ManageUserContainer from './ManageUserContainer';
import UserForm from './components/UserForm';
import UserSelectForm from './components/UserSelectForm';
import getRegisteredCompany from '../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import getUserList from '../../../redux/actions/UserActions/getUserListActions';
import ViewUser from './components/ViewUser';

const ManageUser = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, info, error } = useSelector((state) => state.getRegisteredCompanyReducer);

  const assets = ['Add Users', 'Edit Users', 'View Users'];

  const addImage = `${process.env.PUBLIC_URL}/img/Add.jpg`;
  const editImage = `${process.env.PUBLIC_URL}/img/Edit.jpg`;
  const viewImage = `${process.env.PUBLIC_URL}/img/View.jpg`;

  const assetData = [
    {
      id: 1,
      img: addImage,
      assetType: assets[0],
    },
    {
      id: 2,
      img: editImage,
      assetType: assets[1],
    },
    {
      id: 3,
      img: viewImage,
      assetType: assets[2],
    },
  ];

  const [showUserForm, setShowUserForm] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSelectUser, setShowSelectUser] = useState(false);
  const [showUserView, setShowUserView] = useState(false);
  const companyId = localStorage.getItem('companyId');

  const toggleUserForm = () => {
    setGoBack(false);
    setShowUserForm(true);
    setShowSelectUser(false);
  };

  const toggleUserSelect = () => {
    setGoBack(false);
    setShowUserForm(false);
    setShowSelectUser(true);
  };
  const toggleUserView = () => {
    if (companyId) {
      dispatch(getUserList(companyId));
    }
    setGoBack(false);
    setShowUserForm(false);
    setShowSelectUser(false);
    setShowUserView(true);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getRegisteredCompany(companyId));
    }
  }, []);
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              {loading || !info || info === undefined ? (
                <DotDotLoader loadingState={loading} />
              ) : (
                <CompanyDetailsContainer
                  img={Ava}
                  name={info.company.name}
                  website={info.company.website}
                  role={info.company.company_type}
                  city={info.company.city}
                  country={info.company.country}
                  showLink={false}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {showUserForm === true && goBack === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>User Registration Section</h6>
            <Col xs={12} sm={12} md={6}>
              <UserForm setGoBack={setGoBack} editMode={editMode} />
            </Col>
          </>
        ) : showSelectUser === true && goBack === false && showUserForm === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Edit User details</h6>
            <Col xs={12} sm={12} md={6}>
              <UserSelectForm setGoBack={setGoBack} />
            </Col>
          </>
        ) : showSelectUser === false &&
          goBack === false &&
          showUserForm === false &&
          showUserView === true ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>View User details</h6>
            <Col xs={12} sm={12} md={6}>
              <ViewUser setGoBack={setGoBack} />
            </Col>
          </>
        ) : (
          <>
            <h6 style={{ paddingBottom: '20px' }}>User Settings</h6>
            <Col xs={12} sm={12} md={6}>
              <Card height="auto">
                <CardBody>
                  {assetData.map(({ id, assetType, img }) => (
                    <div
                      key={id}
                      onClick={() =>
                        assetType === assets[0]
                          ? toggleUserForm()
                          : assetType === assets[1]
                          ? toggleUserSelect()
                          : assetType === assets[2]
                          ? toggleUserView()
                          : ''
                      }
                    >
                      <ManageUserContainer img={img} assettype={assetType} />
                    </div>
                  ))}
                  <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{ width: '100%' }}
                    >
                      Back
                    </Button>
                  </FormButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageUser;

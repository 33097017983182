import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon';
import WebIcon from 'mdi-react/WebIcon';
import CityIcon from 'mdi-react/CityIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import CogIcon from 'mdi-react/CogIcon';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { registerCompanyValidate } from '@/utils/validate.js';
import BusinessNetworkForm from './BusinessNetworkForm';
import addCompany, {
  addCompanyReset,
} from '../../../../redux/actions/CompanyActions/addCompanyActions';
import updateCompany, {
  updateCompanyReset,
} from '../../../../redux/actions/CompanyActions/updateCompanyActions';
import getCompanyRelation from '../../../../redux/actions/CompanyActions/getCompanyRelationActions';
import getRoleList from '../../../../redux/actions/RolesActions/getRoleListActions';

const CompanyForm = ({ editMode, setGoBack, setRegisterMode, setChangeComp }) => {
  const dispatch = useDispatch();

  const getRegisteredCompanyReducerInfo = useSelector(
    (state) => state.getRegisteredCompanyReducer.info
  );
  const getRegisteredCompanyReducerLoading = useSelector(
    (state) => state.getRegisteredCompanyReducer.loading
  );
  const getRoleListReducerInfo = useSelector((state) => state.getRoleListReducer.info);
  const addCompanyReducerLoading = useSelector((state) => state.addCompanyReducer.loading);
  const addCompanyReducerInfo = useSelector((state) => state.addCompanyReducer.info);
  const updateCompanyReducerInfo = useSelector((state) => state.updateCompanyReducer.info);
  const updateCompanyReducerLoading = useSelector((state) => state.updateCompanyReducer.loading);
  const [apiData, setApiData] = useState(
    getRegisteredCompanyReducerInfo ? getRegisteredCompanyReducerInfo.company : {}
  );
  const [btnClicked, setBtnClicked] = useState(false);
  const [addCompanyMode, setAddCompanyMode] = useState(false);
  const [showOtherForm, setShowOtherForm] = useState(false);
  const [updateBtnClicked, setUpdateBtnClicked] = useState(false);
  const companyId = localStorage.getItem('companyId');

  const handleCancel = () => {
    setRegisterMode(false);
    setGoBack(false);
  };

  const handleRegister = (values) => {
    if (Object.keys(values).length !== 0) {
      dispatch(addCompany(values));
      setBtnClicked(true);
    }
  };

  const handleUpdate = (values) => {
    if (Object.keys(values).length !== 0) {
      dispatch(updateCompany(values, companyId));
      if (editMode) {
        setUpdateBtnClicked(true);
      }
    }
  };

  if (btnClicked === true && addCompanyReducerInfo !== '') {
    setTimeout(() => {
      // dispatch(addCompanyReset()); //reset in business network form cleanup
      // dispatch(getCompanyRelation());
      setAddCompanyMode(true);
      setShowOtherForm(true);
      setBtnClicked(false);
    }, 200);
  }

  if (updateBtnClicked === true && updateCompanyReducerInfo !== '') {
    setTimeout(() => {
      dispatch(updateCompanyReset());
      setChangeComp(false);
      setUpdateBtnClicked(false);
    }, 200);
  }

  const dropdownValue = [
    {
      id: apiData.company_id,
      value: apiData.company_type,
      label: apiData.company_type,
    },
  ];

  useEffect(() => {
    dispatch(getRoleList('COMPANY'));
    return () => {
      setBtnClicked(false);
    };
  }, []);

  return (
    <>
      {showOtherForm && addCompanyMode ? (
        <BusinessNetworkForm setShowBusinessNetworkForm={setShowOtherForm} mode={addCompanyMode} />
      ) : (
        <>
          {getRegisteredCompanyReducerLoading ? (
            <DotDotLoader loadingState={getRegisteredCompanyReducerLoading} />
          ) : (
            <Form
              onSubmit={!editMode ? handleRegister : handleUpdate}
              initialValues={editMode && apiData}
              validate={registerCompanyValidate}
            >
              {({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                  {addCompanyReducerLoading || updateCompanyReducerLoading ? (
                    <DotDotLoader
                      loadingState={addCompanyReducerLoading || updateCompanyReducerLoading}
                    />
                  ) : (
                    ''
                  )}
                  <FormGroup>
                    <FormGroupLabel>Company Name</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <OfficeBuildingIcon />
                      </FormGroupIcon>
                      <Field
                        name="name"
                        component={FormField}
                        type="text"
                        placeholder="Company Name"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Website</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <WebIcon />
                      </FormGroupIcon>
                      <Field
                        name="website"
                        component={FormField}
                        type="text"
                        placeholder="Website"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <CityIcon />
                      </FormGroupIcon>
                      <Field
                        name="city"
                        component={FormField}
                        type="text"
                        placeholder="City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Country</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <CityIcon />
                      </FormGroupIcon>
                      <Field
                        name="country"
                        component={FormField}
                        type="text"
                        placeholder="Country"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Description</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <TextBoxIcon />
                      </FormGroupIcon>
                      <Field
                        name="description"
                        component={FormField}
                        type="text"
                        placeholder="Description"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Company Type</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <CogIcon />
                      </FormGroupIcon>
                      <Field
                        name="company_type"
                        component={renderSelectField}
                        type="text"
                        options={
                          getRoleListReducerInfo
                            ? getRoleListReducerInfo.roles.map(({ id, description }) => ({
                                id: id,
                                value: description,
                                label: description,
                              }))
                            : []
                        }
                        placeholder="Select company type"
                        initialValue={editMode ? dropdownValue : ''}
                        isDisabled={editMode && true}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {/* ADD CODE UPLADING IMAGE */}
                  {/* <FormGroup>
                      <FormGroupLabel>Add file</FormGroupLabel>
                      <FormGroupField>
                        <FormGroupIcon>
                          <EmailIcon />
                        </FormGroupIcon>
                        <Field
                          name='fileVertical'
                          component={renderFileInputField}
                          required
                        />
                      </FormGroupField>
                    </FormGroup> */}
                  {!editMode ? (
                    <>
                      <FormButtonToolbar
                        style={{
                          width: '50%',
                          paddingRight: '10px',
                          paddingTop: '20px',
                          margin: '0',
                        }}
                      >
                        <Button
                          variant="secondary"
                          type="button"
                          style={{ width: '100%', margin: '0' }}
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                      <FormButtonToolbar
                        style={{
                          width: '50%',
                          paddingLeft: '10px',
                          margin: '0',
                          paddingTop: '20px',
                        }}
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ width: '100%', margin: '0' }}
                        >
                          Register
                        </Button>
                      </FormButtonToolbar>
                    </>
                  ) : (
                    <>
                      <FormButtonToolbar
                        style={{
                          width: '50%',
                          paddingRight: '10px',
                          paddingTop: '20px',
                          margin: '0',
                        }}
                      >
                        <Button
                          variant="secondary"
                          type="button"
                          style={{ width: '100%', margin: '0' }}
                          onClick={() => setChangeComp(false)}
                        >
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                      <FormButtonToolbar
                        style={{
                          width: '50%',
                          paddingLeft: '10px',
                          margin: '0',
                          paddingTop: '20px',
                        }}
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ width: '100%', margin: '0' }}
                        >
                          Update
                        </Button>
                      </FormButtonToolbar>
                    </>
                  )}
                </FormContainer>
              )}
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default CompanyForm;

import {
  GET_FLEETS_LOADING,
  GET_FLEETS_SUCCESS,
  GET_FLEETS_FAIL,
  GET_ALL_FLEETS_LOADING,
  GET_ALL_FLEETS_SUCCESS,
  GET_ALL_FLEETS_FAIL,
} from '../../shared/constants/getAssociationsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
  allfleetsloading: false,
  allfleetsinfo: '',
  allfleetserror: '',
};
const getFleetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLEETS_LOADING:
      return { ...state, loading: true };
    case GET_FLEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_FLEETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_FLEETS_LOADING:
      return { ...state, allfleetsloading: true };
    case GET_ALL_FLEETS_SUCCESS:
      return {
        ...state,
        allfleetsloading: false,
        allfleetsinfo: action.payload,
      };
    case GET_ALL_FLEETS_FAIL:
      return {
        ...state,
        allfleetsloading: false,
        allfleetserror: action.payload,
      };
    default:
      return state;
  }
};

export default getFleetsReducer;

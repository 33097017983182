import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import ProgressBar from '@/shared/components/ProgressBar';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { percentage } from '@/utils/helpers';
import getDepotHvacByBrand from '../../../../redux/actions/DepotDashboardActions/getDepotHvacByBrand';

const DepotHvacByBrand = ({ fleet_id, depot_id, changeComp, setChangeComp, companyId, tag }) => {
  const dispatch = useDispatch();

  const { loading, info, error } = useSelector((state) => state.getDepotHvacByBrandReducer);

  useEffect(() => {
    dispatch(getDepotHvacByBrand(companyId, fleet_id, depot_id, tag));
    setChangeComp(false);
  }, [depot_id, tag]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Hvac units by brands</CardTitle>
          <CardSubhead>HVAC distribution by brands registered</CardSubhead>
        </CardTitleWrap>
        <MainContainer>
          {loading ? (
            <DotDotLoader loadingState={loading} />
          ) : info ? (
            info
              .filter((item) => item.type === 'supplier_name')
              .map((item) =>
                Object.entries(item.values)
                  .filter(([key]) => key !== 'total')
                  .map(([key, val]) => (
                    <Fragment key={`${key} - ${val}`}>
                      <p>{`${key} - ${val}`}</p>
                      <ProgressBar
                        top
                        now={percentage(val, item.values['total'])}
                        label={`${percentage(val, item.values['total'])}%`}
                        size="small"
                        gradient="total-vehicles"
                        rounded
                      />
                    </Fragment>
                  ))
              )
          ) : (
            'No data found'
          )}
        </MainContainer>
      </CardBody>
    </Card>
  );
};

export default DepotHvacByBrand;

const MainContainer = styled.div`
  overflow-y: hidden;
  height: 360px;

  &:hover {
    overflow-y: auto;
  }
`;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getCompanyData from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getCompanyDataAction';
import { getGroupListReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getGroupListAction';

const SharedForm = ({ form, onDepotChange, disableFields, editMode, initialValues }) => {
  const dispatch = useDispatch();

  const [companyData, setCompanyData] = useState('');
  const [fleetData, setFleetData] = useState('');
  const { loading, filterOptions } = useSelector((state) => state.getCompanyDataReducer);

  const getAllRegisteredFields = form
    .getRegisteredFields()
    .filter((item) => !item.includes('company'));

  const onCompanyChange = (e) => {
    if (e) {
      setCompanyData(`pto_id=${e.id}`);
    }
    if (!e) {
      setCompanyData('');
      setFleetData('');
      getAllRegisteredFields.map((item) => form.change(item));
      dispatch(getGroupListReset());
    }
  };

  const onFleetChange = (e) => {
    if (e) {
      setFleetData(`&fleet_id=${e.id}`);
    }
    if (!e) {
      setFleetData('');
      getAllRegisteredFields.map((item) => form.change(item));
      dispatch(getGroupListReset());
    }
  };

  useEffect(() => {
    if (!editMode) {
      dispatch(getCompanyData(companyData, fleetData));
    }
    return () => {
      dispatch(getGroupListReset());
    };
  }, [companyData, fleetData]);

  return (
    <>
      <FormGroup>
        <FormGroupLabel>Company</FormGroupLabel>
        <FormGroupField>
          <Field
            name="company"
            component={renderSelectField}
            options={filterOptions.operators}
            placeholder="Select company"
            isClearable
            parse={(e) => {
              onCompanyChange(e);
              return e;
            }}
            isDisabled={disableFields ? true : false}
            isLoading={loading}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Fleet</FormGroupLabel>
        <FormGroupField>
          <Field
            name="fleet"
            component={renderSelectField}
            options={filterOptions && companyData ? filterOptions.fleets : []}
            placeholder="Select fleet"
            isClearable
            parse={(e) => {
              onFleetChange(e);
              return e;
            }}
            isDisabled={disableFields ? true : false}
            isLoading={loading}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Depot</FormGroupLabel>
        <FormGroupField>
          <Field
            name="depot"
            component={renderSelectField}
            options={filterOptions && companyData && fleetData ? filterOptions.depots : []}
            placeholder="Select depot"
            isClearable
            parse={(e) => {
              onDepotChange(e, form);
              return e;
            }}
            isDisabled={disableFields ? true : false}
            isLoading={loading}
          />
        </FormGroupField>
      </FormGroup>
    </>
  );
};

export default SharedForm;

//OTA Update - HVAC Models Constants
export const GET_HVAC_MODELS_LOADING = 'GET_HVAC_MODELS_LOADING';
export const GET_HVAC_MODELS_SUCCESS = 'GET_HVAC_MODELS_SUCCESS';
export const GET_HVAC_MODELS_FAIL = 'GET_HVAC_MODELS_FAIL';
export const GET_HVAC_MODELS_RESET = 'GET_HVAC_MODELS_RESET';

//OTA Update - Group Device List By Modal Constants
export const GET_GROUP_DEVICE_LIST_BY_MODAL_LOADING = 'GET_GROUP_DEVICE_LIST_BY_MODAL_LOADING';
export const GET_GROUP_DEVICE_LIST_BY_MODAL_SUCCESS = 'GET_GROUP_DEVICE_LIST_BY_MODAL_SUCCESS';
export const GET_GROUP_DEVICE_LIST_BY_MODAL_FAIL = 'GET_GROUP_DEVICE_LIST_BY_MODAL_FAIL';
export const GET_GROUP_DEVICE_LIST_BY_MODAL_RESET = 'GET_GROUP_DEVICE_LIST_BY_MODAL_RESET';

//OTA Update - Group Device List By Others Constants
export const GET_GROUP_DEVICE_LIST_BY_OTHERS_LOADING = 'GET_GROUP_DEVICE_LIST_BY_OTHERS_LOADING';
export const GET_GROUP_DEVICE_LIST_BY_OTHERS_SUCCESS = 'GET_GROUP_DEVICE_LIST_BY_OTHERS_SUCCESS';
export const GET_GROUP_DEVICE_LIST_BY_OTHERS_FAIL = 'GET_GROUP_DEVICE_LIST_BY_OTHERS_FAIL';
export const GET_GROUP_DEVICE_LIST_BY_OTHERS_RESET = 'GET_GROUP_DEVICE_LIST_BY_OTHERS_RESET';

//OTA Update - Create Group Constants
export const CREATE_GROUP_LOADING = 'CREATE_GROUP_LOADING';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';
export const CREATE_GROUP_RESET = 'CREATE_GROUP_RESET';

//OTA Update - Update Group Constants
export const UPDATE_GROUP_LOADING = 'UPDATE_GROUP_LOADING';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';
export const UPDATE_GROUP_RESET = 'UPDATE_GROUP_RESET';

//OTA Update - Get Group Details Constants
export const GET_GROUP_DETAILS_LOADING = 'GET_GROUP_DETAILS_LOADING';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAIL = 'GET_GROUP_DETAILS_FAIL';
export const GET_GROUP_DETAILS_RESET = 'GET_GROUP_DETAILS_RESET';

//OTA Update - Group Management - Gateway Constants
//OTA Update - Get Gateway Group List Constants
export const GET_GATEWAY_DEVICE_LIST_LOADING = 'GET_GATEWAY_DEVICE_LIST_LOADING';
export const GET_GATEWAY_DEVICE_LIST_SUCCESS = 'GET_GATEWAY_DEVICE_LIST_SUCCESS';
export const GET_GATEWAY_DEVICE_LIST_FAIL = 'GET_GATEWAY_DEVICE_LIST_FAIL';
export const GET_GATEWAY_DEVICE_LIST_RESET = 'GET_GATEWAY_DEVICE_LIST_RESET';

//OTA Update - Create Gateway Group Constants
export const CREATE_GATEWAY_GROUP_LOADING = 'CREATE_GATEWAY_GROUP_LOADING';
export const CREATE_GATEWAY_GROUP_SUCCESS = 'CREATE_GATEWAY_GROUP_SUCCESS';
export const CREATE_GATEWAY_GROUP_FAIL = 'CREATE_GATEWAY_GROUP_FAIL';
export const CREATE_GATEWAY_GROUP_RESET = 'CREATE_GATEWAY_GROUP_RESET';

//OTA Update - Update Gateway Group Constants
export const UPDATE_GATEWAY_GROUP_LOADING = 'UPDATE_GATEWAY_GROUP_LOADING';
export const UPDATE_GATEWAY_GROUP_SUCCESS = 'UPDATE_GATEWAY_GROUP_SUCCESS';
export const UPDATE_GATEWAY_GROUP_FAIL = 'UPDATE_GATEWAY_GROUP_FAIL';
export const UPDATE_GATEWAY_GROUP_RESET = 'UPDATE_GATEWAY_GROUP_RESET';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import CompanyDetailsContainer from '../ManageCompany/CompanyDetailsContainer';
import ManageRoleContainer from './ManageRoleContainer';
import RoleForm from './components/RoleForm';
import RoleSelectForm from './components/RoleSelectForm';
import ViewRole from './components/ViewRole';
import getRegisteredCompany from '../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import getRoleList from '../../../redux/actions/RolesActions/getRoleListActions';

const ManageRole = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, info, error } = useSelector((state) => state.getRegisteredCompanyReducer);

  const assets = ['Add Roles', 'Edit Roles', 'View Roles'];

  const addImage = `${process.env.PUBLIC_URL}/img/Add.jpg`;
  const editImage = `${process.env.PUBLIC_URL}/img/Edit.jpg`;
  const viewImage = `${process.env.PUBLIC_URL}/img/View.jpg`;

  const assetData = [
    {
      id: 1,
      img: addImage,
      assetType: assets[0],
    },
    {
      id: 2,
      img: editImage,
      assetType: assets[1],
    },
    {
      id: 3,
      img: viewImage,
      assetType: assets[2],
    },
  ];

  const [showRoleForm, setShowRoleForm] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSelectRole, setShowSelectRole] = useState(false);
  const [showRoleView, setShowRoleView] = useState(false);
  const getCompanyId = localStorage.getItem('companyId');

  const toggleRoleForm = () => {
    setGoBack(false);
    setShowRoleForm(true);
    setShowSelectRole(false);
  };

  const toggleRoleSelect = () => {
    setGoBack(false);
    setShowRoleForm(false);
    setShowSelectRole(true);
  };

  const toggleRoleView = () => {
    dispatch(getRoleList('USER'));
    setGoBack(false);
    setShowRoleForm(false);
    setShowSelectRole(false);
    setShowRoleView(true);
  };

  useEffect(() => {
    if (getCompanyId) {
      dispatch(getRegisteredCompany(getCompanyId));
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              {loading || !info || info === undefined ? (
                <DotDotLoader loadingState={loading} />
              ) : (
                <CompanyDetailsContainer
                  img={Ava}
                  name={info.company.name}
                  website={info.company.website}
                  role={info.company.company_type}
                  city={info.company.city}
                  country={info.company.country}
                  showLink={false}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {showRoleForm === true && goBack === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Role Registration Section</h6>
            <Col xs={12} sm={12} md={6}>
              <RoleForm setGoBack={setGoBack} editMode={editMode} />
            </Col>
          </>
        ) : showSelectRole === true && goBack === false && showRoleForm === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Edit Role details</h6>
            <Col xs={12} sm={12} md={6}>
              <RoleSelectForm setGoBack={setGoBack} />
            </Col>
          </>
        ) : showSelectRole === false &&
          goBack === false &&
          showRoleForm === false &&
          showRoleView === true ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>View Role details</h6>
            <Col xs={12} sm={12} md={6}>
              <ViewRole setGoBack={setGoBack} />
            </Col>
          </>
        ) : (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Role Settings</h6>
            <Col xs={12} sm={12} md={6}>
              <Card height="auto">
                <CardBody>
                  {assetData
                    .filter((newItem) => newItem.assetType === assets[2])
                    .map(({ id, assetType, img }) => (
                      <div
                        key={id}
                        onClick={() => (assetType === assets[2] ? toggleRoleView() : '')}
                      >
                        <ManageRoleContainer img={img} assettype={assetType} />
                      </div>
                    ))}
                  <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{ width: '100%' }}
                    >
                      Back
                    </Button>
                  </FormButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageRole;

import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import { GetTableHeader } from '@/utils/helpers';

const CreateTableData = () => {
  const history = useHistory();
  const [refreshTable, setRefreshTable] = useState(false);
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const handlePageChange = (props) => {
    localStorage.setItem('viewVin', JSON.stringify(props.row.original.vin));
    history.push('/global/depot/vehicle');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.status === 'COMPLETED' ? (
                <a href={props.row.original.file_url + '/' + props.row.original.file_name}>
                  <FileDownloadIcon style={{ cursor: 'pointer' }} />
                </a>
              ) : (
                <FileDownloadIcon style={{ cursor: 'pointer', color: '#6c757d' }} />
              )}
            </>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'VIN'}
            key={'VIN'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vin',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <div>
              <LabelSpan onClick={() => handlePageChange(props)} style={{ cursor: 'pointer' }}>
                {props.row.original.vin}
              </LabelSpan>
              <p style={{ fontSize: '10px' }}>{props.row.original.operator_name}</p>
            </div>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Start time (in UTC)'}
            key={'Start time (in UTC)'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'can_dump_start_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'End time (in UTC)'}
            key={'End time (in UTC)'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'can_dump_end_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'CAN channel'}
            key={'CAN channel'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'metadata.can_channel',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Duration (in seconds)'}
            key={'Duration (in seconds)'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'metadata.duration',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'User'}
            key={'User'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'user_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Remarks'}
            key={'Remarks'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'remarks',
        disableGlobalFilter: true,
      },
    ],
    []
  );
  const reactTableData = {
    tableHeaderData: columns,
    refreshTable: refreshTable,
    setRefreshTable: setRefreshTable,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

const LabelSpan = styled.span`
  cursor: pointer;
  color: #1368b1;

  &:hover {
    text-decoration: underline;
  }
`;

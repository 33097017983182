import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SystemSettings from './components/SystemSettings';

const ManageSettings = () => {
  return (
    <Container className='dashboard'>
      <Row className='pb-4'>
        <Col md={6}>
          <h3 className='page-title'>Manage Settings</h3>
          <h6>Configure system settings</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <SystemSettings />
        </Col>
      </Row>
    </Container>
  );
};

export default ManageSettings;

import axios from 'axios';
import {
  EDIT_GATEWAY_SIM_LOADING,
  EDIT_GATEWAY_SIM_SUCCESS,
  EDIT_GATEWAY_SIM_FAIL,
  EDIT_GATEWAY_SIM_RESTORE_INITIAL_VALUES,
} from '@/shared/constants/editGatewaySimConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../config/GlobalUrl';

const editGatewaySim = (gatewayNumber, sim, vinNumber) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_GATEWAY_SIM_LOADING });

    const response = await axios.post(
      ApiURL.EDIT_GATEWAY + vinNumber + '/gateway/update',
      {
        serial_num: gatewayNumber,
        sim_number: sim,
      },
      getConfig()
    );

    successMessage('Sim details updated successfully!');

    dispatch({
      type: EDIT_GATEWAY_SIM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: EDIT_GATEWAY_SIM_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: EDIT_GATEWAY_SIM_FAIL,
        payload: error.response,
      });
    }
  }
};
export const editGatewaySimReset = () => (dispatch) => {
  dispatch({
    type: EDIT_GATEWAY_SIM_RESTORE_INITIAL_VALUES,
  });
};

export default editGatewaySim;

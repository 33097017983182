import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { selectFleetValidate } from '@/utils/validate.js';
import FleetForm from './FleetForm';
import getFleetList from '../../../../redux/actions/FleetActions/getFleetListActions';

const FleetSelectForm = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const [showFleetEditForm, setShowFleetEditForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [fleetId, setFleetId] = useState();
  const [btnClicked, setBtnClicked] = useState(false);

  const companyId = localStorage.getItem('companyId');

  const getRegisteredFleetReducerLoading = useSelector(
    (state) => state.getRegisteredFleetReducer.loading
  );

  const getRegisteredFleetReducerInfo = useSelector(
    (state) => state.getRegisteredFleetReducer.info
  );

  const getRegisteredFleetReducerError = useSelector(
    (state) => state.getRegisteredFleetReducer.error
  );

  const getFleetListReducerLoading = useSelector((state) => state.getFleetListReducer.loading);

  const getFleetListReducerInfo = useSelector((state) => state.getFleetListReducer.info);

  const getFleetListReducerError = useSelector((state) => state.getFleetListReducer.error);

  useEffect(() => {
    if (companyId) {
      dispatch(getFleetList(companyId));
    }
  }, [companyId]);

  const handleSubmit = (values) => {
    if (Object.keys(values).length > 0 && values.fleet.id) {
      setFleetId(values.fleet.id);
      setBtnClicked(true);
      setShowFleetEditForm(true);
    }
  };

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <>
      {showFleetEditForm === true ? (
        <FleetForm
          editMode={editMode}
          setShowFleetEditForm={setShowFleetEditForm}
          fleetId={fleetId}
        />
      ) : (
        <Card height='auto'>
          <CardBody>
            <Form onSubmit={handleSubmit} validate={selectFleetValidate}>
              {({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>Select Fleet</FormGroupLabel>

                    {getFleetListReducerLoading ? (
                      <DotDotLoader loadingState={getFleetListReducerLoading} />
                    ) : (
                      <FormGroupField>
                        <Field
                          name='fleet'
                          component={renderSelectField}
                          type='text'
                          options={
                            getFleetListReducerInfo
                              ? getFleetListReducerInfo.fleet_list.map(({ id, name }) => ({
                                  id: id,
                                  value: name,
                                  label: name,
                                }))
                              : []
                          }
                          placeholder='Select fleet'
                        />
                      </FormGroupField>
                    )}
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant='secondary' type='button' onClick={() => handleCancel()}>
                      Back
                    </Button>
                    <Button variant='primary' type='submit'>
                      Next
                    </Button>
                  </FormButtonToolbar>
                </FormContainer>
              )}
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default FleetSelectForm;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getGlobalDepotLocation from '@/redux/actions/GlobalDashboardActions/getGlobalDepotLocation';
import getMapFilters from '../../../../redux/actions/GlobalDashboardActions/getMapFiltersActions';
import {
  getDepotTags,
  getDepotTagsReset,
} from '../../../../redux/actions/VehicleDashboardActions/getCompanyTagsActions';
import data from '../../../App/Router/data';
import { createArr } from '@/utils/helpers';

const MapFilters = ({ showDepot, setChangeComp, tag }) => {
  const dispatch = useDispatch();
  const { company_id, company_name, company_role_name } = JSON.parse(localStorage.getItem('user'));
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  const dashboardDetails = JSON.parse(localStorage.getItem('dashboardDetails'));
  const fleetId = dashboardDetails ? dashboardDetails.fleet : '';
  const fleetName = dashboardDetails ? dashboardDetails.fleetName : '';
  const depotId = dashboardDetails ? dashboardDetails.depot : '';
  const depotName = dashboardDetails ? dashboardDetails.depotName : '';
  const operatorName = dashboardDetails ? dashboardDetails.operatorName : '';
  const operatorId = dashboardDetails ? dashboardDetails.operatorId : '';

  //values from localStorage
  const initFleet = showDepot && fleetId ? fleetId : '';
  const initOperator = showDepot && operatorId ? operatorId : '';
  const initDepot = showDepot && depotId ? depotId : '';

  const [operatorSelected, setOperatorSelected] = useState(initOperator);
  const [spSelected, setSpSelected] = useState('');
  const [fleetSelected, setFleetSelected] = useState(initFleet);
  const [depotSelected, setDepotSelected] = useState(initDepot);
  const [depotColorSelected, setDepotColorSelected] = useState('');
  const shouldReset = false;

  const getMapFiltersReducer = useSelector((state) => state.getMapFiltersReducer.info);
  const getMapFiltersReducerLoading = useSelector((state) => state.getMapFiltersReducer.loading);
  const getDepotTagsReducerInfo = useSelector((state) => state.getDepotTagsReducer.info);

  useEffect(() => {
    dispatch(getMapFilters(operatorSelected, spSelected, fleetSelected, depotSelected));
    if (!showDepot) {
      dispatch(
        getGlobalDepotLocation(operatorSelected, spSelected, fleetSelected, depotColorSelected)
      );
    }
  }, [operatorSelected, spSelected, fleetSelected, depotSelected, depotColorSelected]);

  const updateLocal = (fleet_id, fleet_name, depot_id, depot_name, operator_name, operator_id) => {
    const updatedDashboardDetails = {
      fleet: fleet_id,
      fleetName: fleet_name,
      depot: depot_id,
      depotName: depot_name,
      operatorName: operator_name,
      operatorId: operator_id,
    };
    localStorage.setItem('dashboardDetails', JSON.stringify(updatedDashboardDetails));
    setChangeComp(JSON.stringify(updatedDashboardDetails));
  };

  const handleOperatorChange = (values) => {
    showDepot && tag('');
    setFleetSelected('');
    setDepotSelected('');
    if (values) {
      setOperatorSelected(values.id);
      showDepot && updateLocal(fleetId, fleetName, depotId, depotName, values.value, values.id);
    }
    if (!values) {
      setOperatorSelected('');
      showDepot && updateLocal(0, '', 0, '', '', 0);
    }
  };

  const handleServiceProviderChange = (e) => {
    if (e) {
      setSpSelected(e.id);
    }
    if (!e) {
      setSpSelected('');
    }
  };

  const handleFleetChange = (e) => {
    showDepot && tag('');
    setDepotSelected('');
    if (e) {
      setFleetSelected(e.id);
      showDepot && updateLocal(e.id, e.value, 0, '', operatorName, operatorId);
    }
    if (!e) {
      setFleetSelected('');
      showDepot && updateLocal(0, '', 0, '', operatorName, operatorId);
    }
  };

  const handleDepotChange = (e) => {
    showDepot && tag('');
    if (e) {
      setDepotSelected(e.id);
      dispatch(getDepotTags(e.id));
      showDepot && updateLocal(fleetId, fleetName, e.id, e.value, operatorName, operatorId);
    }
    if (!e) {
      setDepotSelected('');
      showDepot && updateLocal(fleetId, fleetName, 0, '', operatorName, operatorId);
      dispatch(getDepotTagsReset());
    }
  };

  const handleDepotStatusChange = (e) => {
    if (e) {
      setDepotColorSelected(e.value);
    }
    if (!e) {
      setDepotColorSelected('');
    }
  };

  const handleTagChange = (e) => {
    if (e) {
      tag(e.id);
    }
    if (!e) {
      tag('');
    }
  };
  const operatorArray = getMapFiltersReducer
    ? createArr(getMapFiltersReducer.associations.operators)
    : [];
  const spArray = getMapFiltersReducer
    ? createArr(getMapFiltersReducer.associations.service_providers)
    : [];
  const fleetArray = getMapFiltersReducer
    ? createArr(getMapFiltersReducer.associations.fleets)
    : [];
  const depotArray = getMapFiltersReducer
    ? createArr(getMapFiltersReducer.associations.depots)
    : [];

  const fleetInfo = fleetArray.length ? fleetArray[0] : { id: 0, value: '', label: '' };
  const depotInfo = depotArray.length ? depotArray[0] : { id: 0, value: '', label: '' };
  const operatorInfo = operatorArray.length ? operatorArray[0] : { id: 0, value: '', label: '' };

  if (
    localStorage.getItem('dashboardDetails') === JSON.stringify({ fleet: 0, depot: 0 }) &&
    getMapFiltersReducer
  ) {
    updateLocal(fleetInfo.id, fleetInfo.value, depotInfo.id, depotInfo.value, operatorInfo.value);
  }

  const handleSubmit = () => {};

  const defaultVals = [
    {
      id: company_id,
      value: operatorName,
      label: operatorName,
    },
  ];
  const defaultValsComapnyName = [
    {
      id: company_id,
      value: company_name,
      label: company_name,
    },
  ];

  const fleetValFromLocal = [
    {
      id: fleetId,
      value: fleetName,
      label: fleetName,
    },
  ];

  const depotValFromLocal = [
    {
      id: depotId,
      value: depotName,
      label: depotName,
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, form }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroup style={{ marginBottom: '0' }}>
            <FormGroupField>
              <Field
                name="operator_name"
                component={renderSelectField}
                type="text"
                options={getMapFiltersReducer ? operatorArray : []}
                placeholder={'Operator'}
                parse={(e) => {
                  handleOperatorChange(e);
                  form.change('fleet_name', '');
                  form.change('depot_name', '');
                  form.change('tag_name', '');
                  return e;
                }}
                initialValue={
                  companyRole.includes(company_role_name) &&
                  getMapFiltersReducer &&
                  Object.keys(getMapFiltersReducer.associations.operators).includes(`${company_id}`)
                    ? defaultValsComapnyName
                    : showDepot
                    ? defaultVals && defaultVals[0].value
                      ? defaultVals
                      : ''
                    : ''
                }
                isDisabled={
                  companyRole.includes(company_role_name) &&
                  getMapFiltersReducer &&
                  Object.keys(getMapFiltersReducer.associations.operators).includes(
                    `${company_id}`
                  ) &&
                  true
                }
                isClearable
                isLoading={getMapFiltersReducerLoading}
              />
              {!showDepot && (
                <Field
                  name="sp_name"
                  component={renderSelectField}
                  type="text"
                  options={getMapFiltersReducer ? spArray : []}
                  placeholder={'Service provider'}
                  parse={(e) => {
                    handleServiceProviderChange(e);
                    return e;
                  }}
                  initialValue={
                    companyRole.includes(company_role_name) &&
                    getMapFiltersReducer &&
                    Object.keys(getMapFiltersReducer.associations.service_providers).includes(
                      `${company_id}`
                    )
                      ? defaultValsComapnyName
                      : ''
                  }
                  isDisabled={
                    companyRole.includes(company_role_name) &&
                    getMapFiltersReducer &&
                    Object.keys(getMapFiltersReducer.associations.service_providers).includes(
                      `${company_id}`
                    ) &&
                    true
                  }
                  isClearable
                  isLoading={getMapFiltersReducerLoading}
                />
              )}
              <Field
                name="fleet_name"
                component={renderSelectField}
                type="text"
                options={getMapFiltersReducer ? fleetArray : []}
                placeholder={'Fleet'}
                parse={(e) => {
                  handleFleetChange(e);
                  form.change('depot_name', '');
                  form.change('tag_name', '');
                  return e;
                }}
                isClearable
                initialValue={
                  showDepot && !shouldReset
                    ? fleetValFromLocal && fleetValFromLocal[0].value
                      ? fleetValFromLocal
                      : ''
                    : ''
                }
                isLoading={getMapFiltersReducerLoading}
              />
              {!showDepot && (
                <Field
                  name="depot_status"
                  component={renderSelectField}
                  type="text"
                  options={[
                    {
                      id: 1,
                      value: 'D21D26',
                      label: 'Red',
                    },
                    {
                      id: 2,
                      value: 'F57A08',
                      label: 'Orange',
                    },
                    {
                      id: 3,
                      value: '006B38',
                      label: 'Green',
                    },
                    {
                      id: 4,
                      value: 'A7AEB4',
                      label: 'Grey',
                    },
                  ]}
                  placeholder={'Depot status'}
                  parse={(e) => {
                    handleDepotStatusChange(e);
                    return e;
                  }}
                  isSearchable={false}
                  isClearable
                />
              )}
              {showDepot && (
                <>
                  <Field
                    name="depot_name"
                    component={renderSelectField}
                    type="text"
                    options={getMapFiltersReducer ? depotArray : []}
                    placeholder={'Depot'}
                    parse={(e) => {
                      handleDepotChange(e);
                      form.change('tag_name', '');
                      return e;
                    }}
                    initialValue={
                      showDepot && !shouldReset
                        ? depotValFromLocal && depotValFromLocal[0].value
                          ? depotValFromLocal
                          : ''
                        : ''
                    }
                    isClearable
                  />
                  <Field
                    name="tag_name"
                    component={renderSelectField}
                    type="text"
                    options={getDepotTagsReducerInfo}
                    placeholder={'Tag'}
                    parse={(e) => {
                      handleTagChange(e);
                      return e;
                    }}
                    isClearable
                  />
                </>
              )}
            </FormGroupField>
          </FormGroup>
        </FormContainer>
      )}
    </Form>
  );
};

export default MapFilters;

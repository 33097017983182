import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_COMP_LIST_LOADING,
  GET_COMP_LIST_SUCCESS,
  GET_COMP_FILTER_LIST_SUCCESS,
  GET_COMP_LIST_FAIL,
} from '../../shared/constants/getCompListConstants';

const getCompList = (device, deviceType) => async dispatch => {
  try {
    dispatch({
      type: GET_COMP_LIST_LOADING,
    });

    // setting up the config as headers content-type
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_VEHICLE_COMPONENT_LIST + '?vehicle_engine_type=' + device,
      //  + '&type=' + deviceType,
      config
    );

    const hvac = [
      ...new Map(
        response.data.data.component_types.map(item => [
          item['type'],
          item,
        ])
      ).values(),
    ];
    dispatch({
      type: GET_COMP_LIST_SUCCESS,
      payload: hvac,
    });

    dispatch({
      type: GET_COMP_FILTER_LIST_SUCCESS,
      payload: response.data.data.component_types,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMP_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMP_LIST_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export default getCompList;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from '@/shared/constants/UserProfileConstants/userProfileConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const changePassword = (formVals) => async (dispatch) => {
  const { user_id } = JSON.parse(localStorage.getItem('user'));

  try {
    dispatch({
      type: CHANGE_PASSWORD_LOADING,
    });

    const { old_password, new_password } = formVals;

    const body = {
      old_password,
      new_password,
    };

    const { data } = await axios.post(ApiURL.CHANGE_PASSWORD + `/${user_id}/change_password`, body, getConfig());

    successMessage('Password changed successfully!');

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data.data,
    });

    return data;
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);

      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: error.response.data.data.message,
      });

      return error.response;
    }
  }
};

export default changePassword;

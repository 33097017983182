import axios from 'axios';
import {
  ADD_VEHICLE_TAGS_LOADING,
  ADD_VEHICLE_TAGS_SUCCESS,
  ADD_VEHICLE_TAGS_FAIL,
} from '@/shared/constants/VehicleDashboardConstants/addVehicleTagsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { vehicle_dashboard } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const addVehicleTags = (vin, companyId, tagName, tagDescription) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_VEHICLE_TAGS_LOADING,
    });

    const response = await axios.post(
      ApiURL.ADD_VEHICLE_TAGS + companyId + '/vehicles/' + vin + '/tags/assign',
      {
        tag: tagName,
      },
      getConfig()
    );

    successMessage(vehicle_dashboard.register_tag);

    dispatch({
      type: ADD_VEHICLE_TAGS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: ADD_VEHICLE_TAGS_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: ADD_VEHICLE_TAGS_FAIL,
        payload: error.response,
      });
    }
  }
};

export default addVehicleTags;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl.js';
import {
  GET_PACKAGE_LISTING_KPI_LOADING,
  GET_PACKAGE_LISTING_KPI_SUCCESS,
  GET_PACKAGE_LISTING_KPI_FAIL,
} from '@/shared/constants/OtaUpdateConstants/otaDashboardConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getPackageListingKpi = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PACKAGE_LISTING_KPI_LOADING,
    });

    const params = {
      types: 'package_campaign_status_counts',
    };
    const {
      data: { data },
    } = await axios.get(ApiURL.GET_PACKAGE_COUNT, { params }, getConfig());

    dispatch({
      type: GET_PACKAGE_LISTING_KPI_SUCCESS,
      payload: data.package_campaign_status_counts,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_PACKAGE_LISTING_KPI_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_PACKAGE_LISTING_KPI_FAIL,
        payload: error.response.data,
      });
    }
  }
};

export default getPackageListingKpi;

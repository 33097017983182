const VehicleTagsData = () => {
  const header = [{ id: 1, title: 'Tags' }];

  const headerResponsive = [{ id: 1, title: 'Tags' }];

  const vehicleTagsData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
  };
  return vehicleTagsData;
};

export default VehicleTagsData;

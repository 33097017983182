import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './userDetail';

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/app' }} />
      }
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType,
};

PublicRoute.defaultProps = {
  component: '',
};

export default PublicRoute;

import { useState, useMemo } from 'react';
import { GetTableHeader } from '@/utils/helpers';

const CreateTableData = () => {
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Sim No'}
            key={'Sim No'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'sim_number',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'IMEI'}
            key={'IMEI'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'imei_number',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
    ],
    []
  );
  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

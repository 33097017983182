import axios from 'axios';
import {
  SEND_CAN_TIME_INTERVAL_LOADING,
  SEND_CAN_TIME_INTERVAL_SUCCESS,
  SEND_CAN_TIME_INTERVAL_FAIL,
  SEND_CAN_TIME_INTERVAL_RESET,
} from '../../../shared/constants/DiagnosticsConstants/diagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { diagnostics } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const sendCanInterval = (vin, duration, signalType) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_CAN_TIME_INTERVAL_LOADING,
    });

    const response = await axios.post(
      ApiURL.SEND_CAN_INTERVAL,
      {
        vin: vin,
        duration: duration,
        can_channel: signalType,
      },
      getConfig()
    );
    successMessage(diagnostics.send_can_request);

    dispatch({
      type: SEND_CAN_TIME_INTERVAL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: SEND_CAN_TIME_INTERVAL_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: SEND_CAN_TIME_INTERVAL_FAIL,
        payload: error.response,
      });
    }
  }
};

export const sendCanIntervalReset = () => (dispatch) => {
  dispatch({
    type: SEND_CAN_TIME_INTERVAL_RESET,
  });
};

export default sendCanInterval;

import axios from 'axios';
import {
  UPDATE_FLEET_LOADING,
  UPDATE_FLEET_SUCCESS,
  UPDATE_FLEET_FAIL,
  UPDATE_FLEET_RESET,
} from '@/shared/constants/FleetConstants/updateFleetConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const updateFleet = (companyId, fleetId, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_FLEET_LOADING,
    });

    const { name, region, description, climateZone } = data;

    const response = await axios.post(
      ApiURL.UPDATE_FLEET + companyId + '/fleet/' + fleetId + '/update',
      {
        name: name,
        region: region,
        description: description,
        climate_zone: climateZone.id,
      },
      getConfig()
    );

    successMessage(system_settings.update_fleet);

    dispatch({
      type: UPDATE_FLEET_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPDATE_FLEET_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPDATE_FLEET_FAIL,
        payload: error.response,
      });
    }
  }
};
export const updateFleetReset = () => (dispatch) => {
  dispatch({
    type: UPDATE_FLEET_RESET,
  });
};

export default updateFleet;

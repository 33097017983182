import {
    GET_PACKAGE_COUNT_LOADING,
    GET_PACKAGE_COUNT_SUCCESS,
    GET_PACKAGE_COUNT_FAIL,
  } from "../../../../shared/constants/OtaUpdateConstants/otaDashboardConstants";
  
  const initialState = {
    loading: false,
    info: "",
    error: "",
  };
  const getPackageCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PACKAGE_COUNT_LOADING:
        return { ...state, loading: true };
      case GET_PACKAGE_COUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          info: action.payload,
          error: "",
        };
      case GET_PACKAGE_COUNT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getPackageCountReducer;
  
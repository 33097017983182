import {
  EDIT_GATEWAY_SIM_LOADING,
  EDIT_GATEWAY_SIM_SUCCESS,
  EDIT_GATEWAY_SIM_FAIL,
  EDIT_GATEWAY_SIM_RESTORE_INITIAL_VALUES,
  RESET_ERROR,
} from '../../shared/constants/editGatewaySimConstants';

const initialValues = {
  loading: false,
  info: '',
  error: '',
};
const editGatewayReducer = (state = initialValues, action) => {
  switch (action.type) {
    case EDIT_GATEWAY_SIM_LOADING:
      return { ...state, loading: true };

    case EDIT_GATEWAY_SIM_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };

    case EDIT_GATEWAY_SIM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_GATEWAY_SIM_RESTORE_INITIAL_VALUES:
      return {
        loading: false,
        info: '',
        error: '',
      };
    case RESET_ERROR:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default editGatewayReducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import ComponentsContainer from './ComponentsContainer';
// import ComponentsListWithType from './ComponentsListWithType';
import ComponentsListWithType from '../../HvacDevices/components/ComponentsListWithType';
import getCompList from '../../../../redux/actions/getCompListActions';
import getHvacCompCount from '../../../../redux/actions/getHvacCompCountActions';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import HvacDevices from '../../HvacDevices';
import { useHistory } from 'react-router';

const Ava3 = `${process.env.PUBLIC_URL}/img/14.png`;

const ComponentsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getCompListReducer = useSelector((state) => state.getCompListReducer.info);

  const getCompListReducerLoading = useSelector((state) => state.getCompListReducer.loading);

  const getHvacCountReducer = useSelector((state) => state.getHvacCountReducer.info);

  const getHvacCountReducerLoading = useSelector((state) => state.getHvacCountReducer.loading);

  const [showComps, setShowComps] = useState(false);
  const [compType, setCompType] = useState('');

  useEffect(() => {
    setTimeout(() => {
      const getVehicleInfo = JSON.parse(localStorage.getItem('vehicleDetails'));
      const getVehicleEngineType = getVehicleInfo.engine_type;
      const getVehicleHvacId = getVehicleInfo.hvac_id;

      if (getVehicleInfo) {
        dispatch(getCompList(getVehicleEngineType));
        dispatch(getHvacCompCount(getVehicleHvacId));
      }
    }, 2000);
  }, []);

  const handleClick = (item) => {
    const index = getCompListReducer.find((i) => i.type === item);
    setCompType(index.type);
    setShowComps(true);
  };

  return (
    <Col md={6} lg={6} sm={12}>
      {showComps === true
        ? history.push({
            pathname: '/hvacDevices',
            state: { cType: compType },
          })
        : ''}
      <Row>
        <h6 style={{ paddingBottom: '20px' }}>HVAC details for the vehicle</h6>

        <Card height="auto" style={{ marginBottom: '20px' }}>
          <CardBody style={{ padding: '20px 30px' }}>
            {!getHvacCountReducer ||
            getHvacCountReducer === undefined ||
            !getCompListReducer ||
            getCompListReducer === undefined ||
            getHvacCountReducerLoading === true ||
            getCompListReducerLoading === true ? (
              <SimpleLoader />
            ) : getHvacCountReducer['AC'] > 0 || getHvacCountReducer['Heater'] > 0 ? (
              getCompListReducer.map((comps, key) => (
                <div onClick={() => handleClick(comps.type)} id={comps.type} key={key}>
                  <ComponentsContainer
                    img={Ava3}
                    comps
                    assettype={comps.type}
                    count={
                      !getHvacCountReducer[comps.type]
                        ? '0 units'
                        : getHvacCountReducer[comps.type] + ' units'
                    }
                  />
                </div>
              ))
            ) : (
              getCompListReducer
                .filter((comps) => comps.type === 'AC' || comps.type === 'Heater')
                .map((filteredComps, key) => (
                  <div
                    onClick={() => handleClick(filteredComps.type)}
                    id={filteredComps.type}
                    key={key}
                  >
                    <ComponentsContainer
                      img={Ava3}
                      assettype={filteredComps.type}
                      count={
                        !getHvacCountReducer[filteredComps.type]
                          ? '0 units'
                          : getHvacCountReducer[filteredComps.type] + ' units'
                      }
                    />
                  </div>
                ))
            )}
          </CardBody>
        </Card>
      </Row>
      {/* )} */}
    </Col>
  );
};

export default ComponentsList;

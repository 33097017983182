import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getToken } from './userDetail';

const PrivateRoute = ({ children }) => {
  const getLToken = getToken();
  return getLToken ? children : <Redirect to="/login" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.object,
};
PrivateRoute.defaultProps = {
  children: '',
};

export default PrivateRoute;

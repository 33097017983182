import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import getGlobalHealthSummary from '@/redux/actions/GlobalDashboardActions/getGlobalHealthSummary';
import { setFilter } from '@/redux/actions/HealthStatusDashboardActions/getHealthStatusDetailActions';
import PieChartCard from '@/shared/components/PieChartCard';
import Path from '@/utils/routes/routePathNames';

const GlobalHealthStatusSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGlobalHealthSummary());
  }, []);

  const { loading, info, error } = useSelector((state) => state.getGlobalHealthSummaryReducer);

  const placeholderData = [
    {
      value: 0,
      fill: '#B4C6CC',
      name: 'Connectivity',
    },
    {
      value: 0,
      fill: '#FF754A',
      name: 'Data',
    },
    {
      value: 0,
      fill: '#F5A608',
      name: 'Preventive',
    },
    {
      value: 0,
      fill: '#DC2323',
      name: 'Conditional',
    },
    {
      value: 1,
      fill: '#eee',
      name: 'No Data',
    },
  ];

  const handleClick = (e) => {
    if (e) {
      setTimeout(() => {
        history.push({
          pathname: Path.HEALTH_DASH,
        });
        dispatch(
          setFilter({
            state: {
              filterValue: e.payload ? `type-${e.payload.name}` : null,
              filterValueName: e.payload ? e.payload.name : null,
            },
          })
        );
      }, 100);
    }
  };

  return (
    <PieChartCard
      title={'HEALTH STATUS SUMMARY'}
      subheading={'Open alarms distribution'}
      loading={loading}
      data={info.alarmData}
      placeholderData={placeholderData}
      datakey={'value'}
      total={info.total}
      error={error}
      handleClick={handleClick}
    />
  );
};

export default GlobalHealthStatusSummary;

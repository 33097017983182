import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import CompanyDetailsContainer from '../CompanyDetailsContainer';
import CompanyForm from './CompanyForm';
import getRegisteredCompany from '../../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import ManageCompanyContainer from '../ManageCompanyContainer';
import BusinessNetworkForm from './BusinessNetworkForm';
import getRoles from '../../../../redux/actions/getRolesActions';
import getCompanyRelation from '../../../../redux/actions/CompanyActions/getCompanyRelationActions';
import data from '../../../App/Router/data';
import getCompanySysSettingsCount from '../../../../redux/actions/SystemSettingsActions/getCompanySystemSettingsCountActions';

const ManageCompanySettings = ({ companyId }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;
  const businessNetwork = `${process.env.PUBLIC_URL}/img/Businessnetwork.jpg`;
  const fleetImage = `${process.env.PUBLIC_URL}/img/Fleet.jpg`;
  const depotImage = `${process.env.PUBLIC_URL}/img/Depot.jpg`;
  const userImage = `${process.env.PUBLIC_URL}/img/Users.jpg`;
  const roleImage = `${process.env.PUBLIC_URL}/img/Roles.jpg`;

  const history = useHistory();
  const dispatch = useDispatch();

  const getRegisteredCompanyReducerInfo = useSelector(
    (state) => state.getRegisteredCompanyReducer.info
  );
  const getRegisteredCompanyReducerLoading = useSelector(
    (state) => state.getRegisteredCompanyReducer.loading
  );
  const getCompanySysSettingsCountInfo = useSelector(
    (state) => state.getCompanySysSettingsCountReducer.info
  );

  const [changeComp, setChangeComp] = useState(false);
  const [showBusinessNetworkForm, setShowBusinessNetworkForm] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const getCompanyId = localStorage.getItem('companyId');
  const getUserDetails = JSON.parse(localStorage.getItem('user'));
  const getCompanyRole = getUserDetails.company_role_name;
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  // Temp route variables
  const fleetUrl = '/settings/company-settings/select/manage/fleets';
  const depotUrl = '/settings/company-settings/select/manage/depots';
  const usersUrl = '/settings/company-settings/select/manage/users';
  const rolesUrl = '/settings/company-settings/select/manage/roles';
  const notificationsUrl = '/settings/company-settings/select/manage/notifications';
  const assets = ['Fleets', 'Depots', 'Users', 'Roles', 'Notifications'];

  const handleClick = () => {
    if (getCompanyRole === adminRole[0]) {
      setChangeComp(true);
    }
  };
  const handleBusinessNetworkClick = () => {
    setShowBusinessNetworkForm(true);
    dispatch(getRoles(getCompanyId));
    // dispatch(getCompanyRelation());
  };

  useEffect(() => {
    if (getCompanyId) {
      dispatch(getRegisteredCompany(getCompanyId));
      dispatch(getCompanySysSettingsCount(getCompanyId));
    }
  }, [getCompanyId, changeComp]);

  const dataBusiness = [
    {
      id: 1,
      img: businessNetwork,
      assetType: 'Business Network',
    },
  ];

  const settingsData = [
    {
      id: 1,
      img: fleetImage,
      assetType: assets[0],
      totalnumber: getCompanySysSettingsCountInfo
        ? getCompanySysSettingsCountInfo.company_system_settings_counts[0].total_fleet
        : 0,
      link: fleetUrl,
    },
    {
      id: 2,
      img: depotImage,
      assetType: assets[1],
      totalnumber: getCompanySysSettingsCountInfo
        ? getCompanySysSettingsCountInfo.company_system_settings_counts[1].total_depot
        : 0,
      link: depotUrl,
    },
    {
      id: 3,
      img: userImage,
      assetType: assets[2],
      totalnumber: getCompanySysSettingsCountInfo
        ? getCompanySysSettingsCountInfo.company_system_settings_counts[2].total_user
        : 0,
      link: usersUrl,
    },
    {
      id: 4,
      img: roleImage,
      assetType: assets[3],
      totalnumber: getCompanySysSettingsCountInfo
        ? getCompanySysSettingsCountInfo.company_system_settings_counts[3].user_role
        : 0,
      link: rolesUrl,
    },
  ];

  return (
    <Container>
      {changeComp ? (
        <Row>
          <Col xs={12} sm={12} md={6}>
            <h3 className="page-title">Edit Company</h3>
            <h6 style={{ paddingBottom: '20px' }}>Edit Company details</h6>
            <Card height="auto">
              <CardBody>
                <CompanyForm setChangeComp={setChangeComp} editMode={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  {getRegisteredCompanyReducerLoading ||
                  !getRegisteredCompanyReducerInfo ||
                  getRegisteredCompanyReducerInfo === undefined ? (
                    <DotDotLoader loadingState={getRegisteredCompanyReducerLoading} />
                  ) : (
                    <CompanyDetailsContainer
                      img={Ava}
                      name={getRegisteredCompanyReducerInfo.company.name}
                      website={getRegisteredCompanyReducerInfo.company.website}
                      role={getRegisteredCompanyReducerInfo.company.company_type}
                      city={getRegisteredCompanyReducerInfo.company.city}
                      country={getRegisteredCompanyReducerInfo.company.country}
                      onClick={handleClick}
                      showLink={getCompanyRole === adminRole[0] ? true : false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {!showBusinessNetworkForm ? (
            <>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <h6 style={{ paddingBottom: '20px' }}>Company business network</h6>
                  <Card height="auto">
                    <CardBody>
                      {dataBusiness.map(({ id, assetType, img }) => (
                        <div key={id} onClick={() => handleBusinessNetworkClick()}>
                          <ManageCompanyContainer assettype={assetType} img={img} />
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <h6 style={{ paddingBottom: '20px' }}>Settings type</h6>
                  <Card height="auto">
                    <CardBody>
                      {/* Add a condition for app_admin */}
                      {getCompanyRole === companyRole[0] || getCompanyRole === adminRole[0]
                        ? settingsData.map((newItem) => (
                            <div
                              key={newItem.id}
                              onClick={() =>
                                newItem.assetType === assets[0]
                                  ? history.push(fleetUrl)
                                  : newItem.assetType === assets[1]
                                  ? history.push(depotUrl)
                                  : newItem.assetType === assets[2]
                                  ? history.push(usersUrl)
                                  : newItem.assetType === assets[3]
                                  ? history.push(rolesUrl)
                                  : ''
                              }
                            >
                              <ManageCompanyContainer
                                assettype={newItem.assetType}
                                img={newItem.img}
                                totalnumber={newItem.totalnumber}
                              />
                            </div>
                          ))
                        : settingsData
                            .filter(
                              (item) => item.assetType !== assets[0] && item.assetType !== assets[1]
                            )
                            .map((newItem) => (
                              <div
                                key={newItem.id}
                                onClick={() =>
                                  newItem.assetType === assets[2]
                                    ? history.push(usersUrl)
                                    : history.push(rolesUrl)
                                }
                              >
                                <ManageCompanyContainer
                                  assettype={newItem.assetType}
                                  img={newItem.img}
                                  totalnumber={newItem.totalnumber}
                                />
                              </div>
                            ))}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={12} sm={12} md={6}>
                <h6 style={{ paddingBottom: '20px' }}>Edit Company business network details</h6>
                <BusinessNetworkForm
                  setShowBusinessNetworkForm={setShowBusinessNetworkForm}
                  viewMode={viewMode}
                  disableContent={getCompanyRole === adminRole[0] ? false : true}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default ManageCompanySettings;

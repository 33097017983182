import axios from 'axios';
import {
  EDIT_VIN_REQUEST,
  EDIT_VIN_SUCCESS,
  EDIT_VIN_FAIL,
  EDIT_VIN_RESET,
} from '@/shared/constants/editVehicleConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { manage_assets } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../config/GlobalUrl';

const editVehicleActions = (vehicleDetails) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_VIN_REQUEST });

    const {
      vin,
      engine_type,
      manufacturer,
      model,
      body_number,
      bus_num,
      plate_num,
      pta_name,
      operator_name,
      service_provider,
      fleet_name,
      depot_name,
    } = vehicleDetails;

    const response = await axios.post(
      ApiURL.VEHICLE_REGISTER + vin + '/update',
      {
        engine_type: engine_type.value,
        manufacturer: manufacturer ? manufacturer : null,
        model: model ? model : null,
        body_number: body_number ? body_number : null,
        plate_num: plate_num ? plate_num : null,
        bus_num: bus_num ? bus_num : null,
        pta: pta_name ? pta_name.id : null,
        pto: operator_name.id,
        service_provider: service_provider ? service_provider.id : '',
        fleet: fleet_name.id,
        depo: depot_name.id,
      },
      getConfig()
    );

    successMessage(manage_assets.update_vehicle);

    dispatch({
      type: EDIT_VIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: EDIT_VIN_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: EDIT_VIN_FAIL,
        payload: error.response,
      });
    }
  }
};

export const editVehicleActionsReset = () => (dispatch) => {
  dispatch({
    type: EDIT_VIN_RESET,
  });
};

export default editVehicleActions;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_CAN_DATA_LISTING_LOADING,
  GET_CAN_DATA_LISTING_SUCCESS,
  GET_CAN_DATA_LISTING_FAIL,
} from '@/shared/constants/DiagnosticsConstants/diagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { sortingFunc } from '@/utils/helpers.js';

const getCanDataListing = (pageNumber, numberofRecords, sortData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CAN_DATA_LISTING_LOADING,
    });

    const params = {
      p: pageNumber ? pageNumber : null,
      page_size: numberofRecords ? numberofRecords : null,
      sort: sortData ? sortingFunc(sortData) : null,
      can_dump_status: '',
    };

    const { data } = await axios.get(ApiURL.GET_DIAGNOSTICS_LISTING, { params }, getConfig());

    dispatch({
      type: GET_CAN_DATA_LISTING_SUCCESS,
      payload: { data: data.data.diagnostic_status, pagination: data.pagination },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_CAN_DATA_LISTING_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_CAN_DATA_LISTING_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getCanDataListing;

import axios from 'axios';
import {
  ADD_HVAC_COMP_LOADING,
  ADD_HVAC_COMP_SUCCESS,
  ADD_HVAC_COMP_FAIL,
} from '@/shared/constants/addHvacCompConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { manage_assets } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../config/GlobalUrl';

const addHvacComp = (hvac_id, componentId, serialNumber, manufacturerId) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_HVAC_COMP_LOADING,
    });

    const response = await axios.post(
      ApiURL.ADD_HVAC_COMP + hvac_id + '/components/add',
      {
        component_type: componentId,
        serial_num: serialNumber,
        supplier_detail: manufacturerId,
      },
      getConfig()
    );
    successMessage(manage_assets.register_hvac);
    dispatch({
      type: ADD_HVAC_COMP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: ADD_HVAC_COMP_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: ADD_HVAC_COMP_FAIL,
        payload: error.response,
      });
    }
  }
};

export default addHvacComp;

import React, { useEffect } from 'react';
import { Card, CardBody } from '@/shared/components/Card';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import getLastTimestamp from '../../../../redux/actions/getLastTimestampActions';
import DotDotLoader from '@/shared/components/DotDotLoader';

const GatewayLastTimestamp = ({ vin }) => {
  const dispatch = useDispatch();

  const getLastTimestampReducer = useSelector((state) => state.getLastTimestampReducer);
  const getLastTimestampReducerLoading = useSelector(
    (state) => state.getLastTimestampReducer.loading
  );
  const getLastTimestampReducerError = useSelector((state) => state.getLastTimestampReducer.error);

  useEffect(() => {
    dispatch(getLastTimestamp(vin));
  }, [vin]);

  return (
    <Card height="auto" style={{ height: '188px' }}>
      <CardBody>
        <MainContainer>
          <TimeStampContainer>
            <TimeStampDescription>{'Last Data Point received (in UTC)'}</TimeStampDescription>
          </TimeStampContainer>
          <TimeStampContainer>
            {getLastTimestampReducerLoading === true ||
            !getLastTimestampReducer ||
            getLastTimestampReducer === undefined ? (
              <DotDotLoader loadingState={getLastTimestampReducerLoading} />
            ) : getLastTimestampReducerError ? (
              <TimeStampDescriptionError>No data found</TimeStampDescriptionError>
            ) : (
              <>
                <TimeStamp>
                  {getLastTimestampReducer.info
                    ? getLastTimestampReducer.info.timestamp.split('T')[0]
                    : ''}
                </TimeStamp>
                <TimeStamp>
                  {getLastTimestampReducer.info
                    ? getLastTimestampReducer.info.timestamp.split('T')[1].split('.')[0]
                    : ''}
                </TimeStamp>
              </>
            )}
          </TimeStampContainer>
        </MainContainer>
      </CardBody>
    </Card>
  );
};

export default GatewayLastTimestamp;

// region STYLES

const MainContainer = styled.div`
  text-transform: uppercase;
  height: 100%;
  padding: 10px 0;
  text-align: center;
`;

const TimeStampDescription = styled.p`
  font-size: 12px;
  color: #9baab0;
  padding-bottom: 10px;
`;
const TimeStampDescriptionError = styled.p`
  font-size: 13px;
  text-transform: capitalize;
`;
const TimeStampContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const TimeStamp = styled.p`
  font-size: 13px;
  color: #37baeb;
`;

// end region STYLES

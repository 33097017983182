import {
  REMOVE_VEHICLE_TAG_LOADING,
  REMOVE_VEHICLE_TAG_SUCCESS,
  REMOVE_VEHICLE_TAG_FAIL,
  REMOVE_VEHICLE_TAG_RESET,
} from '@/shared/constants/VehicleDashboardConstants/addVehicleTagsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const removeVehicleTagReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_VEHICLE_TAG_LOADING:
      return { ...state, loading: true };
    case REMOVE_VEHICLE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case REMOVE_VEHICLE_TAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_VEHICLE_TAG_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default removeVehicleTagReducer;

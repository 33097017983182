import {
  GET_CAMPAIGN_LISTING_LOADING,
  GET_CAMPAIGN_LISTING_SUCCESS,
  GET_CAMPAIGN_LISTING_FAIL,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
  pagination: { totalPages: 0, totalRecords: 0 },
};
const getCampaignListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LISTING_LOADING:
      return { ...state, loading: true };
    case GET_CAMPAIGN_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        pagination: action.payload.pagination,
      };
    case GET_CAMPAIGN_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getCampaignListingReducer;

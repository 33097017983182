import React from 'react';
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { colorText } from '@/utils/palette';

const Breadcrumbs = (props) => {
  const { history, crumbs } = props;

  // Don't render a single breadcrumb.
  // if (crumbs.length <= 1) {
  //   return null;
  // }
  {
    crumbs.length > 0 ? (
      <Breadcrumb.Item> Home </Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item> Home </Breadcrumb.Item>
    );
  }
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <BreadCrumbContainer>
            <Breadcrumb style={{ fontSize: '15px' }}>
              {/* {crumbs.length > 0 ? (
            // <Link onClick={() => history.push("/")}>Home</Link>
            <Breadcrumb.Item active> Home </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item active> Home </Breadcrumb.Item>
          )} */}
              {crumbs.map(({ name, path }, key) =>
                key + 1 === crumbs.length ? (
                  <BreadcrumbActive active key={key}>
                    {name}
                  </BreadcrumbActive>
                ) : (
                  <BreadcrumbActive key={key} onClick={() => history.push(path[0])}>
                    {name}
                  </BreadcrumbActive>
                )
              )}
            </Breadcrumb>
          </BreadCrumbContainer>
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(Breadcrumbs);

const BreadCrumbContainer = styled.div`
  @media (max-width: 767px) {
    margin-top: -40px;
    padding: 30px 0 40px 0;
  }
`;

const BreadcrumbActive = styled(Breadcrumb.Item)`
  color: ${colorText} !important;

  a {
    color: #37baeb !important;
  }
`;

import axios from 'axios';
import {
  SET_INITIAL_DEPOT_FILTER_ACTION,
  RESET_INITIAL_DEPOT_FILTER_ACTION,
} from '@/shared/constants/DepotConstants/setInitialDepotFiltersConstant';
import { getConfig } from '@/shared/helpers/apiConfig';
import { ApiURL } from '@/config/GlobalUrl';
import data from '@/containers/App/Router/data';

const setInitialDepotFilterActions = () => async (dispatch) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails.company_id;
  const companyRoleName = userDetails.company_role_name;
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  let params = {};

  const response = await axios.get(ApiURL.GET_MAP_FILTERS, getConfig());
  if (companyRole.includes(companyRoleName)) {
    params.pto_id = companyId;
  } else {
    params.pto_id = Object.keys(response.data.data.associations.operators)[0];
  }

  const responseOperator = await axios.get(ApiURL.GET_MAP_FILTERS, { params: params }, getConfig());
  params.fleet_id = Object.keys(responseOperator.data.data.associations.fleets)[0];

  const responseOpFleet = await axios.get(ApiURL.GET_MAP_FILTERS, { params: params }, getConfig());
  params.depot_id = Object.keys(responseOpFleet.data.data.associations.depots)[0];

  dispatch({
    type: SET_INITIAL_DEPOT_FILTER_ACTION,
    payload: {
      operator: params.pto_id,
      fleet: params.fleet_id,
      depot: params.depot_id,
    },
  });
};

export const updateOtaRowReset = () => async (dispatch) => {
  dispatch({
    type: RESET_INITIAL_DEPOT_FILTER_ACTION,
  });
};

export default setInitialDepotFilterActions;

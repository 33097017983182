import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  UPDATE_GATEWAY_GROUP_LOADING,
  UPDATE_GATEWAY_GROUP_SUCCESS,
  UPDATE_GATEWAY_GROUP_FAIL,
  UPDATE_GATEWAY_GROUP_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const updateGatewayGroup = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_GATEWAY_GROUP_LOADING,
    });

    const response = await axios.patch(
      ApiURL.UPDATE_GATEWAY_GROUP,
      { operations: formValues },
      getConfig()
    );

    successMessage('Gateway group updated successfully!');
    dispatch({
      type: UPDATE_GATEWAY_GROUP_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPDATE_GATEWAY_GROUP_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPDATE_GATEWAY_GROUP_FAIL,
        payload: error.response,
      });
    }
  }
};

export const updateGatewayGroupReset = () => async (dispatch) => {
  dispatch({
    type: UPDATE_GATEWAY_GROUP_RESET,
  });
};

export default updateGatewayGroup;

import {
  GET_GATEWAY_MASTER_LIST_LOADING,
  GET_GATEWAY_MASTER_LIST_SUCCESS,
  GET_GATEWAY_MASTER_LIST_FAIL,
  GET_CPUID_MASTER_LIST_LOADING,
  GET_CPUID_MASTER_LIST_SUCCESS,
  GET_CPUID_MASTER_LIST_FAIL,
} from '@/shared/constants/GatewayMasterConstants/gatewayMasterConstants';

const initialState = {
  loading: false,
  info: [],
  pagination: {
    totalPages: 0,
    totalRecords: 0,
  },
  error: '',
  info_list: [],
  error_list: '',
};

const getGatewayMasterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY_MASTER_LIST_LOADING:
      return { ...state, loading: true };
    case GET_GATEWAY_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        pagination: action.payload.pagination,
      };
    case GET_GATEWAY_MASTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CPUID_MASTER_LIST_LOADING:
      return { ...state, loading: true };
    case GET_CPUID_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        info_list: action.payload,
      };
    case GET_CPUID_MASTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error_list: action.payload,
      };
    default:
      return state;
  }
};

export default getGatewayMasterListReducer;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder } from '@/utils/palette';
import { marginRight, left } from '@/utils/directions';

const RoleListContainer = ({ img, roleName, error }) => (
  <RoleListContainerWrap>
    {!error ? (
      <>
        <RoleListContainerImageWrap>
          <img src={img} alt="" />
        </RoleListContainerImageWrap>
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            padding: '0',
            margin: '0',
          }}
        >
          <div style={{ paddingBottom: '5px', paddingTop: '0' }}>
            <RoleListContainerName>
              {roleName}
              {/* {', '} */}
              {/* {companyName} */}
            </RoleListContainerName>
          </div>
          <div>
            <RoleListContainerSub>
              {/* {region} */}
              {/* {', '} */}
              {/* {climateZone} */}
            </RoleListContainerSub>
          </div>
        </div>
      </>
    ) : (
      <div>{error}</div>
    )}
  </RoleListContainerWrap>
);

RoleListContainer.propTypes = {
  img: PropTypes.string,
  roleName: PropTypes.string,
};

export default RoleListContainer;

// region STYLES

const RoleListContainerWrap = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};

  &:last-child {
    border-bottom: none;
  }
`;

const RoleListContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const RoleListContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`;

const RoleListContainerSub = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #999999;
`;

// endregion

import axios from 'axios';
import {
  REGISTER_FLEET_LOADING,
  REGISTER_FLEET_SUCCESS,
  REGISTER_FLEET_FAIL,
  REGISTER_FLEET_RESET,
} from '@/shared/constants/FleetConstants/registerFleetConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const registerFleet = (companyId, data) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_FLEET_LOADING,
    });

    const { name, region, description, climateZone } = data;

    const response = await axios.post(
      ApiURL.REGISTER_FLEET + companyId + '/fleet/register',
      {
        name: name,
        region: region,
        description: description,
        climate_zone_id: climateZone.id,
      },
      getConfig()
    );

    successMessage(system_settings.register_fleet);

    dispatch({
      type: REGISTER_FLEET_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: REGISTER_FLEET_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: REGISTER_FLEET_FAIL,
        payload: error.response,
      });
    }
  }
};

export const registerFleetReset = () => (dispatch) => {
  dispatch({
    type: REGISTER_FLEET_RESET,
  });
};

export default registerFleet;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_ALARM_COUNT_LOADING,
  GET_ALARM_COUNT_SUCCESS,
  GET_ALARM_COUNT_FAIL,
} from '@/shared/constants/HealthStatusDashboardConstants/healthStatusDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getAlarmCount = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALARM_COUNT_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const { data } = await axios.get(
      ApiURL.GET_ALARM_COUNT + companyId + '/healthstatusvehicle',
      getConfig()
    );

    const alarmData = data?.data?.health_status_summary?.color_wise_count;
    const totalAlarms = data?.data?.health_status_summary?.total_count;

    const alarmCount = {
      total: totalAlarms,
      orange: alarmData.filter((item) => item.color_name === 'orange').pop()?.count,
      red: alarmData.filter((item) => item.color_name === 'red').pop()?.count,
      grey: alarmData.filter((item) => item.color_name === 'grey').pop()?.count,
    };

    dispatch({
      type: GET_ALARM_COUNT_SUCCESS,
      payload: alarmCount,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_ALARM_COUNT_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_ALARM_COUNT_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getAlarmCount;

import {
  GET_VEHICLES_WITH_GATEWAY_LOADING,
  GET_VEHICLES_WITH_GATEWAY_SUCCESS,
  GET_TOTAL_VEHICLES_WITH_GATEWAY_SUCCESS,
  GET_VEHICLES_WITH_GATEWAY_FAIL,
} from '../../../shared/constants/DepotDashboardConstants/getVehiclesWithGatewayConstants';

const initialState = {
  loading: false,
  info: '',
  total: '',
  error: '',
};
const getVehiclesWithGatewayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLES_WITH_GATEWAY_LOADING:
      return { ...state, loading: true };
    case GET_VEHICLES_WITH_GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_TOTAL_VEHICLES_WITH_GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload,
      };
    case GET_VEHICLES_WITH_GATEWAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        info: '',
        total: '',
      };
    default:
      return state;
  }
};

export default getVehiclesWithGatewayReducer;

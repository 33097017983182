import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getGatewayDiagnosticsList from '@/redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsListActions';
import getGatewayDiagnosticsListing, {
  getGatewayDiagnosticsListingReset,
} from '@/redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsListingAction';

const GatewayDiagnosticsForm = ({
  setCpuIdValue,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setIsFiltered,
  isFiltered,
}) => {
  const dispatch = useDispatch();

  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const { loading, info, error } = useSelector((state) => state.getGatewayDiagnosticsListReducer);

  useEffect(() => {
    // dispatch(getGatewayDiagnosticsList());
  }, []);

  const onSubmit = () => {};

  const handleCpuIdChange = (values) => {
    if (values && values.value) {
      setCpuIdValue(values.value);
      dispatch(
        getGatewayDiagnosticsListing(
          values.value,
          pageNumber,
          numberofRecords,
          startDate,
          endDate,
          ''
        )
      );
      setIsFiltered(true);
    } else {
      setCpuIdValue('');
      setIsFiltered(false);
      dispatch(getGatewayDiagnosticsListingReset());
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroup style={{ marginBottom: '0' }}>
            <Row>
              <StyledCol>
                <FormGroupField>
                  <Field
                    name="cpu_id"
                    component={renderSelectField}
                    placeholder={'Select gateway'}
                    options={
                      info
                        ? info.data.map(({ cpu_id, link }) => ({
                            value: link,
                            label: cpu_id,
                          }))
                        : []
                    }
                    isClearable
                    parse={(e) => {
                      handleCpuIdChange(e);
                      return e;
                    }}
                  />
                </FormGroupField>
              </StyledCol>
            </Row>
          </FormGroup>
        </FormContainer>
      )}
    </Form>
  );
};

const StyledCol = styled(Col)`
  padding: 0px !important;
`;

export default GatewayDiagnosticsForm;

import React from 'react';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Calendar from '@/shared/components/Calendar';
import { Card, CardBody } from '@/shared/components/Card';

const CalendarData = () => {
  const { info } = useSelector((state) => state.getGatewayDiagnosticsMonthReducer);

  const events =
    info && info.response
      ? info.response.map((item, index) => {
          return {
            id: index,
            filename: item.filename,
            start: moment.utc(item.create_time),
            end: moment.utc(item.create_time),
            download_link: item.file_key,
            cpuId: info.cpuId,
          };
        })
      : [];

  return (
    <Col md={12} lg={12} xl={12}>
      <Card height="85vh" style>
        <CardBody height="100%">
          <Calendar events={events} small={false} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CalendarData;

export const GET_SYS_SETTINGS_COUNT_LOADING = 'GET_SYS_SETTINGS_COUNT_LOADING';
export const GET_SYS_SETTINGS_COUNT_SUCCESS = 'GET_SYS_SETTINGS_COUNT_SUCCESS';
export const GET_SYS_SETTINGS_COUNT_FAIL = 'GET_SYS_SETTINGS_COUNT_FAIL';

export const GET_COMPANY_SYS_SETTINGS_COUNT_LOADING = 'GET_COMPANY_SYS_SETTINGS_COUNT_LOADING';
export const GET_COMPANY_SYS_SETTINGS_COUNT_SUCCESS = 'GET_COMPANY_SYS_SETTINGS_COUNT_SUCCESS';
export const GET_COMPANY_SYS_SETTINGS_COUNT_FAIL = 'GET_COMPANY_SYS_SETTINGS_COUNT_FAIL';

//Get depot list for manage noti
export const GET_DEPOT_LIST_LOADING = 'GET_DEPOT_LIST_LOADING';
export const GET_DEPOT_LIST_SUCCESS = 'GET_DEPOT_LIST_SUCCESS';
export const GET_DEPOT_LIST_FAIL = 'GET_DEPOT_LIST_FAIL';

//Get depot vehicles
export const GET_DEPOT_VEHICLES_LOADING = 'GET_DEPOT_VEHICLES_LOADING';
export const GET_DEPOT_VEHICLES_SUCCESS = 'GET_DEPOT_VEHICLES_SUCCESS';
export const GET_DEPOT_VEHICLES_FAIL = 'GET_DEPOT_VEHICLES_FAIL';
export const GET_DEPOT_VEHICLES_RESET = 'GET_DEPOT_VEHICLES_RESET';

//Get users list
export const GET_USERS_LIST_LOADING = 'GET_USERS_LIST_LOADING';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL';
export const GET_USERS_LIST_RESET = 'GET_USERS_LIST_RESET';

//Notifications
export const GET_SELECTED_USER_VEHICLES_LOADING = 'GET_SELECTED_USER_VEHICLES_LOADING';
export const GET_SELECTED_USER_VEHICLES_SUCCESS = 'GET_SELECTED_USER_VEHICLES_SUCCESS';
export const GET_SELECTED_USER_VEHICLES_FAIL = 'GET_SELECTED_USER_VEHICLES_FAIL';
export const GET_SELECTED_USER_VEHICLES_RESET = 'GET_SELECTED_USER_VEHICLES_RESET';

//get notification listing
export const GET_NOTIFICATION_LIST_LOADING = 'GET_NOTIFICATION_LIST_LOADING';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAIL = 'GET_NOTIFICATION_LIST_FAIL';
export const GET_NOTIFICATION_LIST_RESET = 'GET_NOTIFICATION_LIST_RESET';

//get profile notification information
export const GET_PROFILE_NOTIFICATION_LIST_LOADING = 'GET_PROFILE_NOTIFICATION_LIST_LOADING';
export const GET_PROFILE_NOTIFICATION_LIST_SUCCESS = 'GET_PROFILE_NOTIFICATION_LIST_SUCCESS';
export const GET_PROFILE_NOTIFICATION_LIST_FAIL = 'GET_PROFILE_NOTIFICATION_LIST_FAIL';

//Get DEPOT_NOTIFICATION_TYPES

export const GET_DEPOT_NOTIFICATION_TYPES_LOADING = 'GET_DEPOT_NOTIFICATION_TYPESS_LOADING';
export const GET_DEPOT_NOTIFICATION_TYPES_SUCCESS = 'GET_DEPOT_NOTIFICATION_TYPES_SUCCESS';
export const GET_DEPOT_NOTIFICATION_TYPES_FAIL = 'GET_DEPOT_NOTIFICATION_TYPES_FAIL';
export const GET_DEPOT_NOTIFICATION_TYPES_RESET = 'GET_DEPOT_NOTIFICATION_TYPES_RESET';

//Get total company vehicles constants
export const GET_TOTAL_COMPANY_VEHICLES_LOADING = 'GET_TOTAL_COMPANY_VEHICLES_LOADING';
export const GET_TOTAL_COMPANY_VEHICLES_SUCCESS = 'GET_TOTAL_COMPANY_VEHICLES_SUCCESS';
export const GET_TOTAL_COMPANY_VEHICLES_FAIL = 'GET_TOTAL_COMPANY_VEHICLES_FAIL';

//Get global health summary constants
export const GET_GLOBAL_HEALTH_SUMMARY_LOADING = 'GET_GLOBAL_HEALTH_SUMMARY_LOADING';
export const GET_GLOBAL_HEALTH_SUMMARY_SUCCESS = 'GET_GLOBAL_HEALTH_SUMMARY_SUCCESS';
export const GET_GLOBAL_HEALTH_SUMMARY_FAIL = 'GET_GLOBAL_HEALTH_SUMMARY_FAIL';

//Get global health detail constants
export const GET_GLOBAL_HEALTH_DETAIL_LOADING = 'GET_GLOBAL_HEALTH_DETAIL_LOADING';
export const GET_GLOBAL_HEALTH_DETAIL_SUCCESS = 'GET_GLOBAL_HEALTH_DETAIL_SUCCESS';
export const GET_GLOBAL_HEALTH_DETAIL_FAIL = 'GET_GLOBAL_HEALTH_DETAIL_FAIL';

//Get global map constants
export const GET_GLOBAL_DEPOT_LOCATION_LOADING = 'GET_GLOBAL_DEPOT_LOCATION_LOADING';
export const GET_GLOBAL_DEPOT_LOCATION_SUCCESS = 'GET_GLOBAL_DEPOT_LOCATION_SUCCESS';
export const GET_GLOBAL_DEPOT_LOCATION_FAIL = 'GET_GLOBAL_DEPOT_LOCATION_FAIL';

import React from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { Form, Field } from "react-final-form";
import { FormContainer } from "@/shared/components/form/FormElements";
import renderAsyncSelectField from "@/shared/components/form/AsyncSelect";
import { getConfig } from "../../../../shared/helpers/apiConfig";
import { ApiURL } from "../../../../config/GlobalUrl";

const SearchField = ({
  changeComp,
  diagnostics,
  setVin,
  isGateway,
  reset,
  setReset,
  setCpuId,
}) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const companyId = userDetails.company_id;
  const history = useHistory();

  const getHighlightedText = (text, highlight) => {
    const parts = String(text).split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      String(part).toLowerCase() === highlight.toLowerCase() ||
      String(part).toUpperCase() === highlight.toUpperCase() ? (
        <span
          key={index}
          style={{
            backgroundColor: "#f5e642",
            borderRadius: "2px",
            color: "black",
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const loadOptions = async (inputValue) => {
    const response = await axios.get(
      ApiURL.SEARCH_VIN_NUMBER +
        companyId +
        "/vehicles/get_vins?search_string=" +
        inputValue +
        `&is_gateway=${isGateway ? isGateway : ""}`,
      getConfig()
    );
    const responseJSON = await response.data.data.vins;

    const result = responseJSON.filter((item) => {
      return Object.values(item).some(
        (val) =>
          String(val).toLowerCase().includes(inputValue.toLowerCase()) ||
          String(val).toUpperCase().includes(inputValue.toUpperCase())
      );
    });

    const newResult = result.map(
      ({ vin, body_number, plate_num, bus_num, cpu_id }) => ({
        id: `${vin}-${cpu_id}`,
        value: vin,
        label: (
          <>
            VIN: {getHighlightedText(vin, inputValue)}
            <span style={{ display: "block", fontSize: "10px" }}>
              <span style={{ display: "block" }}>
                Body No.: {getHighlightedText(body_number, inputValue)},
              </span>
              <span style={{ display: "block" }}>
                License Plate: {getHighlightedText(plate_num, inputValue)},
              </span>
              <span style={{ display: "block" }}>
                Bus No.: {getHighlightedText(bus_num, inputValue)},
              </span>
              <span style={{ display: "block" }}></span>Cpu Id:{" "}
              {getHighlightedText(cpu_id, inputValue)}
            </span>
          </>
        ),
        newData: {
          vin: `VIN: ${vin}`,
          body_number,
          plate_num,
          bus_num,
          cpu_id,
        },
      })
    );
    return newResult;
  };

  const handleChange = (e) => {
    if (e && !diagnostics) {
      localStorage.setItem("viewVin", JSON.stringify(e.value));
      localStorage.setItem(
        "viewVinGateway",
        JSON.stringify(e.id.split("-")[1])
      );
      if (!changeComp) {
        history.push("/global/depot/vehicle");
      }
      if (changeComp) {
        changeComp(true);
      }
    }
    if (e && diagnostics) {
      setVin(e.value);
      setCpuId && setCpuId(e.id);
    }
    if (!e && diagnostics) {
      setVin("");
      setCpuId && setCpuId("");
    }
  };

  const resetfunction = (form) => {
    setTimeout(() => {
      if (reset) {
        form.restart();
        setReset(false);
      }
    }, 100);
  };

  return (
    <Form onSubmit={() => {}}>
      {({ handleSubmit, form }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Field
            name="vinsearch"
            component={renderAsyncSelectField}
            cacheOptions
            loadOptions={loadOptions}
            placeholder={
              "Search VIN, Body No., Bus No., License Plate or Cpu Id"
            }
            parse={(e) => {
              handleChange(e);
              return e;
            }}
            noOptionsMessage={(e) => (e.inputValue ? "No vehicle found" : null)}
            isClearable
          />
          {resetfunction(form)}
        </FormContainer>
      )}
    </Form>
  );
};

export default SearchField;

export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_SUCCESS_FIRST_TIME = 'SIGN_IN_SUCCESS_FIRST_TIME';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_IN_RESET = 'SIGN_IN_RESET';

export const RESET_PWD_LOADING = 'RESET_PWD_LOADING';
export const RESET_PWD_SUCCESS = 'RESET_PWD_SUCCESS';
export const RESET_PWD_FAIL = 'RESET_PWD_FAIL';
export const RESET_PWD_RESET = 'RESET_PWD_RESET';

export const FORGOT_PWD_LOADING = 'FORGOT_PWD_LOADING';
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS';
export const FORGOT_PWD_FAIL = 'FORGOT_PWD_FAIL';
export const FORGOT_PWD_RESET = 'FORGOT_PWD_RESET';

export const RESEND_PWD_LOADING = 'RESEND_PWD_LOADING';
export const RESEND_PWD_SUCCESS = 'RESEND_PWD_SUCCESS';
export const RESEND_PWD_FAIL = 'RESEND_PWD_FAIL';
export const RESEND_PWD_RESET = 'RESEND_PWD_RESET';

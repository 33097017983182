import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GLOBAL_HEALTH_DETAIL_LOADING,
  GET_GLOBAL_HEALTH_DETAIL_SUCCESS,
  GET_GLOBAL_HEALTH_DETAIL_FAIL,
} from '@/shared/constants/globalDashboardConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { sortingFunc } from '@/utils/helpers.js';

const getGlobalHealthDetail = (pageNumber, numberofRecords, sortData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GLOBAL_HEALTH_DETAIL_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const params = {
      p: pageNumber ? pageNumber : null,
      page_size: numberofRecords ? numberofRecords : null,
      sort: sortData ? sortingFunc(sortData) : null,
    };

    const { data } = await axios.get(
      ApiURL.GET_TOTAL_COMPANY_VEHICLES + companyId + '/depots/get_depots',
      { params: params },
      getConfig()
    );

    dispatch({
      type: GET_GLOBAL_HEALTH_DETAIL_SUCCESS,
      payload: {
        data: data.data.depots_details,
        pagination: data.pagination,
      },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GLOBAL_HEALTH_DETAIL_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GLOBAL_HEALTH_DETAIL_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getGlobalHealthDetail;

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_DEPOT_LIST_LOADING,
  GET_DEPOT_LIST_SUCCESS,
  GET_DEPOT_LIST_FAIL,
  GET_DEPOT_LIST_RESET,
} from '../../../shared/constants/DepotConstants/getDepotListConstants';

const getDepotList = (companyId, fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_LIST_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_DEPOT_LIST + companyId + '/fleet_id/' + fleetId,
      config
    );

    dispatch({
      type: GET_DEPOT_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};
export const resetDepotList = () => (dispatch) => {
  dispatch({
    type: GET_DEPOT_LIST_RESET,
  });
};
export default getDepotList;

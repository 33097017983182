import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBackground } from '@/utils/palette';
import { left } from '@/utils/directions';
import DotDotLoader from '@/shared/components/DotDotLoader';
import updateOtaRow from '@/redux/actions/OtaUpdateActions/OtaDashboardActions/updateOtaRowAction';

const ReactTableDefaultBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  dashboardType,
  tableOptions,
  history,
}) => {
  const dispatch = useDispatch();
  return (
    <tbody {...getTableBodyProps()}>
      {tableOptions.isLoading && (
        <tr style={{ padding: '0', border: 'none' }}>
          <td style={{ padding: '0' }}>
            <PanelRefresh>
              <DotDotLoader loadingState={tableOptions.isLoading} />
            </PanelRefresh>
          </td>
        </tr>
      )}
      {page.map((row) => {
        prepareRow(row);

        const handleClickGlobalRow = (fleetId, fleetName, depotId, depotName, operatorName) => {
          localStorage.setItem(
            'dashboardDetails',
            JSON.stringify({
              fleet: fleetId,
              fleetName: fleetName,
              depot: depotId,
              depotName: depotName,
              operatorName: operatorName,
            })
          );
          history.push('/global/depot');
        };
        const handleClickDepotRow = () => {
          localStorage.setItem('viewVin', JSON.stringify(row.original.vin));
          history.push('/global/depot/vehicle');
        };
        // const handleClickOtaRow = () => {
        //   dispatch(updateOtaRow(row.original));
        // };
        const handleClickIndividualGroup = () => {
          history.push('/ota/package/listing');
        };
        const handleClickCampaign = () => {
          dispatch(updateOtaRow(row.original));
        };

        return (
          <tr
            {...row.getRowProps()}
            onClick={() =>
              dashboardType === 'Global'
                ? handleClickGlobalRow(
                    row.original.fleet_id,
                    row.original.fleet,
                    row.original.id,
                    row.original.name,
                    row.original.operator
                  )
                : dashboardType === 'Depot'
                ? handleClickDepotRow()
                : // : dashboardType === 'GroupDashboard'
                // ? handleClickOtaRow()
                dashboardType === 'IndividualGroupDashboard'
                ? handleClickIndividualGroup()
                : dashboardType === 'CampaignTable'
                ? handleClickCampaign()
                : ''
            }
            style={{
              cursor: !(dashboardType === 'Diagnostics' || dashboardType === 'OtaDiagnostics')
                ? 'pointer'
                : 'default',
            }}
            className={dashboardType === 'Diagnostics' ? 'no-hover' : ''}
          >
            {row.cells.map((cell) =>
              dashboardType !== '' && dashboardType !== 'Diagnostics' ? (
                <TableData {...cell.getCellProps()} bg={cell.row.original.color}>
                  {cell.render('Cell')}
                </TableData>
              ) : (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              )
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  withDragAndDrop,
  updateDraggableData,
  dashboardType,
  tableOptions,
}) => {
  const history = useHistory();

  return (
    <Fragment>
      <ReactTableDefaultBody
        page={page}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        dashboardType={dashboardType}
        tableOptions={tableOptions}
        history={history}
      />
    </Fragment>
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

export default ReactTableBody;

const TableData = styled.td`
  &:last-child {
    button {
      display: inline-block;
      padding: 2px;
      width: 84px;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border: 1px solid #dddddd;
      background-color: transparent;
    }
    button:hover {
      background-color: #dddddd;
    }
  }
`;

const PanelRefresh = styled.div`
  position: absolute;
  width: 100%;
  // height: 100%;
  top: 50%;
  ${left}: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    // height: 100%;
    z-index: 99;
    top: 50%;
    ${left}: 0;
    border-radius: 5px;
    background-color: ${colorBackground};
    opacity: 0.8;
  }

  svg {
    position: absolute;
    top: calc(50% - 24px);
    ${left}: calc(50% - 24px);
  }
`;

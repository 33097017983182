import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';

const Profile = () => (
  <Container>
    <Row>
      <Col sm={12} md={12} lg={4} xl={4}>
        <ProfileMain />
      </Col>
      <Col sm={12} md={12} lg={8} xl={8}>
        <ProfileTabs />
      </Col>
    </Row>
  </Container>
);

export default Profile;

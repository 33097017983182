import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DownloadDiagnostic from './components/DownloadDiagnostic';

const DiagnosticsOne = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <DownloadDiagnostic />
        </Col>
      </Row>
    </Container>
  );
};

export default DiagnosticsOne;

import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  CLOSE_ALARM_LOADING,
  CLOSE_ALARM_SUCCESS,
  CLOSE_ALARM_FAIL,
} from '../../../shared/constants/HealthStatusDashboardConstants/closeAlarmConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';
import closeVehicleAlarm from '../VehicleDashboardActions/closeVehicleAlarmActions';

const closeAlarm = (vin, alarmId, formData, mainComment) => async (dispatch) => {
  try {
    dispatch({
      type: CLOSE_ALARM_LOADING,
    });

    const response = await axios.post(
      ApiURL.GET_ALARM_CHECKLIST + vin + '/alarm/' + alarmId + '/user_submission',
      {
        submission: {
          checkpoints: formData,
        },
      },
      getConfig()
    );

    dispatch({
      type: CLOSE_ALARM_SUCCESS,
      payload: response.data.data,
    });

    // closing alarm
    dispatch(closeVehicleAlarm(vin, alarmId, mainComment));
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: CLOSE_ALARM_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: CLOSE_ALARM_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default closeAlarm;

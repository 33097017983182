import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_HVAC_LIST_LOADING,
  GET_HVAC_LIST_SUCCESS,
  GET_HVAC_LIST_FAIL,
  GET_HVAC_LIST_RESET,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getHvacList = (depotId, groupName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HVAC_LIST_LOADING,
    });

    const response = await axios.get(ApiURL.GET_HVAC_LIST + depotId + groupName, getConfig());

    dispatch({
      type: GET_HVAC_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_HVAC_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_HVAC_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getHvacListReset = () => (dispatch) => {
  dispatch({
    type: GET_HVAC_LIST_RESET,
  });
};

export default getHvacList;

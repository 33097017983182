import {
  GATEWAY_LOADING,
  GATEWAY_SUCCESS,
  GATEWAY_FAIL,
  RESTORE_INITIAL_VALUES,
  RESET_ERROR,
} from '../../shared/constants/gatewayConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const registerGatewayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GATEWAY_LOADING:
      return { ...state, loading: true, info: '', error: '' };
    case GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GATEWAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESTORE_INITIAL_VALUES:
      return {
        loading: false,
        info: '',
        error: '',
      };
    case RESET_ERROR:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default registerGatewayReducer;

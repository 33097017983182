import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import getRegisteredCompany from '../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import getFleetList from '../../../redux/actions/FleetActions/getFleetListActions';
import data from '../../App/Router/data';
import CompanyDetailsContainer from '../ManageCompany/CompanyDetailsContainer';
import ManageFleetContainer from './ManageFleetContainer';
import ViewFleet from './components/ViewFleet';
import FleetForm from './components/FleetForm';
import FleetSelectForm from './components/FleetSelectForm';

const ManageFleet = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, info, error } = useSelector((state) => state.getRegisteredCompanyReducer);

  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;

  const assets = ['Add Fleets', 'Edit Fleets', 'View Fleets'];

  const addImage = `${process.env.PUBLIC_URL}/img/Add.jpg`;
  const editImage = `${process.env.PUBLIC_URL}/img/Edit.jpg`;
  const viewImage = `${process.env.PUBLIC_URL}/img/View.jpg`;

  const assetData = [
    {
      id: 1,
      img: addImage,
      assetType: assets[0],
    },
    {
      id: 2,
      img: editImage,
      assetType: assets[1],
    },
    {
      id: 3,
      img: viewImage,
      assetType: assets[2],
    },
  ];
  const [showFleetForm, setShowFleetForm] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSelectFleet, setShowSelectFleet] = useState(false);
  const [showFleetView, setShowFleetView] = useState(false);
  const companyId = localStorage.getItem('companyId');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyRoleName = userDetails.company_role_name;

  const toggleFleetForm = () => {
    setGoBack(false);
    setShowFleetForm(true);
    setShowSelectFleet(false);
  };

  const toggleFleetSelect = () => {
    setGoBack(false);
    setShowFleetForm(false);
    setShowSelectFleet(true);
  };

  const toggleFleetView = () => {
    if (companyId) {
      dispatch(getFleetList(companyId));
    }
    setGoBack(false);
    setShowFleetForm(false);
    setShowSelectFleet(false);
    setShowFleetView(true);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getRegisteredCompany(companyId));
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              {loading || !info || info === undefined ? (
                <DotDotLoader loadingState={loading} />
              ) : (
                <CompanyDetailsContainer
                  img={Ava}
                  name={info.company.name}
                  website={info.company.website}
                  role={info.company.company_type}
                  city={info.company.city}
                  country={info.company.country}
                  showLink={false}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {showFleetForm === true && goBack === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Fleet Registration Section</h6>
            <Col xs={12} sm={12} md={6}>
              <FleetForm setGoBack={setGoBack} editMode={editMode} />
            </Col>
          </>
        ) : showSelectFleet === true && goBack === false && showFleetForm === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Edit Fleet details</h6>
            <Col xs={12} sm={12} md={6}>
              <FleetSelectForm setGoBack={setGoBack} />
            </Col>
          </>
        ) : showSelectFleet === false &&
          goBack === false &&
          showFleetForm === false &&
          showFleetView === true ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>View Fleet details</h6>
            <Col xs={12} sm={12} md={6}>
              <ViewFleet setGoBack={setGoBack} />
            </Col>
          </>
        ) : (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Fleet Settings</h6>
            <Col xs={12} sm={12} md={6}>
              <Card height="auto">
                <CardBody>
                  {info &&
                    assetData
                      .filter(({ assetType }) =>
                        adminRole.includes(companyRoleName) &&
                        !canAccess.includes(info.company.company_type)
                          ? assetType === assets[2]
                          : assetType
                      )
                      .map(({ id, assetType, img }) => (
                        <div
                          key={id}
                          onClick={() =>
                            assetType === assets[0]
                              ? toggleFleetForm()
                              : assetType === assets[1]
                              ? toggleFleetSelect()
                              : assetType === assets[2]
                              ? toggleFleetView()
                              : ''
                          }
                        >
                          <ManageFleetContainer img={img} assettype={assetType} />
                        </div>
                      ))}
                  <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{ width: '100%' }}
                    >
                      Back
                    </Button>
                  </FormButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageFleet;

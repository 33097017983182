import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import SimpleLoader from '@/shared/components/SimpleLoader';
import RoleForm from './RoleForm';
import getFleetList from '../../../../redux/actions/FleetActions/getFleetListActions';

const RoleSelectForm = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const [showRoleEditForm, setShowRoleEditForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [roleId, setRoleId] = useState();
  const [btnClicked, setBtnClicked] = useState(false);

  const companyId = localStorage.getItem('companyId');

  const getRegisteredFleetReducerLoading = useSelector(
    (state) => state.getRegisteredFleetReducer.loading
  );

  const getRegisteredFleetReducerInfo = useSelector(
    (state) => state.getRegisteredFleetReducer.info
  );

  const getRegisteredFleetReducerError = useSelector(
    (state) => state.getRegisteredFleetReducer.error
  );

  const getFleetListReducerLoading = useSelector(
    (state) => state.getFleetListReducer.loading
  );

  const getFleetListReducerInfo = useSelector(
    (state) => state.getFleetListReducer.info
  );

  const getFleetListReducerError = useSelector(
    (state) => state.getFleetListReducer.error
  );

  // useEffect(() => {
  //   if (companyId) {
  //     dispatch(getRoleList(companyId));
  //   }
  // }, [companyId]);

  const handleSubmit = (values) => {
    if (Object.keys(values).length > 0 && values.role.id) {
      setRoleId(values.role.id);
      setBtnClicked(true);
      setShowRoleEditForm(true);
    }
  };

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <>
      {showRoleEditForm === true ? (
        <RoleForm
          editMode={editMode}
          setShowRoleEditForm={setShowRoleEditForm}
          roleId={roleId}
        />
      ) : (
        <Card height='auto'>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                  {/* {getRegisteredFleetReducerLoading === true ? (
                    <SimpleLoader />
                  ) : (
                    ''
                  )} */}
                  <FormGroup>
                    <FormGroupLabel>Select Role</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name='name'
                        component='input'
                        type='text'
                        placeholder='Role Name'
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button
                      variant='secondary'
                      type='button'
                      onClick={() => handleCancel()}
                    >
                      Back
                    </Button>
                    <Button variant='primary' type='submit'>
                      Next
                    </Button>
                  </FormButtonToolbar>
                </FormContainer>
              )}
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default RoleSelectForm;

import React, { useEffect } from 'react';
import { Card, CardBody } from '@/shared/components/Card';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { temperatureDetails } from '../../../../redux/actions/getTemperatureActions';

const VehicleTemperature = ({ vin }) => {
  const dispatch = useDispatch();

  const { ambientLoading, ambientInfo, ambientError } = useSelector(
    (state) => state.getTemperatureReducer
  );

  useEffect(() => {
    dispatch(temperatureDetails(vin));
  }, [vin]);

  return (
    <Card height="auto" style={{ height: '188px' }}>
      <CardBody>
        <VehicleTempDetailsContainer>
          <VehicleTempDetails>
            <VehicleTempDetailsText>Temperature (C&deg;)</VehicleTempDetailsText>
          </VehicleTempDetails>
          <VehicleTempDetails>
            <VehicleTempTypeAmbient style={{ color: '#9baab0', paddingRight: '5px' }}>
              AMBIENT:
            </VehicleTempTypeAmbient>
            <VehicleTempTypeAmbient>
              {ambientLoading
                ? '-'
                : ambientInfo
                ? ambientInfo.AmbientTemperature.value?.toFixed(2)
                : ambientError && '-'}
            </VehicleTempTypeAmbient>
          </VehicleTempDetails>
          <VehicleTempDetails>
            <VehicleTempTypeReturn style={{ color: '#9baab0', paddingRight: '5px' }}>
              SALOON:
            </VehicleTempTypeReturn>
            <VehicleTempTypeReturn>
              {ambientLoading
                ? '-'
                : ambientInfo
                ? ambientInfo.saloon.value?.toFixed(2)
                : ambientError && '-'}
            </VehicleTempTypeReturn>
          </VehicleTempDetails>
        </VehicleTempDetailsContainer>
      </CardBody>
    </Card>
  );
};

export default VehicleTemperature;

// region STYLES

const VehicleTempDetailsContainer = styled.div`
  text-transform: uppercase;
  height: 100%;
  padding: 10px 0;
  text-align: center;
`;

const VehicleTempDetailsText = styled.p`
  font-size: 12px;
  color: #9baab0;
  padding-bottom: 10px;
`;

const VehicleTempDetails = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const VehicleTempTypeAmbient = styled.p`
  font-size: 13px;
  color: #37baeb;
`;
const VehicleTempTypeReturn = styled.p`
  font-size: 13px;
  color: #37baeb;
`;

// end region STYLES

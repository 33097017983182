import axios from 'axios';
import {
  GET_LAST_TIMESTAMP_LOADING,
  GET_LAST_TIMESTAMP_SUCCESS,
  GET_LAST_TIMESTAMP_FAIL,
} from '@/shared/constants/getLastTimestampConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '../../config/GlobalUrl';

const getLastTimestamp = (vin) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LAST_TIMESTAMP_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_SIGNAL_TIMESTAMP + vin + '/gateway/last_signal_timestamp',
      getConfig()
    );

    dispatch({
      type: GET_LAST_TIMESTAMP_SUCCESS,
      payload: response.data.data.last_signal_timestamp,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_LAST_TIMESTAMP_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_LAST_TIMESTAMP_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getLastTimestamp;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PieChartCard from '@/shared/components/PieChartCard';
import getDepotHealthStatusSummary from '@/redux/actions/DepotDashboardActions/getDepotHealthStatusSummary';

const DepotHealthStatusSummary = ({
  fleet_id,
  depot_id,
  changeComp,
  setChangeComp,
  companyId,
  tag,
}) => {
  const dispatch = useDispatch();

  const { loading, info, error } = useSelector((state) => state.getHealthStatusSummaryReducer);

  useEffect(() => {
    dispatch(getDepotHealthStatusSummary(companyId, fleet_id, depot_id, tag));
    setChangeComp(false);
  }, [depot_id, tag]);

  const placeholderData = [
    {
      value: 0,
      fill: '#B4C6CC',
      name: 'Connectivity',
    },
    {
      value: 0,
      fill: '#FF754A',
      name: 'Data',
    },
    {
      value: 0,
      fill: '#F5A608',
      name: 'Preventive',
    },
    {
      value: 0,
      fill: '#DC2323',
      name: 'Conditional',
    },
    {
      value: 0,
      fill: '#DC2323',
      name: 'DTC',
    },
    {
      value: 0,
      fill: '#F5A608',
      name: 'DTC',
    },
    {
      value: 1,
      fill: '#eee',
      name: 'No Data',
    },
  ];

  return (
    <PieChartCard
      title={'HEALTH STATUS SUMMARY'}
      subheading={'Open alarms'}
      loading={loading}
      data={info.alarmData}
      placeholderData={placeholderData}
      datakey={'value'}
      total={info.total}
      error={error}
    />
  );
};

export default DepotHealthStatusSummary;

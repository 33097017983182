import axios from 'axios';
import {
  REMOVE_VEHICLE_TAG_LOADING,
  REMOVE_VEHICLE_TAG_SUCCESS,
  REMOVE_VEHICLE_TAG_FAIL,
  REMOVE_VEHICLE_TAG_RESET,
} from '@/shared/constants/VehicleDashboardConstants/addVehicleTagsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { vehicle_dashboard } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const removeVehicleTag = (companyId, vin, tagId) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_VEHICLE_TAG_LOADING,
    });

    const response = await axios.delete(
      ApiURL.REMOVE_VEHICLE_TAG + companyId + '/vin/' + vin + '/vehicle_assoc_tag_remove',
      {
        data: {
          tag_id: tagId,
        },
      },
      getConfig()
    );

    successMessage(vehicle_dashboard.remove_tag);

    dispatch({
      type: REMOVE_VEHICLE_TAG_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: REMOVE_VEHICLE_TAG_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: REMOVE_VEHICLE_TAG_FAIL,
        payload: error.response,
      });
    }
  }
};

export const resetRemoveVehicleTag = () => (dispatch) => {
  dispatch({
    type: REMOVE_VEHICLE_TAG_RESET,
  });
};

export default removeVehicleTag;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import ClipboardClockOutlineIcon from 'mdi-react/ClipboardClockOutlineIcon';
import { addDays } from 'date-fns';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '../../../../shared/components/DotDotLoader';
import getMaintenanceMeta from '../../../../redux/actions/MaintenanceActions/getMaintenanceMetaActions';
import addMaintenanceMeta from '../../../../redux/actions/MaintenanceActions/addMaintenanceMetaActions';
import { preventiveMainValidation } from '../../../../utils/validate';

const PreventiveForm = ({ setChangeComp, setAsset }) => {
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [changeInputType, setChangeInputType] = useState(false);

  const { loading, info, error } = useSelector((state) => state.getMaintenanceMetaReducer);
  const addMaintenanceMetaReducerInfo = useSelector(
    (state) => state.addMaintenanceMetaReducer.info
  );

  const handleCancel = () => {
    setChangeComp(false);
    setAsset('');
  };

  const handleRegister = (values) => {
    if (values) {
      dispatch(addMaintenanceMeta(localStorage.getItem('NewVin'), values));
      setBtnClicked(true);
    }
  };

  if (btnClicked && addMaintenanceMetaReducerInfo) {
    setTimeout(() => {
      handleCancel();
    }, 100);
  }

  useEffect(() => {
    dispatch(getMaintenanceMeta(localStorage.getItem('NewVin')));
    return () => {
      setBtnClicked(false);
    };
  }, []);

  const dropdownVal = [
    {
      value: info && info.length > 0 ? info[0].mode : '',
      label:
        info && info.length > 0
          ? info[0].mode === 'time_period_days'
            ? 'Time of Service'
            : 'Operating Hours'
          : '',
    },
  ];

  const handleFormInputChange = (values) => {
    if (values.value === 'time_period_days') {
      setChangeInputType(true);
    }
    if (values.value !== 'time_period_days') {
      setChangeInputType(false);
    }
  };
  return (
    <>
      {loading ? (
        <DotDotLoader loadingState={loading} />
      ) : error ? (
        <div>Error loading data</div>
      ) : (
        <Form
          onSubmit={handleRegister}
          validate={preventiveMainValidation}
          initialValues={info && info.length > 0 ? info[0] : {}}
        >
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete={'off'}>
              <FormGroup>
                <FormGroupLabel>Maintenance Metric</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="mode"
                    component={renderSelectField}
                    options={[
                      { id: 1, value: 'operating_hours', label: 'Operating Hours' },
                      { id: 2, value: 'time_period_days', label: 'Time of service' },
                    ]}
                    isDisabled={info && info.length > 0 && true}
                    initialValue={dropdownVal}
                    parse={(e) => {
                      handleFormInputChange(e);
                      form.reset();
                      return e;
                    }}
                    placeholder={'Please select Maintenance Metric'}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Level A</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="level_a"
                    component={!changeInputType ? FormField : renderDatePickerField}
                    type={!changeInputType ? 'text' : 'date'}
                    maxdate={addDays(new Date(), -1)}
                    placeholder={'Please enter level a'}
                    disabled={info && info.length > 0 && true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Level B</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="level_b"
                    component={!changeInputType ? FormField : renderDatePickerField}
                    type={!changeInputType ? 'text' : 'date'}
                    maxdate={addDays(new Date(), -1)}
                    placeholder={'Please enter level b'}
                    disabled={info && info.length > 0 && true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Level C</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="level_c"
                    component={!changeInputType ? FormField : renderDatePickerField}
                    type={!changeInputType ? 'text' : 'date'}
                    maxdate={addDays(new Date(), -1)}
                    placeholder={'Please enter level c'}
                    disabled={info && info.length > 0 && true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Level D</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="level_d"
                    component={!changeInputType ? FormField : renderDatePickerField}
                    type={!changeInputType ? 'text' : 'date'}
                    maxdate={addDays(new Date(), -1)}
                    placeholder={'Please enter level d'}
                    disabled={info && info.length > 0 && true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Level E</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <ClipboardClockOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="level_e"
                    component={!changeInputType ? FormField : renderDatePickerField}
                    type={!changeInputType ? 'text' : 'date'}
                    maxdate={addDays(new Date(), -1)}
                    placeholder={'Please enter level e'}
                    disabled={info && info.length > 0 && true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar
                style={{
                  width: '50%',
                  paddingRight: '10px',
                  paddingTop: '20px',
                  margin: '0',
                }}
              >
                <Button
                  variant="secondary"
                  type="button"
                  style={{ width: '100%', margin: '0' }}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </FormButtonToolbar>
              {!(info && info.length > 0) ? (
                <FormButtonToolbar
                  style={{
                    width: '50%',
                    paddingLeft: '10px',
                    margin: '0',
                    paddingTop: '20px',
                  }}
                >
                  <Button variant="primary" type="submit" style={{ width: '100%', margin: '0' }}>
                    Register
                  </Button>
                </FormButtonToolbar>
              ) : (
                ''
              )}
            </FormContainer>
          )}
        </Form>
      )}
    </>
  );
};

export default PreventiveForm;

import axios from 'axios';
import {
  RESET_PWD_LOADING,
  RESET_PWD_SUCCESS,
  RESET_PWD_FAIL,
  RESET_PWD_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const resetPwd = (email, tempPwd, newPwd) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PWD_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      ApiURL.RESET_PWD,
      { username: email, temp_pass: tempPwd, new_pass: newPwd },
      config
    );
    successMessage('Password reset successfully');

    dispatch({
      type: RESET_PWD_SUCCESS,
      payload: response.data.data.message,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: RESET_PWD_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: RESET_PWD_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const resetPwdReset = () => (dispatch) => {
    dispatch({
      type: RESET_PWD_RESET,
    });
  };

export default resetPwd;

import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { WizardButtonToolbar } from '@/shared/components/form/WizardFormElements';
import { FormContainer } from '@/shared/components/form/FormElements';
import { campaignCreationValidateStepThree } from '@/utils/validate';
import FormField from '@/shared/components/form/FormField';
import { StepPropTypes, StepDefaultProps } from './Props';
import renderDateTimePickerField from '../styled-elements/CampaignDatePicker';
import SharedRadio from '../../../SharedElements/SharedRadio';

const StepThree = ({ onSubmit, previousPage, defaultValues }) => {
  return (
    <Form onSubmit={onSubmit} validate={campaignCreationValidateStepThree}>
      {({ handleSubmit, hasValidationErrors, values }) => (
        <FormContainer
          onSubmit={handleSubmit}
          style={{ maxWidth: '610px', width: '100%', marginTop: '25px' }}
          vertical
        >
          <FormGroup>
            <FormGroupLabel>Campaign Name</FormGroupLabel>
            <FormGroupField>
              <Field
                name="campaign_name"
                component={FormField}
                type="text"
                placeholder="Campaign name"
                initialValue={defaultValues?.campaign_name}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Deployment Type</FormGroupLabel>
            <FormGroupField>
              <Field
                name="deployment_type"
                render={SharedRadio}
                label="Instant"
                radioValue={'instant'}
                value={'instant'}
                initialValue={defaultValues?.deployment_type}
                disabled={
                  defaultValues &&
                  defaultValues['hvac-list'].filter(
                    (device) => device.connectivityStatus !== 'online'
                  ).length > 0
                    ? true
                    : false
                }
              />
              <Field
                name="deployment_type"
                render={SharedRadio}
                label="Later"
                radioValue={'later'}
                value={'later'}
                initialValue={defaultValues?.deployment_type}
              />
            </FormGroupField>
          </FormGroup>
          {values.deployment_type === 'later' && (
            <FormGroup>
              <FormGroupLabel>Select Date</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="time"
                  component={renderDateTimePickerField}
                  parse={(e) => {
                    return e;
                  }}
                  placeholder={'Select end date'}
                  timeselected={defaultValues?.time}
                />
              </FormGroupField>
            </FormGroup>
          )}
          <WizardButtonToolbar>
            <Button
              variant="secondary"
              type="button"
              className="previous"
              onClick={() => {
                previousPage(values);
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                (values.deployment_type === 'later' && !defaultValues?.time) || hasValidationErrors
              }
            >
              Submit
            </Button>
          </WizardButtonToolbar>
        </FormContainer>
      )}
    </Form>
  );
};

StepThree.propTypes = StepPropTypes;
StepThree.defaultProps = StepDefaultProps;

export default StepThree;

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_REGISTERED_USER_LOADING,
  GET_REGISTERED_USER_SUCCESS,
  GET_REGISTERED_USER_FAIL,
} from '../../../shared/constants/UserConstants/getRegisteredUserConstants';

const getRegisteredUser = (companyId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REGISTERED_USER_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.get(
      ApiURL.GET_REGISTERED_USER +
        companyId +
        '/user_id/' +
        userId +
        '/get_user_detail',
      config
    );
    dispatch({
      type: GET_REGISTERED_USER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_REGISTERED_USER_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_REGISTERED_USER_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getRegisteredUser;

import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Badge from '@/shared/components/Badge';
import { Button } from '@/shared/components/Button';
import getAlarmChecklist from '../../../../redux/actions/HealthStatusDashboardActions/getAlarmChecklistActions';
import { GetTableHeader } from '../../../../utils/helpers';

const CreateTableData = () => {
  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [rowDetails, setRowDetails] = useState();
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const dispatch = useDispatch();

  const handleClick = (rowData, showInputBox, disableInput) => {
    dispatch(getAlarmChecklist(rowData.vin, rowData.id));
    setShowModal(true);
    setRowDetails(rowData);
    setShowInput(showInputBox);
    setDisableInput(disableInput);
  };

  const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours + ' Hr ' + minutes + ' Mins';
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Type'}
            key={'Alarm Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'type',
        disableGlobalFilter: true,
        Cell: (props) => {
          const bg = props.row.original.color;
          const alarmType = props.row.original.type;
          return <Badge bg={bg}>{alarmType}</Badge>;
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Id'}
            key={'Alarm Id'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'id',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (props.row.original.type === 'Preventive' &&
            props.row.original.status !== 'closed') ||
            (props.row.original.type === 'Conditional' &&
              props.row.original.status !== 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, false)}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Preventive' &&
              props.row.original.status === 'closed') ||
            (props.row.original.type === 'Conditional' &&
              props.row.original.status === 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              // onClick={() => handleClick(props.row.original, true, true)}
              disabled={true}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Connectivity' &&
              props.row.original.status !== 'closed') ||
            (props.row.original.type === 'Data' && props.row.original.status !== 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, false)}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Connectivity' &&
              props.row.original.status === 'closed') ||
            (props.row.original.type === 'Data' && props.row.original.status === 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              disabled={true}
            >
              {props.cell.value}
            </Button>
          ) : (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, true)}
            >
              {props.cell.value}
            </Button>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Details'}
            key={'Alarm Details'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'metadata.display_name',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <div onClick={() => handleClick(row.original, true, true)}>
              <LabelSpan className="span-hover">
                {row.original.metadata
                  ? `${row.original.metadata.display_name.substring(0, 30)}...`
                  : ''}
              </LabelSpan>
            </div>
          );
        },
      },
      {
        Header: 'Time (in UTC)',
        accessor: 'time',
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original.triggered_at.split('T')[1];
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Date'}
            key={'Date'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'triggered_at',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return row.original.triggered_at.split('T')[0];
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Duration'}
            key={'Duration'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'elapsed_mins',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return toHoursAndMinutes(row.original.elapsed_mins);
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Bus Number'}
            key={'Bus Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'bus_num',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Service Partner'}
            key={'Service Partner'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'service_provider',
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    openModal: showModal,
    closeModal: setShowModal,
    alarmDetails: rowDetails,
    showInputBox: showInput,
    disableInputBox: disableInput,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

//Add some hover effect and show visually that this is link
const LabelSpan = styled.span`
  cursor: pointer;
  color: #37baeb;

  &:hover {
    // color: #37BAEB;
    text-decoration: underline;
  }
`;

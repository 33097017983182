import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import renderSelectField from '@/shared/components/form/Select';
import renderDateTimePickerField from '@/shared/components/form/date-pickers/DateTimePicker';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormButtonToolbar,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import getDiagnosticsFile from '../../../../redux/actions/DiagnosticsActions/getDiagnosticsFileActions';
import getSignals, {
  getSignalsReset,
} from '../../../../redux/actions/DiagnosticsActions/getSignalsActions';
import VinSearch from '../../VinSearch';
import { dowloadDiagnostic } from '../../../../utils/validate';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';

const DownloadDiagnostic = () => {
  const dispatch = useDispatch();

  const getSignalsReducer = useSelector((state) => state.getSignalsReducer.info);
  const getSignalsReducerLoading = useSelector((state) => state.getSignalsReducer.loading);
  const getDiagnosticsFileReducer = useSelector((state) => state.getDiagnosticsFileReducer.loading);

  const [vin, setVin] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeobj, setStartTimeobj] = useState('');
  const [endTimeobj, setEndTimeobj] = useState('');
  const [signals, setSignals] = useState();
  const [isMultiOptions, setMultiOptions] = useState(true);
  const [startminute, setstartminute] = useState('');
  const [starthour, setstarthour] = useState('');
  const [starthmtime, setstarthmtime] = useState('');
  const [endminute, setendminute] = useState('');
  const [endhour, setendhour] = useState('');
  const [endhmtime, setendhmtime] = useState('');
  const [reset, setReset] = useState(false);
  const [startDateString, setstartDateString] = useState('');
  const [endDateString, setendDateString] = useState('');
  const [vinChanged, setVinChanged] = useState(false);
  const sameDates =
    startDateString &&
    endDateString &&
    startDateString === endDateString &&
    starthmtime &&
    endhmtime;

  const options = getSignalsReducer
    ? getSignalsReducer.signal
        .filter((item) => item.name)
        .map(({ id, name }) => ({
          id: id,
          value: name,
          label: name,
        }))
    : [];
  const [optionsUpdated, setoptionsUpdated] = useState([]);

  useEffect(() => {
    if (options) {
      setoptionsUpdated(options);
    }
  }, [getSignalsReducer, vin]);

  useEffect(() => {
    if ((vin || signals) && !vinChanged) {
      setVinChanged(true);
    }
  }, [vin]);

  const handleChangeStartTime = (values) => {
    //called just for date selection
    if (values) {
      setStartTimeobj(values);
      setstartDateString(values.toDateString());
      setSignals();
      if (starthmtime) {
        values.setHours(starthmtime.slice(0, 2));
        values.setMinutes(starthmtime.slice(3, 5));
        const utcStartTime = moment.utc(values).format();
        setStartTime(utcStartTime);
      }
    }
  };
  const handleChangeStartDuration = (values) => {
    //callled just for time selection
    if (!values) {
      setstartminute('');
      setstarthour('');
      setstarthmtime('');
      setStartTime('');
      setSignals();
    }

    if (values) {
      setstarthmtime(values.format('HH:mm'));
      const myval = startTimeobj;
      setstartminute(values.format('mm'));
      setstarthour(values.format('HH'));
      setSignals();
      if (startTimeobj) {
        myval.setHours(values.format('HH'));
        myval.setMinutes(values.format('mm'));
        const utcStartTime = moment.utc(myval).format();
        setStartTime(utcStartTime);
      }
    }
  };
  const handleChangeEndTime = (values) => {
    if (values) {
      setEndTimeobj(values);
      setendDateString(values.toDateString());
      setSignals();
      if (endhmtime) {
        values.setHours(endhmtime.slice(0, 2));
        values.setMinutes(endhmtime.slice(3, 5));
        const utcEndTime = moment.utc(values).format();
        setEndTime(utcEndTime);
      }
    }
  };
  const handleChangeEndDuration = (values) => {
    if (!values) {
      setendminute('');
      setendhour('');
      setendhmtime('');
      setEndTime('');
      setSignals();
    }
    if (values) {
      setendhmtime(values.format('HH:mm'));
      const myval = endTimeobj;
      setendminute(values.format('mm'));
      setendhour(values.format('HH'));
      setSignals();
      if (endTimeobj) {
        myval.setHours(values.format('HH'));
        myval.setMinutes(values.format('mm'));
        const utcEndTime = moment.utc(myval).format();
        setEndTime(utcEndTime);
      }
    }
  };

  const handleSignals = (values) => {
    const valuesStr = JSON.stringify(values);
    if (values && values.length >= 1) {
      setSignals(values);
      if (valuesStr.includes('Select All')) {
        setMultiOptions(false);
      } else {
        // options.shift();
        setoptionsUpdated(options);
      }
    } else {
      setoptionsUpdated(options);
      setMultiOptions(true);
      setSignals();
    }
  };

  useEffect(() => {
    return () => {
      if (getSignalsReducer) {
        dispatch(getSignalsReset());
      }
    };
  }, []);

  const onSubmit = (values) => {
    if (values.signals && values.signals.length > 0 && vin && startTime && endTime) {
      let signalIdsArr = [];
      const getSignalIds = values.signals.map(({ id }) => id);
      signalIdsArr.push(...getSignalIds);
      dispatch(getDiagnosticsFile(vin, startTime, endTime, signalIdsArr));
      setVin('');
      setStartTime('');
      setEndTime('');
      setSignals();
      setMultiOptions(true);
      setoptionsUpdated([]);
      setEndTimeobj('');
      setStartTimeobj('');
      setendhmtime('');
      setstarthmtime('');
    }
  };

  const resetfunction = (form) => {
    if (sameDates && endhmtime <= starthmtime) {
      setTimeout(() => {
        form.change('time_duration_end', '');
        form.change('time_duration_start', '');
        setendhmtime('');
        setstarthmtime('');
        setEndTime('');
        setStartTime('');
        setendhour('');
        setendminute('');
        setstarthour('');
        setstartminute('');
        setSignals();
      }, 100);
    }
    setTimeout(() => {
      if (vinChanged) {
        form.change('signals', '');
        setSignals();
        setVinChanged(false);
      }
    }, 10);
    setTimeout(() => {
      if (getDiagnosticsFileReducer) {
        setReset(true);
        if (getSignalsReducer) {
          dispatch(getSignalsReset());
        }
        form.restart();
      }
    }, 100);
  };
  const menuFunc = () => {
    if ((!vin || !startTime || !endTime) && getSignalsReducer) {
      dispatch(getSignalsReset());
    }
    if (vin && startTime && endTime && signals === undefined) {
      dispatch(getSignals(vin, startTime, endTime));
    }
  };
  return (
    <Card style={{ height: 'auto' }}>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>{'Download Signal Data'}</CardTitle>
        </CardTitleWrap>
        {getDiagnosticsFileReducer || getSignalsReducerLoading ? (
          <DotDotLoader loadingState={getDiagnosticsFileReducer || getSignalsReducerLoading} />
        ) : (
          ''
        )}
        <FormGroup>
          <FormGroupLabel>VIN:</FormGroupLabel>
          <VinSearch
            diagnostics={true}
            setVin={setVin}
            isGateway={'True'}
            reset={reset}
            setReset={setReset}
          />
        </FormGroup>
        <Form onSubmit={onSubmit} validate={dowloadDiagnostic}>
          {({ handleSubmit, submitting, form, values, errors }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormHalfContainer>
                <FormGroup>
                  <FormGroupLabel>Start Date:</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="start_time"
                      component={renderDateTimePickerField}
                      parse={(e) => {
                        handleChangeStartTime(e);
                        form.change('signals', '');
                        return e;
                      }}
                      placeholder={'Select start date'}
                      timeselected={endTimeobj}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>End Date:</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="end_time"
                      component={renderDateTimePickerField}
                      parse={(e) => {
                        handleChangeEndTime(e);
                        form.change('signals', '');
                        return e;
                      }}
                      placeholder={'Select end date'}
                      timeselected={startTimeobj}
                    />
                  </FormGroupField>
                </FormGroup>
              </FormHalfContainer>
              <FormHalfContainer>
                <FormGroup>
                  <FormGroupLabel>Start Time:</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="time_duration_start"
                      component={renderTimePickerField}
                      placeholderText={'Select start time'}
                      parse={(e) => {
                        handleChangeStartDuration(e);
                        form.change('signals', '');
                        return e;
                      }}
                      value={moment()}
                      timeMode={true}
                      startminute={startminute}
                      starthour={starthour}
                      endminute={endminute}
                      endhour={endhour}
                      startdate={startTimeobj}
                      endate={endTimeobj}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>End Time:</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="time_duration_end"
                      component={renderTimePickerField}
                      placeholderText={'Select end time'}
                      parse={(e) => {
                        handleChangeEndDuration(e);
                        form.change('signals', '');
                        return e;
                      }}
                      value={moment()}
                      timeMode={true}
                      startminute={startminute}
                      starthour={starthour}
                      endminute={endminute}
                      endhour={endhour}
                      startdate={startTimeobj}
                      endate={endTimeobj}
                    />
                  </FormGroupField>
                </FormGroup>
              </FormHalfContainer>
              <FormGroup>
                <FormGroupLabel>Current Data Selection:</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="signals"
                    component={renderSelectField}
                    isMulti
                    placeholder="Select Signals"
                    options={getSignalsReducer ? (isMultiOptions ? optionsUpdated : []) : []}
                    parse={(e) => {
                      handleSignals(e);
                      return e;
                    }}
                    onMenuOpen={menuFunc}
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: '100%' }}
                  disabled={!(vin && startTime && endTime && signals) || submitting}
                >
                  {resetfunction(form)}
                  Download Data
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default DownloadDiagnostic;

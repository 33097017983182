import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import { updateOtaRowReset } from "@/redux/actions/OtaUpdateActions/OtaDashboardActions/updateOtaRowAction";
import GroupListing from "./components/GroupListing/components/GroupListing";
import GroupCreationForm from "./components/GroupCreationForm";
import CreateTableData from "./components/GroupListing/components/CreateData";

const GroupDashboard = () => {
  const dispatch = useDispatch();
  const reactTableData = CreateTableData();
  const otaRowSelected = useSelector((state) => state.updateOtaRowReducer.info);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(updateOtaRowReset());
    };
  }, []);

  return (
    <Container>
      {!reactTableData.showGroupForm && !showForm ? (
        <Row
          className={"d-flex justify-content-end"}
          style={{ marginTop: "-40px" }}
        >
          <Col lg={3} className={"d-flex justify-content-end"}>
            <Button variant="primary" onClick={() => setShowForm(true)}>
              Create Group
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {!reactTableData.showGroupForm && !showForm ? (
            <GroupListing reactTableData={reactTableData} />
          ) : (!reactTableData.showGroupForm && showForm) ||
            (reactTableData.showGroupForm && !showForm) ? (
            <GroupCreationForm
              editMode={reactTableData.showGroupForm}
              setEditMode={reactTableData.setShowGroupForm}
              setShowForm={setShowForm}
              getRowDetails={
                reactTableData.showGroupForm ? reactTableData.rowDetails : {}
              }
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
      {/* {otaRowSelected && !showForm && <IndividualGroupListing />} */}
    </Container>
  );
};

export default GroupDashboard;

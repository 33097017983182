import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetTableHeader } from '@/utils/helpers';
import styled from 'styled-components';
import PencilIcon from 'mdi-react/PencilIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleOutlineIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleOutlineIcon';
import { colorTextAdditional } from '@/utils/palette';
import Path from '@/utils/routes/routePathNames';
import updateDepotNotification from '../../../../redux/actions/SystemSettingsActions/ManageNotifications/updateDepotNotification';
import getNotificationList from '../../../../redux/actions/SystemSettingsActions/ManageNotifications/getNotificationList';

const CreateTableData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const goToForm = (props) => {
    setTimeout(() => {
      history.push(Path.NOTIFICATION_FORM);
    }, 200);
    localStorage.setItem('notificationDetails', JSON.stringify(props.row.original));
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Company'}
            key={'Company'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'company_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depot_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Type'}
            key={'Alarm Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'distribution_type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Vehicle count'}
            key={'Vehicle count'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_counts',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'User count'}
            key={'User count'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'user_counts',
        disableGlobalFilter: true,
      },
      {
        Header: 'View',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          const [dataObject, setdataObject] = useState(props.row.original);
          const handleClick = () => {
            const temp = dataObject;
            const stateActive = !temp.is_active;
            delete temp.is_active;
            temp.is_active = stateActive;
            setdataObject(temp);
            dispatch(
              updateDepotNotification(
                temp.company_id,
                temp.depot_id,
                temp.is_active,
                temp.distribution_type
              )
            );
            dispatch(getNotificationList());
          };

          return (
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                width: '50%',
              }}
            >
              <StyledViewIcon
                onClick={() => goToForm(props)}
                style={{ cursor: 'pointer', width: '20px' }}
              />
              {dataObject.is_active ? (
                <CheckCircleIcon
                  color="green"
                  onClick={handleClick}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <CloseCircleIcon color="red" onClick={handleClick} style={{ cursor: 'pointer' }} />
              )}
            </span>
          );
        },
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort,
  };
  return reactTableData;
};

export default CreateTableData;

const StyledViewIcon = styled(PencilIcon)`
  cursor: pointer;
  color: ${colorTextAdditional};
`;

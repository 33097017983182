import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorAdditional, colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';
import { Button } from '@/shared/components/Button';
import { Col, Row } from 'react-bootstrap';

const UserListContainer = ({
  img,
  userName,
  companyName,
  userEmail,
  userDesignation,
  userRole,
  status,
  onClick,
  error,
  resendBtnClicked,
}) => (
  <UserListContainerWrap>
    {!error ? (
      <Row>
        <Col lg={8} md={8} xs={8} xl={8}>
          <div style={{ display: 'flex' }}>
            <UserListContainerImageWrap>
              <img src={img} alt="" />
            </UserListContainerImageWrap>
            <div
              style={{
                display: 'inline-block',
                width: '100%',
                padding: '0',
                margin: '0',
              }}
            >
              <div style={{ paddingBottom: '5px', paddingTop: '0' }}>
                <UserListContainerName>
                  {userName}
                  {', '}
                  {companyName}
                </UserListContainerName>
              </div>
              <div>
                <UserListContainerSub>
                  {userEmail}
                  {', '}
                  {userDesignation}
                  {', '}
                  {userRole}
                </UserListContainerSub>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          {status && (
            <Button
              variant="primary"
              onClick={onClick}
              style={{
                minWidth: '100%',
                padding: '5px',
                margin: '0',
                maxWidth: '100%',
                maxHeight: '40px',
                minHeight: '40px',
              }}
              disabled={resendBtnClicked}
            >
              {'Password expired'}
            </Button>
          )}
        </Col>
      </Row>
    ) : (
      <div>{error}</div>
    )}
  </UserListContainerWrap>
);

UserListContainer.propTypes = {
  img: PropTypes.string,
  userName: PropTypes.string,
  companyName: PropTypes.string,
  userEmail: PropTypes.string,
  userDesignation: PropTypes.string,
  status: PropTypes.bool,
  error: PropTypes.string,
  resendBtnClicked: PropTypes.bool,
};

export default UserListContainer;

// region STYLES

const UserListContainerWrap = styled.div`
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};

  &:last-child {
    border-bottom: none;
  }
`;

// const UserListContainerLink = styled.div`
//   margin: 5px;
//   position: absolute;
//   ${right}: 0;
//   padding: 5px 10px;
//   line-height: 16px;
//   border: 1px solid black;
//   cursor: pointer;
// `;

// const UserListContainerParagragh = styled.p``;

const UserListContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const UserListContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`;

const UserListContainerSub = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #999999;
`;

// const UserListContainerCount = styled.p`
//   color: ${colorAdditional};
//   line-height: 15px;
//   font-size: 11px;
//   margin: 0;

//   @media (max-width: 1100px) and (min-width: 990px) {
//     font-size: 10px;
//   }
// `;

// endregion

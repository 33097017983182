import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Alert } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import FormField from '@/shared/components/form/FormField';
import { vinValidate } from '../../../../utils/validate';

const SelectVehicleForm = ({ onSubmit }) => {
  const history = useHistory();

  const { loading, info, error } = useSelector((state) => state.getVinReducer);

  return (
    <Card>
      <CardBody>
        {info ? (
          <>
            <Alert variant="success" show={!!info.status}>
              {info.status}
            </Alert>
            <DotDotLoader loadingState={true} />
          </>
        ) : (
          ''
        )}
        <CardTitleWrap>
          <CardTitle>Vehicle Identifier</CardTitle>
          {loading && <DotDotLoader loadingState={loading} />}
        </CardTitleWrap>
        <Form onSubmit={onSubmit} validate={vinValidate}>
          {({ handleSubmit, pristine }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup style={{ paddingBottom: '100px', paddingTop: '20px' }}>
                <FormGroupLabel>VIN</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="vin"
                    type="text"
                    component={FormField}
                    placeholder="Please enter a VIN number"
                    required
                    maxLength={17}
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar
                style={{
                  width: '50%',
                  paddingRight: '10px',
                }}
              >
                <Button
                  variant="secondary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
              </FormButtonToolbar>
              <FormButtonToolbar
                style={{
                  width: '50%',
                  marginBottom: '10px',
                  paddingLeft: '10px',
                }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: '100%' }}
                  disabled={pristine}
                >
                  Next
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

SelectVehicleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SelectVehicleForm;

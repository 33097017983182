import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  REGISTER_SIM_NUMBER_MASTER_LOADING,
  REGISTER_SIM_NUMBER_MASTER_SUCCESS,
  REGISTER_SIM_NUMBER_MASTER_FAIL,
} from '@/shared/constants/SimMasterConstants/simMasterConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { gateway } from '@/shared/helpers/successMessages';

const registerSimMaster = (values) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_SIM_NUMBER_MASTER_LOADING,
    });

    const { sim_number, imei_number, country, country_code, operator } = values;

    const response = await axios.post(
      ApiURL.SIM_REGISTER_MASTER,
      {
        sim_number,
        imei_number,
        country,
        country_code,
        operator,
      },
      getConfig()
    );

    successMessage(gateway.register_sim_master);
    dispatch({
      type: REGISTER_SIM_NUMBER_MASTER_SUCCESS,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: REGISTER_SIM_NUMBER_MASTER_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: REGISTER_SIM_NUMBER_MASTER_FAIL,
        payload: error.response.data.data.message,
      });

      return error.response;
    }
  }
};

export default registerSimMaster;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Path from '@/utils/routes/routePathNames';
import { updateOtaRowReset } from '@/redux/actions/DepotActions/setInitialDepotFilterActions';
import KpiCard from '@/shared/components/KpiCard';
import CampaignCreation from './components/campaign-form';
import CreateTableData from './components/campaign-listing/CreateData';
import CampaignDeviceStatusListing from './components/campaign-listing/CampaignDeviceStatusListing';
import CreateDataDeviceStatus from './components/campaign-listing/CreateDataDeviceStatus';
import CampaignListing from './components/campaign-listing/CampaignListing';

const CampaignManagement = () => {
  const dispatch = useDispatch();
  const reactTableData = CreateTableData();
  const reactTableDataDeviceStatus = CreateDataDeviceStatus();
  const location = useLocation();
  const otaRowSelected = useSelector((state) => state.updateOtaRowReducer.info);

  useEffect(() => {
    return () => {
      dispatch(updateOtaRowReset());
    };
  }, []);

  return (
    <Container>
      {location.pathname === Path.CAMPAIGN_CREATION ? (
        <CampaignCreation />
      ) : location.pathname === Path.CAMPAIGN_LISTING &&
        (!otaRowSelected || !reactTableData.goToDetails) ? (
        <>
          {/* <Row>
            <Col md={12} xl={3} lg={6} xs={12}>
              <KpiCard
              // title={'In Progress'}
              // color={'#F5A608'}
              // gradient={'preventive-maintenance'}
              // count={info?.in_progress}
              // total={info?.total}
              // loading={loading}
              // error={error}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <KpiCard
              // title={'In Progress'}
              // color={'#F57A08'}
              // gradient={'red-maintenance'}
              // count={info?.in_progress}
              // total={info?.total}
              // loading={loading}
              // error={error}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <KpiCard
              // title={'In Progress'}
              // color={'#DC2323'}
              // gradient={'green-maintenance'}
              // count={info?.in_progress}
              // total={info?.total}
              // loading={loading}
              // error={error}
              />
            </Col>
            <Col md={12} xl={3} lg={6} xs={12}>
              <KpiCard
              // title={'In Progress'}
              // color={'#006B38'}
              // gradient={'preventive-maintenance'}
              // count={info?.in_progress}
              // total={info?.total}
              // loading={loading}
              // error={error}
              />
            </Col>
          </Row> */}
          <Row>
            <Col md={12} xl={12} lg={12} xs={12}>
              <CampaignListing reactTableData={reactTableData} />
            </Col>
          </Row>
        </>
      ) : location.pathname === Path.CAMPAIGN_LISTING &&
        reactTableData.goToDetails &&
        otaRowSelected ? (
        <CampaignDeviceStatusListing
          reactTableData={reactTableData}
          reactTableDataDeviceStatus={reactTableDataDeviceStatus}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default CampaignManagement;

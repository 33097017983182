import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { selectCompanyValidate } from '@/utils/validate.js';
import getRegisteredCompany from '../../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import getCompanyList from '../../../../redux/actions/CompanyActions/getCompanyListActions';

const CompanySelect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const sysAssetType = localStorage.getItem('systemAsset');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const loggedInCompanyId = userDetails.company_id;
  const [btnClicked, setBtnClicked] = useState(false);

  const getCompanyListReducerLoading = useSelector((state) => state.getCompanyListReducer.loading);
  const getCompanyListReducerInfo = useSelector((state) => state.getCompanyListReducer.info);
  const getRegisteredCompanyReducerInfo = useSelector(
    (state) => state.getRegisteredCompanyReducer.info
  );
  const getRegisteredCompanyReducerLoading = useSelector(
    (state) => state.getRegisteredCompanyReducer.loading
  );

  useEffect(() => {
    if (loggedInCompanyId) {
      dispatch(getCompanyList(loggedInCompanyId));
    }
    return () => {
      setBtnClicked(false);
    };
  }, [loggedInCompanyId]);

  const handleSubmit = (values) => {
    if (Object.keys(values).length > 0 && values.company.id) {
      dispatch(getRegisteredCompany(values.company.id));
      localStorage.setItem('companyId', values.company.id);
      setBtnClicked(true);
    }
  };

  if (btnClicked === true && getRegisteredCompanyReducerInfo !== '') {
    setTimeout(() => {
      if (sysAssetType && sysAssetType !== 'companies') {
        history.push(`/settings/company-settings/select/manage/${sysAssetType}`);
      } else {
        history.push('/settings/company-settings/select/manage');
      }
      setBtnClicked(false);
    }, 1000);
  }

  return (
    <Container>
      <Row sm={12} md={6} lg={6}>
        <Col>
          <h3 className="page-title">Edit Company</h3>
          <h6 style={{ paddingBottom: '20px' }}>Edit Company details</h6>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit} validate={selectCompanyValidate}>
                {({ handleSubmit }) => (
                  <FormContainer onSubmit={handleSubmit}>
                    <FormGroup>
                      <FormGroupLabel>Select Company</FormGroupLabel>
                      {getCompanyListReducerLoading || getRegisteredCompanyReducerLoading ? (
                        <DotDotLoader
                          loadingState={
                            getCompanyListReducerLoading || getRegisteredCompanyReducerLoading
                          }
                        />
                      ) : (
                        <FormGroupField>
                          <Field
                            name="company"
                            component={renderSelectField}
                            type="text"
                            options={
                              getCompanyListReducerInfo &&
                              Object.entries(getCompanyListReducerInfo.company_list).length !== 0
                                ? getCompanyListReducerInfo.company_list.map(
                                    ({ company_id, company_name, role }) => ({
                                      id: company_id,
                                      value: company_name,
                                      label: company_name + ', ' + role,
                                    })
                                  )
                                : []
                            }
                            placeholder="Select company"
                          />
                        </FormGroupField>
                      )}
                    </FormGroup>
                    <FormButtonToolbar
                      style={{
                        width: '50%',
                        paddingRight: '10px',
                        paddingTop: '20px',
                        margin: '0',
                      }}
                    >
                      <Button
                        variant="secondary"
                        type="button"
                        style={{ width: '100%', margin: '0' }}
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                    </FormButtonToolbar>
                    <FormButtonToolbar
                      style={{
                        width: '50%',
                        paddingLeft: '10px',
                        margin: '0',
                        paddingTop: '20px',
                      }}
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: '100%', margin: '0' }}
                      >
                        Next
                      </Button>
                    </FormButtonToolbar>
                  </FormContainer>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanySelect;

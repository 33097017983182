import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Wizard from './components/WizardForm';

const VehicleRegisteration = () => {
  const getVinReducer = useSelector((state) => state.getVinReducer.info);

  return (
    <Container>
      {getVinReducer && getVinReducer.status === 'success' ? (
        <>
          <Row className="pb-4">
            <Col md={6}>
              <h3 className="page-title">Edit vehicle</h3>
            </Col>
          </Row>

          <Wizard isDisabled={true} />
        </>
      ) : (
        <>
          <Row className="pb-4">
            <Col md={6}>
              <h3 className="page-title">Add vehicle</h3>
            </Col>
          </Row>
          <Wizard />
        </>
      )}
    </Container>
  );
};

export default VehicleRegisteration;

import {
  GET_DEPOT_HVAC_LOADING,
  GET_DEPOT_HVAC_SUCCESS,
  GET_DEPOT_HVAC_FAIL,
} from '@/shared/constants/DepotDashboardConstants/depotDashboardConstants.js';

const initialState = {
  loading: false,
  info: [],
  error: '',
};
const getDepotHvacByBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPOT_HVAC_LOADING:
      return { ...state, loading: true };
    case GET_DEPOT_HVAC_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_DEPOT_HVAC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getDepotHvacByBrandReducer;

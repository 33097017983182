import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import RegisteredVehicleDetailsImage from '../Hvac/components/RegisteredVehicleDetailsImage';
import GatewayRegister from './components/GatewayRegister';

const Gateway = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Gateway</h3>
          <h6 style={{ paddingBottom: '20px' }}>
            Add or Edit assets
          </h6>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} className="mb-2">
          <RegisteredVehicleDetailsImage hasLink={false}/>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <GatewayRegister />
        </Col>
      </Row>
    </Container>
  );
};

export default Gateway;

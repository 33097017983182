import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_DEPOT_LIST_LOADING,
  GET_DEPOT_LIST_SUCCESS,
  GET_DEPOT_LIST_FAIL,
} from '@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getDepotList = (company_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_LIST_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_DEPOT_LIST_NOTI + 'company_id/' + company_id + '/depots_list',
      getConfig()
    );

    const getDepots = data?.data?.depot_list?.map(({ id, name }) => ({
      id,
      value: name,
      label: name,
    }));

    dispatch({
      type: GET_DEPOT_LIST_SUCCESS,
      payload: getDepots,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getDepotList;

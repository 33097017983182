import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VinSearch from '../VinSearch';
import GlobalVehicleCount from './components/GlobalVehicleCount';
import DepotLocation from './components/DepotLocation';
import GlobalHealthStatusSummary from './components/GlobalHealthStatusSummary';
import CreateTableData from './components/CreateData';
import GlobalHealthDetail from './components/GlobalHealthDetail';

const GlobalDashboard = () => {
  const [localValuesUpdated, setLocalValuesUpdated] = useState(false);
  const reactTableData = CreateTableData();

  useEffect(() => {
    if (!localStorage.getItem('dashboardDetails')) {
      localStorage.setItem('dashboardDetails', JSON.stringify({ fleet: 0, depot: 0 }));
    }
  }, []);

  return (
    <Container>
      <Row
        className={'d-flex justify-content-end'}
        style={{ marginTop: '-40px', paddingBottom: '30px' }}
      >
        <VinSearch />
      </Row>
      <Row>
        <GlobalVehicleCount />
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={9} className="d-flex align-items-stretch">
          <DepotLocation changeComp={localValuesUpdated} setChangeComp={setLocalValuesUpdated} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} className="d-flex align-items-stretch">
          <GlobalHealthStatusSummary />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <GlobalHealthDetail reactTableData={reactTableData} />
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalDashboard;

import axios from 'axios';
import {
  UPDATE_DEPOT_LOADING,
  UPDATE_DEPOT_SUCCESS,
  UPDATE_DEPOT_FAIL,
  UPDATE_DEPOT_RESET,
} from '@/shared/constants/DepotConstants/updateDepotConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const updateDepot = (companyId, depotId, fleetId, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DEPOT_LOADING,
    });

    const { name, address, city, country, lat, long } = data;

    const response = await axios.post(
      ApiURL.UPDATE_DEPOT + companyId + '/fleet/' + fleetId + '/depot/' + depotId + '/update',
      {
        name: name,
        address: address,
        city: city,
        country: country,
        lat: lat,
        long: long,
      },
      getConfig()
    );

    successMessage(system_settings.update_depot);

    dispatch({
      type: UPDATE_DEPOT_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPDATE_DEPOT_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPDATE_DEPOT_FAIL,
        payload: error.response,
      });
    }
  }
};
export const updateDepotReset = () => (dispatch) => {
  dispatch({
    type: UPDATE_DEPOT_RESET,
  });
};

export default updateDepot;

import axios from "axios";
import { ApiURL } from "../../../config/GlobalUrl";
import {
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_RESET,
} from "../../../shared/constants/CompanyConstants/getRegisteredCompanyConstants";
import { getConfig } from "../../../shared/helpers/apiConfig.js";

const getRegisteredCompany = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_REGISTERED_COMPANY + companyId,
      getConfig()
    );

    dispatch({
      type: GET_COMPANY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPANY_FAIL,
        payload: "network error",
      });
    } else {
      dispatch({
        type: GET_COMPANY_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export const resetRegisteredCompany = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANY_RESET,
  });
};

export default getRegisteredCompany;

import {
  GET_HEALTH_STATUS_SUMMARY_SUMMARY_LOADING,
  GET_HEALTH_STATUS_SUMMARY_SUCCESS,
  GET_HEALTH_STATUS_SUMMARY_FAIL,
} from '@/shared/constants/DepotDashboardConstants/depotDashboardConstants';

const initialState = {
  loading: false,
  info: {},
  error: '',
};
const getHealthStatusSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEALTH_STATUS_SUMMARY_SUMMARY_LOADING:
      return { ...state, loading: true };
    case GET_HEALTH_STATUS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_HEALTH_STATUS_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getHealthStatusSummaryReducer;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import { Field, Form } from 'react-final-form';
import getSupplierComps from '../../../../redux/actions/getSuppliersAction';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import data from './data';
import deleteHvacComp from '../../../../redux/actions/deleteHvacCompActions';
import editHvacComp from '../../../../redux/actions/editHvacCompActions';
import { resetCompSerialNumber } from '../../../../redux/actions/getCompSerialNumberActions';
import { getCompSerialNumber } from '../../../../redux/actions/getCompSerialNumberActions';

const ComponentsEdit = ({
  serialNum,
  manId,
  manName,
  deviceName,
  componentId,
  setShowEditComp,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getSuppliersReducer = useSelector((state) => state.getSuppliersReducer.info);
  const getSuppliersReducerLoading = useSelector((state) => state.getSuppliersReducer.loading);
  const deleteHvacCompReducer = useSelector((state) => state.deleteHvacCompReducer);
  const editHvacCompReducer = useSelector((state) => state.editHvacCompReducer);
  const getCompSerialNumberReducer = useSelector((state) => state.getCompSerialNumberReducer);
  const getVehicleInfo = JSON.parse(localStorage.getItem('vehicleDetails'));
  const getCompSerialNumberReducerLoading = useSelector(
    (state) => state.getCompSerialNumberReducer.loading
  );
  const getVehicleEngineType = getVehicleInfo.engine_type;
  const hvacID = getVehicleInfo.hvac_id;
  const [dropdownValue, setDropdownValue] = useState({
    value: '',
    manufacturer_id: 0,
  });
  const [flip, setFlip] = useState(false);
  const [compDeleted, setCompDeleted] = useState(false);
  const [compEdited, setCompEdited] = useState(false);
  const [serialCheck, setSerialCheck] = useState(false);
  const [serialValue, setSerialValue] = useState();
  const [manufacturerId, setManufacturerId] = useState();

  const onSubmit = (values) => {
    if (values.select.id === 1) {
      dispatch(getCompSerialNumber(values.input));
      setSerialCheck(true);
      setSerialValue(values.input);
      setManufacturerId(values.select.id);
    }
    if (values.select.id !== 1) {
      dispatch(editHvacComp(hvacID, componentId, values.selectcomponents.id, ''));
    }
    setCompEdited(true);
  };
  const handleClick = () => {
    dispatch(deleteHvacComp(hvacID, componentId));
    setCompDeleted(true);
  };

  if (
    deleteHvacCompReducer.info !== '' &&
    compDeleted === true &&
    deleteHvacCompReducer.loading === false
  ) {
    setShowEditComp(false);
  }

  if (getCompSerialNumberReducer.info && serialCheck === true) {
    if (manufacturerId && manufacturerId === 1) {
      dispatch(editHvacComp(hvacID, componentId, manufacturerId, serialValue));
      dispatch(resetCompSerialNumber());
      setSerialCheck(false);
    }
  }
  if (
    editHvacCompReducer.info !== '' &&
    compEdited === true &&
    editHvacCompReducer.loading === false
  ) {
    setShowEditComp(false);
  }

  const handleChange = (values) => {
    setDropdownValue(values);
    setFlip(true);
    dispatch(getSupplierComps(getVehicleEngineType, values.value));
  };

  useEffect(() => {
    if (manId !== 1) {
      dispatch(getSupplierComps(getVehicleEngineType, manName));
    }
    return () => {
      dispatch(resetCompSerialNumber());
    };
  }, []);

  const defaultSelectValues = {
    value: manName,
    id: manId,
    label: manName,
  };

  const defaultDeviceValues = {
    value: deviceName,
    id: componentId,
    label: deviceName,
  };

  const validateComponentsEdit = (values) => {
    const errors = {};

    if (!values.select) {
      errors.select = 'Manufacterer shouldn’t be empty';
    }

    if (!values.selectcomponents) {
      errors.selectcomponents = 'Device shouldn’t be empty';
    }

    if (!values.input) {
      errors.input = 'Serial Number shouldn’t be empty';
    } else if (!/^[0-9]+$/g.test(values.input)) {
      errors.input = 'Invalid Serial Number';
    }

    if (values.select && values.selectcomponents && errors.input) {
      delete errors.input;
    }
    if (values.select && values.input && errors.selectcomponents) {
      delete errors.selectcomponents;
    }
    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validateComponentsEdit}>
      {({ handleSubmit, form }) => (
        <FormContainer onSubmit={handleSubmit}>
          {(deleteHvacCompReducer && deleteHvacCompReducer.loading === true) ||
          (editHvacCompReducer && editHvacCompReducer.loading === true) ||
          getCompSerialNumberReducerLoading === true ? (
            <FormGroup>
              <SimpleLoader />
            </FormGroup>
          ) : (
            ''
          )}
          <FormGroup style={{ marginBottom: '0' }}>
            <FormGroupLabel style={{ float: 'right' }}>
              <TrashCanOutlineIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
            </FormGroupLabel>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Manufacturer</FormGroupLabel>
            <FormGroupField>
              <Field
                name="select"
                component={renderSelectField}
                type="text"
                options={data.map(({ id, name }) => ({
                  id: id,
                  value: name,
                  label: name,
                }))}
                parse={(e) => {
                  handleChange({
                    value: e.value,
                    manufacturer_id: e.id,
                  });
                  form.reset();
                  return e;
                }}
                initialValue={defaultSelectValues}
              />
            </FormGroupField>
          </FormGroup>
          {(serialNum && dropdownValue.manufacturer_id === 0) ||
          (serialNum && dropdownValue.manufacturer_id === 1) ||
          dropdownValue.manufacturer_id === 1 ? (
            <FormGroup>
              <FormGroupLabel>Serial Number</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="input"
                  component={FormField}
                  type="text"
                  placeholder="Please enter a serial number"
                  defaultValue={flip === false ? serialNum : ''}
                />
              </FormGroupField>
            </FormGroup>
          ) : dropdownValue.manufacturer_id !== 0 || dropdownValue.manufacturer_id !== 1 ? (
            <FormGroup>
              <FormGroupLabel>Device</FormGroupLabel>
              <FormGroupField>
                {getSuppliersReducerLoading === true ? (
                  <SimpleLoader />
                ) : (
                  <Field
                    name="selectcomponents"
                    component={renderSelectField}
                    type="text"
                    placeholder="Please select a device"
                    options={
                      getSuppliersReducer
                        ? getSuppliersReducer.map(({ id, component, unit_type }) => ({
                            id: id,
                            value: unit_type,
                            label: unit_type + component,
                          }))
                        : []
                    }
                    initialValue={flip === false ? defaultDeviceValues : ''}
                  />
                )}
              </FormGroupField>
            </FormGroup>
          ) : (
            ''
          )}
          <FormButtonToolbar style={{ width: '100%', marginBottom: '0' }}>
            <Button
              variant="secondary"
              style={{ width: '100%' }}
              onClick={() => {
                setShowEditComp(false);
              }}
            >
              Cancel
            </Button>
          </FormButtonToolbar>
          <FormButtonToolbar style={{ width: '100%', marginTop: '0' }}>
            <Button variant="primary" type="submit" style={{ width: '100%' }}>
              Save
            </Button>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  );
};

export default ComponentsEdit;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMetaDataResetAction } from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getmetadataAction';
import Form from './Form';
import createCampaign from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/createCampaignAction';
import FormModal from '../styled-elements/FormModal';
const CampaignCreation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [moveToNextPage, setMoveToNextPage] = useState(false);

  const onSubmit = (values) => {
    if (Object.entries(values).length > 0) {
      dispatch(createCampaign(values, history));
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('campaignRowDetails');
      dispatch(getMetaDataResetAction());
    };
  }, []);

  const modalClose = () => {
    setShowModal((prev) => !prev);
    setMoveToNextPage(false);
  };

  const modalSubmit = () => {
    setMoveToNextPage(true);
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showModal ? (
        <FormModal
          title="Create Campaign"
          handleClose={modalClose}
          handleSubmit={modalSubmit}
          show={showModal}
          type={'warning'}
        >
          You have selected some gateways that are offline. You cannot select instant strategy with
          this. Are you sure you want to continue?
        </FormModal>
      ) : (
        ''
      )}
      <Form
        onSubmit={onSubmit}
        setShowModal={setShowModal}
        moveToNextPage={moveToNextPage}
        setMoveToNextPage={setMoveToNextPage}
      />
    </>
  );
};
export default CampaignCreation;

import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import Magnify from 'mdi-react/MagnifyIcon';
import { renderComponentField } from '@/shared/components/form/FormField';
import styled from 'styled-components';
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorText,
  colorSelectHoverAccentHover,
} from '@/utils/palette';
import { borderRight } from '@/utils/directions';

export const AsyncSelectField = React.forwardRef(
  ({ onChange, value, name, placeholder, options, ...other }, ref) => {
    const handleChange = (selectedOption) => {
      onChange(selectedOption);
    };

    // Style for the dropdown
    const customStyles = {
      control: (styles) => ({
        ...styles,
        cursor: 'text',
        flexDirection: 'row-reverse',
      }),
      input: (styles) => ({
        ...styles,
        textTransform: 'lowercase',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        paddingLeft: '5px',
        paddingRight: '5px',
      }),

      placeholder: (base) => {
        return {
          ...base,
          color: `${colorText}`,
        };
      },
      valueContainer: (styles) => ({
        ...styles,
        padding: '0',
      }),
      clearIndicator: (base) => ({
        ...base,
        position: 'absolute',
        right: 0,
      }),
      loadingIndicator: (provided) => {
        return {
          ...provided,
          color: `${colorText}`,
          position: 'absolute',
          right: 0,
        };
      },
    };

    const DropdownIndicator = () => {
      return <Magnify style={{ height: '18px', width: '28px', color: `${colorText}` }} />;
    };

    // Custom SingleValue component
    const CustomSingleValue = ({ children, ...props }) => {
      return (
        <components.SingleValue {...props}>
          {props.data.newData.vin}
          <span style={{ display: 'block', fontSize: '10px' }}>
            <>Bus No: {props.data.newData.bus_num}, </>
            <>Plate No: {props.data.newData.plate_num}, </>
            <>Cpu Id: {props.data.newData.cpu_id}</>
          </span>
        </components.SingleValue>
      );
    };

    return (
      <StyledSelect
        name={name}
        value={value}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          SingleValue: CustomSingleValue,
        }}
        ref={ref}
        {...other}
      />
    );
  }
);

AsyncSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    }),
  ]).isRequired,
};

AsyncSelectField.defaultProps = {
  placeholder: '',
  options: [],
};

export default renderComponentField(AsyncSelectField);

// region STYLES

const StyledSelect = styled(AsyncSelect)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  color: ${colorText};

  .react-select__control {
    height: 32px;
    // border-radius: 0 !important;
    border-radius: 5px;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }
  .react-select__option {
    &.react-select__option--is-focused,
    &:hover {
      background-color: ${colorSelectHoverAccentHover};
      color: #000000 !important;
    }
  }

  .react-select__input {
    height: auto;
    color: ${colorText};
    text-align: center;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

// endregion

import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  GET_MAP_FILTERS_LOADING,
  GET_MAP_FILTERS_SUCCESS,
  GET_MAP_FILTERS_FAIL,
} from '../../../shared/constants/GlobalDashboardConstants/getMapFiltersConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getMapFilters = (operatorId, spId, fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAP_FILTERS_LOADING,
    });

    const params = {
      pto_id: operatorId ? operatorId : null,
      service_provider_id: spId ? spId : null,
      fleet_id: fleetId ? fleetId : null,
      // depot_id: depotId ? depotId : null, //Remove Depot id to make sure no extra api call
    };

    const response = await axios.get(ApiURL.GET_MAP_FILTERS, { params }, getConfig());

    dispatch({
      type: GET_MAP_FILTERS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_MAP_FILTERS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_MAP_FILTERS_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getMapFilters;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  StyledPreTag,
} from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import { uploadPackageFileCheckReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/uploadPackageFileCheckAction';
import PackageFileUpload from '../../../SharedElements/PackageFileUpload';

const EcontrolPackageCreationForm = ({ form, disableFields, editMode, initialValues, values }) => {
  const dispatch = useDispatch();

  const getAllRegisteredFields = form
    .getRegisteredFields()
    .filter((item) => !item.includes('company'))
    .filter((item) => !item.includes('fleet'))
    .filter((item) => !item.includes('depot'))
    .filter((item) => !item.includes('group'));

  const uploadPackageFileCheckReducer = useSelector(
    (state) => state.uploadPackageFileCheckReducer.info
  );
  const uploadPackageFileCheckLoading = useSelector(
    (state) => state.uploadPackageFileCheckReducer.loading
  );
  const uploadPackageFileCheckError = useSelector(
    (state) => state.uploadPackageFileCheckReducer.error
  );

  useEffect(() => {
    return () => {
      getAllRegisteredFields.map((item) => form.change(item));
      dispatch(uploadPackageFileCheckReset());
    };
  }, []);

  return (
    <>
      {/* Might add this later. Had to remove it because unit name is in metadat */}
      {/* <FormGroup>
        <FormGroupLabel>Unit Name</FormGroupLabel>
        <FormGroupField>
          <Field
            name="unit_name"
            component={renderSelectField}
            options={[
              { id: 'Master', value: 'Master', label: 'Master' },
              { id: 'HMI', value: 'HMI', label: 'HMI' },
              { id: 'Slave', value: 'Slave', label: 'Slave' },
            ]}
            placeholder="Select type"
          />
        </FormGroupField>
      </FormGroup> */}
      <FormGroup>
        <FormGroupLabel>Upload software file</FormGroupLabel>
        <FormGroupField>
          <Field
            name="file"
            component={PackageFileUpload}
            placeholder="Please select a software file"
            formValues={values}
            disabled={!values.company || !values.fleet || !values.depot || !values.group}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Software Version Number</FormGroupLabel>
        <FormGroupField>
          <Field
            name="software-version"
            component={FormField}
            type="text"
            placeholder="Software version number"
            initialValue={
              uploadPackageFileCheckReducer && !uploadPackageFileCheckError
                ? uploadPackageFileCheckReducer.message['software_version']
                : undefined
            }
            disabled={true}
          />
        </FormGroupField>
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>Metadata</FormGroupLabel>
        <FormGroupField>
          <StyledPreTag>
            {uploadPackageFileCheckReducer?.message?.metadata &&
              !uploadPackageFileCheckError &&
              JSON.stringify(uploadPackageFileCheckReducer.message.metadata, null, 2)}
            {!uploadPackageFileCheckReducer && 'Metadata'}
          </StyledPreTag>
        </FormGroupField>
      </FormGroup>
    </>
  );
};

export default EcontrolPackageCreationForm;

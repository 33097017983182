import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GROUP_DETAILS_LOADING,
  GET_GROUP_DETAILS_SUCCESS,
  GET_GROUP_DETAILS_FAIL,
  GET_GROUP_DETAILS_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getGroupDetails = (groupName, depotId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GROUP_DETAILS_LOADING,
    });

    const body = {
      name: groupName,
      depot_id: depotId,
    };
    const response = await axios.post(ApiURL.GET_GROUP_DETAILS, body, getConfig());

    dispatch({
      type: GET_GROUP_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GROUP_DETAILS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GROUP_DETAILS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGroupDetailsReset = () => async (dispatch) => {
  dispatch({
    type: GET_GROUP_DETAILS_RESET,
  });
};

export default getGroupDetails;

const Path = {
  HOME: '/',
  LOGIN: '/login',

  //Global Dashboard
  GLOBAL_DASH: '/global',

  //Health Status Dashboard
  HEALTH_DASH: '/healthstatus',

  //Manage Assets Path Names
  ASSET_DASHBOARD: '/app/hvac_dashboard',

  //System Settings
  NOTIFICATION: '/notifications',
  NOTIFICATION_FORM: '/notifications/create',

  //OTA Path Names
  PACKAGE_CREATION: '/ota/package/creation',
  PACKAGE_LISTING: '/ota/package/listing',
  CAMPAIGN_CREATION: '/ota/campaign/creation',
  CAMPAIGN_LISTING: '/ota/campaign/listing',
  OTA_DASHBOARD: '/ota/dashboard',
  GROUP_CREATION: '/ota/group/creation',
  GROUP_LISTING: '/ota/group/listing',
};

export default Path;

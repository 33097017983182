import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_USER_PROFILE_LOADING,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
} from '@/shared/constants/UserProfileConstants/userProfileConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getUserProfile = () => async (dispatch) => {
  const { user_id } = JSON.parse(localStorage.getItem('user'));

  try {
    dispatch({
      type: GET_USER_PROFILE_LOADING,
    });

    const { data } = await axios.get(ApiURL.GET_USER_PROFILE + `/${user_id}/profile`, getConfig());

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: data?.data?.profile,
    });
    localStorage.setItem('userProfile', JSON.stringify(data?.data?.profile));
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
        payload: error,
      });
    }
  }
};

export default getUserProfile;

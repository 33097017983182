import {
  REGISTER_CPU_NUMBER_LOADING,
  REGISTER_CPU_NUMBER_SUCCESS,
  REGISTER_CPU_NUMBER_FAIL,
} from '@/shared/constants/GatewayMasterConstants/gatewayMasterConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const registerCpuNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_CPU_NUMBER_LOADING:
      return { ...state, loading: true };
    case REGISTER_CPU_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case REGISTER_CPU_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default registerCpuNumberReducer;

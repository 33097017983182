import axios from 'axios';
import {
  CLOSE_VEHICLE_ALARM_LOADING,
  CLOSE_VEHICLE_ALARM_SUCCESS,
  CLOSE_VEHICLE_ALARM_FAIL,
  CLOSE_VEHICLE_ALARM_RESET,
} from '@/shared/constants/VehicleDashboardConstants/closeVehicleAlarmConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const closeVehicleAlarm = (vin, alarmId, closeComment) => async (dispatch) => {
  try {
    dispatch({
      type: CLOSE_VEHICLE_ALARM_LOADING,
    });

    const response = await axios.post(
      ApiURL.CLOSE_VEHICLE_ALARM + vin + '/alarms/' + alarmId + '/close',
      {
        status: 'close',
        comment: closeComment,
      },
      getConfig()
    );
    successMessage('Alarm closed successfully!');

    dispatch({
      type: CLOSE_VEHICLE_ALARM_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CLOSE_VEHICLE_ALARM_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: CLOSE_VEHICLE_ALARM_FAIL,
        payload: error.response,
      });
    }
  }
};

export const closeVehicleAlarmReset = () => async (dispatch) => {
  dispatch({
    type: CLOSE_VEHICLE_ALARM_RESET,
  });
};

export default closeVehicleAlarm;

import axios from 'axios';
import {
  GET_DIAGNOSTICS_FILE_LOADING,
  GET_DIAGNOSTICS_FILE_SUCCESS,
  GET_DIAGNOSTICS_FILE_FAIL,
  GET_DIAGNOSTICS_FILE_RESET,
} from '../../../shared/constants/DiagnosticsConstants/diagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { diagnostics } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const getDiagnosticsFile = (vin, startTime, endTime, signals) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DIAGNOSTICS_FILE_LOADING,
    });

    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `${vin}_${startTime}_${endTime}`;

    const response = await axios.post(
      ApiURL.GET_DIAGNOSTICS_FILE,
      {
        start_time: startTime,
        end_time: endTime,
        metadata: {
          filter: {
            signal_ids: signals,
          },
        },
        vin: vin,
      },
      getConfig()
    );

    link.href = URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
    link.click();

    successMessage(diagnostics.get_csv_request);

    dispatch({
      type: GET_DIAGNOSTICS_FILE_SUCCESS,
      payload: [],
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: GET_DIAGNOSTICS_FILE_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: GET_DIAGNOSTICS_FILE_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getDiagnosticsFileReset = () => (dispatch) => {
  dispatch({
    type: GET_DIAGNOSTICS_FILE_RESET,
  });
};

export default getDiagnosticsFile;

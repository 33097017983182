import React, { useState, useMemo } from 'react';
import EditIcon from 'mdi-react/EditIcon';
import { GetTableHeader } from '@/utils/helpers';

const CreateTableData = () => {
  const [sort, setSort] = useState();
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [rowDetails, setRowDetails] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const editGroup = (rowData) => {
    setShowGroupForm(true);
    setRowDetails(rowData.row.original);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          return <EditIcon style={{ cursor: 'pointer' }} onClick={() => editGroup(props)} />;
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Device Type'}
            key={'Device Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Group Name'}
            key={'Group Name'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'label',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Group Type'}
            key={'Group Type'}
            onClick={() => sortData(props)}
          />
        ),
        Cell: (props) => {
          const checks = props.row.original.checks;
          if (checks !== 'open') {
            return 'Model specific';
          }
          return 'Custom';
        },
        accessor: 'checks',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Operator'}
            key={'Operator'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'operator_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Fleet'}
            key={'Fleet'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'fleet_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depot_name',
        disableGlobalFilter: true,
      },
      //SHOW DEVICE COUNT ACCORDING TO DEVICE TYPE
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Device Count'}
            key={'Device Count'}
            onClick={() => sortData(props)}
          />
        ),
        Cell: (props) => {
          const deviceType = props.row.original.type;
          if (deviceType === 'econtrol') {
            return props.row.original.econtrol_count;
          }
          if (deviceType === 'gateway') {
            return props.row.original.gateway_count;
          }
        },
        accessor: 'device_count',
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
    setShowGroupForm: setShowGroupForm,
    showGroupForm: showGroupForm,
    rowDetails: rowDetails,
  };
  return reactTableData;
};

export default CreateTableData;

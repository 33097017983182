import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  GET_VEHICLES_WITH_GATEWAY_LOADING,
  GET_VEHICLES_WITH_GATEWAY_SUCCESS,
  GET_TOTAL_VEHICLES_WITH_GATEWAY_SUCCESS,
  GET_VEHICLES_WITH_GATEWAY_FAIL,
} from '../../../shared/constants/DepotDashboardConstants/getVehiclesWithGatewayConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getVehiclesWithGateway = (companyId, fleetId, depotId, tag) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VEHICLES_WITH_GATEWAY_LOADING,
    });

    const params = {
      tag_id: tag ? tag : null,
    };

    const response = await axios.get(
      ApiURL.GET_VEHICLES_WITH_GATEWAY +
        companyId +
        '/fleets/' +
        fleetId +
        '/depots/' +
        depotId +
        '/counts/gateways',
      { params },
      getConfig()
    );

    const totalPieArrayData = response.data.data.counts
      .filter((newItem) => newItem['total_vehicles'])
      .map((item) => ({
        total_pie_value: Object.values(item)[0],
      }));

    const pieData = response.data.data.counts
      .filter((newItem) => !newItem['total_vehicles'])
      .map((item) => ({
        pie_value: Object.values(item)[0],
      }));

    const obj = [
      {
        values:
          Object.entries(totalPieArrayData).length > 0
            ? totalPieArrayData[0].total_pie_value - pieData[0].pie_value
            : 0,
      },
      {
        values: Object.entries(totalPieArrayData).length > 0 ? pieData[0].pie_value : 0,
      },
    ];

    const pieArrayData = obj.map((item) => ({
      pie_value: Object.values(item)[0],
    }));

    dispatch({
      type: GET_VEHICLES_WITH_GATEWAY_SUCCESS,
      payload: { pieArrayData },
    });

    dispatch({
      type: GET_TOTAL_VEHICLES_WITH_GATEWAY_SUCCESS,
      payload: response.data.data.counts,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_VEHICLES_WITH_GATEWAY_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_VEHICLES_WITH_GATEWAY_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getVehiclesWithGateway;

import React from 'react';
import { useHistory } from 'react-router';
import MaintenanceTypes from './components/MaintenanceTypes';

const MaintenanceSetup = () => {
  const history = useHistory();
  return <MaintenanceTypes />;
};

export default MaintenanceSetup;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from '@/shared/components/Card';
import { Col } from 'react-bootstrap';
import ComponentsContainer from '../../HvacComponents/components/ComponentsContainer';
import ComponentsAdd from './ComponentsAdd';
import getCompList from '../../../../redux/actions/getCompListActions';
import getHvacCompCount from '../../../../redux/actions/getHvacCompCountActions';
import SimpleLoader from '../../../../shared/components/SimpleLoader';

const Ava3 = `${process.env.PUBLIC_URL}/img/14.png`;

const ComponentsListWithType = ({ componentType }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getVehicleInfo = JSON.parse(
      localStorage.getItem('vehicleDetails')
    );
    const getVehicleEngineType = getVehicleInfo.engine_type;
    const getVehicleHvacId = getVehicleInfo.hvac_id;
    if (getVehicleInfo) {
      dispatch(getCompList(getVehicleEngineType));
      dispatch(getHvacCompCount(getVehicleHvacId));
    }
  }, []);

  const getCompListReducer = useSelector(
    state => state.getCompListReducer.filtered_info
  );

  const loading = useSelector(
    state => state.getCompListReducer.loading
  );

  const getHvacCountReducer = useSelector(
    state => state.getHvacCountReducer.filtered
  );

  const [addComps, setAddComps] = useState(false);
  const [compName, setCompName] = useState();
  const [compId, setCompId] = useState();
  const [showComp, setShowComp] = useState(false);

  const handleClick = (componentName, componentId) => {
    const { name } = getCompListReducer.find(
      type => type.name === componentName
    );
    const { id } = getCompListReducer.find(
      type => type.id === componentId
    );
    setCompName(name);
    setCompId(id);
    setAddComps(true);
    setShowComp(true);
  };

  return (
    <>
      {addComps === true && showComp === true ? (
        <ComponentsAdd
          componentName={compName}
          componentId={compId}
          showComponent={showComp}
          setShowComponent={setShowComp}
        />
      ) : (
        <Col xs={12} sm={12} md={6}>
          <Card height="auto">
            <CardBody>
              <CardTitleWrap>
                <CardTitle>{componentType} Type</CardTitle>
              </CardTitleWrap>
              {!getCompListReducer ||
              getCompListReducer === undefined ||
              !getHvacCountReducer ||
              getHvacCountReducer === undefined ||
              loading === true ? (
                <SimpleLoader />
              ) : (
                getCompListReducer.map(comps =>
                  comps.type === componentType ? (
                    <div
                      onClick={() =>
                        handleClick(comps.name, comps.id)
                      }
                      id={comps.name}
                    >
                      <ComponentsContainer
                        img={Ava3}
                        assettype={comps.name}
                        count={
                          !getHvacCountReducer[comps.name]
                            ? '0 units'
                            : getHvacCountReducer[comps.name] +
                              ' units'
                        }
                      />
                    </div>
                  ) : (
                    ''
                  )
                )
              )}
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ComponentsListWithType;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  CREATE_PACKAGE_LOADING,
  CREATE_PACKAGE_SUCCESS,
  CREATE_PACKAGE_FAIL,
  CREATE_PACKAGE_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { errorFunction, successMessage } from '@/shared/helpers/alertMessages';
import { package_creation } from '@/shared/helpers/successMessages';
import Path from '@/utils/routes/routePathNames';

const createPackage = (formValues, history) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PACKAGE_LOADING,
    });

    const formData = new FormData();

    formData.append('company_id', formValues.company.id);
    formData.append('fleet_id', formValues.fleet.id);
    formData.append('depot_id', formValues.depot.id);
    formData.append('group_name', formValues.group.value);
    // formData.append('unit_name', formValues.unit_name.id);
    formData.append('software_version', formValues['software-version']);
    formData.append('file', formValues.file.file);

    const response = await axios.post(
      ApiURL.CREATE_PACKAGE,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
      getConfig()
    );
    successMessage(package_creation.upload_package);
    history.push(Path.PACKAGE_LISTING);
    dispatch({
      type: CREATE_PACKAGE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CREATE_PACKAGE_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: CREATE_PACKAGE_FAIL,
        payload: error.response,
      });
    }
  }
};

export const createPackageReset = () => (dispatch) => {
  dispatch({
    type: CREATE_PACKAGE_RESET,
  });
};

export default createPackage;

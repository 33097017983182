import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import {
  WizardFormWrap,
  StepContainer,
  StepWrapper,
  StepStyle,
  MainContainer,
} from '../styled-elements/FormStyles';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const WizardForm = ({ onSubmit, setShowModal, setMoveToNextPage, moveToNextPage }) => {
  const initFormOne = JSON.parse(localStorage.getItem('campaignRowDetails'));
  const [page, setPage] = useState(1);
  const [data, setData] = useState(initFormOne ? initFormOne : {});
  const [prefilledFormOne, setPrefilledFormOne] = useState(initFormOne ? true : false);

  const nextPage = (newData) => {
    if (page === 1) {
      if (data['package-list'] || newData['hvac-list']) {
        delete data['package-list'];
        delete newData['package-list'];
      }
      if (data['hvac-list'] || newData['hvac-list']) {
        delete data['hvac-list'];
        delete newData['hvac-list'];
      }
      delete data.campaign_name;
      delete newData.campaign_name;
      delete data.deployment_type;
      delete newData.deployment_type;
      setPage(page + 1);
    }
    if (page === 2) {
      if (
        newData['hvac-list'].filter((device) => device.connectivityStatus !== 'online').length > 0
      ) {
        setShowModal(true);
      }
      if (
        newData['hvac-list'].filter((device) => device.connectivityStatus !== 'online').length === 0
      ) {
        setPage(page + 1);
      }
    }
    setData((pre) => ({ ...pre, ...newData }));
  };

  const previousPage = () => {
    setData((pre) => ({ ...pre }));
    setPage(page - 1);
  };

  const submitHandler = (newData) => {
    const formOneTwoData = data;
    setData((pre) => ({ ...pre, ...newData }));
    onSubmit({ ...formOneTwoData, ...newData });
  };

  useEffect(() => {
    if (page === 2) {
      setPage(page + 1);
    }
    return () => {
      setMoveToNextPage(false);
    };
  }, [moveToNextPage]);

  const steps = [
    {
      label: 'Step',
      step: 1,
    },
    {
      label: 'Step',
      step: 2,
    },
    {
      label: 'Step',
      step: 3,
    },
  ];

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (page - 1)}%`;

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Campaign Management</CardTitle>
          <CardSubhead>Create a campaign</CardSubhead>
        </CardTitleWrap>
        <MainContainer>
          <StepContainer width={width}>
            {steps.map(({ step }) => (
              <StepWrapper key={step} active={page === step}>
                <StepStyle active={page === step} page={page} step={step}>
                  {page > step ? (
                    <p>
                      <CheckIcon />
                    </p>
                  ) : (
                    <p>{step}</p>
                  )}
                </StepStyle>
              </StepWrapper>
            ))}
          </StepContainer>
        </MainContainer>
        <WizardFormWrap>
          {page === 1 && (
            <StepOne
              onSubmit={nextPage}
              defaultValues={data}
              initialValues={data}
              prefilledFormOne={prefilledFormOne}
            />
          )}
          {page === 2 && (
            <StepTwo
              previousPage={previousPage}
              onSubmit={nextPage}
              defaultValues={data} //might not need it
              initialValues={data}
              prefilledFormOne={prefilledFormOne}
            />
          )}
          {page === 3 && (
            <StepThree
              previousPage={previousPage}
              onSubmit={submitHandler}
              defaultValues={data} //might not need it
              initialValues={data}
              prefilledFormOne={prefilledFormOne}
            />
          )}
        </WizardFormWrap>
      </CardBody>
    </Card>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;

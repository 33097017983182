import React from 'react';
import ComponentList from './components/ComponentsList';

const HvacComponnents = () => {
  return (
    <>
      <ComponentList />
    </>
  );
};

export default HvacComponnents;

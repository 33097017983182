import axios from 'axios';
import {
  REGISTER_CPU_NUMBER_LOADING,
  REGISTER_CPU_NUMBER_SUCCESS,
  REGISTER_CPU_NUMBER_FAIL,
} from '@/shared/constants/GatewayMasterConstants/gatewayMasterConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { gateway } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const registerCpuNumber = (values) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_CPU_NUMBER_LOADING,
    });
    const { serial_number, cpu_number, manufacturer } = values;

    const response = await axios.post(
      ApiURL.CPU_REGISTER,
      {
        serial_number,
        cpu_number,
        manufacturer: manufacturer.value,
      },
      getConfig()
    );
    successMessage(gateway.register_cpu_number);
    dispatch({
      type: REGISTER_CPU_NUMBER_SUCCESS,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: REGISTER_CPU_NUMBER_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: REGISTER_CPU_NUMBER_FAIL,
      });
      return error.response;
    }
  }
};

export default registerCpuNumber;

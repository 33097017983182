import React from 'react';
import { Col } from 'react-bootstrap';
import SearchField from './components/SearchField';

const VinSearch = ({ setChangeComp, diagnostics, setVin, isGateway, reset, setReset, setCpuId }) => {
  return (
    <>
      {!diagnostics ? (
        <Col md={6} lg={4} sm={12} xs={12}>
          <SearchField
            changeComp={setChangeComp}
            diagnostics={diagnostics}
            setVin={setVin}
            isGateway={isGateway}
          />
        </Col>
      ) : (
        <Col md={12} lg={12} sm={12} xs={12}>
          <SearchField
            changeComp={setChangeComp}
            diagnostics={diagnostics}
            setVin={setVin}
            isGateway={isGateway}
            reset={reset}
            setReset={setReset}
            setCpuId={setCpuId}
          />
        </Col>
      )}
    </>
  );
};

export default VinSearch;

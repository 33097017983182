import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { Field, Form } from 'react-final-form';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { gatewaySerialNumber } from '../../../../redux/actions/registerGatewayActions';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import GatewayRegistered from './GatewayRegistered';
import { gatewayRegisterationValidate } from '../../../../utils/validate';

const GatewayRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const vehicleDetailsFromLocal = JSON.parse(localStorage.getItem('vehicleDetails'));
  const registerGatewayReducer = useSelector((state) => state.registerGatewayReducer);

  const loading = useSelector((state) => state.registerGatewayReducer.loading);
  const [btnClicked, setBtnClicked] = useState(false);

  const onSubmit = (values) => {
    if (values && values.gateway && vehicleDetailsFromLocal.vin) {
      dispatch(gatewaySerialNumber(values.gateway, vehicleDetailsFromLocal.vin));
      setBtnClicked(true);
    }
  };

  if (registerGatewayReducer && registerGatewayReducer.info !== '' && btnClicked === true) {
    setTimeout(() => {
      history.push('/gateway/list');
      localStorage.setItem(
        'GatewayDetails',
        JSON.stringify(registerGatewayReducer.info.data.component.serial_number)
      );
    }, 2000);
    setBtnClicked(false);
  }

  return (
    <>
      {vehicleDetailsFromLocal.gateway_id !== null ? (
        <Row>
          <GatewayRegistered hideVehicleDetails={true} />
        </Row>
      ) : (
        <Col md={6} lg={6} sm={12}>
          <Card height="auto">
            <CardBody style={{ padding: '30px' }}>
              <Form onSubmit={onSubmit} validate={gatewayRegisterationValidate}>
                {({ handleSubmit }) => (
                  <FormContainer onSubmit={handleSubmit}>
                    {loading && <SimpleLoader />}
                    <FormGroup>
                      <FormGroupLabel>Gateway System Part Number</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="gateway"
                          component={FormField}
                          type="text"
                          placeholder="Please enter gateway serial number"
                          required
                          autoComplete="off"
                          maxLength={200}
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormButtonToolbar
                      style={{
                        width: '100%',
                        marginTop: '0',
                        paddingTop: '150px',
                      }}
                    >
                      <Button variant="primary" type="submit" style={{ width: '100%' }}>
                        Next
                      </Button>
                    </FormButtonToolbar>
                  </FormContainer>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default GatewayRegister;

import axios from 'axios';
import {
  SIM_LOADING,
  SIM_SUCCESS,
  SIM_FAIL,
  SIM_RESTORE_INITIAL_VALUES,
} from '@/shared/constants/simConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../config/GlobalUrl';

export const simNumber = (gatewayNumber, sim, vinNumber) => async (dispatch) => {
  try {
    dispatch({
      type: SIM_LOADING,
    });

    const response = await axios.post(
      ApiURL.SIM_REGISTER + vinNumber + '/gateway/update',
      {
        serial_num: gatewayNumber,
        sim_number: sim,
      },
      getConfig()
    );
    successMessage('Sim registered successfully!');

    dispatch({
      type: SIM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: SIM_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: SIM_FAIL,
        payload: error.response,
      });
    }
  }
};

export const resetValues = () => (dispatch) => {
  dispatch({
    type: SIM_RESTORE_INITIAL_VALUES,
  });
};

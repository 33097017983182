export const GET_ROLES_LOADING = 'GET_ROLES_LOADING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const GET_SP_LOADING = 'GET_SP_LOADING';
export const GET_SP_SUCCESS = 'GET_SP_SUCCESS';
export const GET_SP_FAIL = 'GET_SP_FAIL';

export const GET_FLEETS_LOADING = 'GET_FLEETS_LOADING';
export const GET_FLEETS_SUCCESS = 'GET_FLEETS_SUCCESS';
export const GET_FLEETS_FAIL = 'GET_FLEETS_FAIL';

export const GET_ALL_FLEETS_LOADING = 'GET_ALL_FLEETS_LOADING';
export const GET_ALL_FLEETS_SUCCESS = 'GET_ALL_FLEETS_SUCCESS';
export const GET_ALL_FLEETS_FAIL = 'GET_ALL_FLEETS_FAIL';

export const GET_OP_FLEETS_LOADING = 'GET_OP_FLEETS_LOADING';
export const GET_OP_FLEETS_SUCCESS = 'GET_OP_FLEETS_SUCCESS';
export const GET_OP_FLEETS_FAIL = 'GET_OP_FLEETS_FAIL';
export const GET_OP_FLEETS_RESET = 'GET_OP_FLEETS_RESET';

export const GET_DEPOT_LOADING = 'GET_DEPOT_LOADING';
export const GET_DEPOT_SUCCESS = 'GET_DEPOT_SUCCESS';
export const GET_DEPOT_FAIL = 'GET_DEPOT_FAIL';
export const GET_DEPOT_RESET = 'GET_DEPOT_RESET';

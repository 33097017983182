import axios from 'axios';
import {
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,
} from '@/shared/constants/UserConstants/updateUserConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const updateUser = (companyId, userId, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_LOADING,
    });

    const { username, email, contact, designation, role } = data;

    const response = await axios.post(
      ApiURL.UPDATE_USER + companyId + '/user_id/' + userId + '/user_update',
      {
        username: username,
        email: email,
        contact: contact,
        designation: designation,
        role_code: role.value,
      },
      getConfig()
    );

    successMessage(system_settings.update_user);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: error.response,
      });
    }
  }
};
export const updateUserReset = () => (dispatch) => {
  dispatch({
    type: UPDATE_USER_RESET,
  });
};

export default updateUser;

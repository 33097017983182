import React, { useState } from 'react';
import { components } from 'react-select';

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);

  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  const style = {
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'flex ',
    cursor: 'pointer',
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
      data={data}
    >
      <span style={{ marginRight: '12px' }}>
        <input
          type="checkbox"
          name={data.value}
          value={data.value}
          id={data.id}
          disabled={false}
          onChange={() => null}
          checked={isSelected}
        />
      </span>
      {children}
    </components.Option>
  );
};

export default InputOption;

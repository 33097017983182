import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import Tooltip from '@/shared/components/Tooltip.jsx';
import DotDotLoader from '@/shared/components/DotDotLoader';
import getGatewayDiagnosticsMonth, {
  getGatewayDiagnosticsMonthReset,
} from '@/redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsMonthActions';
import GatewayForm from './GatewayForm';
import { ApiURL } from '../../../../config/GlobalUrl';
import SimpleLoader from '@/shared/components/SimpleLoader';
import VinSearch from '../../VinSearch';

const CustomToolbar = ({ label, date, onNavigate, onView }) => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedCpuId, setSelectedCpuId] = useState();
  const [reset, setReset] = useState(false);
  const [vin, setVin] = useState('');

  const { loading } = useSelector((state) => state.getGatewayDiagnosticsListReducer);
  const gatewayMonthLoading = useSelector(
    (state) => state.getGatewayDiagnosticsMonthReducer.loading
  );

  useEffect(() => {
    const month = date.getMonth() + 1;
    const formattedMonth = String(month).padStart(2, '0');
    const year = date.getFullYear();
    const totalDays = moment(date, 'YYYY-MM').daysInMonth();
    const startDate = `${year}-${formattedMonth}-01`;
    const endDate = `${year}-${formattedMonth}-${totalDays}`;

    setSelectedDate(date);
    selectedCpuId &&
      dispatch(
        getGatewayDiagnosticsMonth(
          selectedCpuId ? selectedCpuId.split('-')[1] : '',
          startDate,
          endDate
        )
      );

    return () => {
      dispatch(getGatewayDiagnosticsMonthReset());
    };
  }, [date, selectedCpuId]);

  return (
    <>
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <Tooltip dir={'rtl'} text={'Previous Year'}>
            <button
              type="button"
              onClick={() => {
                const newDate = moment(selectedDate).subtract(1, 'year').toDate();
                onNavigate('DATE', newDate);
              }}
            >
              <ChevronDoubleLeftIcon style={{ height: '100%', width: '100%' }} />
            </button>
          </Tooltip>
          <Tooltip dir={'rtl'} text={'Previous Month'}>
            <button type="button" onClick={() => onNavigate('PREV')}>
              <span className="lnr lnr-chevron-left"></span>
            </button>
          </Tooltip>
          <Tooltip dir={'rtl'} text={'Current Month'}>
            <button
              type="button"
              onClick={() => {
                setSelectedDate(currentDate);
                onNavigate('DATE', currentDate);
              }}
            >
              <span className="lnr lnr-calendar-full"></span>
            </button>
          </Tooltip>
          <Tooltip dir={'rtl'} text={'Next Month'}>
            <button
              type="button"
              onClick={() => {
                onView('month');
                onNavigate('NEXT');
              }}
              disabled={selectedDate.getMonth() === currentDate.getMonth() ? true : false}
            >
              <span className="lnr lnr-chevron-right"></span>
            </button>
          </Tooltip>
          <Tooltip dir={'rtl'} text={'Next Year'}>
            <button
              type="button"
              onClick={() => {
                const newDate = moment(selectedDate).add(1, 'year').toDate();
                onNavigate('DATE', newDate);
              }}
              disabled={selectedDate.getFullYear() === currentDate.getFullYear() ? true : false}
            >
              <ChevronDoubleRightIcon
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            </button>
          </Tooltip>
        </span>
        <span className="rbc-toolbar-label">
          {label}
          {loading || gatewayMonthLoading ? (
            <DotDotLoader
              loadingState={loading || gatewayMonthLoading}
              style={{ padding: '10px 0 0 0' }}
            />
          ) : (
            ''
          )}
        </span>
        <span className="rbc-btn-group" style={{ width: '400px' }}>
          {/* <GatewayForm setSelectedCpuId={setSelectedCpuId} /> */}
          <VinSearch
            diagnostics={true}
            setVin={setVin}
            setCpuId={setSelectedCpuId}
            isGateway={true}
            reset={false}
            setReset={setReset}
          />
        </span>
      </div>
    </>
  );
};

export const CustomEventWrapper = ({ fileData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadfile = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    let file =
      ApiURL.DOWNLOAD_GATEWAY_DIAGNOSTICS_FILE_FOR_MONTH +
      `gateways/${fileData.cpuId}/file/download?file_key=${fileData.download_link}`;

    setIsLoading(true);
    axios
      .get(file, {
        headers: headers,
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // Create a downloadable link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileData.filename}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 302) {
          const redirectedUrl = error.response.headers.location;

          return axios.get(redirectedUrl, {
            responseType: 'blob',
            headers: headers,
          });
        } else {
          // console.error('Error downloading file:', error);
          throw error;
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        // console.error('Error handling redirect:', error);
      });
  };
  return (
    <Tooltip dir={'rtl'} text={fileData.filename}>
      <StyledWrapper>
        <StyledParagraph>{fileData.filename}</StyledParagraph>
        <FileDownloadIcon
          style={{
            cursor: 'pointer',
            color: 'darkblue',
            flexShrink: 0,
            opacity: `${isLoading ? '0.5' : '1'}`,
            pointerEvents: `${isLoading ? 'none' : 'auto'}`,
          }}
          onClick={downloadfile}
        />
      </StyledWrapper>
    </Tooltip>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #e8f3fc;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  margin: 10px 2px 2px 2px;
`;

const StyledParagraph = styled.p`
  text-align: center;
  overflow-y: hidden;
  max-height: 20px;
  max-width: 80%;
  color: darkblue;
  font-weight: bold;

  /* Custom scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: darkgray transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 139, 0.2);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export default CustomToolbar;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditIcon from 'mdi-react/EditIcon';
import { right, marginRight, left } from '@/utils/directions';

const GatewayListing = ({
  img,
  gatewayManufacturer,
  companyName,
  gatewaySerialNumber,
  sim,
  showEdit,
}) => (
  <GatewayList>
    <GatewayImage>
      <img src={img} alt="Gateway Image" />
    </GatewayImage>
    <div>
      <RegisteredDeviceManufacture>
        {gatewayManufacturer}
        {' | '}
        {companyName}
      </RegisteredDeviceManufacture>
      <RegisteredDeviceType>{gatewaySerialNumber}</RegisteredDeviceType>
      <DeviceSim>Sim Number: {sim}</DeviceSim>
    </div>
    <DeviceLink>{showEdit && <EditIcon />}</DeviceLink>
  </GatewayList>
);

GatewayListing.propTypes = {
  gatewayManufacturer: PropTypes.string,
  gatewaySerialNumber: PropTypes.string,
  companyName: PropTypes.string,
};

export default GatewayListing;

// region STYLES

const GatewayList = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  margin-bottom: 50px;
  text-align: ${left};
  cursor: pointer;
`;

const DeviceLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
  color: #1368b1;
`;

const RegisteredDeviceType = styled.p`
  // font-weight: 500;
  // line-height: 18px;
  // margin-bottom: 0;
  // margin-top: 3px;
  // display: inline;
  padding-bottom: 10px;
`;

const RegisteredDeviceManufacture = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  display: inline;
`;

const GatewayImage = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;
const DeviceSim = styled.p`
  // font-weight: 500;
  // line-height: 18px;
  // margin-bottom: 0;
  // margin-top: 3px;
  // display: inline;
`;

// endregion

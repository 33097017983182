import React, { useState, useMemo } from 'react';
import { GetTableHeader } from '../../../../utils/helpers';
import Popover from '@/shared/components/Popover';
import Badge from '@/shared/components/Badge';

const CreateTableData = () => {
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const StatusPopover = ({ dir, header, body, placement, trigger, status, bg }) => {
    return (
      <Popover dir={dir} header={header} body={body} placement={placement} trigger={trigger}>
        <Badge bg={bg}>{status}</Badge>
      </Popover>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Vehicle Status'}
            key={'Vehicle Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'color_code',
        disableGlobalFilter: true,
        Cell: (props) => {
          const bg = props.row.original.color_code;
          const vehicleStatus = props.row.original.vehicle_status;
          const onlineStatus = 'Vehicle is currently active and connected to the system';
          const sleepingStatus =
            'Vehicle has not been active and connected to the system for less then 24 hours';
          const offlineStatus =
            'Vehicle has not communicated with the system for more then 24 hours';

          return (
            <StatusPopover
              dir={'ltr'}
              header={''}
              body={
                vehicleStatus === 'ONLINE'
                  ? onlineStatus
                  : vehicleStatus === 'SLEEPING'
                  ? sleepingStatus
                  : vehicleStatus === 'OFFLINE'
                  ? offlineStatus
                  : ''
              }
              placement={'right'}
              trigger={['focus', 'hover']}
              status={vehicleStatus}
              bg={bg}
            />
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'VIN'}
            key={'VIN'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vin',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Bus Number'}
            key={'Bus Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'bus_num',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Fleet'}
            key={'Fleet'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'fleet',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depo',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Open Alarms'}
            key={'Open Alarms'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'open_alarm_count',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Engine Type'}
            key={'Open Alarms'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'engine_type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Manufacturer'}
            key={'Manufacturer'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'manufacturer',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Model Type'}
            key={'Model Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'model',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Body Number'}
            key={'Body Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'body_number',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Service Partner'}
            key={'Service Partner'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'service_provider',
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

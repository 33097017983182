import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_CAMPAIGN_DEVICE_STATUS_LISTING_LOADING,
  GET_CAMPAIGN_DEVICE_STATUS_LISTING_SUCCESS,
  GET_CAMPAIGN_DEVICE_STATUS_LISTING_FAIL,
  GET_CAMPAIGN_DEVICE_STATUS_LISTING_RESET,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { sortingFunc } from '@/utils/helpers.js';

const getCampaignDeviceStatusListing =
  (deviceId, pageNumber, numberofRecords, sortData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CAMPAIGN_DEVICE_STATUS_LISTING_LOADING,
      });

      const params = {
        p: pageNumber ? pageNumber : null,
        page_size: numberofRecords ? numberofRecords : null,
        sort: sortData ? sortingFunc(sortData) : null,
      };

      const response = await axios.get(
        ApiURL.GET_CAMPAIGN_DEVICE_STATUS_LISTING + deviceId + '/devices/status',
        { params: params },
        getConfig()
      );

      dispatch({
        type: GET_CAMPAIGN_DEVICE_STATUS_LISTING_SUCCESS,
        payload: {
          data: response.data.data,
          pagination: response.data.pagination,
        },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_CAMPAIGN_DEVICE_STATUS_LISTING_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_CAMPAIGN_DEVICE_STATUS_LISTING_FAIL,
          payload: error.response,
        });
      }
    }
  };

export const getCampaignDeviceStatusListingReset = () => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_DEVICE_STATUS_LISTING_RESET,
  });
};

export default getCampaignDeviceStatusListing;

import React, { useState } from 'react';
import { components } from 'react-select';
import CheckIcon from 'mdi-react/CheckIcon';
import styled from 'styled-components';
import { colorAccent, colorIcon, colorText, colorWhite } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';

const GroupInputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);

  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  const style = {
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'flex ',
    cursor: 'pointer',
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
      data={data}
      {...rest}
    >
      <span style={{ marginRight: '12px' }}>
        <input
          type="checkbox"
          name={data.value}
          value={data.value}
          id={data.value}
          checked={isSelected}
          onChange={() => null}
        />
        {/* <CheckboxWrap disabled={isDisabled} htmlFor={data.value}> */}
        {/* <CheckboxInput
        type="checkbox"
        id={data.value}
        name={data.value}
        onChange={() => null}
        checked={isSelected}
        disabled={isDisabled}
      />
      <CheckboxCustom>
        <CheckIcon />
      </CheckboxCustom>
      <CheckboxLabel>{children}</CheckboxLabel> */}
        {/* </CheckboxWrap> */}
      </span>
      {children}
    </components.Option>
  );
};

export default GroupInputOption;
// region STYLES

const CheckboxCustom = styled.span`
  // position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.color || colorIcon};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  background-color: ${(props) => props.color || 'transparent'};

  svg {
    transition: all 0.3s;
    opacity: 0;
    height: 16px;
    width: 16px;
    fill: ${(props) => (props.color ? colorWhite : colorAccent)};
  }
`;

const CheckboxLabel = styled.span`
  line-height: 18px;
  ${paddingLeft}: 28px;
  padding-top: 2px;
  color: ${colorText};
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
`;

const CheckboxInput = styled.input`
  display: none;

  &:checked + ${CheckboxCustom} {
    svg {
      opacity: 1;
    }
  }
`;

// endregion

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_ROLE_LIST_LOADING,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_LIST_FAIL,
} from '../../../shared/constants/RoleConstants/getRoleListConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getRoleList = (roleType) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROLE_LIST_LOADING,
    });

    const response = await axios.get(ApiURL.GET_ROLE_LIST + '?role_scope=' + roleType, getConfig());

    dispatch({
      type: GET_ROLE_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_ROLE_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_ROLE_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getRoleList;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  DOWNLOAD_PACKAGE_LOADING,
  DOWNLOAD_PACKAGE_SUCCESS,
  DOWNLOAD_PACKAGE_FAIL,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const downloadPackage = (packageId) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_PACKAGE_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.DOWNLOAD_PACKAGE + packageId + '/download',
      getConfig()
    );
    const download_url = data.data.download_url;

    const a = document.createElement('a');
    a.href = download_url;
    a.download = download_url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    dispatch({
      type: DOWNLOAD_PACKAGE_SUCCESS,
      payload: download_url,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: DOWNLOAD_PACKAGE_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: DOWNLOAD_PACKAGE_FAIL,
        payload: error.response,
      });
    }
  }
};

export default downloadPackage;

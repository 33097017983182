import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormButtonToolbar,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import renderSelectField from '@/shared/components/form/Select';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';
import DotDotLoader from '@/shared/components/DotDotLoader';
import sendCanInterval from '../../../../redux/actions/DiagnosticsActions/sendCanIntervalActions';
import VinSearch from '../../VinSearch';
import { validateFullDiagnosticForm } from '../../../../utils/validate';

const FullDiagnosticForm = () => {
  const dispatch = useDispatch();

  const [duration, setDuration] = useState('');
  const [minSelected, setminSelected] = useState('');
  const [secSelected, setSecSelected] = useState('');
  const [signalType, setSignalType] = useState('');
  const [vin, setVin] = useState('');
  const [reset, setReset] = useState(false);

  const sendCanIntervalReducerLoading = useSelector(
    (state) => state.sendCanIntervalReducer.loading
  );

  const sendCanIntervalReducerInfo = useSelector((state) => state.sendCanIntervalReducer.info);

  const onSubmit = () => {
    if (vin && duration && duration !== '00:00:00' && signalType) {
      dispatch(sendCanInterval(vin, duration, signalType));
    }
  };

  const handleSignalType = (e) => {
    if (e) {
      setSignalType(e.value);
    }
    if (!e) {
      setSignalType('');
    }
  };

  const handleChangeDuration = (values) => {
    if (values) {
      setDuration(values.format('mm:ss'));
      setminSelected(values.format('mm'));
      setSecSelected(values.format('ss'));
    }
  };

  let tempForm;

  const resetfunction = (form) => {
    tempForm = form;
  };

  useEffect(() => {
    return () => {
      tempForm.restart();
      setVin('');
      setDuration('');
      setminSelected('');
      setSecSelected('');
      setSignalType('');
      setReset(true);
    };
  }, [sendCanIntervalReducerInfo]);

  return (
    <Col md={12} lg={12} sm={12}>
      <Row>
        {sendCanIntervalReducerLoading && (
          <DotDotLoader loadingState={sendCanIntervalReducerLoading} />
        )}
        <FormGroup>
          <FormGroupLabel>VIN</FormGroupLabel>
          <FormGroupField>
            <VinSearch
              diagnostics={true}
              setVin={setVin}
              isGateway={true}
              reset={reset}
              setReset={setReset}
            />
          </FormGroupField>
        </FormGroup>
        <Form onSubmit={onSubmit} validate={validateFullDiagnosticForm}>
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <FormGroupLabel>Data Collection Duration</FormGroupLabel>
                <FormGroupField style={{ height: 'auto' }}>
                  <Field
                    name="time_duration"
                    component={renderTimePickerField}
                    placeholderText={'Duration'}
                    parse={(e) => {
                      handleChangeDuration(e);
                      return e;
                    }}
                    minuteSelected={minSelected}
                    secondSelected={secSelected}
                    value={moment()}
                    fullDiagnosticForm={true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Select CAN channel</FormGroupLabel>
                <FormGroupField style={{ height: 'auto' }}>
                  <Field
                    name="signal_type"
                    component={renderSelectField}
                    placeholder={'Select CAN channel'}
                    options={[
                      { id: 1, value: 'can0', label: 'CAN0' },
                      { id: 2, value: 'can1', label: 'CAN1' },
                    ]}
                    parse={(e) => {
                      handleSignalType(e);
                      return e;
                    }}
                    isClearable
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!(vin && duration && signalType)}
                  onClick={resetfunction(form)}
                >
                  Start Collecting
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </Row>
    </Col>
  );
};

export default FullDiagnosticForm;

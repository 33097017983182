import {
  CREATE_GATEWAY_GROUP_LOADING,
  CREATE_GATEWAY_GROUP_SUCCESS,
  CREATE_GATEWAY_GROUP_FAIL,
  CREATE_GATEWAY_GROUP_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const createGatewayGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GATEWAY_GROUP_LOADING:
      return { ...state, loading: true };
    case CREATE_GATEWAY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case CREATE_GATEWAY_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_GATEWAY_GROUP_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default createGatewayGroupReducer;

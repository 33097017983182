const BasicTableData = () => {
  const header = [
    { id: 1, title: 'Components' },
    { id: 2, title: 'Status' },
  ];

  const headerResponsive = [
    { id: 1, title: 'Components' },
    { id: 2, title: 'Status' },
  ];

  const basicTableData = {
    tableHeaderData: header,
    tableHeaderResponsiveData: headerResponsive,
  };
  return basicTableData;
};

export default BasicTableData;

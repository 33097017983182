import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const createNotification = (formValues, comp_id, depot, history) => async (dispatch) => {
  try {
    const body = {
      vin_list: formValues?.vehicle_list?.map((item) => item.id),
      notification_user_id_list: formValues.user_list.map((item) => item.id),
      distribution_type: formValues.alarm_type,
      notification_type: 'email',
    };

    const response = await axios.post(
      ApiURL.POST_NOTIFICATIONS + `${comp_id}/depots/${depot}/notifications/create`,
      body,
      getConfig()
    );
    successMessage(response.data.data.message);
    history.push('/notifications');
  } catch (error) {
    console.log('error', error);
  }
};

export default createNotification;

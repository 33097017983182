import axios from 'axios';
import moment from 'moment';
import {
  ADD_MAINTENANCE_LOADING,
  ADD_MAINTENANCE_SUCCESS,
  ADD_MAINTENANCE_FAIL,
} from '@/shared/constants/MaintenanceConstants/maintenanceMetaConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { maintenance } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const addMaintenanceMeta = (vin, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MAINTENANCE_LOADING,
    });

    const convertDate = (dateVal) => {
      return moment(dateVal).unix() / 86400;
    };

    const response = await axios.post(
      ApiURL.ADD_PREVENTIVE_MAINTENANCE + vin + '/metadata/create',
      {
        mode: data.mode.value,
        level_a:
          data.mode.value === 'time_period_days' ? convertDate(data['level_a']) : data['level_a'],
        level_b:
          data.mode.value === 'time_period_days' ? convertDate(data['level_b']) : data['level_b'],
        level_c:
          data.mode.value === 'time_period_days' ? convertDate(data['level_c']) : data['level_c'],
        level_d:
          data.mode.value === 'time_period_days' ? convertDate(data['level_d']) : data['level_d'],
        level_e:
          data.mode.value === 'time_period_days' ? convertDate(data['level_e']) : data['level_e'],
      },
      getConfig()
    );
    successMessage(maintenance.register_preventive_maintenance);
    dispatch({
      type: ADD_MAINTENANCE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: ADD_MAINTENANCE_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: ADD_MAINTENANCE_FAIL,
        payload: error.response,
      });
    }
  }
};

export default addMaintenanceMeta;

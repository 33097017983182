import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_BASE_FIRMWARE_VERSION_LOADING,
  GET_BASE_FIRMWARE_VERSION_SUCCESS,
  GET_BASE_FIRMWARE_VERSION_FAIL,
  GET_BASE_FIRMWARE_VERSION_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getBaseFirmwareVersion = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BASE_FIRMWARE_VERSION_LOADING,
    });

    const { data } = await axios.get(ApiURL.GET_BASE_FIRMWARE_VERSION, getConfig());
    const base_firmware_list = data.data.base_firmware_list;

    dispatch({
      type: GET_BASE_FIRMWARE_VERSION_SUCCESS,
      payload: base_firmware_list.map((item) => ({
        id: item.id,
        value: item.version,
        label: item.version,
      })),
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_BASE_FIRMWARE_VERSION_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_BASE_FIRMWARE_VERSION_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getBaseFirmwareVersionReset = () => (dispatch) => {
  dispatch({
    type: GET_BASE_FIRMWARE_VERSION_RESET,
  });
};

export default getBaseFirmwareVersion;

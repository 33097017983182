import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { colorText } from '@/utils/palette';
import { ToggleButtonField } from '@/shared/components/form/ToggleButton';
import { TopbarLinkTitle } from '../../Layout/components/topbar/BasicTopbarComponents';
import CreateTableData from './components/CreateData';
import GatewayDiagnosticsListing from './components/GatewayDiagnosticsListing';
import CalendarData from './CalendarData';

const GatewayDiagnostics = () => {
  const reactTableData = CreateTableData();

  const [cpuIdValue, setCpuIdValue] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isFiltered, setIsFiltered] = useState('');
  const [renderList, setRenderList] = useState(true);

  return (
    <Container>
      <Row className={'d-flex justify-content-end'} style={{ marginTop: '-30px' }}>
        <Col lg={2} className={'d-flex justify-content-end'} style={{ marginBottom: '15px' }}>
          <StyledToggleConatiner>
            <TopbarLinkTitle style={{ width: '80%' }}>List View</TopbarLinkTitle>
            <StyledToggle
              name={'calendar'}
              id={'calendar'}
              value={!renderList}
              onChange={() => {
                setRenderList((pre) => !pre);
              }}
              style={{
                backgroundColor: '#ddd',
              }}
            />
          </StyledToggleConatiner>
        </Col>
      </Row>
      {!renderList ? (
        <Row>
          <Col sm={12} md={12} lg={12}>
            <GatewayDiagnosticsListing
              reactTableData={reactTableData}
              cpuIdValue={cpuIdValue ? cpuIdValue.split('-')[1] : ''}
              startDate={startDate}
              endDate={endDate}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              setCpuIdValue={setCpuIdValue}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <CalendarData />
        </Row>
      )}
    </Container>
  );
};

export default GatewayDiagnostics;

export const StyledToggleConatiner = styled.span`
  display: flex;
  justify-content: ${(props) => (props.iconRight ? 'right' : 'left')};
  padding: 9px 0px 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: ${colorText};
`;

export const StyledToggle = styled(ToggleButtonField)`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorAdditional, colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const MaintenanceTypesContainer = ({ img, assettype, totalnumber }) => (
  <MaintenanceTypesContainerWrap>
    <MaintenanceTypesContainerImageWrap>
      <img src={img} alt="" />
    </MaintenanceTypesContainerImageWrap>
    <div>
      <MaintenanceTypesContainerName>{assettype}</MaintenanceTypesContainerName>
      <MaintenanceTypesContainerCount>{totalnumber}</MaintenanceTypesContainerCount>
    </div>
    <MaintenanceTypesContainerLink>
      <span style={{ color: '#1368B1' }} className="lnr lnr-chevron-right" />
    </MaintenanceTypesContainerLink>
  </MaintenanceTypesContainerWrap>
);

MaintenanceTypesContainer.propTypes = {
  img: PropTypes.string,
  assettype: PropTypes.string,
  link: PropTypes.string,
};

export default MaintenanceTypesContainer;

// region STYLES

const MaintenanceTypesContainerWrap = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const MaintenanceTypesContainerLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
`;

const MaintenanceTypesContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const MaintenanceTypesContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
`;

const MaintenanceTypesContainerCount = styled.p`
  color: ${colorAdditional};
  line-height: 15px;
  font-size: 11px;
  margin: 0;

  @media (max-width: 1100px) and (min-width: 990px) {
    font-size: 10px;
  }
`;

// endregion

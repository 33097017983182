import { Badge as BootstrapBadge } from 'react-bootstrap';
import styled from 'styled-components';
import {
  colorAccent,
  colorBlue,
  colorGray,
  colorLightGray,
  colorRed,
  colorWhite,
  colorYellow,
} from '@/utils/palette';

const getColor = (variant) => {
  switch (true) {
    case variant?.includes('secondary'):
      return colorLightGray;
    case variant?.includes('primary'):
      return colorBlue;
    case variant?.includes('success'):
      return colorAccent;
    case variant?.includes('warning'):
      return colorYellow;
    case variant?.includes('danger'):
      return colorRed;
    default:
      return `#${variant}`;
  }
};

const Badge = styled(BootstrapBadge)`
  color: ${(props) => (props.bg === 'secondary' ? colorGray : colorWhite)};
  background-color: ${(props) => getColor(props.bg)} !important;
  width: 84px;
  border-radius: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  min-height: 22px;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 20.8px;
  border: 1px solid #${(props) => props.bg};

  &:empty {
    display: flex;
  }
`;

export default Badge;

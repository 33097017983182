import axios from 'axios';
import jwt from 'jwt-decode';
import { ApiURL } from '../../config/GlobalUrl';
import { successMessage } from '@/shared/helpers/alertMessages';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_REMOVE_CREDS = 'USER_LOGIN_REMOVE_CREDS';
export const OTP_VALIDATION_LOADING = 'OTP_VALIDATION_LOADING';
export const OTP_VALIDATION_SUCCESS = 'OTP_VALIDATION_SUCCESS';
export const OTP_VALIDATION_ERROR = 'OTP_VALIDATION_ERROR';

export const login = (email, mobile) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    // setting up the config as headers content-type
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      ApiURL.LOGIN,
      { email_id: email, mobile_number: mobile },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', email);
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export const logout = (tokenExpiry) => async (dispatch) => {
  try {
    localStorage.clear();
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    window.location.href = '/login';
    if (tokenExpiry) {
      successMessage('Logged out due to session expiry!');
    }
  } catch (error) {}
};

export const removeCredsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REMOVE_CREDS,
    });
  } catch (error) {}
};

export const validateOTP = (emailOTP, mobileOTP, email, mobile) => async (dispatch) => {
  try {
    dispatch({
      type: OTP_VALIDATION_LOADING,
    });

    // setting up the config as headers content-type
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      ApiURL.LOGIN_OTP,
      {
        email_otp: emailOTP,
        mobile_otp: mobileOTP,
        email_id: email,
        mobile_number: mobile,
      },
      config
    );

    const token = response.data.data.access_token;
    const user = jwt(token);

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('isloggedin', true);
    // sessionStorage.setItem('isloggedin', true);
    localStorage.setItem('companyId', user.company_id);

    dispatch({
      type: OTP_VALIDATION_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: OTP_VALIDATION_ERROR,
        payload: 'Network Error',
      });
    } else {
      dispatch({
        type: OTP_VALIDATION_ERROR,
        payload: error.response.data.data.message,
      });
    }
  }
};

//OTA Update - Get Campaign Listing Constants
export const GET_CAMPAIGN_LISTING_LOADING = 'GET_CAMPAIGN_LISTING_LOADING';
export const GET_CAMPAIGN_LISTING_SUCCESS = 'GET_CAMPAIGN_LISTING_SUCCESS';
export const GET_CAMPAIGN_LISTING_FAIL = 'GET_CAMPAIGN_LISTING_FAIL';

//OTA Update - Get HVAC List Constants
export const GET_HVAC_LIST_LOADING = 'GET_HVAC_LIST_LOADING';
export const GET_HVAC_LIST_SUCCESS = 'GET_HVAC_LIST_SUCCESS';
export const GET_HVAC_LIST_FAIL = 'GET_HVAC_LIST_FAIL';
export const GET_HVAC_LIST_RESET = 'GET_HVAC_LIST_RESET';

//OTA Update - Create Campaign Constants
export const CREATE_CAMPAIGN_LOADING = 'CREATE_CAMPAIGN_LOADING';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL';

//OTA Update - Get Campaign Device Status Listing Constants
export const GET_CAMPAIGN_DEVICE_STATUS_LISTING_LOADING =
  'GET_CAMPAIGN_DEVICE_STATUS_LISTING_LOADING';
export const GET_CAMPAIGN_DEVICE_STATUS_LISTING_SUCCESS =
  'GET_CAMPAIGN_DEVICE_STATUS_LISTING_SUCCESS';
export const GET_CAMPAIGN_DEVICE_STATUS_LISTING_FAIL = 'GET_CAMPAIGN_DEVICE_STATUS_LISTING_FAIL';
export const GET_CAMPAIGN_DEVICE_STATUS_LISTING_RESET = 'GET_CAMPAIGN_DEVICE_STATUS_LISTING_RESET';

//campaign creation package metadata
export const GET_METADATA_LOADING = 'GET_METADATA_LOADING';
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS';
export const GET_METADATA_FAIL = 'GET_METADATA_FAIL';
export const GET_METADATA_RESET = 'GET_METADATA_RESET';

//OTA - Send Service Mode Enable Constants
export const SERVICE_MODE_ENABLE_LOADING = 'SERVICE_MODE_ENABLE_LOADING';
export const SERVICE_MODE_ENABLE_SUCCESS = 'SERVICE_MODE_ENABLE_SUCCESS';
export const SERVICE_MODE_ENABLE_FAIL = 'SERVICE_MODE_ENABLE_FAIL';
export const SERVICE_MODE_ENABLE_RESET = 'SERVICE_MODE_ENABLE_RESET';

//OTA - Get Service Mode Device Status Constants
export const GET_SERVICE_MODE_DEVICE_STATUS_LOADING = 'GET_SERVICE_MODE_DEVICE_STATUS_LOADING';
export const GET_SERVICE_MODE_DEVICE_STATUS_SUCCESS = 'GET_SERVICE_MODE_DEVICE_STATUS_SUCCESS';
export const GET_SERVICE_MODE_DEVICE_STATUS_FAIL = 'GET_SERVICE_MODE_DEVICE_STATUS_FAIL';
export const GET_SERVICE_MODE_DEVICE_STATUS_RESET = 'GET_SERVICE_MODE_DEVICE_STATUS_RESET';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import getRoles from '../../../../redux/actions/getRolesActions';
import getDepots, { getDepotsReset } from '../../../../redux/actions/getDepotsActions';
import { vehicleRegisterationValidatePartTwo } from '../../../../utils/validate';
import { getOpFleets, getOpFleetsReset } from '../../../../redux/actions/getFleetsActions';

const WizardFormTwo = ({ onSubmit, previousPage, initialValues, buttonState, defaultValues }) => {
  const getRolesReducer = useSelector((state) => state.getRolesReducer.info);
  const getDepotsReducer = useSelector((state) => state.getDepotsReducer.info);
  const getOpFleetsReducer = useSelector((state) => state.getOpFleetsReducer);

  const dispatch = useDispatch();
  const getUser = JSON.parse(localStorage.getItem('user'));
  const { company_id, company_name, company_role_name } = getUser;

  const preFill = {
    id: company_id,
    value: company_name,
    label: company_name,
    field: company_role_name,
  };

  const [compId, setCompId] = useState(company_id);
  const [isSelectable, setIsSelectable] = useState(true);

  const handleOperatorChange = (e) => {
    if (e) {
      company_role_name === 'admin' && setCompId(e.id);
      dispatch(getOpFleets(e.id));
      setIsSelectable(false);
    }
    if (!e) {
      company_role_name === 'admin' && setCompId(company_id);
      dispatch(getOpFleetsReset());
      setIsSelectable(true);
    }
  };

  const handleFleetChange = (e) => {
    if (e) {
      dispatch(getDepots(compId, e.id));
    }
    if (!e) {
      dispatch(getDepotsReset());
    }
  };

  const ptaValFromResponse = [
    {
      label: defaultValues ? defaultValues.pta_name : null,
      value: defaultValues ? defaultValues.pta_name : null,
    },
  ];
  const operatorValFromResponse = [
    {
      label: defaultValues ? defaultValues.operator_name : null,
      value: defaultValues ? defaultValues.operator_name : null,
    },
  ];
  const serviceProviderValFromResponse = [
    {
      label: defaultValues ? defaultValues.service_provider : null,
      value: defaultValues ? defaultValues.service_provider : null,
    },
  ];

  const fleetValFromResponse = [
    {
      label: defaultValues ? defaultValues.fleet_name : null,
      value: defaultValues ? defaultValues.fleet_name : null,
    },
  ];

  const depotValFromResponse = [
    {
      label: defaultValues ? defaultValues.depot_name : null,
      value: defaultValues ? defaultValues.depot_name : null,
    },
  ];
  const opArr =
    getRolesReducer && getRolesReducer.associations.length > 0 && defaultValues
      ? getRolesReducer.associations
          .flatMap(({ role, companies }) =>
            role === 'operator'
              ? companies.map(({ name, id }) => ({
                  id: id,
                  value: name,
                  label: name,
                }))
              : []
          )
          .find((o) => o.value === defaultValues.operator_name)
      : [];

  useEffect(() => {
    dispatch(getRoles(compId));
  }, [compId]);

  useEffect(() => {
    if (defaultValues && defaultValues.operator_name && opArr) {
      dispatch(getOpFleets(opArr.id));
    }
    if(!defaultValues && company_role_name === 'operator'){
      dispatch(getOpFleets(preFill.id));

    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={vehicleRegisterationValidatePartTwo}
    >
      {({ handleSubmit, form, values }) => (
        <FormContainer onSubmit={handleSubmit} style={{ width: '100%' }}>
          <FormGroup>
            <FormGroupLabel>Operator</FormGroupLabel>
            <FormGroupField>
              <Field
                name="operator_name"
                component={renderSelectField}
                type="text"
                options={
                  getRolesReducer && getRolesReducer.associations.length > 0
                    ? getRolesReducer.associations.flatMap(({ role, companies }) =>
                        role === 'operator'
                          ? companies.map(({ name, id }) => ({
                              id: id,
                              value: name,
                              label: name,
                            }))
                          : []
                      )
                    : []
                }
                initialValue={
                  defaultValues
                    ? operatorValFromResponse
                    : preFill.field === 'operator'
                    ? preFill
                    : ''
                }
                parse={(e) => {
                  handleOperatorChange(e);
                  if (company_role_name === 'admin') {
                    form.change('service_provider', '');
                    form.change('pta_name', '');
                  }
                  form.change('fleet_name', '');
                  form.change('depot_name', '');
                  return e;
                }}
                placeholder={'Select Fleet Operator'}
                isClearable
                isDisabled={preFill.field === 'operator'}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Service Partner</FormGroupLabel>
            <FormGroupField>
              <Field
                name="service_provider"
                component={renderSelectField}
                type="text"
                options={
                  getRolesReducer && getRolesReducer.associations.length > 0
                    ? getRolesReducer.associations.flatMap(({ role, companies }) =>
                        role === 'service_provider'
                          ? companies.map(({ name, id }) => ({
                              id: id,
                              value: name,
                              label: name,
                            }))
                          : []
                      )
                    : []
                }
                initialValue={
                  serviceProviderValFromResponse[0].value !== null
                    ? serviceProviderValFromResponse
                    : preFill.field === 'service_provider'
                    ? preFill
                    : ''
                }
                placeholder={'Select Service Partner'}
                isClearable
                isDisabled={preFill.field === 'service_provider'}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>PTA</FormGroupLabel>
            <FormGroupField>
              <Field
                name="pta_name"
                component={renderSelectField}
                type="text"
                options={
                  getRolesReducer && getRolesReducer.associations.length > 0
                    ? getRolesReducer.associations.flatMap(({ role, companies }) =>
                        role === 'pta'
                          ? companies.map(({ name, id }) => ({
                              id: id,
                              value: name,
                              label: name,
                            }))
                          : []
                      )
                    : []
                }
                initialValue={
                  ptaValFromResponse[0].value !== null
                    ? ptaValFromResponse
                    : preFill.field === 'pta'
                    ? preFill
                    : ''
                }
                placeholder={'Select Transportaion Authority'}
                isClearable
                isDisabled={preFill.field === 'pta'}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Fleet ID</FormGroupLabel>
            <FormGroupField>
              <Field
                name="fleet_name"
                component={renderSelectField}
                type="text"
                options={
                  getOpFleetsReducer.info
                    ? getOpFleetsReducer.info.fleet_list.map(({ id, name }) => ({
                        id: id,
                        value: name,
                        label: name,
                      }))
                    : []
                }
                parse={(e) => {
                  handleFleetChange(e);
                  form.change('depot_name', '');
                  return e;
                }}
                initialValue={defaultValues ? fleetValFromResponse : ''}
                placeholder={'Select Fleet'}
                isClearable
                isDisabled={values.operator_name ? false : isSelectable && !buttonState}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Depot Name</FormGroupLabel>
            <FormGroupField>
              <Field
                name="depot_name"
                component={renderSelectField}
                type="text"
                options={
                  getDepotsReducer && getDepotsReducer.depots.length > 0
                    ? getDepotsReducer.depots.map(({ id, name }) => ({
                        id: id,
                        label: name,
                        value: name,
                      }))
                    : []
                }
                initialValue={defaultValues ? depotValFromResponse : ''}
                placeholder={'Select Depot'}
                isClearable
                isDisabled={values.fleet_name ? false : isSelectable && !buttonState}
              />
            </FormGroupField>
          </FormGroup>
          <FormButtonToolbar
            style={{
              width: '100%',
              marginBottom: '0',
              marginLeft: '0',
            }}
          >
            <Button
              variant="secondary"
              type="button"
              className="previous"
              onClick={previousPage}
              style={{ width: '100%', marginLeft: '0' }}
            >
              Back
            </Button>
          </FormButtonToolbar>
          <FormButtonToolbar
            style={{
              width: '100%',
              marginTop: '0',
              marginLeft: '0',
            }}
          >
            {buttonState === true ? (
              <Button variant="primary" type="submit" style={{ width: '100%', marginLeft: '0' }}>
                Update
              </Button>
            ) : (
              <Button variant="primary" type="submit" style={{ width: '100%', marginLeft: '0' }}>
                Register
              </Button>
            )}
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  );
};

WizardFormTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default WizardFormTwo;

import React, { useState, useMemo } from 'react';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import { ApiURL } from '@/config/GlobalUrl';
import { GetTableHeader } from '../../../../utils/helpers';

const CreateTableData = () => {
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'CPU Id'}
            key={'CPU Id'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'cpu_id',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Creation Date'}
            key={'Creation Date'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'creation_date',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'File Type'}
            key={'File Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'type_of_file',
        disableGlobalFilter: true,
      },
      {
        Header: 'Download File',
        accessor: '',
        disableSortBy: true,
        Cell: (props) => {
          return (
            <a href={ApiURL.DOWNLOAD_GATEWAY_DIAGNOSTICS_FILE + props.row.original.file_key}>
              <FileDownloadIcon style={{ cursor: 'pointer' }} />
            </a>
          );
        },
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

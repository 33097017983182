import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Path from '@/utils/routes/routePathNames';
import KpiCard from '@/shared/components/KpiCard';
import getPackageListingKpi from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getPackageListingKpi';
import PackageCreationForm from '../PackageManagement/PackageCreation';
import PackageListing from '../PackageManagement/PackageListing';

const PackageManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, info, error } = useSelector((state) => state.getPackageListingKpiReducer);

  useEffect(() => {
    dispatch(getPackageListingKpi());
  }, []);

  return (
    <Container>
      {location.pathname === Path.PACKAGE_LISTING && (
        <Row>
          <Col md={12} xl={3} lg={6} xs={12}>
            <KpiCard
              title={'In Progress'}
              color={'#F5A608'}
              gradient={'preventive-maintenance'}
              count={info?.in_progress}
              total={info?.total}
              loading={loading}
              error={error}
            />
          </Col>
          <Col md={12} xl={3} lg={6} xs={12}>
            <KpiCard
              title={'Pending'}
              color={'#F57A08'}
              gradient={'orange-vehicles'}
              count={info?.pending}
              total={info?.total}
              loading={loading}
              error={error}
            />
          </Col>
          <Col md={12} xl={3} lg={6} xs={12}>
            <KpiCard
              title={'Failed'}
              color={'#DC2323'}
              gradient={'red-vehicles'}
              count={info?.failed}
              total={info?.total}
              loading={loading}
              error={error}
            />
          </Col>
          <Col md={12} xl={3} lg={6} xs={12}>
            <KpiCard
              title={'Completed'}
              color={'#006B38'}
              gradient={'green-vehicles'}
              count={info?.completed}
              total={info?.total}
              loading={loading}
              error={error}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {location.pathname === Path.PACKAGE_CREATION ? (
            <PackageCreationForm />
          ) : location.pathname === Path.PACKAGE_LISTING ? (
            <PackageListing />
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PackageManagement;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colorFitness, colorAdditional } from '@/utils/palette';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import getVehiclesWithGateway from '../../../../redux/actions/DepotDashboardActions/getVehiclesWithGatewayActions';

const VehiclesWithGateway = ({ fleet_id, depot_id, changeComp, setChangeComp, companyId, tag }) => {
  const dispatch = useDispatch();

  const { info, total } = useSelector((state) => state.getVehiclesWithGatewayReducer);

  const COLORS = ['#F2F4F7', '#F6DA6E'];

  useEffect(() => {
    dispatch(getVehiclesWithGateway(companyId, fleet_id, depot_id, tag));
    setChangeComp(false);
  }, [depot_id, tag]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Vehicles with gateway</CardTitle>
        </CardTitleWrap>
        <div style={{ textAlign: 'center' }}>
          <DashboardHealthChartWrap style={{ width: '100%' }}>
            <ResponsiveContainer height={180} style={{ padding: '0', width: '100%' }}>
              <PieChart>
                <Pie
                  data={info ? info.pieArrayData : [{ pie_value: 0, pie_value: 1 }]}
                  cy={85}
                  innerRadius={70}
                  outerRadius={80}
                  dataKey="pie_value"
                  startAngle={90}
                  endAngle={450}
                >
                  {info ? (
                    info.pieArrayData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))
                  ) : (
                    <Cell key={`cell-1`} fill={'#F2F4F7'} />
                  )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <DashboardHealthChartInfo>
              <DashboardHealthChartNumber>
                {info && total ? total[1].vehicle_with_gateway : 0}
              </DashboardHealthChartNumber>
            </DashboardHealthChartInfo>
          </DashboardHealthChartWrap>
          <DashboardHealthGoal>
            Total vehicles: {info && total ? total[0].total_vehicles : 0}
          </DashboardHealthGoal>
        </div>
      </CardBody>
    </Card>
  );
};

export default VehiclesWithGateway;

const DashboardHealthChartWrap = styled.div`
  position: relative;
  margin-top: 50px;
`;

const DashboardHealthChartInfo = styled.div`
  position: absolute;
  width: 100%;
  top: calc(50% - 30px);
  animation: label 1.5s ease-in;

  p {
    margin: 0;
  }
`;

const DashboardHealthChartNumber = styled.p`
  font-size: 56px;
  line-height: 60px;
  color: ${colorFitness};
`;

const DashboardHealthGoal = styled.p`
  font-size: 12px;
  color: ${colorAdditional};
  margin-top: 10px;
`;

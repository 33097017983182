import themeReducer from './themeReducer';
import rtlReducer from './rtlReducer';
import roundBordersReducer from './roundBordersReducer';
import blocksShadowsReducer from './blocksShadowsReducer';
import sidebarReducer from './sidebarReducer';
import customizerReducer from './customizerReducer';
import signInReducer from './SignInReducers/signInReducer';
import resetPwdReducer from './SignInReducers/resetPwdReducer';
import forgotPwdReducer from './SignInReducers/forgotPwdReducer';
import resendPwdReducer from './SignInReducers/resendPwdReducer';
import loginReducer from './loginReducer';
import vinNumReducer from './vinNumReducer';
import getCompListReducer from './getCompListReducer';
import { vehicleRegisterReducer } from './vehicleReducer';
import getVinReducer from './getVinReducer';
import editVehicleReducer from './editVehicleReducer';
import registerGatewayReducer from './registerGatewayReducer';
import getGatewayReducer from './getGatewayReducer';
import registerSimReducer from './registerSimReducer';
import editGatewayReducer from './editGatewayReducer';
import validateOTPReducer from './validateOTPReducer';
import getHvacCountReducer from './getHvacCompCountReducer';
import getCompSerialNumberReducer from './getCompSerialNumberReducer';
import getSuppliersReducer from './getSuppliersReducer';
import addHvacCompReducer from './addHvacCompReducer';
import getCompsReducer from './getCompsReducer';
import deleteHvacCompReducer from './deleteHvacCompReducer';
import editHvacCompReducer from './editHvacCompReducer';
import getRolesReducer from './getRolesReducer';
import getFleetsReducer from './getFleetsReducer';
import getDepotsReducer from './getDepotsReducer';
import getTemperatureReducer from './getTemperatureReducer';
import getCompStatusReducer from './getCompStatusReducer';
import getVehicleLocationReducer from './getVehicleLocationReducer';
import getLastTimestampReducer from './getLastTimestampReducer';
import getRegisteredHvacReducer from './getRegisteredHvacReducer';
import getVehicleTagsReducer from './getVehicleTagsReducer';
import addVehicleTagsReducer from './VehicleDashboardReducers/addVehicleTagsReducer';
import removeVehicleTagReducer from './VehicleDashboardReducers/removeVehicleTagReducer';
import getVehicleAlarmsReducer from './VehicleDashboardReducers/getVehicleAlarmsReducer';
import getCompanyTagsReducer from './VehicleDashboardReducers/getCompanyTagsReducer';
import closeVehicleAlarmReducer from './VehicleDashboardReducers/closeVehicleAlarmReducer';
import searchVinNumberReducer from './VehicleDashboardReducers/searchVinNumberReducer';

//Global Dashboard
import getTotalCompanyVehiclesReducer from './GlobalDashboardReducers/getTotalCompanyVehiclesReducer';
import getGlobalHealthSummaryReducer from './GlobalDashboardReducers/getGlobalHealthSummaryReducer';
import getGlobalHealthDetailReducer from './GlobalDashboardReducers/getGlobalHealthDetailReducer';
import getGlobalDepotLocationReducer from './GlobalDashboardReducers/getGlobalDepotLocationReducer';

import getMapFiltersReducer from './GlobalDashboardReducers/getMapFiltersReducer';

//Depot Dashboard
import getTotalVehiclesReducer from './DepotDashboardReducers/getTotalVehiclesReducer';
import getDepotHvacByBrandReducer from './DepotDashboardReducers/getDepotHvacByBrandReducer';
import getHealthStatusSummaryReducer from './DepotDashboardReducers/getHealthStatusSummaryReducer';
import getVehiclesWithGatewayReducer from './DepotDashboardReducers/getVehiclesWithGatewayReducer';
import getDepotVehicleStatusReducer from './DepotDashboardReducers/getDepotVehicleStatusReducer';
import getDepotTagsReducer from './DepotDashboardReducers/getDepotTagsReducer';

//Health Status Dashboard
import getAlarmCountReducer from './HealthStatusDashboardReducers/getAlarmCountReducer';

import getAssetsCountReducer from './getAssetsCountReducer';
import getHealthStatusDetailReducer from './HealthStatusDashboardReducers/getHealthStatusDetailReducer';
import getAlarmChecklistReducer from './HealthStatusDashboardReducers/getAlarmChecklistReducer';
import closeAlarmReducer from './HealthStatusDashboardReducers/closeAlarmReducer';
import getRegisteredCompanyReducer from './CompanyReducers/getRegisteredCompanyReducer';
import getCompanyListReducer from './CompanyReducers/getCompanyListReducer';
import addCompanyReducer from './CompanyReducers/addCompanyReducer';
import getCompanyRelationReducer from './CompanyReducers/getCompanyRelationReducer';
import registerCompanyRelationReducer from './CompanyReducers/registerCompanyRelationReducer';
import updateCompanyReducer from './CompanyReducers/updateCompanyReducer';
import registerFleetReducer from './FleetReducers/registerFleetReducer';
import getRegisteredFleetReducer from './FleetReducers/getRegisteredFleetReducer';
import getFleetListReducer from './FleetReducers/getFleetListReducer';
import updateFleetReducer from './FleetReducers/updateFleetReducer';
import getSysSettingsCountReducer from './SystemSettingsReducers/getSystemSettingsCountReducer';
import getCompanySysSettingsCountReducer from './SystemSettingsReducers/getCompanySysSettingsCountReducer';
import getDepotListReducer from './DepotReducers/getDepotListReducer';
import getDepotListByCompanyReducer from './DepotReducers/getDepotListByCompanyReducer';
import getRegisteredDepotReducer from './DepotReducers/getRegisteredDepotReducer';
import registerDepotReducer from './DepotReducers/registerDepotReducer';
import updateDepotReducer from './DepotReducers/updateDepotReducer';
import getUserListReducer from './UserReducers/getUserListReducer';
import getRegisteredUserReducer from './UserReducers/getRegisteredUserReducer';
import updateUserReducer from './UserReducers/updateUserReducer';
import registerUserReducer from './UserReducers/registerUserReducer';
import getRoleListReducer from './RoleReducers/getRoleListReducer';

//Manage Settings - Manage Notifications
import getDepotListNotiReducer from './SystemSettingsReducers/ManageNotifications/getDepotListNotiReducer';
import getDepotVehiclesReducer from './SystemSettingsReducers/ManageNotifications/getDepotVehiclesReducer';
import getUsersListNotiReducer from './SystemSettingsReducers/ManageNotifications/getUsersListNotiReducer';
import getSelectedUserVehiclesReducer from './SystemSettingsReducers/ManageNotifications/getSelectedUserVehiclesReducer';
import getSignalsReducer from './DiagnosticsReducers/getSignalsReducer';
import getDiagnosticsFileReducer from './DiagnosticsReducers/getDiagnosticsFileReducer';
import sendCanIntervalReducer from './DiagnosticsReducers/sendCanIntervalReducer';
import getNotificationListReducer from './SystemSettingsReducers/ManageNotifications/getNotificationListReducer';
import getDepotNotificationTypesReducer from './SystemSettingsReducers/ManageNotifications/getDepotNotificationTypesReducer';

//Retrieve Data
import getCanDataListingReducer from './DiagnosticsReducers/getCanDataListingReducer';
import deleteCanDumpReducer from './DiagnosticsReducers/deleteCanDumpReducer';
import getMaintenanceMetaReducer from './MaintenanceReducers/getMaintenanceMetaReducer';
import addMaintenanceMetaReducer from './MaintenanceReducers/addMaintenanceMetaReducer';

//Gateway Master
import registerCpuNumberReducer from './GatewayMasterReducers/registerCpuNumberReducer';
import getGatewayMasterListReducer from './GatewayMasterReducers/getGatewayMasterListReducer';

//Sim Master
import registerSimMasterReducer from './SimMasterReducers/registerSimMasterReducer';
import getSimMasterListReducer from './SimMasterReducers/getSimMasterListReducer';

import getVehicleGatewayDetailsReducer from './VehicleDashboardReducers/getVehicleGatewayDetailsReducer';
import downloadIndexErrorCodeReducer from './VehicleDashboardReducers/downloadIndexErrorCodeReducer';
import getOpFleetsReducer from './VehicleRegistrationReducers/getOpFleetsReducer';

//Gateway Diagnostics
import getGatewayDiagnosticsListReducer from './GatewayDiagnosticsReducers/getGatewayDiagnosticsListReducer';
import getGatewayDiagnosticsListingReducer from './GatewayDiagnosticsReducers/getGatewayDiagnosticsListingReducer';
import getGatewayDiagnosticsMonthReducer from './GatewayDiagnosticsReducers/getGatewayDiagnosticsMonthReducer';
//OTA Update - Package Management
import getCompanyDataReducer from './OtaUpdateReducers/PackageMgtReducers/getCompanyDataReducer';
import getGroupListReducer from './OtaUpdateReducers/PackageMgtReducers/getGroupListReducer';
import uploadPackageFileCheckReducer from './OtaUpdateReducers/PackageMgtReducers/uploadPackageFileCheckReducer';
import createPackageReducer from './OtaUpdateReducers/PackageMgtReducers/createPackageReducer';
import getPackageListingReducer from './OtaUpdateReducers/PackageMgtReducers/getPackageListingReducer';

//OTA Update - Package Management - Gateway Package Creation
import getBaseFirmwareVersionReducer from './OtaUpdateReducers/PackageMgtReducers/GatewayPackageCreationReducers/getBaseFirmwareVersionReducer';
import createGatewayPackageReducer from './OtaUpdateReducers/PackageMgtReducers/GatewayPackageCreationReducers/createGatewayPackageReducer';
import getGatewayConfigReducer from './OtaUpdateReducers/PackageMgtReducers/GatewayPackageCreationReducers/getGatewayConfigReducer';

//OTA Update - Package Management
import downloadPackageReducer from './OtaUpdateReducers/PackageMgtReducers/downloadPackageReducer';

//OTA Update - Campaign Management
import getCampaignListingReducer from './OtaUpdateReducers/CampaignMgtReducers/getCampaignListingReducer';
import getHvacListReducer from './OtaUpdateReducers/CampaignMgtReducers/getHvacListReducer';
import getCampaignDeviceStatusListingReducer from './OtaUpdateReducers/CampaignMgtReducers/getCampaignDeviceStatusListingReducer';
import createCampaignReducer from './OtaUpdateReducers/CampaignMgtReducers/createCampaignReducer';
import getMetadataReducer from './OtaUpdateReducers/CampaignMgtReducers/getMetadataReducer';
import serviceModeEnableReducer from './OtaUpdateReducers/CampaignMgtReducers/serviceModeEnableReducer';
import getServiceModeDeviceStatusReducer from './OtaUpdateReducers/CampaignMgtReducers/getServiceModeDeviceStatusReducer';

//OTA Update - Group Management
import getHvacModelsReducer from './OtaUpdateReducers/GroupMgtReducers/getHvacModelsReducer';
import getGroupDeviceListByModalReducer from './OtaUpdateReducers/GroupMgtReducers/getGroupDeviceListByModalReducer';
import getGroupDeviceListByOthersReducer from './OtaUpdateReducers/GroupMgtReducers/getGroupDeviceListByOthersReducer';
import createGroupReducer from './OtaUpdateReducers/GroupMgtReducers/createGroupReducer';
import updateGroupReducer from './OtaUpdateReducers/GroupMgtReducers/updateGroupReducer';
import getGroupDetailsReducer from './OtaUpdateReducers/GroupMgtReducers/getGroupDetailsReducer';

//OTA Update - Group Management - Gateway
import getGatewayDeviceListReducer from './OtaUpdateReducers/GroupMgtReducers/GatewayGroup/getGatewayDeviceListReducer';
import createGatewayGroupReducer from './OtaUpdateReducers/GroupMgtReducers/GatewayGroup/createGatewayGroupReducer';
import updateGatewayGroupReducer from './OtaUpdateReducers/GroupMgtReducers/GatewayGroup/updateGatewayGroupReducer';

//OTA Update - Dashboard
import updateOtaRowReducer from './OtaUpdateReducers/OtaDashboardReducers/updateOtaRowReducer';
import getGatewayUnitsByBrandReducer from './OtaUpdateReducers/OtaDashboardReducers/getGatewayUnitsByBrandReducer';
import getGatewayListingReducer from './OtaUpdateReducers/OtaDashboardReducers/getGatewayListingReducer';
import getPackageCountReducer from './OtaUpdateReducers/OtaDashboardReducers/getPackageCountReducer';
import getPackageListingKpiReducer from './OtaUpdateReducers/PackageMgtReducers/getPackageListingKpiReducer';

//initial depot filters
import setInitialDepotFilterreducers from './DepotDashboardReducers/setInitialDepotFilterreducers';

//User Profile
import getUserProfileReducer from './UserProfileReducers/getUserProfileReduer';
import updateUserProfileReducer from './UserProfileReducers/updateUserProfileReducer';

export {
  themeReducer,
  rtlReducer,
  sidebarReducer,
  customizerReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  signInReducer,
  resetPwdReducer,
  forgotPwdReducer,
  resendPwdReducer,
  loginReducer,
  vinNumReducer,
  getCompListReducer,
  vehicleRegisterReducer,
  getVinReducer,
  editVehicleReducer,
  registerGatewayReducer,
  getGatewayReducer,
  registerSimReducer,
  editGatewayReducer,
  validateOTPReducer,
  getHvacCountReducer,
  getCompSerialNumberReducer,
  getSuppliersReducer,
  addHvacCompReducer,
  getCompsReducer,
  deleteHvacCompReducer,
  editHvacCompReducer,
  getRolesReducer,
  getFleetsReducer,
  getDepotsReducer,
  getTemperatureReducer,
  getCompStatusReducer,
  getVehicleLocationReducer,
  getLastTimestampReducer,
  getRegisteredHvacReducer,
  getVehicleTagsReducer,
  //Depot Dashboard
  getHealthStatusSummaryReducer,
  getDepotHvacByBrandReducer,
  getTotalVehiclesReducer,
  getVehiclesWithGatewayReducer,
  getDepotVehicleStatusReducer,
  addVehicleTagsReducer,
  removeVehicleTagReducer,
  getVehicleAlarmsReducer,
  getCompanyTagsReducer,
  closeVehicleAlarmReducer,
  searchVinNumberReducer,

  //Global Dashboard
  getTotalCompanyVehiclesReducer,
  getGlobalHealthSummaryReducer,
  getGlobalHealthDetailReducer,
  getGlobalDepotLocationReducer,

  //Health Status Dashboard
  getAlarmCountReducer,
  getAssetsCountReducer,
  getHealthStatusDetailReducer,
  getAlarmChecklistReducer,
  closeAlarmReducer,
  getRegisteredCompanyReducer,
  getCompanyListReducer,
  addCompanyReducer,
  getCompanyRelationReducer,
  registerCompanyRelationReducer,
  updateCompanyReducer,
  registerFleetReducer,
  getRegisteredFleetReducer,
  getFleetListReducer,
  updateFleetReducer,
  getSysSettingsCountReducer,
  getCompanySysSettingsCountReducer,
  getDepotListReducer,
  getRegisteredDepotReducer,
  registerDepotReducer,
  updateDepotReducer,
  getUserListReducer,
  getDepotListByCompanyReducer,
  getRegisteredUserReducer,
  updateUserReducer,
  registerUserReducer,
  getRoleListReducer,
  //Manage Settings - Manage Notifications
  getDepotListNotiReducer,
  getDepotVehiclesReducer,
  getUsersListNotiReducer,
  getSelectedUserVehiclesReducer,
  getNotificationListReducer,
  getSignalsReducer,
  getDiagnosticsFileReducer,
  sendCanIntervalReducer,
  getDepotNotificationTypesReducer,

  //Retrieve Data
  getCanDataListingReducer,
  getMapFiltersReducer,
  deleteCanDumpReducer,
  getMaintenanceMetaReducer,
  addMaintenanceMetaReducer,

  //Gateway Master
  registerCpuNumberReducer,
  getGatewayMasterListReducer,

  //Sim Master
  registerSimMasterReducer,
  getSimMasterListReducer,
  getVehicleGatewayDetailsReducer,
  downloadIndexErrorCodeReducer,
  getOpFleetsReducer,
  getDepotTagsReducer,

  //Gateway Diagnostics
  getGatewayDiagnosticsListReducer,
  getGatewayDiagnosticsListingReducer,
  getGatewayDiagnosticsMonthReducer,

  //OTA Update - Package Management
  getCompanyDataReducer,
  getGroupListReducer,
  uploadPackageFileCheckReducer,
  createPackageReducer,
  getPackageListingReducer,

  //OTA Update - Package Management - Gateway Package Creation
  getBaseFirmwareVersionReducer,
  createGatewayPackageReducer,
  getGatewayConfigReducer,

  //OTA Update - Campaign Management
  getCampaignListingReducer,
  getHvacListReducer,
  getCampaignDeviceStatusListingReducer,
  createCampaignReducer,
  getMetadataReducer,
  serviceModeEnableReducer,
  getServiceModeDeviceStatusReducer,

  //OTA Update - OTA Dashboard
  updateOtaRowReducer,
  getGatewayUnitsByBrandReducer,
  getGatewayListingReducer,
  getPackageCountReducer,
  getPackageListingKpiReducer,

  //OTA Update - Group Management
  getHvacModelsReducer,
  getGroupDeviceListByModalReducer,
  getGroupDeviceListByOthersReducer,
  createGroupReducer,
  updateGroupReducer,
  getGroupDetailsReducer,

  //OTA Update - Group Management - Gateway
  getGatewayDeviceListReducer,
  createGatewayGroupReducer,
  updateGatewayGroupReducer,

  //OTA Update - Package Management
  downloadPackageReducer,

  //initial depot reducers
  setInitialDepotFilterreducers,

  //User Profile
  getUserProfileReducer,
  updateUserProfileReducer,
};

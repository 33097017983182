import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const updateDepotNotification = (comp_id, depot, notify, notificationtype) => async (dispatch) => {
  try {
    const body = {
      is_active: notify,
    };

    const response = await axios.post(
      ApiURL.POST_NOTIFICATIONS +
        `${comp_id}/depots/${depot}/notifications/${notificationtype}/update`,
      body,
      getConfig()
    );
    // successMessage(response.data.data.message);
    return response.data.data;
  } catch (error) {
    console.log('error', error);
  }
};

export default updateDepotNotification;

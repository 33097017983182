import React from 'react';
import ComponentsListWithType from './components/ComponentsListWithType';
import { useHistory, useLocation } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import RegisteredVehicleDetailsImage from '../Hvac/components/RegisteredVehicleDetailsImage';
import ComponentsRegistered from './components/ComponentsRegistered';

const HvacDevices = () => {
  const location = useLocation();
  const history = useHistory();
  // const getHvacComp = location.state.cType;

  return (
    <>
      {location.state ? (
        <Container>
          {/* <Row className="mb-2">
        <Col md={6}>
          <h3 className="page-title">Add {getHvacComp}</h3>
          <h6 style={{ paddingBottom: '20px' }}>Edit Assets</h6>
        </Col>
      </Row> */}
          <Row>
            <Col md={12} lg={12} className='mb-2'>
              <RegisteredVehicleDetailsImage hasLink={false} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <ComponentsListWithType componentType={location.state.cType} />
            </Col>
          </Row>
        </Container>
      ) : (
        // ''
        <Container>
          <Row>
            <ComponentsRegistered />
          </Row>
        </Container>
      )}
    </>
  );
};

export default HvacDevices;

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';
import { DatePickerWrap } from '@/shared/components/form/date-pickers/DatePickerElements';

const CampaignDatePicker = ({ onChange, placeholder, timeselected }) => {
  const [startDate, setStartDate] = useState(timeselected || null);

  const handleChange = (date) => {
    setStartDate(date);
    onChange(date);
  };
  const currentDate = new Date();

  useEffect(() => {
    if (!timeselected) {
      setStartDate(null);
    }
  }, [timeselected]);

  useEffect(() => {
    if (timeselected) {
      onChange(timeselected);
    }
  }, []);

  return (
    <DatePickerWrap>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        dateFormat="MMMM d, yyyy HH:mm:ss"
        dropDownMode="select"
        showTimeInput={true}
        withPortal={isMobileOnly}
        placeholderText={placeholder}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        minDate={currentDate}
        value={startDate ? startDate : timeselected}
      />
    </DatePickerWrap>
  );
};

CampaignDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default renderComponentField(CampaignDatePicker);

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_DEPOT_LIST_BY_COMPANY_LOADING,
  GET_DEPOT_LIST_BY_COMPANY_SUCCESS,
  GET_DEPOT_LIST_BY_COMPANY_FAIL,
} from '../../../shared/constants/DepotConstants/getDepotListByCompanyConstants';

const getDepotListByCompany = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_LIST_BY_COMPANY_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_DEPOT_LIST_BY_COMPANY + companyId + '/depots_list',
      config
    );

    dispatch({
      type: GET_DEPOT_LIST_BY_COMPANY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_LIST_BY_COMPANY_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_LIST_BY_COMPANY_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getDepotListByCompany;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getDepotVehicleStatus from '@/redux/actions/DepotDashboardActions/getDepotVehicleStatus';
import MapFilters from '../../GlobalDashboard/components/MapFilters';

const DepotVehicleStatus = ({
  reactTableData,
  fleet_id,
  depot_id,
  changeComp,
  setChangeComp,
  companyId,
  tag,
  setSelectedTag,
  showDepot,
}) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  useEffect(() => {
    dispatch(
      getDepotVehicleStatus(
        companyId,
        depot_id,
        tag,
        pageNumber,
        numberofRecords,
        reactTableData.sort
      )
    );
    setChangeComp(false);
  }, [depot_id, tag, reactTableData.sort]);

  const { loading, info, pagination, error } = useSelector(
    (state) => state.getDepotVehicleStatusReducer
  );

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getDepotVehicleStatus(companyId, depot_id, tag, pageIndex + 1, pageSize, reactTableData.sort)
    );
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12} lg={4} sm={12}>
            <CardTitleWrap>
              <CardTitle>Vehicle Status Details</CardTitle>
            </CardTitleWrap>
          </Col>
          <Col md={12} lg={8} sm={12}>
            <MapFilters showDepot={showDepot} setChangeComp={setChangeComp} tag={setSelectedTag} />
          </Col>
        </Row>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={info}
          tableConfig={tableConfig}
          paginationDetail={pagination}
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Depot'}
        />
        {loading ? '' : info && info.length > 0 ? '' : <TableNoData />}
      </CardBody>
    </Card>
  );
};

DepotVehicleStatus.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DepotVehicleStatus;

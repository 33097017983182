import axios from 'axios';
import jwt from 'jwt-decode';
import {
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_IN_SUCCESS_FIRST_TIME,
  SIGN_IN_FAIL,
  SIGN_IN_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const signIn = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: SIGN_IN_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(ApiURL.SIGN_IN, { email: email, password: password }, config);

    if (response.data.data.access_token) {
      const token = response.data.data.access_token;
      const user = jwt(token);

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      localStorage.setItem('isloggedin', true);
      localStorage.setItem('companyId', user.company_id);
      localStorage.setItem('userInfo', email);

      successMessage('Log in successful');

      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: response.data.data.message,
      });
    }
    if (!response.data.data.access_token) {
      dispatch({
        type: SIGN_IN_SUCCESS_FIRST_TIME,
        payload: response.data.data.message,
      });
    }
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: SIGN_IN_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: SIGN_IN_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const signInReset = () => (dispatch) => {
  dispatch({
    type: SIGN_IN_RESET,
  });
};

// export const signOut = () => (dispatch)=>{
//   try {

//   } catch (error) {

//   }
// }

export default signIn;

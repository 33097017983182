import {
  GET_SUPPLIERS_LOADING,
  GET_SUPPLIERS_SUCCESS,
  GET_SUPPLIERS_FAIL,
} from '../../shared/constants/getSuppliersConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getSuppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS_LOADING:
      return { ...state, loading: true };
    case GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_SUPPLIERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getSuppliersReducer;

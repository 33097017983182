import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FormContainer } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getGatewayDiagnosticsList from '@/redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsListActions';
import { getGatewayDiagnosticsMonthReset } from '../../../../redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsMonthActions';

const GatewayForm = ({ setSelectedCpuId }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    console.log('here');
  };

  useEffect(() => {
    dispatch(getGatewayDiagnosticsList());
  }, [dispatch]);

  const { loading, info } = useSelector((state) => state.getGatewayDiagnosticsListReducer);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Field
            name="cpu_id"
            component={renderSelectField}
            placeholder={'Select gateway'}
            options={
              info
                ? info.data.map(({ cpu_id, link }) => ({
                    value: link,
                    label: cpu_id,
                    id: cpu_id,
                  }))
                : []
            }
            parse={(e) => {
              if (e) {
                setSelectedCpuId(e.id);
              } else {
                setSelectedCpuId(null);
                dispatch(getGatewayDiagnosticsMonthReset());
              }
              return e;
            }}
            isClearable
          />
        </FormContainer>
      )}
    </Form>
  );
};

export default GatewayForm;

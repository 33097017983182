import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_SELECTED_USER_VEHICLES_LOADING,
  GET_SELECTED_USER_VEHICLES_SUCCESS,
  GET_SELECTED_USER_VEHICLES_FAIL,
  GET_SELECTED_USER_VEHICLES_RESET,
  GET_DEPOT_NOTIFICATION_TYPES_LOADING,
  GET_DEPOT_NOTIFICATION_TYPES_SUCCESS,
  GET_DEPOT_NOTIFICATION_TYPES_FAIL,
  GET_DEPOT_NOTIFICATION_TYPES_RESET,
} from '@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getSelectedUserVehicles = (companyId, depot, alarmType) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELECTED_USER_VEHICLES_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_SELECTED_USER_VEHICLES + companyId + `/depots/${depot}/notifications/${alarmType}`,
      getConfig()
    );

    dispatch({
      type: GET_SELECTED_USER_VEHICLES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_SELECTED_USER_VEHICLES_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_SELECTED_USER_VEHICLES_FAIL,
        // payload: error.response.data.data.message,
        payload: error,
      });
    }
  }
};

export const resetSelectedUserVehicles = () => async (dispatch) => {
  dispatch({
    type: GET_SELECTED_USER_VEHICLES_RESET,
  });
};

export const getDepotNotificationTypes = (companyId, depot) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_NOTIFICATION_TYPES_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_SELECTED_USER_VEHICLES + companyId + `/depots/${depot}/notifications/types/list`,
      getConfig()
    );

    dispatch({
      type: GET_DEPOT_NOTIFICATION_TYPES_SUCCESS,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_NOTIFICATION_TYPES_FAIL,
        payload: 'network error',
      });
      return error;
    } else {
      dispatch({
        type: GET_DEPOT_NOTIFICATION_TYPES_FAIL,
        // payload: error.response.data.data.message,
        payload: error,
      });
      return error;
    }
  }
};

export const resetGetDepotNotificationTypes = () => async (dispatch) => {
  dispatch({
    type: GET_DEPOT_NOTIFICATION_TYPES_RESET,
  });
};

export default getSelectedUserVehicles;

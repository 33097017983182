import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_GATEWAY_LOADING,
  GET_GATEWAY_SUCCESS,
  GET_GATEWAY_FAIL,
  GET_RESTORE_INITIAL_VALUES,
} from '../../shared/constants/getGatewayConstants';

export const getGateway = (vinNumber) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GATEWAY_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_GATEWAY + vinNumber + '/gateway/',
      config
    );

    dispatch({
      type: GET_GATEWAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GATEWAY_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GATEWAY_FAIL,
        payload: error.response.data.data.extra
          ? error.response.data.data.extra.fields[0]
          : error.response.data.data.message,
      });
    }
  }
};

export const resetValues = () => (dispatch) => {
  dispatch({
    type: GET_RESTORE_INITIAL_VALUES,
  });
};

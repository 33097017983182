import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { dashColor } from '@/utils/palette';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import getVehicleLocation from '../../../../redux/actions/getVehicleLocationActions';

const containerStyle = {
  height: '360px',
};

const VehicleLocation = ({ vin }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  const [isOpened, setIsOpened] = useState(true);

  const onToggleIsOpen = () => {
    setIsOpened(!isOpened);
  };

  const onLoad = (infoWindow) => {
    console.log('infoWindow: ', infoWindow);
  };

  const theme = useSelector((state) => state.theme.className);

  const defaultMapOptions = {
    disableDefaultUI: true,
    keyboardShortcuts: false,
    gestureHandling: 'cooperative',
    zoomControl: true,
    styles:
      theme === 'dark'
        ? [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8ec3b9',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1a3646',
                },
              ],
            },
            {
              featureType: 'administrative.country',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878',
                },
              ],
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#64779e',
                },
              ],
            },
            {
              featureType: 'administrative.province',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878',
                },
              ],
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#334e87',
                },
              ],
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#283d6a',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#6f9ba5',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#3C7680',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#304a7d',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c6675',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#255763',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#b0d5ce',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#283d6a',
                },
              ],
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3a4762',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#0e1626',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#4e6d70',
                },
              ],
            },
          ]
        : [],
  };

  const dispatch = useDispatch();

  const { loading, info, error } = useSelector((state) => state.getVehicleLocationReducer);
  const getVehicleGatewayDetailsReducerInfo = useSelector(
    (state) => state.getVehicleGatewayDetailsReducer.info
  );
  const getVinReducer = useSelector((state) => state.getVinReducer.info);

  useEffect(() => {
    dispatch(getVehicleLocation(vin));
  }, [vin]);

  var pinSVGFilled =
    'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';

  return (
    <Card style={{ height: '483px' }}>
      <CardBody>
        <CardTitleWrap style={{ marginBottom: '12px' }}>
          <CardTitle>Vehicle Location</CardTitle>
          <CardSubhead style={{ textTransform: 'capitalize' }}>
            {getVehicleGatewayDetailsReducerInfo &&
            getVehicleGatewayDetailsReducerInfo[0].vehicle[0].vehicle_status
              ? getVehicleGatewayDetailsReducerInfo[0].vehicle[0].vehicle_status.toLowerCase()
              : ''}
          </CardSubhead>
        </CardTitleWrap>
        {isLoaded ? (
          <GoogleMap
            id="infoWindowMap"
            mapContainerStyle={containerStyle}
            center={{
              lat: info ? info.gps.lat : 54.526,
              lng: info ? info.gps.long : 15.2551,
            }}
            zoom={info ? 18 : 2}
            options={defaultMapOptions}
          >
            {!loading && info && getVehicleGatewayDetailsReducerInfo ? (
              <Marker
                position={{
                  lat: info.gps.lat,
                  lng: info.gps.long,
                }}
                onClick={onToggleIsOpen}
                animation={window.google.maps.Animation.DROP}
                icon={{
                  path: pinSVGFilled,
                  fillOpacity: 1,
                  fillColor: `#${getVehicleGatewayDetailsReducerInfo[0].vehicle[0].color_code}`,
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  strokeColor: '#FFFFFF',
                  scale: 1.5,
                  anchor: new window.google.maps.Point(10, 20),
                }}
              >
                {!isOpened && (
                  <InfoWindow
                    onLoad={onLoad}
                    options={{
                      closeBoxURL: '',
                      enableEventPropagation: true,
                    }}
                    position={{
                      lat: info.gps.lat,
                      lng: info.gps.long,
                    }}
                  >
                    <MapMarkerContent>
                      {info.gps.lat} {info.gps.long}
                    </MapMarkerContent>
                  </InfoWindow>
                )}
              </Marker>
            ) : (
              ''
            )}
          </GoogleMap>
        ) : (
          <ErrorSpan>{'No data found'}</ErrorSpan>
        )}
      </CardBody>
    </Card>
  );
};

export default VehicleLocation;

// region STYLES

const MapMarkerContent = styled.div`
  font-size: 12px;
  width: 130px;
  min-height: 40px;
  position: relative;
`;

const ErrorSpan = styled.span`
  color: ${(props) => dashColor(props)};
`;

// endregion

import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import Path from '@/utils/routes/routePathNames';
import { colorTextAdditional } from '@/utils/palette';
import downloadPackage from '@/redux/actions/OtaUpdateActions/PackageMgtActions/downloadPackage';
import { GetTableHeader } from '../../../../../../utils/helpers';

const CreateTableData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const goToCampaignCreationPage = (props) => {
    setTimeout(() => {
      history.push(Path.CAMPAIGN_CREATION);
    }, 200);
    localStorage.setItem('campaignRowDetails', JSON.stringify(props.row.original));
  };

  const handlePackageDownload = (props) => {
    dispatch(downloadPackage(props.row.original.id));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'View',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          return props.row.original.type && props.row.original.type !== 'gateway' ? (
            <StyledViewIcon onClick={() => goToCampaignCreationPage(props)} />
          ) : (
            <StyledViewIconDisabled />
          );
        },
      },
      // {
      //   Header: 'Download Package',
      //   accessor: '',
      //   disableGlobalFilter: true,
      //   Cell: (props) => {
      //     return (
      //       <FileDownloadIcon
      //         onClick={() => handlePackageDownload(props)}
      //         style={{ cursor: 'pointer' }}
      //       />
      //     );
      //   },
      // },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Package Name'}
            key={'Package Name'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Device Type'}
            key={'Device Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Company'}
            key={'Company'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'operator_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Fleet'}
            key={'Fleet'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'fleet_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Unit Name'}
            key={'Unit Name'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'unit_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depot_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Group'}
            key={'Group'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'group_label',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Version'}
            key={'Version'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'software_version',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Creation time'}
            key={'Creation time'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'creation_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
    ],
    []
  );
  const reactTableData = {
    tableHeaderData: columns,
    sort,
  };
  return reactTableData;
};

export default CreateTableData;

const StyledViewIcon = styled(OpenInNewIcon)`
  cursor: pointer;
  color: ${colorTextAdditional};
`;

const StyledViewIconDisabled = styled(OpenInNewIcon)`
  color: #ddd;
`;

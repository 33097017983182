import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import { dashColor } from '@/utils/palette';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import renderMultiSelectField from '@/shared/components/form/MultiSelect';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import VehicleTagsData from './VehicleTagData';
import getVehicleTags from '../../../../redux/actions/getVehicleTagsActions';
import addVehicleTags from '../../../../redux/actions/VehicleDashboardActions/addVehicleTagsActions';
import getCompanyTags from '../../../../redux/actions/VehicleDashboardActions/getCompanyTagsActions';
import removeVehicleTag, {
  resetRemoveVehicleTag,
} from '../../../../redux/actions/VehicleDashboardActions/removeVehicleTagActions';

const { tableHeaderData } = VehicleTagsData();

const VehicleTags = ({ vin }) => {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [removeBtnClicked, setRemoveBtnClicked] = useState(false);
  const [changeComp, setChangeComp] = useState(false);

  const getVehicleTagsReducerInfo = useSelector((state) => state.getVehicleTagsReducer.info);
  const getVehicleTagsReducerLoading = useSelector((state) => state.getVehicleTagsReducer.loading);
  const getVehicleTagsReducerError = useSelector((state) => state.getVehicleTagsReducer.error);
  const getCompanyTagsReducerInfo = useSelector((state) => state.getCompanyTagsReducer.info);
  const addVehicleTagsReducerLoading = useSelector((state) => state.addVehicleTagsReducer.loading);
  const addVehicleTagsReducerInfo = useSelector((state) => state.getVehicleTagsReducer.info);
  const removeVehicleTagReducerInfo = useSelector((state) => state.removeVehicleTagReducer.info);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails.company_id;

  useEffect(() => {
    dispatch(getVehicleTags(vin));
    setChangeComp(false);
    return () => {
      setShowComponent(false);
    };
  }, [vin, changeComp]);

  const flipComponent = () => {
    setShowComponent((prevCheck) => !prevCheck);
    dispatch(getCompanyTags(companyId));
  };

  const onSubmit = (values) => {
    if (values && values.multiselect && Object.entries(values.multiselect).length > 0) {
      dispatch(addVehicleTags(vin, companyId, values.multiselect[0].value));
      setBtnClicked(true);
    }
  };

  if (addVehicleTagsReducerInfo && btnClicked) {
    setTimeout(() => {
      setBtnClicked(false);
      setChangeComp(true);
    }, 200);
  }

  const handleRemoveTag = (values) => {
    if (values) {
      dispatch(removeVehicleTag(companyId, vin, values.tag_id));
      setRemoveBtnClicked(true);
    }
  };

  if (removeVehicleTagReducerInfo && removeBtnClicked) {
    setTimeout(() => {
      dispatch(resetRemoveVehicleTag());
      setRemoveBtnClicked(false);
      setChangeComp(true);
    }, 200);
  }

  return (
    <Card style={{ height: '483px' }}>
      {!showComponent ? (
        <CardBody>
          <CardTitleWrap
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '22px',
            }}
          >
            <CardTitle>Vehicle Tags</CardTitle>
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
              }}
              variant="primary"
              onClick={flipComponent}
            >
              Manage Tag
            </Button>
          </CardTitleWrap>
          <div style={{ overflowY: 'auto', height: '85%' }}>
            <Table striped responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item) => (
                    <th key={item.id}>{item.title}</th>
                  ))}
                </tr>
              </thead>
              {!getVehicleTagsReducerInfo ||
              getVehicleTagsReducerInfo === undefined ||
              getVehicleTagsReducerLoading === true ? (
                <tbody
                  style={{
                    height: '200px',
                  }}
                >
                  <tr style={{ display: 'none' }}>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <DotDotLoader loadingState={getVehicleTagsReducerLoading} />
                    </td>
                  </tr>
                </tbody>
              ) : Object.entries(getVehicleTagsReducerInfo.vehicle_tags).length === 0 ||
                getVehicleTagsReducerError !== '' ? (
                <CustomBody>
                  <tr style={{ display: 'none' }}>
                    <td></td>
                  </tr>
                  <CustomTr>
                    <td>
                      <ErrorSpan>{'No tags found'}</ErrorSpan>
                    </td>
                  </CustomTr>
                </CustomBody>
              ) : (
                <tbody>
                  {getVehicleTagsReducerInfo.vehicle_tags.map((item) => (
                    <tr key={item.name}>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </CardBody>
      ) : (
        <CardBody>
          <CardTitleWrap
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <CardTitle>Please enter a tag</CardTitle>
          </CardTitleWrap>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                {addVehicleTagsReducerLoading && (
                  <DotDotLoader loadingState={addVehicleTagsReducerLoading} />
                )}
                <FormGroup>
                  <FormGroupLabel>Tag</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="multiselect"
                      multiple
                      component={renderMultiSelectField}
                      isMulti
                      options={
                        getCompanyTagsReducerInfo
                          ? getCompanyTagsReducerInfo.tags.map(({ tag }) => ({
                              id: tag,
                              value: tag,
                              label: tag,
                            }))
                          : []
                      }
                      placeholder={'Select or create a tag'}
                    />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar
                  style={{
                    width: '50%',
                    paddingRight: '10px',
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{ width: '100%' }}
                    onClick={() => setShowComponent(false)}
                  >
                    Cancel
                  </Button>
                </FormButtonToolbar>
                <FormButtonToolbar
                  style={{
                    width: '50%',
                    marginBottom: '10px',
                    paddingLeft: '10px',
                  }}
                >
                  <Button variant="primary" type="submit" style={{ width: '100%' }}>
                    Add
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
          <div style={{ overflowY: 'auto', height: '45%' }}>
            <Table striped responsive>
              <thead>
                <tr>
                  {tableHeaderData.map((item) => (
                    <th key={item.id}>{item.title}</th>
                  ))}
                </tr>
              </thead>
              {!getVehicleTagsReducerInfo ||
              getVehicleTagsReducerInfo === undefined ||
              getVehicleTagsReducerLoading === true ? (
                <tbody
                  style={{
                    height: '200px',
                  }}
                >
                  <tr style={{ display: 'none' }}>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <DotDotLoader loadingState={getVehicleTagsReducerLoading} />
                    </td>
                  </tr>
                </tbody>
              ) : Object.entries(getVehicleTagsReducerInfo.vehicle_tags).length === 0 ||
                getVehicleTagsReducerError !== '' ? (
                <CustomBody>
                  <tr style={{ display: 'none' }}>
                    <td></td>
                  </tr>
                  <CustomTr>
                    <td>
                      <ErrorSpan>{'No tags found'}</ErrorSpan>
                    </td>
                  </CustomTr>
                </CustomBody>
              ) : (
                <tbody>
                  {getVehicleTagsReducerInfo.vehicle_tags.map((item) => (
                    <tr key={item.name}>
                      <td>
                        <span
                          className="lnr lnr-cross"
                          aria-label="close-btn"
                          type="button"
                          style={{ fontSize: '20px' }}
                          onClick={() => handleRemoveTag(item)}
                        ></span>
                      </td>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </CardBody>
      )}
    </Card>
  );
};

export default VehicleTags;

const ErrorSpan = styled.span`
  color: ${(props) => dashColor(props)};
  text-align: center;
`;

const CustomBody = styled.tbody`
  height: 200px;
`;

const CustomTr = styled.tr`
  height: 200px;
  text-align: center;
`;

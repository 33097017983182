import {
  RESEND_PWD_LOADING,
  RESEND_PWD_SUCCESS,
  RESEND_PWD_FAIL,
  RESEND_PWD_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const resendPwdReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESEND_PWD_LOADING:
      return { ...state, loading: true };
    case RESEND_PWD_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case RESEND_PWD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESEND_PWD_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default resendPwdReducer;

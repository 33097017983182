import axios from 'axios';
import {
  GET_AMBIENT_TEMP_LOADING,
  GET_AMBIENT_TEMP_SUCCESS,
  GET_AMBIENT_TEMP_FAIL,
} from '@/shared/constants/getTempConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '../../config/GlobalUrl';

// export const getAmbientTemperature = (vin) => async (dispatch) => {
//   try {
//     dispatch({
//       type: GET_AMBIENT_TEMP_LOADING,
//     });

//     // setting up the config as headers content-type
//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     };

//     const response = await axios.get(
//       ApiURL.GET_TEMP + vin + '/gateway/signals/AmbientTemperature',
//       config
//     );

//     dispatch({
//       type: GET_AMBIENT_TEMP_SUCCESS,
//       payload: response.data.data.signal,
//     });
//   } catch (error) {
//     if (error.response === undefined) {
//       dispatch({
//         type: GET_AMBIENT_TEMP_FAIL,
//         payload: 'network error',
//       });
//     } else {
//       dispatch({
//         type: GET_AMBIENT_TEMP_FAIL,
//         payload: error.response,
//       });
//     }
//   }
// };

// export const getReturnTemperature = (vin) => async (dispatch) => {
//   try {
//     dispatch({
//       type: GET_RETURN_TEMP_LOADING,
//     });

//     // setting up the config as headers content-type
//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     };

//     const response = await axios.get(ApiURL.GET_TEMP + vin + '/gateway/signals/saloon', config);

//     dispatch({
//       type: GET_RETURN_TEMP_SUCCESS,
//       payload: response.data.data.signal,
//     });
//   } catch (error) {
//     if (error.response === undefined) {
//       dispatch({
//         type: GET_RETURN_TEMP_FAIL,
//         payload: 'network error',
//       });
//     } else {
//       dispatch({
//         type: GET_RETURN_TEMP_FAIL,
//         payload: error.response,
//       });
//     }
//   }
// };

export const temperatureDetails = (vin) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AMBIENT_TEMP_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_TEMP +
        vin +
        '/gateway/signals?names=AmbientTemperature%2CSaloon%2CHVAC_OperatingMinutes&display_type=COMPONENT_ON_OFF',
      getConfig()
    );

    dispatch({
      type: GET_AMBIENT_TEMP_SUCCESS,
      payload: response.data.data.signal,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_AMBIENT_TEMP_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_AMBIENT_TEMP_FAIL,
        payload: error.response,
      });
    }
  }
};

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GATEWAY_CONFIG_LOADING,
  GET_GATEWAY_CONFIG_SUCCESS,
  GET_GATEWAY_CONFIG_FAIL,
  GET_GATEWAY_CONFIG_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getGatewayConfig = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_GATEWAY_CONFIG_LOADING,
    });

    const { data } = await axios.get(ApiURL.GET_GATEWAY_CONFIG, getConfig());
    const custom = data.data.configurations.custom;

    dispatch({
      type: GET_GATEWAY_CONFIG_SUCCESS,
      payload: custom.map((item) => ({
        id: item.name,
        value: item.name,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{item.name}</span>
            <span>Version: {item.version}</span>
          </div>
        ),
        version: item.version,
        data: `${item.name} | Version: ${item.version}`,
      })),
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GATEWAY_CONFIG_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GATEWAY_CONFIG_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGatewayConfigReset = () => (dispatch) => {
  dispatch({
    type: GET_GATEWAY_CONFIG_RESET,
  });
};

export default getGatewayConfig;

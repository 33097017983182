import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import MapFilters from './MapFilters';

const DepotLocation = ({ changeComp, setChangeComp }) => {
  const pinSVGFilled =
    'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
  const theme = useSelector((state) => state.theme.className);
  const history = useHistory();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  const containerStyle = {
    height: '360px',
  };

  const defaultMapOptions = {
    disableDefaultUI: true,
    keyboardShortcuts: false,
    gestureHandling: 'cooperative',
    zoomControl: true,
    styles:
      theme === 'dark'
        ? [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8ec3b9',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1a3646',
                },
              ],
            },
            {
              featureType: 'administrative.country',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878',
                },
              ],
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#64779e',
                },
              ],
            },
            {
              featureType: 'administrative.province',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#4b6878',
                },
              ],
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#334e87',
                },
              ],
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#283d6a',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#6f9ba5',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#3C7680',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#304a7d',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c6675',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#255763',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#b0d5ce',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#023e58',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#98a5be',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1d2c4d',
                },
              ],
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#283d6a',
                },
              ],
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3a4762',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#0e1626',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#4e6d70',
                },
              ],
            },
          ]
        : [],
  };

  const [mapData, setMapData] = useState();
  const [labelOriginFilled, setLabelOriginFilled] = useState();

  const handleOnLoad = (map) => {
    setLabelOriginFilled(new window.google.maps.Point(12, 10));

    const bounds = new window.google.maps.LatLngBounds();
    getGlobalDepotLocationReducer
      ? getGlobalDepotLocationReducer.global_depots_vins_count.forEach((item) =>
          bounds.extend(new window.google.maps.LatLng(item.lat, item.long))
        )
      : bounds.extend(new window.google.maps.LatLng(47.0469167, 12.6557471));

    map.setCenter(bounds.getCenter());
    map.setZoom(2.5);
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const getGlobalDepotLocationReducer = useSelector(
    (state) => state.getGlobalDepotLocationReducer.info
  );

  useEffect(() => {
    if (getGlobalDepotLocationReducer && mapData) {
      handleOnLoad(mapData);
    }
  }, [getGlobalDepotLocationReducer]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);

    const dashboardDetails = {
      fleet: marker.fleet_id,
      fleetName: marker.fleet,
      depot: marker.id,
      depotName: marker.name,
      operatorName: marker.operator_name,
    };
    localStorage.setItem('dashboardDetails', JSON.stringify(dashboardDetails));

    history.push('/global/depot');
  };

  const handleHover = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12} lg={4} sm={12}>
            <CardTitleWrap>
              <CardTitle>GLOBAL DEPOT VIEW</CardTitle>
              <CardSubhead>Depot locations</CardSubhead>
            </CardTitleWrap>
          </Col>
          <Col md={12} lg={8} sm={12}>
            <Row className={'d-flex justify-content-end'}>
              <Col md={10} lg={10} sm={12}>
                <MapFilters changeComp={changeComp} setChangeComp={setChangeComp} />
              </Col>
            </Row>
          </Col>
        </Row>
        {isLoaded && (
          <GoogleMap
            id="depotLocationMap"
            mapContainerStyle={containerStyle}
            onLoad={(map) => {
              handleOnLoad(map);
              setMapData(map);
            }}
            onClick={() => setActiveMarker(null)}
            options={defaultMapOptions}
          >
            {getGlobalDepotLocationReducer && (
              <MarkerClusterer>
                {(cluster) =>
                  getGlobalDepotLocationReducer.global_depots_vins_count.map((marker) => (
                    <Marker
                      key={marker.id}
                      position={{
                        lat: marker.lat,
                        lng: marker.long,
                      }}
                      clusterer={cluster}
                      onClick={() => handleActiveMarker(marker)}
                      onMouseOver={() => handleHover(marker.id)}
                      onMouseOut={() => setActiveMarker(null)}
                      animation={window.google.maps.Animation.DROP}
                      icon={{
                        fillOpacity: 1,
                        path: pinSVGFilled,
                        anchor: new window.google.maps.Point(10, 20),
                        fillColor: `#${marker.color_code}`,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: '#FFFFFF',
                        scale: 1.5,
                        labelOrigin: labelOriginFilled,
                      }}
                    >
                      {marker && activeMarker === marker.id ? (
                        <InfoWindow
                          onCloseClick={() => setActiveMarker(null)}
                          options={{
                            closeBoxURL: '',
                            enableEventPropagation: true,
                          }}
                          position={{
                            lat: marker.lat,
                            lng: marker.long,
                          }}
                        >
                          <MapMarkerContent>
                            <MapMarkerContentText>Depot: {marker.name}</MapMarkerContentText>
                            <MapMarkerContentText>Fleet: {marker.fleet}</MapMarkerContentText>
                          </MapMarkerContent>
                        </InfoWindow>
                      ) : (
                        ''
                      )}
                    </Marker>
                  ))
                }
              </MarkerClusterer>
            )}
          </GoogleMap>
        )}
      </CardBody>
    </Card>
  );
};

export default DepotLocation;

const MapMarkerContent = styled.div`
  font-size: 12px;
  width: 130px;
  min-height: 40px;
  position: relative;
`;

const MapMarkerContentText = styled.p`
  padding: 2px;
  color: #000000;
`;

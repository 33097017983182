import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GLOBAL_DEPOT_LOCATION_LOADING,
  GET_GLOBAL_DEPOT_LOCATION_SUCCESS,
  GET_GLOBAL_DEPOT_LOCATION_FAIL,
} from '@/shared/constants/globalDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getGlobalDepotLocation = (operatorId, spId, fleetId, depotStatus) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GLOBAL_DEPOT_LOCATION_LOADING,
    });

    const getUserDetails = JSON.parse(localStorage.getItem('user'));
    const getCompanyId = getUserDetails.company_id;

    const params = {
      pto_id: operatorId ? operatorId : null,
      service_provider_id: spId ? spId : null,
      fleet_id: fleetId ? fleetId : null,
      color_code: depotStatus ? depotStatus : null,
    };

    const { data } = await axios.get(
      ApiURL.GET_DEPOT_LOCATION + getCompanyId + '/dashboards/global/views/depot',
      { params },
      getConfig()
    );
    dispatch({
      type: GET_GLOBAL_DEPOT_LOCATION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GLOBAL_DEPOT_LOCATION_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GLOBAL_DEPOT_LOCATION_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getGlobalDepotLocation;

import SortIcon from 'mdi-react/SortIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import Tooltip from '@/shared/components/Tooltip.jsx';

export const createArr = (arr) => {
  if (arr) {
    return Object.entries(arr).map((e) => ({
      id: e[0],
      value: e[1],
      label: e[1],
    }));
  } else {
    return [];
  }
};

export const percentage = (partialValue, totalValue) => {
  if (!partialValue || !totalValue) return 0;
  return Math.round((100 * partialValue) / totalValue);
};

export const sortingFunc = (sortData) => {
  if (sortData && sortData.column) {
    if (sortData.column.isSortedDesc === true) {
      return `-${sortData.column.id}`;
    } else if (sortData.column.isSortedDesc === false) {
      return `${sortData.column.id}`;
    } else {
      return '';
    }
  } else {
    return '-triggered_at';
  }
};

export const GetTableHeader = ({ headerName, onClick, item }) => {
  const tooltipText =
    item.column.isSortedDesc === undefined
      ? 'Toggle Sorting'
      : item.column.isSortedDesc
      ? 'Descending'
      : 'Ascending';
  return (
    <Tooltip dir={'rtl'} text={tooltipText}>
      <span onClick={!item.disableSortBy ? onClick : null} style={{ width: '100%' }}>
        <span>
          <span style={{ marginRight: '5px' }}>{headerName}</span>
          {item.column.isSortedDesc === undefined ? (
            <SortIcon />
          ) : (
            <span>{item.column.isSortedDesc ? <SortDescendingIcon /> : <SortAscendingIcon />}</span>
          )}
        </span>
      </span>
    </Tooltip>
  );
};

export const slugify = (str) => {
  if (str) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    return str;
  } else {
    return '';
  }
};

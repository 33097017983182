import {
  GET_GATEWAY_UNITS_BY_BRAND_LOADING,
  GET_GATEWAY_UNITS_BY_BRAND_SUCCESS,
  GET_GATEWAY_UNITS_BY_BRAND_FAIL,
} from "../../../../shared/constants/OtaUpdateConstants/otaDashboardConstants";

const initialState = {
  loading: false,
  info: "",
  error: "",
};
const getGatewayUnitsByBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY_UNITS_BY_BRAND_LOADING:
      return { ...state, loading: true };
    case GET_GATEWAY_UNITS_BY_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: "",
      };
    case GET_GATEWAY_UNITS_BY_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getGatewayUnitsByBrandReducer;

import { useState, useMemo } from 'react';
import styled from 'styled-components';
import PageNextIcon from 'mdi-react/PageNextOutlineIcon';
import { colorTextAdditional } from '@/utils/palette';
import { GetTableHeader } from '@/utils/helpers';
import CustomProgressBar from './CustomProgressBar';

const CreateTableData = () => {
  const [sort, setSort] = useState();
  const [goToDetails, setGoToDetails] = useState(false);
  const [deviceId, setDeviceId] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const changeComponent = (props) => {
    setDeviceId(props.row.original);
    setGoToDetails(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          return <StyledPageNextIcon onClick={() => changeComponent(props)} />;
        },
      },
      {
        Header: 'Device Status',
        accessor: '',
        disableGlobalFilter: true,
        Cell: (props) => {
          const progressData = {
            total: props.row.original.total_devices ? props.row.original.total_devices : 0,
            pending: props.row.original.pending_devices ? props.row.original.pending_devices : 0,
            completed: props.row.original.successful_devices
              ? props.row.original.successful_devices
              : 0,
            failed: props.row.original.failed_devices ? props.row.original.failed_devices : 0,
          };
          return <CustomProgressBar progressData={progressData} />;
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Campaign Name'}
            key={'Campaign Name'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Package Name'}
            key={'Package Name'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'package_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depot_name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Group'}
            key={'Group'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'group_label',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Deployment type'}
            key={'Deployment type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'deployment_type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Version'}
            key={'Version'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'software_version',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Submission time'}
            key={'Submission time'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'submission_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Scheduled at'}
            key={'Scheduled at'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'scheduled_at',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Campaign Status'}
            key={'Campaign Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
    ],
    []
  );
  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
    setGoToDetails: setGoToDetails,
    goToDetails: goToDetails,
    deviceId: deviceId,
  };
  return reactTableData;
};

export default CreateTableData;

const StyledPageNextIcon = styled(PageNextIcon)`
  cursor: pointer;
  color: ${colorTextAdditional};
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  CREATE_GATEWAY_PACKAGE_LOADING,
  CREATE_GATEWAY_PACKAGE_SUCCESS,
  CREATE_GATEWAY_PACKAGE_FAIL,
  CREATE_GATEWAY_PACKAGE_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { errorFunction, successMessage } from '@/shared/helpers/alertMessages';
import { package_creation } from '@/shared/helpers/successMessages';
import Path from '@/utils/routes/routePathNames';

const createGatewayPackage = (formValues, history) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_GATEWAY_PACKAGE_LOADING,
    });

    const body = {
      group_name: formValues.group.value,
      type: formValues.device_type,
      custom_config_name: formValues.config_set.value,
      custom_config_version: formValues.config_set.version,
      depot_id: formValues.depot.id,
      label: formValues.gateway_package_name,
      base_firmware_version: formValues.base_firmware_version.value,
    };
    const response = await axios.post(ApiURL.CREATE_GATEWAY_PACKAGE, body, getConfig());

    dispatch({
      type: CREATE_GATEWAY_PACKAGE_SUCCESS,
      payload: response.data.data,
    });
    successMessage(package_creation.create_gateway_package);
    history.push(Path.PACKAGE_LISTING);
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CREATE_GATEWAY_PACKAGE_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: CREATE_GATEWAY_PACKAGE_FAIL,
        payload: error.response,
      });
    }
  }
};

export const createGatewayPackageReset = () => (dispatch) => {
  dispatch({
    type: CREATE_GATEWAY_PACKAGE_RESET,
  });
};

export default createGatewayPackage;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import PrivateRoute from '../../../utils/routes/privateRoutes';
import PublicRoute from '../../../utils/routes/publicRoutes';
import SignIn from '../../Account/SignIn';

const Router = () => {
  return (
    <MainWrapper>
      <main>
        <Switch>
          <PublicRoute exact path="/" component={SignIn} />
          <PublicRoute path="/login" component={SignIn} />
          <PrivateRoute>
            <Route path="/" component={WrappedRoutes} />
          </PrivateRoute>
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;

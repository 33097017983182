import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditIcon from 'mdi-react/EditIcon';
import { colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const RegisteredDevicesContainer = ({ img, deviceType, manufacturer, serialNum, showEdit }) => (
  <RegisteredDevices>
    <RegisteredDeviceImage>
      <img src={img} alt="" />
    </RegisteredDeviceImage>
    <div>
      <RegisteredDeviceManufacture>
        {manufacturer}
        {' | '}
      </RegisteredDeviceManufacture>
      <RegisteredDeviceType>{deviceType}</RegisteredDeviceType>
      <SerialNum>{serialNum}</SerialNum>
    </div>
    <DeviceLink>{showEdit && <EditIcon />}</DeviceLink>
  </RegisteredDevices>
);

RegisteredDevicesContainer.propTypes = {
  deviceType: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  serialNum: PropTypes.string.isRequired,
  img: PropTypes.string,
};

export default RegisteredDevicesContainer;

// region STYLES

const RegisteredDevices = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const DeviceLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
  color: #1368b1;

  // svg {
  //   ${marginRight}: 0;
  //   margin-top: 2px;
  //   height: 16px;
  //   width: 16px;
  // }
`;

const RegisteredDeviceType = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  display: inline;
`;

const RegisteredDeviceManufacture = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  display: inline;
`;

const SerialNum = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
`;

const RegisteredDeviceImage = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

// endregion

const SERVER_BASE_URL = 'https://eberspacher-web-api-etm.uat.orahi.com';
export const WEBSOCKET_BASE_URL = 'wss://etm-websocket.uat.orahi.com';
export const WEBSOCKET_PORT = '8764';
// Local server address
// const SERVER_BASE_URL = 'http://192.168.0.116:8001';

export class ApiURL {
  // User Login
  static LOGIN = SERVER_BASE_URL + '/api/v1/users/auth/send_otp/';
  // User Login OTP
  static LOGIN_OTP = SERVER_BASE_URL + '/api/v1/users/auth/verify_otp/';
  // Sign In
  static SIGN_IN = SERVER_BASE_URL + '/api/v1/users/login';
  // Reset Pwd
  static RESET_PWD = SERVER_BASE_URL + '/api/v1/users/resetpassword';
  // Forgot Pwd
  static FORGOT_PWD = SERVER_BASE_URL + '/api/v1/users/forget_password';
  // Resend Pwd
  static RESEND_PWD = SERVER_BASE_URL + '/api/v1/users/';

  // Get Assets Count
  static GET_ASSETS_COUNT = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get a Vehicle Details
  static GET_VEHICLE_DETAILS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register Vehicle
  static VEHICLE_REGISTER = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register tags to a vehicle
  static REGISTER_TAGS = SERVER_BASE_URL + '/api/v1/vehicles/';
  //Remove vehicle tag
  static REMOVE_VEHICLE_TAG = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // GET OP FLEETS
  static GET_OP_FLEETS = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // GET DEPOT TAGS
  static GET_DEPOT_TAGS = SERVER_BASE_URL + '/api/v1/companies/depo_id/';

  // Get components list
  static GET_VEHICLE_COMPONENT_LIST = SERVER_BASE_URL + '/api/v1/hvac/components/list';
  // Get HVAC components count
  static GET_HVAC_COMPONENT_COUNT = SERVER_BASE_URL + '/api/v1/hvac/';
  // Get eberspacher component serial number
  static GET_COMP_SERIAL_NUMBER = SERVER_BASE_URL + '/api/v1/hvac/components/eberspacher/';
  // Get supplier components list
  static GET_SUPPLIER_COMP_LIST = SERVER_BASE_URL + '/api/v1/hvac/components/suppliers/details';
  // Get components linked with HVAC
  static GET_COMPS = SERVER_BASE_URL + '/api/v1/hvac/';
  // Add devices
  static ADD_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';
  // Edit devices
  static EDIT_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';
  // Delete devices
  static DELETE_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';

  // Register Gateway
  static GATEWAY_REGISTER = SERVER_BASE_URL + '/api/v1/gateway/register';
  // Get Gateway Details
  static GET_GATEWAY = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register Sim
  static SIM_REGISTER = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Edit Gateway Serial Number
  static EDIT_GATEWAY = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register CPU Number
  static CPU_REGISTER = SERVER_BASE_URL + '/api/v1/gateway/master_gateway_register';
  // Get CPU Number List
  static GET_CPU_NUMBER_LIST = SERVER_BASE_URL + '/api/v1/gateway/master_gateway_list';
  // Register Sim Details
  static SIM_REGISTER_MASTER = SERVER_BASE_URL + '/api/v1/gateway/master_sim_register';
  // Get Sim Master List
  static GET_SIM_MASTER_LIST = SERVER_BASE_URL + '/api/v1/gateway/master_sim_list';

  // Get Roles
  static GET_ROLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Fleet
  static GET_FLEETS = SERVER_BASE_URL + '/api/v1/companies/operators/';
  // Get Depot
  static GET_DEPOT = SERVER_BASE_URL + '/api/v1/companies/operators/';

  // Vehicle Dashboard
  // Get temperature
  static GET_TEMP = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Components Status
  static GET_COMP_STATUS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Vehicle Location
  static GET_VEHICLE_LOCATION = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Last Signal Timestamp
  static GET_SIGNAL_TIMESTAMP = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Vehicle Tags
  static GET_VEHICLE_TAGS = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Company Tags
  static GET_COMPANY_TAGS = SERVER_BASE_URL + 'api/v1/companies/';
  // Add Vehicle Tags
  static ADD_VEHICLE_TAGS = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Vehicle Alarms
  static GET_VEHICLE_ALARMS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Close Vehicle Alarm
  static CLOSE_VEHICLE_ALARM = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Search Vin Number
  static SEARCH_VIN_NUMBER = SERVER_BASE_URL + '/api/v1/companies/';

  // Depot Dashboard
  // Get Total Vehicles
  static GET_TOTAL_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Health Status Summary
  static GET_HEALTH_STATUS_SUMMARY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Hvac Units By Brand
  static GET_HVAC_UNITS_BY_BRAND = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Hvac Units By Type
  static GET_HVAC_UNITS_BY_TYPE = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Number Of Vehicles With Gateway
  static GET_VEHICLES_WITH_GATEWAY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Vehicle Status
  static GET_DEPOT_VEHICLE_STATUS = SERVER_BASE_URL + '/api/v1/companies/vehicle_group/company_id/';

  // Global Dashboard
  // Get Total Company Vehicles
  static GET_TOTAL_COMPANY_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Global Health Summary
  static GET_GLOBAL_HEALTH_SUMMARY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Location
  static GET_DEPOT_LOCATION = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Health Status
  static GET_DEPOT_HEALTH_STATUS = SERVER_BASE_URL + '/api/v1/companies/';
  // Get map filters
  static GET_MAP_FILTERS = SERVER_BASE_URL + '/api/v1/companies/associations/actives';

  // Health Status Dashboard
  // Get Alarm Count
  static GET_ALARM_COUNT = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Health Status Detail
  static GET_HEALTH_STATUS_DETAIL = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get Alarm Checklist
  static GET_ALARM_CHECKLIST = SERVER_BASE_URL + '/api/v1/vehicles/';

  // Company Registeration
  // Get Registered Company
  static GET_REGISTERED_COMPANY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Company List
  static GET_COMPANY_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Add Company
  static ADD_COMPANY = SERVER_BASE_URL + '/api/v1/companies/company/register';
  // Update Company
  static UPDATE_COMPANY_DETAILS = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Company Relation
  static GET_COMPANY_RELATION = SERVER_BASE_URL + '/api/v1/companies/companies/fetch';
  static REGISTER_COMPANY_RELATION = SERVER_BASE_URL + '/api/v1/companies/companies/fetch';

  // Fleet Registration
  // Add Fleet
  static REGISTER_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Fleet
  static GET_REGISTERED_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Fleet List
  static GET_FLEET_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  //Update Fleet
  static UPDATE_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';

  // Depot Registration
  // Add Depot
  static REGISTER_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Depot
  static GET_REGISTERED_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Depot List
  static GET_DEPOT_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get Depot LIst By Company
  static GET_DEPOT_LIST_BY_COMPANY = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Update Depot
  static UPDATE_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';

  // User Registration
  // Add User
  static REGISTER_USER = SERVER_BASE_URL + '/api/v1/users/';
  // Get User
  static GET_REGISTERED_USER = SERVER_BASE_URL + '/api/v1/users/company/';
  // Get User List
  static GET_USER_LIST = SERVER_BASE_URL + '/api/v1/users/company/';
  // Update User
  static UPDATE_USER = SERVER_BASE_URL + '/api/v1/users/company/';

  // System Settings
  static GET_SYS_SETTINGS_COUNT = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Company System Settings Count
  static GET_COMPANY_SYS_SETTINGS_COUNT = SERVER_BASE_URL + '/api/v1/companies/company_id/';

  // Get Role List
  static GET_ROLE_LIST = SERVER_BASE_URL + '/api/v1/core/role';

  // Manage Notifications
  // Get Depot List
  static GET_DEPOT_LIST_NOTI = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Vehicles
  static GET_DEPOT_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get User List
  static GET_USER_LIST_NOTI = SERVER_BASE_URL + '/api/v1/users/company/';
  // Get selected users and vehicles
  static GET_SELECTED_USER_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  //create notifications
  static POST_NOTIFICATIONS = SERVER_BASE_URL + '/api/v1/companies/';
  //get notification list
  static GET_NOTIFICATION_LIST = SERVER_BASE_URL + '/api/v1/companies/notifications/list';

  // Diagnostics
  // Get Signals Request
  static GET_SIGNALS = SERVER_BASE_URL + '/api/v1/gateway/signal_list';
  // Get Diagnostics File
  static GET_DIAGNOSTICS_FILE = SERVER_BASE_URL + '/api/v1/gateway/diagnostic_download';
  // Send CAN Interval
  static SEND_CAN_INTERVAL = SERVER_BASE_URL + '/api/v1/gateway/diagnostics/can_dump';
  // Get Diagnostic Listing
  static GET_DIAGNOSTICS_LISTING =
    SERVER_BASE_URL + '/api/v1/gateway/diagnostics/list?type=CAN_DUMP';
  // Delete CAN File
  static DELETE_CAN_FILE = SERVER_BASE_URL + '/api/v1/gateway/diagnostics/';

  //Download Index Error code
  static DOWNLOAD_INDEX_ERROR_CODE = SERVER_BASE_URL + '/api/v1/gateway/index_error_code';
  //Maintenance
  // Get Preventive Maintenance
  static GET_PREVENTIVE_MAINTENANCE = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Add Preventive Maintenance
  static ADD_PREVENTIVE_MAINTENANCE = SERVER_BASE_URL + '/api/v1/vehicles/';

  // Gateway Diagnostics
  // Get Gateway List
  static GET_GATEWAY_DIAGNOSTICS_LIST = SERVER_BASE_URL + '/api/v1/diagnostics/cpu_ids/list';
  // Get Gateway Diagnostics Listing
  static GET_GATEWAY_DIAGNOSTICS_LISTING = SERVER_BASE_URL + '/api/v1/diagnostics';
  // Download Gateway Diagnostics File
  static DOWNLOAD_GATEWAY_DIAGNOSTICS_FILE = SERVER_BASE_URL + '/api/v1/diagnostics/download/';
  // Get Gateway Diagnostics File For Month
  static GET_GATEWAY_DIAGNOSTICS_FILE_FOR_MONTH = SERVER_BASE_URL + '/api/v1/diagnostics/gateways/';

  static DOWNLOAD_GATEWAY_DIAGNOSTICS_FILE_FOR_MONTH = SERVER_BASE_URL + '/api/v1/diagnostics/';

  // OTA Update - Package Management - Econtrol Package Creation
  // Get Group List
  static GET_GROUP_LIST = SERVER_BASE_URL + '/api/v1/otas/depots/';
  // Upload Package File Check
  static UPLOAD_PACKAGE_FILE_CHECK = SERVER_BASE_URL + '/api/v1/otas/packages/e-control/upload';
  // Create Package
  static CREATE_PACKAGE = SERVER_BASE_URL + '/api/v1/otas/packages/e-control/upload';
  // Get Package Listing
  static GET_PACKAGE_LISTING = SERVER_BASE_URL + '/api/v1/otas/packages/list';

  //OTA Update - Package Management - Gateway Package Creation
  // Get Base Firmware Version
  static GET_BASE_FIRMWARE_VERSION = SERVER_BASE_URL + '/api/v1/otas/base_firmware/list';
  // Get Gateway Config
  static GET_GATEWAY_CONFIG = SERVER_BASE_URL + '/api/v1/otas/configurations';
  // Create Gateway Package
  static CREATE_GATEWAY_PACKAGE = SERVER_BASE_URL + '/api/v1/otas/packages/gateway/create';

  // OTA Update - Package Management
  static DOWNLOAD_PACKAGE = SERVER_BASE_URL + '/api/v1/otas/packages/';

  // OTA Update - Campaign Management
  // Get Hvac List
  static GET_HVAC_LIST = SERVER_BASE_URL + '/api/v1/otas/hvacs/components/list';
  // Get Campagin Listing
  static GET_CAMPAIGN_LISTING = SERVER_BASE_URL + '/api/v1/otas/campaigns/list';
  // Get Campagin Device Status Listing
  static GET_CAMPAIGN_DEVICE_STATUS_LISTING = SERVER_BASE_URL + '/api/v1/otas/campaigns/';
  // Create Campaign
  static CREATE_CAMPAIGN = SERVER_BASE_URL + '/api/v1/otas/campaigns/submit';
  // Get package list and versions and metadata
  static GET_PACHAKAGES_METADATA = SERVER_BASE_URL + '/api/v1/otas/depots';
  // Servie Mode Enable
  static SERVICE_MODE_ENABLE = SERVER_BASE_URL + '/api/v1/gateway/command/service_mode';
  // Get Service Mode Device Status
  static GET_SERVICE_MODE_DEVICE_STATUS =
    SERVER_BASE_URL + '/api/v1/gateway/command/service_mode/status';

  // OTA Update - Dashboard
  static GET_GATEWAY_BY_BRAND = SERVER_BASE_URL + '/api/v1/otas/counts';
  static GET_PACKAGE_COUNT = SERVER_BASE_URL + '/api/v1/otas/counts';
  static GET_GROUP_LISTING = SERVER_BASE_URL + '/api/v1/otas/groups/list/v2';
  static GET_GROUP_DETAILS_LISTING = SERVER_BASE_URL + '/api/v1/otas/groups/';

  // OTA Update - Group Management - Econtrol
  // Get Hvac Models
  static GET_HVAC_MODELS = SERVER_BASE_URL + '/api/v1/otas/depots/';
  // Create Group
  static CREATE_GROUP = SERVER_BASE_URL + '/api/v1/otas/group/create';
  // Update Group
  static UPDATE_GROUP = SERVER_BASE_URL + '/api/v1/otas/group/update';
  // Get Group Device List By Modal
  static GET_GROUP_DEVICE_LIST_BY_MODAL = SERVER_BASE_URL + '/api/v1/otas/depots/';
  // Get Group Device List Others
  static GET_GROUP_DEVICE_LIST_BY_OTHERS =
    SERVER_BASE_URL + '/api/v1/otas/groups/econtrol/list_devices';
  // Get Group Details
  static GET_GROUP_DETAILS = SERVER_BASE_URL + '/api/v1/otas/group/details';

  // OTA Update - Group Management - Gateway
  // Get Gateway Group List
  static GET_GATEWAY_DEVICE_LIST = SERVER_BASE_URL + '/api/v1/otas/groups/gateway/list_devices';
  // Create Gateway Group
  static CREATE_GATEWAY_GROUP = SERVER_BASE_URL + '/api/v1/otas/group/create';
  // Update Gateway Group
  static UPDATE_GATEWAY_GROUP = SERVER_BASE_URL + '/api/v1/otas/group/update';

  //User Profile
  //Get User Profile
  static GET_USER_PROFILE = SERVER_BASE_URL + '/api/v1/users';
  //Change Password
  static CHANGE_PASSWORD = SERVER_BASE_URL + '/api/v1/users';
}

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import SimpleLoader from '@/shared/components/SimpleLoader';
import VehicleDetails from './components/VehicleDetails';
import VehicleTemperature from './components/VehicleTemperature';
import VehicleRegisteredHvacs from './components/VehicleRegisteredHvacs';
import GatewayLastTimestamp from './components/GatewayLastTimestamp';
import VehicleLocation from './components/VehicleLocation';
import VehicleHvacCompStatus from './components/VehicleHvacCompStatus';
import VehicleTags from './components/VehicleTags';
import VehicleHealthStatus from './components/VehicleHealthStatus';
import CreateTableData from './components/CreateData';
import VinSearch from '../VinSearch';

const VehicleDashboard = () => {
  const reactTableData = CreateTableData();
  const dispatch = useDispatch();
  const [reset, setreset] = useState(false);
  const propVin = JSON.parse(localStorage.getItem('viewVin'));

  const [compRefresh, setCompRefresh] = useState(false);

  useEffect(() => {
    if (compRefresh === true) {
      setCompRefresh(false);
    }
  }, [compRefresh]);

  useEffect(() => {
    setreset(true);
  }, [propVin]);

  return (
    <Container>
      <Row className={'d-flex justify-content-end'} style={{ marginTop: '-40px' }}>
        <VinSearch setChangeComp={setCompRefresh} reset={reset} />
      </Row>
      {!propVin ? (
        <SimpleLoader />
      ) : (
        <>
          <Row style={{ paddingTop: '30px' }}>
            <Col md={6} lg={4} sm={12}>
              <VehicleDetails vin={propVin} />
            </Col>
            <Col md={6} lg={2} sm={12}>
              <VehicleTemperature vin={propVin} />
            </Col>
            <Col md={6} lg={2} sm={12}>
              <GatewayLastTimestamp vin={propVin} />
            </Col>
            <Col md={6} lg={4} sm={12}>
              <VehicleRegisteredHvacs vin={propVin} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} sm={12}>
              <VehicleLocation vin={propVin} />
            </Col>
            <Col md={6} lg={4} sm={12}>
              <VehicleHvacCompStatus vin={propVin} />
            </Col>
            <Col md={6} lg={4} sm={12}>
              <VehicleTags vin={propVin} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} sm={12}>
              <VehicleHealthStatus reactTableData={reactTableData} vin={propVin} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default VehicleDashboard;

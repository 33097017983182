import React from 'react';
import styled from 'styled-components';
import { colorIcon, colorDarkText } from '@/utils/palette';
import { right } from '@/utils/directions';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { colorBackground, colorText } from '@/utils/palette';
import { Button } from '@/shared/components/Button';

const DCAlarmModal = ({ openModalState, closeModalState, alarmDetails }) => {
  const handleClose = () => closeModalState(false);

  return (
    <StyledModal
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalState}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <StyledModalHeader>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={handleClose}
        />
        <StyledModalTitle>
          {alarmDetails && alarmDetails.status === 'open' ? 'Alarm Open' : 'Alarm Closed'}
        </StyledModalTitle>
      </StyledModalHeader>
      <StyledModalBody>
        {alarmDetails ? (
          <div style={{ fontSize: '13px' }}>
            <div style={{ textAlign: 'left' }}>
              <p>VIN: {alarmDetails.vin}</p>
              <p>Alarm Id: {alarmDetails.id}</p>
              <p>Alarm type: {alarmDetails.type}</p>
              <p>
                Alarm opening time:{' '}
                {alarmDetails.triggered_at.split('T')[1].split('.')[0] +
                  ', ' +
                  alarmDetails.triggered_at.split('T')[0]}
              </p>
              {alarmDetails.type === 'Data' ? (
                <>
                  <p>
                    Alarm details:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>Display Name: {alarmDetails.metadata.display_name}</li>
                      <li>Id: {alarmDetails.metadata.alarm_id}</li>
                      <li>Device: {alarmDetails.metadata.device}</li>
                      <li>Cause: {alarmDetails.metadata.cause}</li>
                      <li>Signal Id: {alarmDetails.metadata.signal_id}</li>
                      {'description' in alarmDetails.metadata && (
                        <li>Description: {alarmDetails.metadata.description}</li>
                      )}
                      {'error_code' in alarmDetails.metadata && (
                        <li>Error Code: {alarmDetails.metadata.error_code}</li>
                      )}
                    </ul>
                  </p>
                </>
              ) : alarmDetails.type === 'DTC' ? (
                <>
                  <p>Alarm details: </p>
                  <ul style={{ listStyleType: 'none' }}>
                    {Object.entries(alarmDetails.metadata).map(([key, value]) => (
                      <li key={key}>
                        {key}: {value.toString()}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p>Alarm details: {alarmDetails.metadata.display_name}</p>
              )}
            </div>
          </div>
        ) : (
          'Error loading alarm details'
        )}
      </StyledModalBody>
      <StyledModalFooter>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{
            marginBottom: '0',
            textAlign: 'center',
            width: '145px',
            margin: '0',
          }}
        >
          Cancel
        </Button>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default DCAlarmModal;

// region STYLES

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    min-width: 20vw;
    // margin-top: 8px
  }
  .modal-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: ${colorBackground};
    color: ${colorText};
  }
`;

const StyledModalHeader = styled(BootstrapModal.Header)`
  display: block;
  padding: 30px 25px 0 25px;
  font-size: 16px;
  border: 0;
  color: #646777;
  text-align: center;
`;

const StyledModalTitle = styled(BootstrapModal.Title)`
  font-size: 16px;
`;

const StyledModalBody = styled(BootstrapModal.Body)`
  padding-right: 25px;
  padding-left: 25px;
  min-height: 10vh;
  overflow: auto;
  text-align: center;
`;

const StyledModalFooter = styled(BootstrapModal.Footer)`
  border: 0;
  display: block;
  text-align: center;
  padding: 20px 0 30px 0;
  margin: 0;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};

  &:hover {
    color: ${colorDarkText};
  }
`;

// endregion

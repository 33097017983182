import {
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_RESET,
} from "../../../shared/constants/CompanyConstants/getRegisteredCompanyConstants";

const initialState = {
  loading: false,
  info: "",
  error: "",
};

const getRegisteredCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_LOADING:
      return { ...state, loading: true };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COMPANY_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getRegisteredCompanyReducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getGatewayListingAction from '@/redux/actions/OtaUpdateActions/OtaDashboardActions/getGatewayListingAction';

const GroupListing = ({ reactTableData }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const { info, loading, error, pagination, filters } = useSelector(
    (state) => state.getGatewayListingReducer
  );

  const tableData = info && info.groups_list ? info.groups_list : [];

  useEffect(() => {
    dispatch(
      getGatewayListingAction('', '', '', '', '', pageNumber, numberofRecords, reactTableData.sort)
    );
  }, [reactTableData.sort]);

  //Need fetchApiData to make api call everytime page is changed
  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getGatewayListingAction(
        filters?.operator,
        filters?.fleet,
        filters?.depot,
        filters?.group,
        filters?.type,
        pageIndex + 1,
        pageSize,
        reactTableData.sort
      )
    );
  };

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Group Details</CardTitle>
        </CardTitleWrap>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={tableData}
          tableConfig={tableConfig}
          paginationDetail={pagination}
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Diagnostics'}
        />
        {loading ? (
          ''
        ) : tableData && tableData.length === 0 ? (
          <TableNoData errorText={'No Data Found'} />
        ) : error ? (
          <TableNoData errorText={'Error'} />
        ) : (
          ''
        )}
      </CardBody>
    </Card>
  );
};

GroupListing.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default GroupListing;

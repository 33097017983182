import React from 'react';
import styled from 'styled-components';
import { colorIcon, colorDarkText } from '@/utils/palette';
import { right } from '@/utils/directions';
import {
  StyledDCModal,
  StyledDCModalHeader,
  StyledDCModalTitle,
  StyledDCModalBody,
  StyledDCModalFooter,
} from '../HealthStatusDashboardElements';
import { Button } from '@/shared/components/Button';

const DCAlarmModal = ({ openModalState, closeModalState, alarmDetails }) => {
  const handleClose = () => closeModalState(false);

  return (
    <StyledDCModal
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalState}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <StyledDCModalHeader>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={handleClose}
        />
        <StyledDCModalTitle>
          {alarmDetails && alarmDetails.status === 'open' ? 'Alarm Open' : 'Alarm Closed'}
        </StyledDCModalTitle>
      </StyledDCModalHeader>
      <StyledDCModalBody>
        {alarmDetails ? (
          <div style={{ fontSize: '13px' }}>
            <div style={{ textAlign: 'left' }}>
              <p>VIN: {alarmDetails.vin}</p>
              <p>Alarm Id: {alarmDetails.id}</p>
              <p>Alarm type: {alarmDetails.type}</p>
              <p>
                Alarm opening time:{' '}
                {alarmDetails.triggered_at.split('T')[1].split('.')[0] +
                  ', ' +
                  alarmDetails.triggered_at.split('T')[0]}
              </p>
              {alarmDetails.status !== 'open' && (
                <>
                  <p>
                    Alarm closed at:{' '}
                    {alarmDetails.closed_at.split('T')[1].split('.')[0] +
                      ', ' +
                      alarmDetails.closed_at.split('T')[0]}
                  </p>
                  <p>Alarm closed by: {alarmDetails.closed_by}</p>
                </>
              )}
              {alarmDetails.type === 'Data' ? (
                <>
                  <p>Alarm details: </p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Display Name: {alarmDetails.metadata.display_name}</li>
                    <li>Id: {alarmDetails.metadata.alarm_id}</li>
                    <li>Device: {alarmDetails.metadata.device}</li>
                    <li>Cause: {alarmDetails.metadata.cause}</li>
                    <li>Signal Id: {alarmDetails.metadata.signal_id}</li>
                    {'description' in alarmDetails.metadata && (
                      <li>Description: {alarmDetails.metadata.description}</li>
                    )}
                    {'error_code' in alarmDetails.metadata && (
                      <li>Error Code: {alarmDetails.metadata.error_code}</li>
                    )}
                  </ul>
                </>
              ) : alarmDetails.type === 'DTC' ? (
                <>
                  <p>Alarm details: </p>
                  <ul style={{ listStyleType: 'none' }}>
                    {Object.entries(alarmDetails.metadata).map(([key, value]) => (
                      <li key={key}>
                        {key}: {value.toString()}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p>Alarm details: {alarmDetails.metadata.display_name}</p>
              )}
            </div>
          </div>
        ) : (
          'Error loading alarm details'
        )}
      </StyledDCModalBody>
      <StyledDCModalFooter>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{
            marginBottom: '0',
            textAlign: 'center',
            width: '145px',
            margin: '0',
          }}
        >
          Cancel
        </Button>
      </StyledDCModalFooter>
    </StyledDCModal>
  );
};

export default DCAlarmModal;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};

  &:hover {
    color: ${colorDarkText};
  }
`;

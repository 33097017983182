import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getCampaignListing from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getCampaignListing';

const CampaignListing = ({ reactTableData }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const { loading, info, error, pagination } = useSelector(
    (state) => state.getCampaignListingReducer
  );

  const tableData = info && info.campaigns ? info.campaigns : [];

  useEffect(() => {
    dispatch(getCampaignListing(pageNumber, numberofRecords, reactTableData.sort));
  }, [reactTableData.sort]);

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(getCampaignListing(pageIndex + 1, pageSize, reactTableData.sort));
  };

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Campaign Management Listing</CardTitle>
        </CardTitleWrap>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={tableData}
          tableConfig={tableConfig}
          paginationDetail={pagination}
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'CampaignTable'}
        />
        {loading ? (
          ''
        ) : tableData && tableData.length === 0 ? (
          <TableNoData errorText={'No Data Found'} />
        ) : error ? (
          <TableNoData errorText={'Error'} />
        ) : (
          ''
        )}
      </CardBody>
    </Card>
  );
};

export default CampaignListing;

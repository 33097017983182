import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  CREATE_GROUP_LOADING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  CREATE_GROUP_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { slugify } from '@/utils/helpers';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const createGroup = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_GROUP_LOADING,
    });
    const body = {
      name:
        formValues.hvac_model.value !== 'Custom'
          ? `${slugify(formValues.hvac_model.value)}@econtrol@${formValues.depot.id}`
          : `${slugify(formValues.group_name)}@econtrol@${formValues.depot.id}`,
      depot_id: formValues.depot.id,
      type: formValues.device_type,
      device_ids: formValues.device_name.map((item) => item.value),
      checks:
        formValues.hvac_model.value === 'Custom' ? 'open' : 'same_model_type_of_roof_top_unit',
      label: formValues.group_name,
      model: formValues.hvac_model.value,
    };

    const response = await axios.post(ApiURL.CREATE_GROUP, body, getConfig());
    successMessage('Group created successfully!');
    dispatch({
      type: CREATE_GROUP_SUCCESS,
      payload: response.data.data,
    });
    return { data: response.data.data };
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CREATE_GROUP_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: CREATE_GROUP_FAIL,
        payload: error.response,
      });
    }
  }
};

export const createGroupReset = () => async (dispatch) => {
  dispatch({
    type: CREATE_GROUP_RESET,
  });
};

export default createGroup;

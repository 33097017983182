import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckCircleOutline from 'mdi-react/CheckCircleOutlineIcon';
import CloseIconOutline from 'mdi-react/CloseCircleOutlineIcon';
import MinusCircleOutlineIcon from 'mdi-react/MinusCircleOutlineIcon';
import { renderComponentField } from '@/shared/components/form/FormField';
import { colorAccent, colorAccentHover, colorIcon, colorText, colorWhite } from '@/utils/palette';
import { left, paddingLeft, marginLeft } from '@/utils/directions';

const RadioButton = ({
  onChange,
  radioValue,
  styleType,
  disabled,
  label,
  name,
  value,
  val,
  bgcolor,
}) => {
  const handleChange = () => {
    onChange(radioValue);
  };

  return (
    <RadioButtonWrap
      disabled={disabled}
      styleType={styleType}
      bgcolor={bgcolor}
      style={
        bgcolor && (val === radioValue || value === radioValue)
          ? { background: colorAccentHover }
          : disabled &&
            (val === radioValue || value === radioValue) &&
            (styleType === 'button' || styleType === 'button2' || styleType === 'button3')
          ? { background: colorAccentHover }
          : { background: '' }
      }
    >
      <RadioButtonInput
        name={name}
        type='radio'
        onChange={handleChange}
        checked={val === radioValue || value === radioValue}
        disabled={disabled}
      />
      <RadioButtonCustom />
      {styleType === 'button' ? (
        <RadioButtonSvgWrap>
          <CheckCircleOutline />
          <CheckCircleOutline />
        </RadioButtonSvgWrap>
      ) : styleType === 'button2' ? (
        <RadioButtonSvgWrap>
          <CloseIconOutline />
          <CloseIconOutline />
        </RadioButtonSvgWrap>
      ) : styleType === 'button3' ? (
        <RadioButtonSvgWrap>
          <MinusCircleOutlineIcon />
          <MinusCircleOutlineIcon />
        </RadioButtonSvgWrap>
      ) : (
        ''
      )}

      <RadioButtonLabel>{label}</RadioButtonLabel>
    </RadioButtonWrap>
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  radioValue: PropTypes.any,
  styleType: PropTypes.string,
  bgcolor: PropTypes.bool,
};

RadioButton.defaultProps = {
  label: '',
  disabled: false,
  radioValue: '',
  styleType: '',
  bgcolor: false,
};

export default renderComponentField(RadioButton);

// region STYLES

const RadioButtonCustom = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s;
  border: 1px solid ${colorIcon};
  display: inline-block;
  vertical-align: middle;
`;

const RadioButtonInput = styled.input`
  display: none;

  &:checked + ${RadioButtonCustom} {
    border-color: ${colorAccent};

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      top: calc(50% - 3px);
      background: ${colorAccent};
      border-radius: 50%;
      ${left}: calc(50% - 3px);
    }
  }
`;

const RadioButtonLabel = styled.span`
  line-height: 18px;
  transition: all 0.3s;
  margin-top: 5px;
  display: none;
  vertical-align: middle;
  ${paddingLeft}: 9px;
  color: ${colorText};
`;

const RadioButtonSvgWrap = styled.span``;

const RadioButtonWrap = styled.label`
  cursor: pointer;
  padding: 0;
  border: 1px solid ${colorAccent};
  min-width: 50px;
  height: 40px;
  transition: all 0.3s;
  display: flex;
  flex-direction: inherit;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${RadioButtonCustom} {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.9;
  `}

  ${RadioButtonInput}:checked ~ ${RadioButtonLabel} {
    color: ${colorWhite};
  }

  ${RadioButtonInput}:checked ~ ${RadioButtonSvgWrap} {
    color: ${colorWhite};
  }

  &:hover {
    background: ${colorAccentHover};

    ${RadioButtonSvgWrap} {
      color: ${colorWhite};
    }
  }

  ${(props) =>
    props.styleType === 'button4' &&
    `
    border: none;

    ${RadioButtonCustom} {
      display: block;
    }

    &:hover {
      background: none;
    }
  `}

  ${(props) =>
    (props.styleType === 'button' ||
      props.styleType === 'button2' ||
      props.styleType === 'button3') &&
    `
    ${RadioButtonSvgWrap} {
      line-height: 1;

      svg {
        fill: ${colorText};
        width: 25px;
        height: 25px;
      }
  
      svg:first-of-type {
        display: none;
      }
    
    ${RadioButtonLabel} {
      margin-top: auto;
      margin-bottom: auto;
      padding: 0;
      color: ${colorText};
      ${marginLeft(props)}: 0;
    }
    
    ${RadioButtonInput}:checked ~ ${RadioButtonSvgWrap} {

      svg:first-of-type {
        display: block;
        fill: ${colorWhite};
      }

      svg:last-of-type {
        display: none;
    }
  }
`}

  @media screen and (max-width: 370px) {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 575px) {
    display: inline-block;
  }

  @media screen and (max-width: 515px) {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 500px) {
    display: inline-block;
  }

  @media screen and (max-width: 356px) {
    display: flex;
    align-items: center;
  }
`;

// endregion

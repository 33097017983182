import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DepotListContainer from '../DepotListContainer';

const ViewDepot = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const Ava = `${process.env.PUBLIC_URL}/img/Depot.jpg`;

  const { loading, info, error } = useSelector((state) => state.getDepotListByCompanyReducer);

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <Card height='auto'>
      <CardBody>
        {loading ? (
          <DotDotLoader loadingState={loading} />
        ) : info && info.depot_list.length > 0 ? (
          info.depot_list.map((item) => (
            <DepotListContainer
              img={Ava}
              depotName={item.name}
              companyName={item.company_name}
              fleetName={item.fleet_name}
              city={item.city}
              key={`${item.name}_${item.id}`}
            />
          ))
        ) : info && info.depot_list.length === 0 ? (
          <DepotListContainer error={'No depot added'} />
        ) : (
          error && <DepotListContainer error={'Error getting depot data'} />
        )}
        <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
          <Button
            variant='secondary'
            type='button'
            onClick={() => handleCancel()}
            style={{ width: '100%' }}
          >
            Back
          </Button>
        </FormButtonToolbar>
      </CardBody>
    </Card>
  );
};

export default ViewDepot;

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Card, CardBody } from '@/shared/components/Card';
import { dashColor } from '@/utils/palette';
import DotDotLoader from '@/shared/components/DotDotLoader';
import data from '../../../../containers/App/Router/data';

const VehicleRegisteredHvacs = ({ vin }) => {
  const history = useHistory();
  const Ava = `${process.env.PUBLIC_URL}/img/air_conditioning_RGB.svg`;
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  const { loading, info, error } = useSelector((state) => state.getVehicleGatewayDetailsReducer);
  const { ambientLoading, ambientInfo } = useSelector((state) => state.getTemperatureReducer);

  const getUserDetails = JSON.parse(localStorage.getItem('user'));
  const companyRoleOfUser = getUserDetails ? getUserDetails.company_role_name : '';

  const convertTime = (minutes) => {
    var duration = moment.duration(minutes, 'minutes');
    var hours = Math.floor(duration.asHours());
    var remainingMinutes = duration.minutes();

    return hours + ' hours ' + remainingMinutes + ' minutes';
  };

  const handleGatewayClick = () => {
    localStorage.setItem('NewVin', info[0]?.vehicle[0]?.vin);
    localStorage.setItem('asset', 'Vehicles');
    history.push('/app/hvac_dashboard');
  };

  return (
    <Card height="auto" style={{ height: '188px' }}>
      <CardBody style={{ overflowY: 'auto' }}>
        <MainContainer>
          <ImageContainer>
            <ImageContainerImage src={Ava} alt="avatar" />
            <ImageContainerTitle>Registered Hvac</ImageContainerTitle>
          </ImageContainer>
          {loading ? (
            <InformationContainer style={{ paddingLeft: '20px' }}>
              <Information>
                <InformationItem>
                  <DotDotLoader loadingState={loading} />
                </InformationItem>
              </Information>
            </InformationContainer>
          ) : (
            <InformationContainer>
              {info && Object.entries(info[1].hvac_details).length !== 0 ? (
                <Information>
                  {info[1].hvac_details.map(
                    ({ component, component_name, supplier_name, unit_type }) => (
                      <InformationItem key={`${component_name}-${component}-${supplier_name}`}>
                        {`${component_name}: ${supplier_name}, ${unit_type}`}
                      </InformationItem>
                    )
                  )}
                </Information>
              ) : (
                <Information>
                  <InformationItem>{'No hvac registered'}</InformationItem>
                </Information>
              )}

              <InformationContainer>
                <Information>
                  Gateway:{' '}
                  {info && info[0].vehicle[0].serial_number ? (
                    adminRole.includes(companyRoleOfUser) ? (
                      <LabelSpan style={{ cursor: 'pointer' }} onClick={handleGatewayClick}>
                        {`${info[0].vehicle[0].gateway_manufacturer}, ${info[0].vehicle[0].serial_number}`}
                      </LabelSpan>
                    ) : (
                      <span>
                        {`${info[0].vehicle[0].gateway_manufacturer}, ${info[0].vehicle[0].serial_number}`}
                      </span>
                    )
                  ) : (
                    'No Gateway Added'
                  )}
                </Information>
                <Information>
                  CPU Id:{' '}
                  {info && info[0].vehicle[0] ? (
                    <span>{`${info[0].vehicle[0].gateway_id}`}</span>
                  ) : (
                    'No Gateway Added'
                  )}
                </Information>
                {info &&
                info[0].vehicle[0].last_system_info_packet &&
                info[0].vehicle[0].last_system_info_packet.ICCID ? (
                  <Information>
                    ICCID: {info[0].vehicle[0].last_system_info_packet.ICCID}
                  </Information>
                ) : (
                  ''
                )}
                <Information>
                  Operating Hours:{' '}
                  {ambientLoading
                    ? ''
                    : ambientInfo && ambientInfo.HVAC_OperatingMinutes.value
                    ? convertTime(ambientInfo.HVAC_OperatingMinutes.value)
                    : ''}
                </Information>
                <Information>
                  Firmware Version: {info ? info[0].vehicle[0].firmware_version : ''}
                </Information>
                <Information>
                  E-Control Version: {info ? info[0].vehicle[0].econtrol_version : ''}
                </Information>
              </InformationContainer>
            </InformationContainer>
          )}
        </MainContainer>
      </CardBody>
    </Card>
  );
};

export default VehicleRegisteredHvacs;
// region STYLES

const MainContainer = styled.div`
  display: flex;
  padding-top: 10px;
`;

const ImageContainer = styled.div`
  border-right: 1px solid ${(props) => dashColor(props)};
  padding-right: 10px;
  text-align: center;
`;

const ImageContainerTitle = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  color: #9baab0;
`;

const ImageContainerImage = styled.img`
  padding: 0;
  margin: 0;
  height: 50px;
  width: 50px;
`;

const InformationContainer = styled.div`
  color: ${(props) => dashColor(props)};
  padding-left: 0px;
  font-size: 12px;
`;

const Information = styled.ul`
  list-style: none;
`;
const InformationItem = styled.li``;
const LabelSpan = styled.span`
  cursor: pointer;
  color: #1368b1;

  &:hover {
    // color: #1368B1;
    text-decoration: underline;
  }
`;
// endregion

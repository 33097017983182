import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { dashColor } from '@/utils/palette';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import TableNoData from '@/shared/components/TableNoData';
import getHealthStatusDetail, {
  resetFilter,
} from '../../../../redux/actions/HealthStatusDashboardActions/getHealthStatusDetailActions';
import AlarmModalTable from './AlarmModalTable';
import DCAlarmModal from './DCAlarmModal';

const HealthStatusDetails = ({ reactTableData }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const getHealthStatusDetailReducerFilter = useSelector(
    (state) => state.getHealthStatusDetailReducer.filter
  );

  const [isFiltered, setIsFiltered] = useState(null);
  const [filteredVal, setFilteredVal] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyId = userDetails.company_id;

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const closeVehicleAlarmReducerInfo = useSelector((state) => state.closeVehicleAlarmReducer.info);

  useEffect(() => {
    setTimeout(() => {
      if (isFiltered === null && Object.entries(getHealthStatusDetailReducerFilter).length > 0) {
        setIsFiltered(getHealthStatusDetailReducerFilter.state.filterValue);
      }
    }, 200);

    return () => {
      setTimeout(() => {
        if (isFiltered !== '' && Object.entries(getHealthStatusDetailReducerFilter).length !== 0) {
          dispatch(resetFilter());
        }
      }, 200);
    };
  }, [getHealthStatusDetailReducerFilter]);

  useEffect(() => {
    dispatch(
      getHealthStatusDetail(companyId, pageNumber, numberofRecords, isFiltered, reactTableData.sort)
    );
  }, [closeVehicleAlarmReducerInfo, isFiltered, reactTableData.sort]);

  const getHealthStatusDetailReducer = useSelector(
    (state) => state.getHealthStatusDetailReducer.info
  );

  const getHealthStatusDetailReducerLoading = useSelector(
    (state) => state.getHealthStatusDetailReducer.loading
  );

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getHealthStatusDetail(companyId, pageIndex + 1, pageSize, isFiltered, reactTableData.sort)
    );
  };

  const filterOptions = [
    {
      id: 1,
      value: 'type-Data',
      label: 'Data',
    },
    {
      id: 2,
      value: 'type-Conditional',
      label: 'Conditional',
    },
    {
      id: 3,
      value: 'type-Preventive',
      label: 'Preventive',
    },
    {
      id: 4,
      value: 'type-Connectivity',
      label: 'Connectivity',
    },
    {
      id: 5,
      value: 'type-DTC',
      label: 'DTC',
    },
    {
      id: 6,
      value: 'status-1',
      label: 'Open',
    },
    {
      id: 7,
      value: 'status-0',
      label: 'Closed',
    },
  ];

  const handleSubmit = () => {
    console.log('filter selected');
  };

  const handleChange = (values) => {
    if (values) {
      setIsFiltered(values.value);
      setFilteredVal(values.label);
      dispatch(resetFilter());
    }
    if (!values) {
      setIsFiltered('');
      setFilteredVal('');
      dispatch(resetFilter());
    }
  };

  const initialVal = [
    {
      id: getHealthStatusDetailReducerFilter?.state?.filterValueName
        ? getHealthStatusDetailReducerFilter.state.filterValueName
        : null,
      value: getHealthStatusDetailReducerFilter?.state?.filterValueName
        ? getHealthStatusDetailReducerFilter.state.filterValueName
        : null,
      label: getHealthStatusDetailReducerFilter?.state?.filterValueName
        ? getHealthStatusDetailReducerFilter.state.filterValueName
        : null,
    },
  ];

  return (
    <Col md={12} lg={12} sm={12}>
      <Card>
        <CardBody>
          <HeaderWrap style={{ display: 'flex' }}>
            <CardTitleWrap>
              <CardTitle>Health Status Details</CardTitle>
              <CardSubhead>{`All ${
                getHealthStatusDetailReducerFilter?.state?.filterValueName
                  ? getHealthStatusDetailReducerFilter.state.filterValueName
                  : filteredVal
                  ? filteredVal
                  : ''
              } Alarms`}</CardSubhead>
            </CardTitleWrap>
            <div style={{ width: '200px' }}>
              <Form onSubmit={handleSubmit}>
                {({ handleSubmit }) => (
                  <FormContainer onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                      <FormGroupField>
                        <Field
                          name="filter"
                          component={renderSelectField}
                          type="text"
                          placeholder="Please select a filter"
                          options={filterOptions}
                          parse={(e) => {
                            handleChange(e);
                            return e;
                          }}
                          initialValue={isFiltered ? initialVal : ''}
                          isClearable
                        />
                      </FormGroupField>
                    </FormGroup>
                  </FormContainer>
                )}
              </Form>
            </div>
          </HeaderWrap>
          <ReactTableBase
            columns={reactTableData.tableHeaderData}
            data={
              getHealthStatusDetailReducer && getHealthStatusDetailReducer.data
                ? getHealthStatusDetailReducer.data.health_status
                : []
            }
            tableConfig={tableConfig}
            paginationDetail={
              getHealthStatusDetailReducer && getHealthStatusDetailReducer.pagination
                ? getHealthStatusDetailReducer.pagination
                : {
                    totalPages: 0,
                    totalRecords: 0,
                  }
            }
            loading={getHealthStatusDetailReducerLoading}
            fetchData={fetchApiData}
            dashboardType={'Diagnostics'}
            isFiltered={isFiltered}
          />
          {getHealthStatusDetailReducerLoading ? (
            ''
          ) : getHealthStatusDetailReducer && getHealthStatusDetailReducer.data ? (
            getHealthStatusDetailReducer.data.health_status.length !== 0 ? (
              ''
            ) : (
              <TableNoData errorText={'No Alarms Found'} />
            )
          ) : (
            <TableNoData errorText={'No Alarms Found'} />
          )}
        </CardBody>
      </Card>
      {reactTableData &&
      reactTableData.openModal === true &&
      (reactTableData.alarmDetails.type === 'Preventive' ||
        reactTableData.alarmDetails.type === 'Conditional') ? (
        <AlarmModalTable
          openModalState={reactTableData.openModal}
          closeModalState={reactTableData.closeModal}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
          alarmDetails={reactTableData.alarmDetails}
          showInput={reactTableData.showInputBox}
          disableInput={reactTableData.disableInputBox}
        />
      ) : reactTableData &&
        reactTableData.openModal === true &&
        (reactTableData.alarmDetails.type === 'Data' ||
          reactTableData.alarmDetails.type === 'Connectivity' ||
          reactTableData.alarmDetails.type === 'DTC') ? (
        <DCAlarmModal
          openModalState={reactTableData.openModal}
          closeModalState={reactTableData.closeModal}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
          alarmDetails={reactTableData.alarmDetails}
          showInput={reactTableData.showInputBox}
          disableInput={reactTableData.disableInputBox}
        />
      ) : (
        ''
      )}
    </Col>
  );
};

HealthStatusDetails.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default HealthStatusDetails;
// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

// endregion

import axios from 'axios';
import {
  RESEND_PWD_LOADING,
  RESEND_PWD_SUCCESS,
  RESEND_PWD_FAIL,
  RESEND_PWD_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const resendPwd = (companyId, email) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_PWD_LOADING,
    });

    const response = await axios.post(
      ApiURL.RESEND_PWD + companyId + '/resend_temp_pass',
      { username: email },
      getConfig()
    );

    successMessage(`New password requested for ${email}`);

    dispatch({
      type: RESEND_PWD_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: RESEND_PWD_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: RESEND_PWD_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const resendPwdReset = () => (dispatch) => {
  dispatch({
    type: RESEND_PWD_RESET,
  });
};

export default resendPwd;

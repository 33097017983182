import axios from 'axios';
import {
  VEHICLE_REGISTRATION_LOADING,
  VEHICLE_REGISTRATION_SUCCESS,
  VEHICLE_REGISTRATION_ERROR,
} from '@/shared/constants/addVehicle';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { manage_assets } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../config/GlobalUrl';

export const vehicleRegisterAction = (registrationDetail) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_REGISTRATION_LOADING });

    const {
      vin,
      engine_type,
      manufacturer,
      model,
      body_number,
      bus_num,
      plate_num,
      pta_name,
      operator_name,
      service_provider,
      fleet_name,
      depot_name,
    } = registrationDetail;

    const { data } = await axios.post(
      ApiURL.VEHICLE_REGISTER + vin + '/register',
      {
        vin,
        engine_type: engine_type.value,
        manufacturer: manufacturer ? manufacturer : null,
        model: model ? model : null,
        body_number: body_number ? body_number : null,
        plate_num: plate_num ? plate_num : null,
        bus_num: bus_num ? bus_num : null,
        pta_id: pta_name ? pta_name.id : null,
        pto_id: operator_name.id,
        service_provider_id: service_provider ? service_provider.id : null,
        fleet_id: fleet_name.id,
        depot_id: depot_name.id,
      },
      getConfig()
    );

    successMessage(manage_assets.register_vehicle);

    dispatch({
      type: VEHICLE_REGISTRATION_SUCCESS,
      payload: data,
    });

    localStorage.setItem('NewVin', vin);
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: VEHICLE_REGISTRATION_ERROR,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: VEHICLE_REGISTRATION_ERROR,
        payload: error.response,
      });
    }
  }
};

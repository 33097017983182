//conditional rendering dashboard constants
export const SET_OTA_ROW_ACTION = 'SET_OTA_ROW_ACTION';
export const RESET_OTA_ROW_ACTION = 'RESET_OTA_ROW_ACTION';

//gateway unit by brand
export const GET_GATEWAY_UNITS_BY_BRAND_LOADING = 'GET_GATEWAY_UNITS_BY_BRAND_LOADING';
export const GET_GATEWAY_UNITS_BY_BRAND_SUCCESS = 'GET_GATEWAY_UNITS_BY_BRAND_SUCCESS';
export const GET_GATEWAY_UNITS_BY_BRAND_FAIL = 'GET_GATEWAY_UNITS_BY_BRAND_FAIL';

//PACKAGE COUNT
export const GET_PACKAGE_COUNT_LOADING = 'GET_PACKAGE_COUNT_LOADING';
export const GET_PACKAGE_COUNT_SUCCESS = 'GET_PACKAGE_COUNT_SUCCESS';
export const GET_PACKAGE_COUNT_FAIL = 'GET_PACKAGE_COUNT_FAIL';

//package listing kpi
export const GET_PACKAGE_LISTING_KPI_LOADING = 'GET_PACKAGE_LISTING_KPI_LOADING';
export const GET_PACKAGE_LISTING_KPI_SUCCESS = 'GET_PACKAGE_LISTING_KPI_SUCCESS';
export const GET_PACKAGE_LISTING_KPI_FAIL = 'GET_PACKAGE_LISTING_KPI_FAIL';

//gateway unit by brand
export const GET_GATEWAY_LISTING_LOADING = 'GET_GATEWAY_LISTING_LOADING';
export const GET_GATEWAY_LISTING_SUCCESS = 'GET_GATEWAY_LISTING_SUCCESS';
export const GET_GATEWAY_LISTING_FAIL = 'GET_GATEWAY_LISTING_FAIL';

//group details
export const GET_GROUP_DETAILS_LOADING = 'GET_GROUP_DETAILS_LOADING';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAIL = 'GET_GROUP_DETAILS_FAIL';
export const GET_GROUP_DETAILS_RESET = 'GET_GROUP_DETAILS_RESET';

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GROUP_LIST_LOADING,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_FAIL,
  GET_GROUP_LIST_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getGroupList = (depotId, deviceType) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GROUP_LIST_LOADING,
    });

    const params = {
      p: 1,
      page_size: 99999,
      'filter-type': deviceType,
    };

    const { data } = await axios.get(
      ApiURL.GET_GROUP_LIST + depotId + '/groups/list',
      { params: params },
      getConfig()
    );
    const groups_list = data.data.groups_list;

    dispatch({
      type: GET_GROUP_LIST_SUCCESS,
      payload: groups_list.map(({ label, name }) => ({
        id: name,
        label: label,
        value: name,
      })),
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GROUP_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GROUP_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGroupListReset = () => async (dispatch) => {
  dispatch({
    type: GET_GROUP_LIST_RESET,
  });
};

export default getGroupList;

import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import RoleListContainer from '../RoleListContainer';

const ViewRole = ({ setGoBack }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Roles.jpg`;

  const { loading, info, error } = useSelector((state) => state.getRoleListReducer);

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <Card height="auto">
      <CardBody>
        {loading ? (
          <DotDotLoader loadingState={loading} />
        ) : info && info.roles.length > 0 ? (
          info.roles.map((item) => (
            <RoleListContainer
              img={Ava}
              roleName={item.description}
              key={`${item.description}_${item.id}`}
            />
          ))
        ) : info && info.roles.length === 0 ? (
          <RoleListContainer error={'No role added'} />
        ) : (
          error && <RoleListContainer error={'Error getting role data'} />
        )}
        <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
          <Button
            variant="secondary"
            type="button"
            onClick={() => handleCancel()}
            style={{ width: '100%' }}
          >
            Back
          </Button>
        </FormButtonToolbar>
      </CardBody>
    </Card>
  );
};

export default ViewRole;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import FormField from "@/shared/components/form/FormField";
import DotDotLoader from "@/shared/components/DotDotLoader";

const ProfileUpdateForm = () => {
  const loading = false;
  const handleUpdateUserProfile = () => {};

  const getUserProfile = JSON.parse(localStorage.getItem("userProfile")) || {};
  const userRole = JSON.parse(localStorage.getItem("user"));
  getUserProfile.role = userRole?.user_role || "";

  return (
    <Card>
      <CardBody>
        {/* <CardTitleWrap> */}
        {/* <CardTitle>User Details</CardTitle> */}
        {/* {loading && <DotDotLoader loadingState={loading} />} */}
        {/* </CardTitleWrap> */}
        <Form onSubmit={handleUpdateUserProfile} initialValues={getUserProfile}>
          {({ handleSubmit, pristine, hasValidationErrors }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <FormGroupLabel>Username</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="username"
                    component={FormField}
                    type="text"
                    // placeholder="Please enter sim number"
                    required
                    maxLength={50}
                    disabled={true}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Email</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="email"
                    component={FormField}
                    type="text"
                    // placeholder="Please enter IMEI number"
                    required
                    maxLength={50}
                    disabled={true}
                  />
                </FormGroupField>
              </FormGroup>
              {/* <FormGroup>
                <FormGroupLabel>Designation</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="designation"
                    component={FormField}
                    type="text"
                    // placeholder="Please enter country"
                    maxLength={30}
                    disabled={true}
                  />
                </FormGroupField>
              </FormGroup> */}
              <FormGroup>
                <FormGroupLabel>Role</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="role"
                    component={FormField}
                    type="text"
                    // placeholder="Please enter country code"
                    maxLength={8}
                    disabled={true}
                  />
                </FormGroupField>
              </FormGroup>
              {/* <FormButtonToolbar
                style={{
                  width: '100%',
                  marginTop: '0',
                  paddingTop: '20px',
                }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  disabled={pristine || hasValidationErrors || loading}
                >
                  Update
                </Button>
              </FormButtonToolbar> */}
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default ProfileUpdateForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { dashColor } from '@/utils/palette';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import Path from '@/utils/routes/routePathNames';
import VehicleDetailsImage from './VehicleDetailsImage';
import getVehicleGatewayDetails from '../../../../redux/actions/VehicleDashboardActions/getVehicleGatewayDetails';
import data from '../../../../containers/App/Router/data';

const VehicleDetails = ({ vin }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  useEffect(() => {
    dispatch(getVehicleGatewayDetails(vin));
  }, [vin]);

  const { loading, info, error } = useSelector((state) => state.getVehicleGatewayDetailsReducer);
  const greyColor = '#A7AEB4';

  const handleVinClick = () => {
    localStorage.setItem('NewVin', info[0]?.vehicle[0]?.vin);
    localStorage.setItem('asset', 'Vehicles');
    history.push(Path.ASSET_DASHBOARD);
  };
  const getUserDetails = JSON.parse(localStorage.getItem('user'));
  const companyRoleOfUser = getUserDetails ? getUserDetails.company_role_name : '';

  return (
    <Card height="auto" style={{ height: '188px' }}>
      <CardBody style={{ paddingTop: '13px', paddingBottom: '11px', overflowY: 'auto' }}>
        <MainContainer>
          <ImageContainer style={{ paddingTop: '25px' }}>
            <VehicleDetailsImage colorCode={info ? info[0].vehicle[0].color_code : greyColor} />
            <ImageContainerTitle>Vehicle details</ImageContainerTitle>
          </ImageContainer>

          {loading ? (
            <InformationContainer>
              <Information style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                <InformationItem>
                  <DotDotLoader loadingState={loading} />
                </InformationItem>
              </Information>
            </InformationContainer>
          ) : (
            <InformationContainer>
              <Information>
                <InformationItem>
                  <span>VIN: </span>
                  {adminRole.includes(companyRoleOfUser) ? (
                    <LabelSpan onClick={handleVinClick}>
                      {info[0] ? info[0].vehicle[0].vin : 'No data found'}
                    </LabelSpan>
                  ) : (
                    <InformationSpan>
                      {info[0] ? info[0].vehicle[0].vin : 'No data found'}
                    </InformationSpan>
                  )}
                </InformationItem>
                <InformationItem>
                  <span>Body number: </span>
                  <InformationSpan>{info[0] ? info[0].vehicle[0].body_number : ''}</InformationSpan>
                </InformationItem>
                <InformationItem>
                  <span>Manufacturer: </span>
                  <InformationSpan>
                    {info ? info[0].vehicle[0].vehicle_manufacturer : ''}
                  </InformationSpan>
                </InformationItem>
                <InformationItem>
                  <span>Model: </span>
                  <InformationSpan>{info ? info[0].vehicle[0].model : ''}</InformationSpan>
                </InformationItem>
                <InformationItem>
                  <span>Engine: </span>
                  <InformationSpan>{info ? info[0].vehicle[0].engine : ''}</InformationSpan>
                </InformationItem>
                <InformationItem>
                  <span>Depot name: </span>
                  <InformationSpan>
                    {info
                      ? `${info[0].vehicle[0].depot_name}, ${info[0].vehicle[0].depot_city}`
                      : ''}
                  </InformationSpan>
                </InformationItem>
                <InformationItem>
                  <span>Fleet name: </span>
                  <InformationSpan>
                    {info
                      ? `${info[0].vehicle[0].fleet_name}, ${info[0].vehicle[0].fleet_region}`
                      : ''}
                  </InformationSpan>
                </InformationItem>
              </Information>
            </InformationContainer>
          )}
        </MainContainer>
      </CardBody>
    </Card>
  );
};

export default VehicleDetails;

// region STYLES

const MainContainer = styled.div`
  display: flex;
  overflow: auto;
  padding-top: 0px;
`;

const ImageContainer = styled.div`
  border-right: 1px solid ${(props) => dashColor(props)};
  padding-right: 10px;
  text-align: center;
`;

const ImageContainerTitle = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  color: #9baab0;
`;

const InformationContainer = styled.div`
  color: ${(props) => dashColor(props)};
  padding-left: 0px;
  font-size: 12px;
`;

const Information = styled.ul`
  list-style: none;
`;
const InformationItem = styled.li``;

const InformationSpan = styled.span`
  // text-transform: uppercase;
`;
const LabelSpan = styled.span`
  cursor: pointer;
  color: #1368b1;

  &:hover {
    // color: #1368B1;
    text-decoration: underline;
  }
`;
// endregion

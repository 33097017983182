import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const ComponentsContainer = ({ assettype, img, count }) => (
  <Devices>
    <DeviceImage>
      <img src={img} alt="" />
    </DeviceImage>
    <div style={{ display: 'block' }}>
      <div>
        <DeviceType>{assettype}</DeviceType>
      </div>
      <div>
        <DeviceCount>{count}</DeviceCount>
      </div>
    </div>
    <DeviceLink>
      <span className="lnr lnr-chevron-right" />
    </DeviceLink>
  </Devices>
);

ComponentsContainer.propTypes = {
  assettype: PropTypes.string.isRequired,
  img: PropTypes.string,
};

export default ComponentsContainer;

// region STYLES

const Devices = styled.div`
  display: flex;
  position: relative;
  padding: 15px 0;
  text-align: ${left};
  cursor: pointer;
  // border-bottom: 1px solid #d8d8d8;

  &:last-child {
    border-bottom: none;
  }
`;

const DeviceLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
  color: #1368b1;
`;

const DeviceType = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
`;

const DeviceCount = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  font-size: 11px;
`;

const DeviceImage = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

// endregion

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import CompanyForm from './CompanyForm';
import ManageCompanyContainer from '../ManageCompanyContainer';
import DotDotLoader from '../../../../shared/components/DotDotLoader';
import getCompanyList from '../../../../redux/actions/CompanyActions/getCompanyListActions';
import CompanyListContainer from '../CompanyListContainer';
import { Button } from '@/shared/components/Button';

const ManageCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [registerMode, setRegisterMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [view, setView] = useState('');

  const addImage = `${process.env.PUBLIC_URL}/img/Add.jpg`;
  const editImage = `${process.env.PUBLIC_URL}/img/Edit.jpg`;
  const viewImage = `${process.env.PUBLIC_URL}/img/View.jpg`;
  const companyImage = `${process.env.PUBLIC_URL}/img/Companies.jpg`;

  const data = [
    {
      id: 1,
      img: addImage,
      assetType: 'Add Company',
      mode: 'register',
    },
    {
      id: 2,
      img: editImage,
      assetType: 'Edit Company',
      mode: 'edit',
    },
    {
      id: 3,
      img: viewImage,
      assetType: 'View Company',
      mode: 'view',
    },
  ];

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const loggedInCompanyId = userDetails.company_id;

  const getCompanyListReducerLoading = useSelector((state) => state.getCompanyListReducer.loading);
  const getCompanyListReducerInfo = useSelector((state) => state.getCompanyListReducer.info);

  const handleClick = (m) => {
    if (m === 'register') {
      setRegisterMode(true);
      setGoBack(true);
    }
    if (m === 'edit') {
      history.push('/settings/company-settings/select');
    }
    if (m === 'view') {
      setRegisterMode(true);
      setGoBack(true);
      setView('view');
      dispatch(getCompanyList(loggedInCompanyId));
    }
  };
  return (
    <Container>
      {!registerMode && !editMode && !goBack ? (
        <>
          <Row className="pb-4">
            <Col md={6}>
              <h3 className="page-title">Manage Company</h3>
              <h6>Add or Edit details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} sm={12}>
              <Card height="auto">
                <CardBody>
                  <CardTitleWrap>
                    <CardTitle>Company Settings</CardTitle>
                  </CardTitleWrap>
                  {data.map(({ id, assetType, mode, img }) => (
                    <div key={id} onClick={() => handleClick(mode)}>
                      <ManageCompanyContainer key={id} assettype={assetType} img={img} />
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : registerMode && goBack && view !== 'view' ? (
        <>
          <Row className="pb-4">
            <Col md={6}>
              <h3 className="page-title">Add Company</h3>
              <h6>Add Company details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} sm={12}>
              <Card height="auto">
                <CardBody>
                  <CardTitleWrap>
                    <CardTitle>Company Settings</CardTitle>
                  </CardTitleWrap>
                  <CompanyForm
                    editMode={editMode}
                    setGoBack={setGoBack}
                    setRegisterMode={setRegisterMode}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : registerMode && goBack && view === 'view' ? (
        <Row>
          <Col md={6} lg={6} sm={12}>
            <Card height="auto">
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Registered Companies</CardTitle>
                </CardTitleWrap>
                {getCompanyListReducerLoading ? (
                  <DotDotLoader loadingState={getCompanyListReducerLoading} />
                ) : getCompanyListReducerInfo &&
                  Object.entries(getCompanyListReducerInfo.company_list).length !== 0 ? (
                  getCompanyListReducerInfo.company_list.map((item) => (
                    <CompanyListContainer
                      key={`${item.company_name}_${item.company_id}`}
                      img={companyImage}
                      companyName={item.company_name}
                      companyRole={item.role}
                      companyId={item.company_id}
                    />
                  ))
                ) : (
                  'Error getting registered companies'
                )}
                <FormButtonToolbar
                  style={{
                    width: '100%',
                    paddingRight: '10px',
                    paddingTop: '20px',
                    margin: '0',
                  }}
                >
                  <Button
                    variant="secondary"
                    type="button"
                    style={{ width: '100%', margin: '0' }}
                    onClick={() => {
                      setGoBack((prev) => !prev);
                      setView('');
                      setRegisterMode((prev) => !prev);
                    }}
                  >
                    Back
                  </Button>
                </FormButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Container>
  );
};

export default ManageCompany;

import { useState, useMemo } from 'react';
import { GetTableHeader } from '@/utils/helpers';

const CreateTableData = () => {
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Serial Number'}
            key={'Serial Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'serial_number',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'CPU Number'}
            key={'CPU Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'cpu_number',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Manufacturer'}
            key={'Manufacturer'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'manufacturer',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Vin'}
            key={'Vin'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vin',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

const data = [
  {
    id: 1,
    name: 'Eberspächer',
    descritpion: 'Eberspächer',
    status: '1',
  },
  {
    id: 2,
    name: 'Konvekta',
    descritpion: 'Konvekta',
    status: '1',
  },
  {
    id: 3,
    name: 'Valeo',
    descritpion: 'Valeo',
    status: '1',
  },
  {
    id: 4,
    name: 'Bock',
    descritpion: 'Bock',
    status: '1',
  },
  
];

export default data;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  AccountCard,
  AccountContent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import { logoImg } from '@/utils/palette';
import SignInForm from '@/shared/components/account/signIn/SignInForm';
import signIn, { signInReset } from '@/redux/actions/SignInActions/signInActions';
import DotDotLoader from '@/shared/components/DotDotLoader';
import ForgotPasswordForm from '@/shared/components/account/forgotPassword/ForgotPasswordForm';
import ResetPasswordForm from '@/shared/components/account/resetPassword/ResetPasswordForm';
import resetPwd, { resetPwdReset } from '../../../redux/actions/SignInActions/resetPwdActions';
import forgotPwd, { forgotPwdReset } from '../../../redux/actions/SignInActions/forgotPwdActions';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userEmail, setUserEmail] = useState('');
  const [userPwd, setUserPwd] = useState('');
  const [btnClicked, setBtnClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resetBtnClicked, setResetBtnClicked] = useState(false);
  const [forgotBtnClicked, setForgotBtnClicked] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const { loading, info, info_first_time } = useSelector((state) => state.signInReducer);
  const resetPwdReducerInfo = useSelector((state) => state.resetPwdReducer.info);
  const resetPwdReducerLoading = useSelector((state) => state.resetPwdReducer.loading);
  const forgotPwdReducerInfo = useSelector((state) => state.forgotPwdReducer.info);
  const forgotPwdReducerLoading = useSelector((state) => state.forgotPwdReducer.loading);

  const submitUserDetail = (values) => {
    if (values.email !== '' && values.password !== '') {
      dispatch(signIn(values.email, values.password));
      setUserPwd(values.password);
      setUserEmail(values.email);
      setBtnClicked(true);
    }
  };

  if (info && btnClicked && localStorage.getItem('token')) {
    setTimeout(() => {
      history.push('/global');
      setBtnClicked(false);
    }, 100);
  }

  if (info_first_time && btnClicked) {
    setTimeout(() => {
      setShowResetForm(true);
      setBtnClicked(false);
    }, 100);
  }

  const handleResetPwd = (values) => {
    if (userEmail && userPwd && values) {
      dispatch(resetPwd(userEmail, userPwd, values.new_password));
      setResetBtnClicked(true);
    }
  };

  if (resetPwdReducerInfo && resetBtnClicked) {
    setTimeout(() => {
      setShowResetForm(false);
      setResetBtnClicked(false);
      dispatch(resetPwdReset());
      dispatch(signInReset());
    }, 100);
  }

  const handleForgotPwd = (values) => {
    if (values) {
      dispatch(forgotPwd(values.email));
      setForgotBtnClicked(true);
    }
  };

  if (forgotPwdReducerInfo && forgotBtnClicked) {
    setTimeout(() => {
      setShowForm(false);
      setForgotBtnClicked(false);
      dispatch(forgotPwdReset());
    }, 100);
  }

  useEffect(() => {
    return () => {
      setShowResetForm(false);
      setBtnClicked(false);
      setResetBtnClicked(false);
      setForgotBtnClicked(false);
      dispatch(resetPwdReset());
      dispatch(signInReset());
      dispatch(forgotPwdReset());
    };
  }, [dispatch]);

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHeader>
            <LoginFormLogo />
            <LoadingContainer>
              {loading || resetPwdReducerLoading || forgotPwdReducerLoading ? (
                <DotDotLoader
                  loadingState={loading || resetPwdReducerLoading || forgotPwdReducerLoading}
                />
              ) : (
                ''
              )}
            </LoadingContainer>
            {!showForm ? (
              <>
                <AccountTitle>Login</AccountTitle>
                <h4 className="subhead">Login to your account</h4>
              </>
            ) : (
              <>
                <AccountTitle>Forgot Password</AccountTitle>
                <h4 className="subhead">Please enter email to change password</h4>
              </>
            )}
          </AccountHeader>
          {!showForm && !showResetForm ? (
            <SignInForm
              onSubmit={submitUserDetail}
              showForm={showForm}
              setShowForm={setShowForm}
              loading={loading}
            />
          ) : showForm && !showResetForm ? (
            <ForgotPasswordForm onSubmit={handleForgotPwd} setShowForm={setShowForm} />
          ) : !showForm && showResetForm ? (
            <ResetPasswordForm onSubmit={handleResetPwd} />
          ) : (
            ''
          )}
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default SignIn;

const LoginFormLogo = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${logoImg};
  text-align: center;

  @media screen and (max-width: 356px) {
    margin: 5px 0;
  }
`;

const AccountHeader = styled.div`
  margin-bottom: 30px;
`;

const LoadingContainer = styled.div`
  height: 40px;
`;

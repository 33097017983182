const data = [
  //Need to add app_admin role logic
  { adminRole: ["admin", "app_admin"] },
  { viewRole: ["view", "View"] },
  { all: ["all"] },
  { companyRole: ["operator", "service_provider", "pta"] },
  { companyAdmin: ["company_admin"] },
  { componentNames: [] },
  { canAccess: ["Fleet Operator"] },
];

export const dataObject = {
  adminRole: ["admin", "app_admin"],
  viewRole: ["view", "View"],
  all: ["all"],
  companyRole: ["operator", "service_provider", "pta"],
  companyAdmin: ["company_admin"],
  componentNames: [],
  canAccess: ["Fleet Operator"],
};

export default data;

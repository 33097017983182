import {
  GET_COMPANY_DATA_LOADING,
  GET_COMPANY_DATA_SUCCESS,
  GET_COMPANY_DATA_FAIL,
} from "@/shared/constants/OtaUpdateConstants/packageMgtConstants";

const initialState = {
  loading: false,
  info: "",
  error: "",
  filterOptions: { operators: [], fleets: [], depots: [] },
};

const getCompanyDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_DATA_LOADING:
      return { ...state, loading: true };
    case GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        filterOptions: action.payload.filterOptions,
      };
    case GET_COMPANY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getCompanyDataReducer;

import axios from 'axios';
import {
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  ADD_COMPANY_RESET,
} from '@/shared/constants/CompanyConstants/addCompanyConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const addCompany = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COMPANY_LOADING,
    });

    const { name, city, company_type, website, description, country } = data;

    const response = await axios.post(
      ApiURL.ADD_COMPANY,
      {
        name: name,
        website: website,
        city: city,
        country: country,
        description: description,
        address: description,
        role_id: company_type.id,
      },
      getConfig()
    );

    localStorage.setItem('companyId', response.data.data.company_id);
    localStorage.setItem('companyRoleId', response.data.data.company_role_id);

    successMessage(system_settings.register_company);
    dispatch({
      type: ADD_COMPANY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: ADD_COMPANY_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: ADD_COMPANY_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export const addCompanyReset = () => (dispatch) => {
  dispatch({
    type: ADD_COMPANY_RESET,
  });
};

export default addCompany;

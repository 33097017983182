import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import EmailIcon from 'mdi-react/EmailIcon';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { useHistory } from 'react-router';
import SimpleLoader from '@/shared/components/SimpleLoader';
import { useDispatch, useSelector } from 'react-redux';
// import registerFleet, {
//   registerFleetReset,
// } from '../../../../redux/actions/FleetActions/registerFleetActions';
// import getRegisteredFleet from '../../../../redux/actions/FleetActions/getRegisteredFleetActions';
// import updateFleet, {
//   updateFleetReset,
// } from '../../../../redux/actions/FleetActions/updateFleetActions';

const RoleForm = ({ setGoBack, editMode, setShowRoleEditForm, roleId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [roleUpdateBtnClick, setRoleUpdateBtnClick] = useState(false);

  const registerFleetReducerLoading = useSelector((state) => state.registerFleetReducer.loading);
  // const registerFleetReducerInfo = useSelector(
  //   (state) => state.registerFleetReducer.info
  // );
  // const registerFleetReducerError = useSelector(
  //   (state) => state.registerFleetReducer.error
  // );
  const getRegisteredFleetReducerLoading = useSelector(
    (state) => state.getRegisteredFleetReducer.loading
  );
  const getRegisteredFleetReducerInfo = useSelector(
    (state) => state.getRegisteredFleetReducer.info
  );
  // const getRegisteredFleetReducerError = useSelector(
  //   (state) => state.getRegisteredFleetReducer.error
  // );
  const updateFleetReducerLoading = useSelector((state) => state.updateFleetReducer.loading);
  // const updateFleetReducerInfo = useSelector(
  //   (state) => state.updateFleetReducer.info
  // );
  // const updateFleetReducerError = useSelector(
  //   (state) => state.updateFleetReducer.error
  // );

  const companyId = localStorage.getItem('companyId');

  const handleCancel = () => {
    setGoBack(true);
  };

  // useEffect(() => {
  //   if (companyId && roleId) {
  //     dispatch(getRegisteredRole(companyId, roleId));
  //   }
  // }, [roleId]);

  const handleRegisterRole = (values) => {
    // if (Object.keys(values).length !== 0) {
    //   dispatch(registerRole(companyId, values));
    //   setBtnClicked(true);
    // }
  };

  // if (btnClicked === true && registerRoleReducerInfo !== '') {
  //   setTimeout(() => {
  //     history.push('/settings/company_settings/select_company/manage_company');
  //     // dispatch(registerRoleReset());
  //     setBtnClicked(false);
  //   }, 1000);
  // }

  const handleUpdateRole = (values) => {
    // if (Object.keys(values).length !== 0 && companyId && roleId) {
    //   dispatch(updateRole(companyId, fleetId, values));
    //   setRoleUpdateBtnClick(true);
    // }
  };

  // if (roleUpdateBtnClick === true && updateRoleReducerInfo !== '') {
  //   setTimeout(() => {
  //     history.push('/settings/company_settings/select_company/manage_company');
  //     // dispatch(updateRoleReset());
  //     setRoleUpdateBtnClick(false);
  //   }, 1000);
  // }
  const selectDropDownValue = [
    {
      id: getRegisteredFleetReducerInfo ? getRegisteredFleetReducerInfo.fleet.climate_zone : '',
      label: getRegisteredFleetReducerInfo ? getRegisteredFleetReducerInfo.fleet.climate_zone : '',
      value: getRegisteredFleetReducerInfo ? getRegisteredFleetReducerInfo.fleet.climate_zone : '',
    },
  ];
  // const [apiData, setApiData] = useState(
  //   getRegisteredFleetReducerInfo !== ''
  //     ? getRegisteredFleetReducerInfo.fleet
  //     : ''
  // );
  return (
    <Card height="auto">
      <CardBody>
        {registerFleetReducerLoading === true ||
        getRegisteredFleetReducerLoading === true ||
        updateFleetReducerLoading === true ? (
          <SimpleLoader />
        ) : (
          <Form
            onSubmit={editMode === false ? handleRegisterRole : handleUpdateRole}
            initialValues={
              editMode === true && getRegisteredFleetReducerInfo !== ''
                ? getRegisteredFleetReducerInfo.fleet
                : ''
            }
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>Role Name</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <EmailIcon />
                    </FormGroupIcon>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Role Name"
                      required
                    />
                  </FormGroupField>
                </FormGroup>
                {editMode === false ? (
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Register
                    </Button>
                  </FormButtonToolbar>
                ) : (
                  <FormButtonToolbar>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => setShowRoleEditForm(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </FormButtonToolbar>
                )}
              </FormContainer>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default RoleForm;

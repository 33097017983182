import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorWhite } from '@/utils/palette';
import { Button } from '@/shared/components/Button';

const NotFound404 = () => (
  <NotFoundContainer>
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}
    >
      <NotFountContent>
        <NotFoundInfo>Ooops! The page you are looking for could not be found :(</NotFoundInfo>
        <Button as={Link} variant="primary" to="/global">
          Back Home
        </Button>
      </NotFountContent>
    </div>
  </NotFoundContainer>
);

export default NotFound404;

// region STYLES

const NotFoundContainer = styled.div`
  text-align: center;
  // height: 100vh;
  // overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-size: cover;
  button {
    margin: 0;
  }
`;

const NotFountContent = styled.div`
  // margin: auto;
  // padding: 10px;
`;

const NotFoundInfo = styled.h3`
  // color: ${colorWhite};
  // margin-bottom: 20px;
  // margin-top: 90px;
`;

// endregion

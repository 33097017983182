import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderComponentField } from '@/shared/components/form/FormField';
import {
  colorFieldsBorder,
  colorText,
  colorAccent,
  colorWhite,
  colorAccentHover,
} from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import uploadPackageFileCheck from '@/redux/actions/OtaUpdateActions/PackageMgtActions/uploadPackageFileCheckAction';
import { uploadPackageFileCheckReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/uploadPackageFileCheckAction';

export const PackageFileUpload = React.forwardRef(
  ({ onChange, name, disabled, placeholder, formValues }, ref) => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleChange = (e) => {
      setError('');
      e.preventDefault();
      const files = [...e.target.files];
      // const regEx =
      //   /^[a-zA-Z0-9_-]*_(E-Control|HMI|SLAVE)_(\d{2}-\d{2}-\d{2}-\d{5}-\d{2})_Rev(\d{2})\.zip$|^[a-zA-Z0-9_-]*_(E-Control|HMI|SLAVE)_(\d{2}-\d{2}-\d{2}-\d{5}-\d{2})_Rev(\d{2})_P(\d{1,7})\.zip$/i;

      if (files.length) {
        const fileSizeMB = files[0].size / 1024 ** 2;
        if (fileSizeMB > 20) {
          setError('File size exceeds 20MB');
        }
        // else if (regEx.test(files[0].name) === false) {
        //   setError('File name is invalid');
        // }
        else {
          onChange({ file: files[0], name: files[0].name });
          const filedata = { file: files[0], name: files[0].name };
          dispatch(uploadPackageFileCheck(formValues, filedata));
        }
      } else {
        dispatch(uploadPackageFileCheckReset());
      }
    };

    return (
      <FormGroupFile>
        <input
          type="file"
          accept=".zip"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          ref={ref}
        />
        {error && <StyledError>{error}</StyledError>}
      </FormGroupFile>
    );
  }
);

PackageFileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

PackageFileUpload.defaultProps = {
  value: null,
};

export default renderComponentField(PackageFileUpload);

// region STYLES

const StyledError = styled.span`
  font-size: 10px;
  line-height: 13px;
  color: #ff4861;
  margin-bottom: -5px;
  display: block;
  margin-top: 10px;
  padding: 0px !important;
`;

const FormGroupFile = styled.div`
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 6px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    border: 1px solid ${colorFieldsBorder};
    color: ${colorText};
    verticle &:hover {
      background: ${colorFieldsBorder};
    }
  }

  span {
    ${paddingLeft}: 10px;
  }

  input::file-selector-button {
    border: none;
    background-color: ${colorAccent};
    color: ${colorWhite};
    cursor: pointer;
    float: right;
    max-height: 20px;
    margin-right: 0;
  }
  input::file-selector-button:hover {
    background-color: ${colorAccentHover};
    transition: all 0.2s ease-in-out;
  }
`;

// endregion

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import RegisteredVehicleDetailsImage from './components/RegisteredVehicleDetailsImage';
import RegisteredGatewayDetails from './components/RegisteredGatewayDetails';
import MaintenanceSetupContainer from './components/MaintenanceSetupContainer';
import HvacComponnents from '../HvacComponents';
import { dataObject } from '../../App/Router/data';

const HvacDashboard = () => {
  const assetType = localStorage.getItem('asset');
  const { viewRole } = dataObject;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Manage Components</h3>
          <h6 style={{ paddingBottom: '20px' }}>Add or Edit Assets</h6>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} className="mb-2">
          <RegisteredVehicleDetailsImage hasLink={true} link={'/wform'} assetType={assetType} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <RegisteredGatewayDetails />
        </Col>
      </Row>
      {!viewRole.includes(userRole) && (
        <Row className="mb-2">
          <Col md={12}>
            <MaintenanceSetupContainer />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12} lg={12}>
          <HvacComponnents />
        </Col>
      </Row>
    </Container>
  );
};

export default HvacDashboard;

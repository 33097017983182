//Register gateway master
export const REGISTER_CPU_NUMBER_LOADING = 'REGISTER_CPU_NUMBER_LOADING';
export const REGISTER_CPU_NUMBER_SUCCESS = 'REGISTER_CPU_NUMBER_SUCCESS';
export const REGISTER_CPU_NUMBER_FAIL = 'REGISTER_CPU_NUMBER_FAIL';

//Get gateway master list
export const GET_GATEWAY_MASTER_LIST_LOADING = 'GET_GATEWAY_MASTER_LIST_LOADING';
export const GET_GATEWAY_MASTER_LIST_SUCCESS = 'GET_GATEWAY_MASTER_LIST_SUCCESS';
export const GET_GATEWAY_MASTER_LIST_FAIL = 'GET_GATEWAY_MASTER_LIST_FAIL';

//Get cpu id master list
export const GET_CPUID_MASTER_LIST_LOADING = 'GET_CPUID_MASTER_LIST_LOADING';
export const GET_CPUID_MASTER_LIST_SUCCESS = 'GET_CPUID_MASTER_LIST_SUCCESS';
export const GET_CPUID_MASTER_LIST_FAIL = 'GET_CPUID_MASTER_LIST_FAIL';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import TableNoData from '@/shared/components/TableNoData';
import getVehicleAlarms from '../../../../redux/actions/VehicleDashboardActions/getVehicleAlarmsActions';
import AlarmModalTable from './AlarmModalTable';
import DCAlarmModal from './DCAlarmModal';
import downloadIndexErrorCode from '../../../../redux/actions/VehicleDashboardActions/downloadIndexErrorCodeActions';

const VehicleHealthStatus = ({ reactTableData, vin }) => {
  const dispatch = useDispatch();

  const filterData = [
    {
      id: 1,
      value: 'status-open',
      label: 'Open',
    },
    {
      id: 2,
      value: 'status-closed',
      label: 'Closed',
    },
  ];

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredVal, setFilteredVal] = useState('');
  const [filteredLabel, setFilteredLabel] = useState('');

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const { loading, info, error } = useSelector((state) => state.getVehicleAlarmsReducer);
  const closeVehicleAlarmReducerInfo = useSelector((state) => state.closeVehicleAlarmReducer.info);

  //Need to replace refreshTable with closealarmreducer in the dependency.
  useEffect(() => {
    dispatch(getVehicleAlarms(vin, pageNumber, numberofRecords, reactTableData.sort, filteredVal));
  }, [closeVehicleAlarmReducerInfo, vin, reactTableData.sort, filteredVal]);

  //Need fetchApiData to make api call everytime page is changed
  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(getVehicleAlarms(vin, pageIndex + 1, pageSize, reactTableData.sort, filteredVal));
  };

  const getVehicleGatewayDetailsReducerInfo = useSelector(
    (state) => state.getVehicleGatewayDetailsReducer.info
  );

  const gatewayId = getVehicleGatewayDetailsReducerInfo[0]
    ? getVehicleGatewayDetailsReducerInfo[0].vehicle[0].gateway_id
    : '';

  const vinNumber = getVehicleGatewayDetailsReducerInfo[0]
    ? getVehicleGatewayDetailsReducerInfo[0].vehicle[0].vin
    : '';

  const handleChange = (values) => {
    if (values) {
      setFilteredVal(values.value);
      setFilteredLabel(values.label);
    }
    if (!values) {
      setFilteredVal('');
      setFilteredLabel('');
    }
  };

  const handleSubmit = () => {};

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>VEHICLE HEALTH STATUS</CardTitle>
              <CardSubhead>{`All ${filteredVal ? filteredLabel : 'Open'} Alarms`}</CardSubhead>
            </CardTitleWrap>
            {gatewayId ? (
              <div
                style={{
                  width: '200px',
                }}
              >
                <p
                  style={{
                    cursor: 'pointer',
                    color: '#1368B1',
                  }}
                  onClick={() => dispatch(downloadIndexErrorCode(gatewayId, vinNumber))}
                >
                  <FileDownloadIcon />
                  E-Control Error Memory
                </p>
              </div>
            ) : (
              ''
            )}
            <div style={{ width: '200px' }}>
              <Form onSubmit={handleSubmit}>
                {({ handleSubmit }) => (
                  <FormContainer onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                      <FormGroupField>
                        <Field
                          name="filter"
                          component={renderSelectField}
                          type="text"
                          placeholder="Please select a filter"
                          options={filterData}
                          parse={(e) => {
                            handleChange(e);
                            return e;
                          }}
                          initialValue={filterData[0]}
                          isClearable
                        />
                      </FormGroupField>
                    </FormGroup>
                  </FormContainer>
                )}
              </Form>
            </div>
          </HeaderWrap>
          <ReactTableBase
            columns={reactTableData.tableHeaderData}
            data={info ? info.alarms : []}
            tableConfig={tableConfig}
            paginationDetail={
              info && info.pagination
                ? info.pagination
                : {
                    totalPages: 0,
                    totalRecords: 0,
                  }
            }
            loading={loading}
            fetchData={fetchApiData}
            dashboardType={'Diagnostics'}
            isFiltered={filteredVal}
          />
          {loading ? (
            ''
          ) : info && info.alarms ? (
            info.alarms.length === 0 ? (
              <TableNoData errorText={'No Alarms Found'} />
            ) : (
              ''
            )
          ) : (
            <TableNoData errorText={'No Alarms Found'} />
          )}
        </CardBody>
      </Card>
      {reactTableData &&
      reactTableData.openModal === true &&
      (reactTableData.alarmDetails.type === 'Preventive' ||
        reactTableData.alarmDetails.type === 'Conditional') ? (
        <AlarmModalTable
          openModalState={reactTableData.openModal}
          closeModalState={reactTableData.closeModal}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          vinNumber={vin}
          alarmDetails={reactTableData.alarmDetails}
          showInput={reactTableData.showInputBox}
          disableInput={reactTableData.disableInputBox}
        />
      ) : reactTableData &&
        reactTableData.openModal === true &&
        (reactTableData.alarmDetails.type === 'Data' ||
          reactTableData.alarmDetails.type === 'Connectivity' ||
          reactTableData.alarmDetails.type === 'DTC') ? (
        <DCAlarmModal
          openModalState={reactTableData.openModal}
          closeModalState={reactTableData.closeModal}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
          alarmDetails={reactTableData.alarmDetails}
          showInput={reactTableData.showInputBox}
          disableInput={reactTableData.disableInputBox}
        />
      ) : (
        ''
      )}
    </Col>
  );
};

VehicleHealthStatus.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default VehicleHealthStatus;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

// endregion

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import KpiCard from '@/shared/components/KpiCard';
import getTotalCompanyVehicles from '@/redux/actions/GlobalDashboardActions/getTotalCompanyVehicles';

const GlobalVehicleCount = () => {
  const dispatch = useDispatch();
  const { loading, info, error } = useSelector((state) => state.getTotalCompanyVehiclesReducer);

  useEffect(() => {
    dispatch(getTotalCompanyVehicles());
  }, []);

  return (
    <>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Green Vehicles'}
          color={'#006B38'}
          gradient={'green-vehicles'}
          count={info.green_vehicles}
          total={info.total_vehicles}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Orange Vehicles'}
          color={'#F57A08'}
          gradient={'orange-vehicles'}
          count={info.orange_vehicles}
          total={info.total_vehicles}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Red Vehicles'}
          color={'#D21D26'}
          gradient={'red-vehicles'}
          count={info.red_vehicles}
          total={info.total_vehicles}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Grey Vehicles'}
          color={'#A7AEB4'}
          gradient={'grey-vehicles'}
          count={info.grey_vehicles}
          total={info.total_vehicles}
          loading={loading}
          error={error}
        />
      </Col>
    </>
  );
};

export default GlobalVehicleCount;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import SimpleLoader from '@/shared/components/SimpleLoader';
import GatewayListing from './GatewayListing';
import RegisteredVehicleDetailsImage from '../../Hvac/components/RegisteredVehicleDetailsImage';
import { resetValues } from '../../../../redux/actions/registerGatewayActions';
import { getGateway } from '../../../../redux/actions/getGatewayActions';
import { simNumber } from '../../../../redux/actions/registerSimActions';
import GatewayEdit from './GatewayEdit';
import data from '../../../App/Router/data';

const GatewayRegistered = ({ hideVehicleDetails }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Gateways.jpg`;

  const history = useHistory();
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [editState, setEditState] = useState(false);
  const [stay, setStay] = useState(false);

  const getGatewayReducer = useSelector((state) => state.getGatewayReducer.info.data);
  const registerSimReducer = useSelector((state) => state.registerSimReducer);
  const loading = useSelector((state) => state.registerSimReducer.loading);

  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  const showFormContainer = () => {
    setShowForm(true);
  };

  const getGatewayList = (gatewayDetails) => {
    dispatch(getGateway(gatewayDetails));
  };

  useEffect(() => {
    dispatch(resetValues());
    setTimeout(() => {
      const getVinDetails = JSON.parse(localStorage.getItem('vehicleDetails'));
      const vinNumber = getVinDetails.vin;
      getGatewayList(vinNumber);
    }, 2000);
    return () => {
      setStay(false);
    };
  }, []);

  const onSubmit = (values) => {
    if (values && values.sim_number) {
      const getGatewayFromLocal = JSON.parse(localStorage.getItem('GatewayDetails'));
      const getVinDetails = JSON.parse(localStorage.getItem('vehicleDetails'));
      const vinNumber = getVinDetails.vin;
      dispatch(simNumber(getGatewayFromLocal, values.sim_number, vinNumber));
      setStay(true);
    }
  };

  if (registerSimReducer.info && stay === true) {
    setTimeout(() => {
      setStay(false);
      history.goBack();
    }, 100);
  }

  const handleSave = () => {
    if (!viewRole.includes(userRole)) {
      setEditState(true);
    }

    localStorage.setItem(
      'GatewayDetails',
      JSON.stringify(getGatewayReducer.gateway_detail.serial_number)
    );
    localStorage.setItem('SimDetails', JSON.stringify(getGatewayReducer.gateway_detail.sim_number));
  };

  return (
    <Container>
      {!hideVehicleDetails ? (
        <Row>
          <Col md={12} lg={12} className="mb-2">
            <RegisteredVehicleDetailsImage hasLink={false} />
          </Col>
        </Row>
      ) : (
        ''
      )}
      <>
        {editState === true ? (
          <GatewayEdit editState={editState} setEditState={setEditState} />
        ) : (
          <Col md={6} lg={6} sm={12}>
            <Card height="auto">
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Gateway Details</CardTitle>
                </CardTitleWrap>
                {!getGatewayReducer || Object.entries(getGatewayReducer).length === 0 ? (
                  <SimpleLoader />
                ) : (
                  <div
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    <GatewayListing
                      gatewayManufacturer={getGatewayReducer.gateway_detail.manufacturer}
                      companyName={getGatewayReducer.gateway_detail.company_name}
                      gatewaySerialNumber={getGatewayReducer.gateway_detail.serial_number}
                      img={Ava}
                      sim={getGatewayReducer.gateway_detail.sim_number}
                      showEdit={!viewRole.includes(userRole) ? true : false}
                    />
                  </div>
                )}
                {showForm ? (
                  <Form onSubmit={onSubmit}>
                    {({ handleSubmit }) => (
                      <FormContainer onSubmit={handleSubmit}>
                        <FormGroup>
                          <FormGroupLabel>Connect SIM to the Gateway</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="sim_number"
                              component="input"
                              type="text"
                              placeholder="Enter sim number"
                            />
                          </FormGroupField>
                          {loading ? <SimpleLoader /> : ''}
                        </FormGroup>
                        <FormButtonToolbar
                          style={{
                            width: '100%',
                            marginTop: '0',
                            paddingTop: '50px',
                          }}
                        >
                          <Button
                            variant="secondary"
                            type="submit"
                            style={{ width: '100%' }}
                            onClick={() => {
                              history.push('/app/hvac_dashboard');
                            }}
                          >
                            Skip
                          </Button>
                        </FormButtonToolbar>
                        <FormButtonToolbar
                          style={{
                            width: '100%',
                            marginTop: '0',
                          }}
                        >
                          <Button variant="primary" type="submit" style={{ width: '100%' }}>
                            Connect
                          </Button>
                        </FormButtonToolbar>
                      </FormContainer>
                    )}
                  </Form>
                ) : getGatewayReducer && getGatewayReducer.gateway_detail.sim_number === null ? (
                  <>
                    <FormButtonToolbar
                      style={{
                        width: '100%',
                        marginTop: '0',
                        paddingTop: '50px',
                      }}
                    >
                      <Button
                        variant="secondary"
                        type="submit"
                        style={{ width: '100%' }}
                        onClick={() => {
                          history.push('/app/hvac_dashboard');
                        }}
                      >
                        Skip
                      </Button>
                    </FormButtonToolbar>
                    <FormButtonToolbar
                      style={{
                        width: '100%',
                        marginTop: '0',
                      }}
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: '100%' }}
                        onClick={showFormContainer}
                      >
                        Connect Gateway to a SIM
                      </Button>
                    </FormButtonToolbar>
                  </>
                ) : (
                  <FormButtonToolbar
                    style={{
                      width: '100%',
                      marginTop: '0',
                      paddingTop: '50px',
                    }}
                  >
                    <Button
                      variant="secondary"
                      type="submit"
                      style={{ width: '100%' }}
                      onClick={() => {
                        history.push('/app/hvac_dashboard');
                      }}
                    >
                      Cancel
                    </Button>
                  </FormButtonToolbar>
                )}
              </CardBody>
            </Card>
          </Col>
        )}
      </>
    </Container>
  );
};

export default GatewayRegistered;

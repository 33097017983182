import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { colorBackground, colorText } from '@/utils/palette';

export const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    min-width: 90vw;
  }
  .modal-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: ${colorBackground};
    color: ${colorText};
  }
`;

export const StyledModalHeader = styled(BootstrapModal.Header)`
  display: block;
  padding: 30px 25px 0 25px;
  font-size: 16px;
  border: 0;
  color: #646777;
`;

export const StyledModalTitle = styled(BootstrapModal.Title)`
  font-size: 16px;
`;

export const StyledModalBody = styled(BootstrapModal.Body)`
  padding-right: 25px;
  padding-left: 25px;
  min-height: 50vh;
  overflow: auto;
`;

export const StyledModalFooter = styled(BootstrapModal.Footer)`
  border: 0;
  display: block;
  text-align: center;
  padding: 20px 0 30px 0;
  margin: 0;
`;

export const StyledDCModal = styled(BootstrapModal)`
  .modal-dialog {
    min-width: 20vw;
  }
  .modal-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: ${colorBackground};
    color: ${colorText};
  }
`;

export const StyledDCModalHeader = styled(BootstrapModal.Header)`
  display: block;
  padding: 30px 25px 0 25px;
  font-size: 16px;
  border: 0;
  color: #646777;
  text-align: center;
`;

export const StyledDCModalTitle = styled(BootstrapModal.Title)`
  font-size: 16px;
`;

export const StyledDCModalBody = styled(BootstrapModal.Body)`
  padding-right: 25px;
  padding-left: 25px;
  min-height: 10vh;
  overflow: auto;
  text-align: center;
`;

export const StyledDCModalFooter = styled(BootstrapModal.Footer)`
  border: 0;
  display: block;
  text-align: center;
  padding: 20px 0 30px 0;
  margin: 0;
`;

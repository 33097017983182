import axios from 'axios';
import {
  GET_GATEWAY_DIAGNOSTICS_LIST_LOADING,
  GET_GATEWAY_DIAGNOSTICS_LIST_SUCCESS,
  GET_GATEWAY_DIAGNOSTICS_LIST_FAIL,
} from '@/shared/constants/GatewayDiagnosticsConstants/gatewayDiagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '../../../config/GlobalUrl';

const getGatewayDiagnosticsList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_GATEWAY_DIAGNOSTICS_LIST_LOADING,
    });

    const response = await axios.get(ApiURL.GET_GATEWAY_DIAGNOSTICS_LIST + '?p=1&page_size=99999', getConfig());

    dispatch({
      type: GET_GATEWAY_DIAGNOSTICS_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getGatewayDiagnosticsList;

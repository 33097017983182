import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  UPDATE_USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
} from '@/shared/constants/UserProfileConstants/userProfileConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';

const updateUserProfile = (formVals) => async (dispatch) => {
  const { user_id } = JSON.parse(localStorage.getItem('user'));

  try {
    dispatch({
      type: UPDATE_USER_PROFILE_LOADING,
    });

    const { notify, profile_photo } = formVals;
    const body = {
      is_notification_active: notify,
      profile_photo,
    };

    const response = await axios.post(
      ApiURL.GET_USER_PROFILE + `/${user_id}/profile/update`,
      body,
      getConfig()
    );

    successMessage('Profile details updated successfully!');

    dispatch({
      type: UPDATE_USER_PROFILE_SUCCESS,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);

      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response);

      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
        payload: error,
      });

      return error.response;
    }
  }
};

export default updateUserProfile;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { paddingLeft, right, left } from '@/utils/directions';

const CompanyDetailsContainer = ({
  name,
  website,
  role,
  city,
  country,
  img,
  onClick,
  showLink,
}) => {
  return (
    <MainContainer onClick={onClick}>
      <CompanyAvatar>
        <img src={img} alt="avatar" />
      </CompanyAvatar>
      <CompanyDetails>
        <CompanyName>{name}</CompanyName>
        <CompanyWebsite>{website}</CompanyWebsite>
        <CompanyOtherDetails>{role}</CompanyOtherDetails>
        <CompanyOtherDetails dir="ltr">
          {city}
          {', '}
          {country}
        </CompanyOtherDetails>
      </CompanyDetails>

      <EditCompanyLink>
        {showLink === true ? <span className="lnr lnr-chevron-right" /> : <span></span>}
      </EditCompanyLink>
    </MainContainer>
  );
};

CompanyDetailsContainer.propTypes = {
  name: PropTypes.string,
  website: PropTypes.string,
  role: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  img: PropTypes.string,
  onClick: PropTypes.func,
  showLink: PropTypes.bool,
};

CompanyDetailsContainer.defaultProps = {
  showLink: true,
};

export default CompanyDetailsContainer;

// region STYLES

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: ${left};
  padding: 20px;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const CompanyAvatar = styled.div`
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
  }
`;

const CompanyDetails = styled.div`
  width: calc(100% - 140px);
  ${paddingLeft}: 20px;

  @media screen and (max-width: 920px) {
    padding-left: 0px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const CompanyName = styled.p`
  font-weight: 900;
  margin: 0;
  line-height: 18px;
`;

const CompanyWebsite = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const CompanyOtherDetails = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const EditCompanyLink = styled.div`
  margin-top: 3px;
  top: 32%;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
  color: #1368b1;
`;
// endregion

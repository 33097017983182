import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_COMPANY_TAGS_LOADING,
  GET_COMPANY_TAGS_SUCCESS,
  GET_COMPANY_TAGS_FAIL,
  GET_DEPOT_TAGS_LOADING,
  GET_DEPOT_TAGS_SUCCESS,
  GET_DEPOT_TAGS_FAIL,
  GET_DEPOT_TAGS_RESET,
} from '../../../shared/constants/VehicleDashboardConstants/getCompanyTagsConstants';
import { getConfig } from '../../../shared/helpers/apiConfig';

const getCompanyTags = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_TAGS_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // const userDetails = JSON.parse(localStorage.getItem('user'));
    // const companyId = userDetails.company_id;

    const response = await axios.get(ApiURL.GET_VEHICLE_TAGS + companyId + '/tags', config);
    dispatch({
      type: GET_COMPANY_TAGS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPANY_TAGS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMPANY_TAGS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getDepotTags = (depotId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEPOT_TAGS_LOADING,
    });
    const { data } = await axios.get(ApiURL.GET_DEPOT_TAGS + depotId + '/tag_list', getConfig());
    dispatch({
      type: GET_DEPOT_TAGS_SUCCESS,
      payload: data.data.depot_taglist.map(({ tag_id, tag_ }) => ({
        id: tag_id,
        value: tag_id,
        label: tag_,
      })),
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_DEPOT_TAGS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_DEPOT_TAGS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getDepotTagsReset = () => async (dispatch) => {
  dispatch({
    type: GET_DEPOT_TAGS_RESET,
  });
};

export default getCompanyTags;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import KpiCard from '@/shared/components/KpiCard';
import getAlarmCount from '@/redux/actions/HealthStatusDashboardActions/getAlarmCount';

const AlarmCount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlarmCount());
  }, []);

  const { loading, info, error } = useSelector((state) => state.getAlarmCountReducer);

  return (
    <>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Total Open Alarms'}
          color={'#37BAEB'}
          gradient={'total-vehicles'}
          count={info.total}
          total={info.total}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Open Orange Alarms'}
          color={'#FF754A'}
          gradient={'data-monitoring'}
          count={info.orange}
          total={info.total}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Open Red Alarms'}
          color={'#DC2323'}
          gradient={'condition-based'}
          count={info.red}
          total={info.total}
          loading={loading}
          error={error}
        />
      </Col>
      <Col md={12} xl={3} lg={6} xs={12}>
        <KpiCard
          title={'Open Grey Alarms'}
          color={'#B4C6CC'}
          gradient={'connectivity-status'}
          count={info.grey}
          total={info.total}
          loading={loading}
          error={error}
        />
      </Col>
    </>
  );
};

export default AlarmCount;

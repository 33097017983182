import React from 'react';
import PackageListingTable from './components/PackageListingTable';
import CreateTableData from './components/CreateData';

const PackageListing = () => {
  const reactTableData = CreateTableData();

  return <PackageListingTable reactTableData={reactTableData} />;
};

export default PackageListing;

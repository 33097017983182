import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import getAlarmChecklist from '../../../../redux/actions/HealthStatusDashboardActions/getAlarmChecklistActions';
import Badge from '@/shared/components/Badge';
import { GetTableHeader } from '../../../../utils/helpers';

const CreateTableData = () => {
  const [showModal, setShowModal] = useState(false);
  const [rowDetails, setRowDetails] = useState(); // need to check if sending empty object fix the undefined error
  const [disableInput, setDisableInput] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const handleClick = (rowData, showInputBox, disableInputBox) => {
    dispatch(getAlarmChecklist(rowData.vin, rowData.id));
    setShowModal(true);
    setRowDetails(rowData);
    setShowInput(showInputBox);
    setDisableInput(disableInputBox);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Type'}
            key={'Alarm Type'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'type',
        disableGlobalFilter: true,
        Cell: (props) => {
          const bg = props.row.original.color;
          const alarmType = props.row.original.type;
          return <Badge bg={bg}>{alarmType}</Badge>;
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Id'}
            key={'Alarm Id'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'id',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (props.row.original.type === 'Preventive' &&
            props.row.original.status !== 'closed') ||
            (props.row.original.type === 'Conditional' &&
              props.row.original.status !== 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, false)}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Preventive' &&
              props.row.original.status === 'closed') ||
            (props.row.original.type === 'Conditional' &&
              props.row.original.status === 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              // onClick={() => handleClick(props.row.original, true, true)}
              disabled={true}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Connectivity' &&
              props.row.original.status !== 'closed') ||
            (props.row.original.type === 'Data' && props.row.original.status !== 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, false)}
            >
              {props.cell.value}
            </Button>
          ) : (props.row.original.type === 'Connectivity' &&
              props.row.original.status === 'closed') ||
            (props.row.original.type === 'Data' && props.row.original.status === 'closed') ||
            (props.row.original.type === 'DTC' && props.row.original.status === 'closed') ? (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              // onClick={() => handleClick(props.row.original, true, true)}
              disabled={true}
            >
              {props.cell.value}
            </Button>
          ) : (
            <Button
              style={{
                width: '100px',
                height: '30px',
                padding: '0',
                fontSize: '13px',
                margin: '0',
                color: '#787985',
              }}
              variant="secondary"
              onClick={() => handleClick(props.row.original, true, true)}
            >
              {props.cell.value}
            </Button>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Alarm Details'}
            key={'Alarm Details'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'metadata.display_name',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <div onClick={() => handleClick(row.original, true, true)}>
              <LabelSpan>
                {row.original.metadata
                  ? `${row.original.metadata.display_name.substring(0, 30)}...`
                  : ''}
              </LabelSpan>
            </div>
          );
        },
      },
      {
        Header: 'Time (in UTC)',
        accessor: 'time',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return row.original.triggered_at.split('T')[1].split('.')[0];
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Date'}
            key={'Date'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'triggered_at',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return row.original.triggered_at.split('T')[0];
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Fleet'}
            key={'Fleet'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'fleet',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'VIN'}
            key={'VIN'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vin',
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                localStorage.setItem('viewVin', JSON.stringify(row.original.vin));
                history.push('/global/depot/vehicle');
              }}
            >
              <LabelSpan>{row.original.vin ? row.original.vin : ''}</LabelSpan>
            </div>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Bus Number'}
            key={'Bus Number'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'bus_num',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'depo',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Service Partner'}
            key={'Service Partner'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'service_provider',
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    openModal: showModal,
    closeModal: setShowModal,
    alarmDetails: rowDetails,
    showInputBox: showInput,
    disableInputBox: disableInput,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

//Add some hover effect and show visually that this is link
const LabelSpan = styled.span`
  cursor: pointer;
  color: #1368b1;

  &:hover {
    // color: #1368B1;
    text-decoration: underline;
  }
`;

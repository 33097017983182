import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorAdditional, colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const FleetListContainer = ({ img, fleetName, companyName, region, climateZone, error }) => (
  <FleetListContainerWrap>
    {!error ? (
      <>
        <FleetListContainerImageWrap>
          <img src={img} alt="" />
        </FleetListContainerImageWrap>
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            padding: '0',
            margin: '0',
          }}
        >
          <div style={{ paddingBottom: '5px', paddingTop: '0' }}>
            <FleetListContainerName>
              {fleetName}
              {', '}
              {companyName}
            </FleetListContainerName>
          </div>
          <div>
            <FleetListContainerSub>
              {region}
              {', '}
              {climateZone}
            </FleetListContainerSub>
          </div>
        </div>
      </>
    ) : (
      <div>{error}</div>
    )}
  </FleetListContainerWrap>
);

FleetListContainer.propTypes = {
  img: PropTypes.string,
  fleetName: PropTypes.string,
  companyName: PropTypes.string,
  region: PropTypes.string,
  climateZone: PropTypes.string,
  error: PropTypes.string,
};

export default FleetListContainer;

// region STYLES

const FleetListContainerWrap = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};

  &:last-child {
    border-bottom: none;
  }
`;

const FleetListContainerLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
`;

const FleetListContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const FleetListContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`;

const FleetListContainerSub = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #999999;
`;

const FleetListContainerCount = styled.p`
  color: ${colorAdditional};
  line-height: 15px;
  font-size: 11px;
  margin: 0;

  @media (max-width: 1100px) and (min-width: 990px) {
    font-size: 10px;
  }
`;

// endregion

import {
  GET_MAINTENANCE_LOADING,
  GET_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE_FAIL,
} from '@/shared/constants/MaintenanceConstants/maintenanceMetaConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getMaintenanceMetaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAINTENANCE_LOADING:
      return { ...state, loading: true };
    case GET_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getMaintenanceMetaReducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import CreateTableData from './CreateData';
import getNotificationList from '@/redux/actions/SystemSettingsActions/ManageNotifications/getNotificationList';

const NotificationListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reactTableData = CreateTableData();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const { info, loading, error, pagination } = useSelector(
    (state) => state.getNotificationListReducer
  );

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  useEffect(() => {
    dispatch(getNotificationList(pageNumber, numberofRecords, reactTableData.sort));
  }, [reactTableData.sort]);

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(getNotificationList(pageIndex + 1, pageSize, reactTableData.sort));
  };

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={12} lg={10} sm={12}>
                  <CardTitleWrap>
                    <CardTitle>Notification List</CardTitle>
                  </CardTitleWrap>
                </Col>
                <Col md={12} lg={2} sm={12} className={'d-flex justify-content-end'}>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      history.push('/notifications/create');
                    }}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
              <ReactTableBase
                columns={reactTableData.tableHeaderData}
                data={info ? info : []}
                tableConfig={tableConfig}
                paginationDetail={pagination}
                loading={loading}
                fetchData={fetchApiData}
                dashboardType={'Diagnostics'}
              />
              {loading ? '' : info && info.length !== 0 ? '' : <TableNoData />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationListing;

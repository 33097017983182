import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorAdditional, colorBorder } from '@/utils/palette';
import { right, marginRight, left } from '@/utils/directions';

const ManageFleetContainer = ({ img, assettype, link, showLink }) => (
  <ManageFleetContainerWrap>
    <ManageFleetContainerImageWrap>
      <img src={img} alt='' />
    </ManageFleetContainerImageWrap>
    <div>
      <ManageFleetContainerName>{assettype}</ManageFleetContainerName>
    </div>
    <ManageFleetContainerLink>
      {showLink === true ? (
        <span style={{ color: '#1368B1' }} className='lnr lnr-chevron-right' />
      ) : (
        <span></span>
      )}
    </ManageFleetContainerLink>
  </ManageFleetContainerWrap>
);

ManageFleetContainer.propTypes = {
  img: PropTypes.string.isRequired,
  assettype: PropTypes.string.isRequired,
  link: PropTypes.string,
  showLink: PropTypes.bool,
};

ManageFleetContainer.defaultProps = {
  showLink: true,
};
export default ManageFleetContainer;

// region STYLES

const ManageFleetContainerWrap = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const ManageFleetContainerLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;

  // svg {
  //   ${marginRight}: 0;
  //   margin-top: 2px;
  //   height: 16px;
  //   width: 16px;
  // }
`;

const ManageFleetContainerImageWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const ManageFleetContainerName = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 10px;
`;

const ManageFleetContainerCount = styled.p`
  color: ${colorAdditional};
  line-height: 15px;
  font-size: 11px;
  margin: 0;

  @media (max-width: 1100px) and (min-width: 990px) {
    font-size: 10px;
  }
`;

// endregion

import {
  GET_METADATA_LOADING,
  GET_METADATA_SUCCESS,
  GET_METADATA_FAIL,
  GET_METADATA_RESET,
} from "@/shared/constants/OtaUpdateConstants/campaignMgtConstants";

const initialState = {
  loading: false,
  info: "",
  error: "",
  pagination: {},
};
const getMetadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_METADATA_LOADING:
      return { ...state, loading: true };
    case GET_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_METADATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_METADATA_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getMetadataReducer;

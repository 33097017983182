import React, { useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FormGroupField, FormContainer, FormGroup } from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getGroupList from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getGroupListAction';
import getCompanyData from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getCompanyDataAction';
import getmetadataAction, {
  getMetaDataResetAction,
} from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getmetadataAction';
import getPackageListing from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getPackageListing';

const Filters = () => {
  const dispatch = useDispatch();
  const onSubmit = () => {};

  const groupListInfo = useSelector((state) => state.getGroupListReducer.info);
  const { filterOptions } = useSelector((state) => state.getCompanyDataReducer);

  useEffect(() => {
    dispatch(getCompanyData('', ''));
  }, []);

  const getMetadataReducer = useSelector((state) => state.getMetadataReducer.info);

  const handleOperatorChange = (e) => {
    if (e) {
      dispatch(getCompanyData(`pto_id=${e.id}`, ''));
      dispatch(getPackageListing('', '', 1, 10));
      dispatch(getMetaDataResetAction());
    } else {
      dispatch(getCompanyData('', ''));
      dispatch(getPackageListing('', '', 1, 10));
      dispatch(getMetaDataResetAction());
    }
  };

  const handleFleetChange = (e, values) => {
    if (e) {
      dispatch(
        getCompanyData(
          values.operator_name ? `pto_id=${values.operator_name.id}` : '',
          `&fleet_id=${e.id}`
        )
      );
      dispatch(getPackageListing('', '', 1, 10));
      dispatch(getMetaDataResetAction());
    } else {
      dispatch(getCompanyData(values.operator_name ? `pto_id=${values.operator_name.id}` : '', ''));
      dispatch(getPackageListing('', '', 1, 10));
      dispatch(getMetaDataResetAction());
    }
  };

  const handleDepotChange = (e, values) => {
    if (e) {
      dispatch(getGroupList(e.id));
      dispatch(getPackageListing(e.id, values.group_name, 1, 10));
      dispatch(getMetaDataResetAction());
    } else {
      dispatch(getPackageListing('', '', 1, 10));
      dispatch(getMetaDataResetAction());
    }
  };

  const handleGroupChange = (e, values) => {
    if (e) {
      dispatch(getPackageListing(values.depot_name?.id, e.value, 1, 10));
      dispatch(getmetadataAction(values.depot_name.id, e.value));
    } else {
      getPackageListing(values.depot_name?.id, '', 1, 10);
      dispatch(getMetaDataResetAction());
    }
  };

  return (
    <Row className={'d-flex justify-content-end'}>
      <Col md={10} lg={10} sm={12} style={{ marginRight: '15px' }}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, hasValidationErrors, values }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormGroup style={{ marginBottom: '0' }}>
                <Row>
                  <StyledCol>
                    <FormGroupField>
                      <Field
                        name="operator_name"
                        component={renderSelectField}
                        options={filterOptions.operators}
                        placeholder="Select company"
                        isClearable
                        parse={(e) => {
                          handleOperatorChange(e);
                          form.change('fleet_name', '');
                          form.change('depot_name', '');
                          form.change('group_name', '');
                          form.change('software-version', '');
                          return e;
                        }}
                      />
                    </FormGroupField>
                  </StyledCol>
                  <StyledCol>
                    <FormGroupField>
                      <Field
                        name="fleet_name"
                        component={renderSelectField}
                        options={filterOptions.fleets}
                        placeholder="Select fleet"
                        isClearable
                        parse={(e) => {
                          handleFleetChange(e, values);
                          form.change('depot_name', '');
                          form.change('group_name', '');
                          form.change('software-version', '');
                          return e;
                        }}
                      />
                    </FormGroupField>
                  </StyledCol>
                  <StyledCol>
                    <FormGroupField>
                      <Field
                        name="depot_name"
                        component={renderSelectField}
                        options={filterOptions.depots}
                        placeholder="Select depot"
                        isClearable
                        parse={(e) => {
                          handleDepotChange(e, values);
                          form.change('group_name', '');
                          form.change('software-version', '');
                          return e;
                        }}
                      />
                    </FormGroupField>
                  </StyledCol>
                  <StyledCol>
                    <FormGroupField>
                      <Field
                        name="group_name"
                        component={renderSelectField}
                        options={
                          groupListInfo && values.depot_name
                            ? groupListInfo.groups_list.map(({ id, name }) => ({
                                id: id,
                                label: name,
                                value: name,
                              }))
                            : []
                        }
                        placeholder="Select Group"
                        parse={(e) => {
                          handleGroupChange(e, values);
                          form.change('software-version', '');
                          return e;
                        }}
                      />
                    </FormGroupField>
                  </StyledCol>
                  <StyledCol>
                    <FormGroupField>
                      <Field
                        name="software-version"
                        placeholder="Select Version Number"
                        component={renderSelectField}
                        options={
                          getMetadataReducer
                            ? getMetadataReducer.packages.map((item) => {
                                return {
                                  id: item.software_version,
                                  value: item.software_version,
                                  label: item.software_version,
                                  metadata: item.metadata,
                                };
                              })
                            : []
                        }
                      />
                    </FormGroupField>
                  </StyledCol>
                </Row>
              </FormGroup>
            </FormContainer>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const StyledCol = styled(Col)`
  padding: 0px !important;
`;

export default Filters;

//OTA Update - Package Management Constants
export const GET_COMPANY_DATA_LOADING = 'GET_COMPANY_DATA_LOADING';
export const GET_COMPANY_DATA_SUCCESS = 'GET_COMPANY_DATA_SUCCESS';
export const GET_COMPANY_DATA_FAIL = 'GET_COMPANY_DATA_FAIL';

//OTA Update - Group List Constants
export const GET_GROUP_LIST_LOADING = 'GET_GROUP_LIST_LOADING';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAIL = 'GET_GROUP_LIST_FAIL';
export const GET_GROUP_LIST_RESET = 'GET_GROUP_LIST_RESET';

//OTA Update - Upload Package File Check Constants
export const UPLOAD_PACKAGE_FILE_CHECK_LOADING = 'UPLOAD_PACKAGE_FILE_CHECK_LOADING';
export const UPLOAD_PACKAGE_FILE_CHECK_SUCCESS = 'UPLOAD_PACKAGE_FILE_CHECK_SUCCESS';
export const UPLOAD_PACKAGE_FILE_CHECK_FAIL = 'UPLOAD_PACKAGE_FILE_CHECK_FAIL';
export const UPLOAD_PACKAGE_FILE_CHECK_RESET = 'UPLOAD_PACKAGE_FILE_CHECK_RESET';

//OTA Update - Create Package Constants
export const CREATE_PACKAGE_LOADING = 'CREATE_PACKAGE_LOADING';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';
export const CREATE_PACKAGE_RESET = 'CREATE_PACKAGE_RESET';

//OTA Update - Get Package Listing Constants
export const GET_PACKAGE_LISTING_LOADING = 'GET_PACKAGE_LISTING_LOADING';
export const GET_PACKAGE_LISTING_SUCCESS = 'GET_PACKAGE_LISTING_SUCCESS';
export const GET_PACKAGE_LISTING_FAIL = 'GET_PACKAGE_LISTING_FAIL';
export const GET_PACKAGE_LISTING_RESET = 'GET_PACKAGE_LISTING_RESET';

//OTA Update - Package Management - Gateway Package Creation Constants

//OTA Update - Get Base Firmware Version Constants
export const GET_BASE_FIRMWARE_VERSION_LOADING = 'GET_BASE_FIRMWARE_VERSION_LOADING';
export const GET_BASE_FIRMWARE_VERSION_SUCCESS = 'GET_BASE_FIRMWARE_VERSION_SUCCESS';
export const GET_BASE_FIRMWARE_VERSION_FAIL = 'GET_BASE_FIRMWARE_VERSION_FAIL';
export const GET_BASE_FIRMWARE_VERSION_RESET = 'GET_BASE_FIRMWARE_VERSION_RESET';

//OTA Update - Create Gateway Package Constants
export const CREATE_GATEWAY_PACKAGE_LOADING = 'CREATE_GATEWAY_PACKAGE_LOADING';
export const CREATE_GATEWAY_PACKAGE_SUCCESS = 'CREATE_GATEWAY_PACKAGE_SUCCESS';
export const CREATE_GATEWAY_PACKAGE_FAIL = 'CREATE_GATEWAY_PACKAGE_FAIL';
export const CREATE_GATEWAY_PACKAGE_RESET = 'CREATE_GATEWAY_PACKAGE_RESET';

//OTA Update - Get Gateway Config Constants
export const GET_GATEWAY_CONFIG_LOADING = 'GET_GATEWAY_CONFIG_LOADING';
export const GET_GATEWAY_CONFIG_SUCCESS = 'GET_GATEWAY_CONFIG_SUCCESS';
export const GET_GATEWAY_CONFIG_FAIL = 'GET_GATEWAY_CONFIG_FAIL';
export const GET_GATEWAY_CONFIG_RESET = 'GET_GATEWAY_CONFIG_RESET';

//OTA Update - Package Management

//OTA Update - Download Package
export const DOWNLOAD_PACKAGE_LOADING = 'DOWNLOAD_PACKAGE_LOADING';
export const DOWNLOAD_PACKAGE_SUCCESS = 'DOWNLOAD_PACKAGE_SUCCESS';
export const DOWNLOAD_PACKAGE_FAIL = 'DOWNLOAD_PACKAGE_FAIL';

import {
  GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING,
  GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS,
  GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL,
  GET_GATEWAY_DIAGNOSTICS_MONTH_RESET,
} from '@/shared/constants/GatewayDiagnosticsConstants/gatewayDiagnosticsConstants';

const initialState = {
  loading: false,
  info: [],
  error: '',
};

const getGatewayDiagnosticsMonthReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING:
      return { ...state, loading: true };
    case GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GATEWAY_DIAGNOSTICS_MONTH_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default getGatewayDiagnosticsMonthReducer;

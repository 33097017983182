import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { simDetails } from '@/utils/validate';
import registerSimMaster from '@/redux/actions/SimMasterActions/registerSimMaster';

const SimMasterForm = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.registerSimMasterReducer);

  const handleRegister = (values, form) => {
    if (values.sim_number && values.imei_number) {
      dispatch(registerSimMaster(values)).then((res) => {
        if (res.status === 200) {
          form.restart();
        }
      });
    }
  };

  return (
    <Card height="auto">
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Sim Details</CardTitle>
          {loading && <DotDotLoader loadingState={loading} />}
        </CardTitleWrap>
        <Form onSubmit={handleRegister} validate={simDetails}>
          {({ handleSubmit, pristine, hasValidationErrors }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <FormGroupLabel>Sim Number</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="sim_number"
                    component={FormField}
                    type="text"
                    placeholder="Please enter sim number"
                    required
                    maxLength={50}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>IMEI Number</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="imei_number"
                    component={FormField}
                    type="text"
                    placeholder="Please enter IMEI number"
                    required
                    maxLength={50}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Country</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="country"
                    component={FormField}
                    type="text"
                    placeholder="Please enter country"
                    maxLength={30}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Country Code</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="country_code"
                    component={FormField}
                    type="text"
                    placeholder="Please enter country code"
                    maxLength={8}
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Vendor</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="operator"
                    component={FormField}
                    type="text"
                    placeholder="Please enter vendor"
                    maxLength={50}
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar
                style={{
                  width: '100%',
                  marginTop: '0',
                  paddingTop: '20px',
                }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  disabled={pristine || hasValidationErrors || loading}
                >
                  Register
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default SimMasterForm;

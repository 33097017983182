import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
} from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { handleUpdatePassword } from "@/utils/validate";
import PasswordField from "@/shared/components/form/Password";
import changePassword from "../../../../redux/actions/UserProfileActions/changePassword";

const ProfileChangePassword = () => {
  const dispatch = useDispatch();
  const loading = false;
  const handleUpdateUserProfile = (values, form) => {
    if (values && Object.keys(values).length > 0) {
      if (values.new_password === values.confirm_password) {
        dispatch(changePassword(values)).then((res) => {
          if (res.status === "success") {
            form.restart();
          }
        });
      }
    }
  };
  return (
    <Card>
      <CardBody>
        {/* <CardTitleWrap> */}
        {/* <CardTitle>User Details</CardTitle> */}
        {/* {loading && <DotDotLoader loadingState={loading} />} */}
        {/* </CardTitleWrap> */}
        <Form
          onSubmit={handleUpdateUserProfile}
          validate={handleUpdatePassword}
        >
          {({ handleSubmit, pristine, hasValidationErrors, values }) => (
            <FormContainer onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <FormGroupLabel>Current Password</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="old_password"
                    component={PasswordField}
                    type="password"
                    placeholder="Please enter current password"
                    keyIcon
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>New Password</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="new_password"
                    component={PasswordField}
                    type="password"
                    placeholder="Please enter new password"
                    keyIcon
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Confirm Password</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="confirm_password"
                    component={PasswordField}
                    type="password"
                    placeholder="Please re-enter new password"
                    keyIcon
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar
                style={{
                  width: "100%",
                  marginTop: "0",
                  paddingTop: "20px",
                }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    pristine ||
                    // hasValidationErrors ||
                    loading
                  }
                >
                  Change Password
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default ProfileChangePassword;

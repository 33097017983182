import axios from 'axios';
import {
  GET_HEALTH_STATUS_LOADING,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_FAIL,
  SET_FILTER_VALUE,
  RESET_FILTER_VALUE,
} from '@/shared/constants/HealthStatusDashboardConstants/getHealthStatusDetailConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { ApiURL } from '../../../config/GlobalUrl';
import { sortingFunc } from '../../../utils/helpers';

const getHealthStatusDetail =
  (companyId, pageNumber, numberofRecords, filter, sortData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_HEALTH_STATUS_LOADING,
      });

      const response = await axios.get(
        ApiURL.GET_HEALTH_STATUS_DETAIL +
          companyId +
          `/health_status?p=${pageNumber}&page_size=${numberofRecords}&sort=${sortingFunc(
            sortData
          )}${filter ? '&filter-' + filter.split('-')[0] + '=' + filter.split('-')[1] : ''}`,
        getConfig()
      );
      dispatch({
        type: GET_HEALTH_STATUS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_HEALTH_STATUS_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_HEALTH_STATUS_FAIL,
          payload: error.response.data.data.message,
        });
      }
    }
  };

export const setFilter = (filterValue) => (dispatch) => {
  dispatch({
    type: SET_FILTER_VALUE,
    payload: filterValue,
  });
};

export const resetFilter = () => (dispatch) => {
  dispatch({
    type: RESET_FILTER_VALUE,
  });
};
export default getHealthStatusDetail;

import {
  CREATE_CAMPAIGN_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const createCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN_LOADING:
      return { ...state, loading: true };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case CREATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default createCampaignReducer;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Pie, Tooltip, Legend, PieChart, ResponsiveContainer, Label } from 'recharts';
import getTooltipStyles from '@/shared/helpers';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import { colorText } from '@/utils/palette';
import DotDotLoader from '@/shared/components/DotDotLoader';

const style = {
  left: 0,
  width: '100%',
  lineHeight: '24px',
};

const renderLegend = ({ payload }) => (
  <PieChartLegend>
    {payload
      .filter((entry) => entry.value !== 'No Data')
      .map((entry) => (
        <li key={`${entry.color}-${entry.value}`}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <span style={{ backgroundColor: entry.color }} />
            <p
              style={{
                marginRight: '10px',
              }}
            >
              {entry.value}
            </p>
            {' - '}
            <p style={{ marginLeft: '10px' }}>{entry.payload.value}</p>
          </div>
        </li>
      ))}
  </PieChartLegend>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

const PieChartCard = ({
  dir,
  title,
  subheading,
  loading,
  data,
  placeholderData,
  total,
  error,
  datakey,
  handleClick,
}) => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const themeName = useSelector((state) => state.theme.className);

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({
        x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10,
        y: e.tooltipPosition.y,
      });
    }
  };

  return (
    <Card height="auto">
      <CardBody>
        <CardTitleWrap>
          <CardTitle>{title}</CardTitle>
          <CardSubhead>{subheading}</CardSubhead>
        </CardTitleWrap>
        <div style={{ height: '360px' }}>
          {loading ? (
            <DotDotLoader loadingState={loading} />
          ) : data ? (
            <ResponsiveContainer height={350}>
              <PieChart>
                {data?.length > 0 ? (
                  <Tooltip
                    position={coordinates}
                    {...getTooltipStyles(themeName)}
                    wrapperStyle={{ zIndex: '1000' }}
                  />
                ) : (
                  ''
                )}
                <Pie
                  data={data?.length > 0 ? data : placeholderData}
                  dataKey={datakey}
                  cy={85}
                  innerRadius={70}
                  outerRadius={80}
                  onMouseMove={data?.length > 0 ? onMouseMove : null}
                  onClick={(e) => (data?.length > 0 ? handleClick(e) : null)}
                  style={{ cursor: 'pointer' }}
                  animationBegin={0}
                  animationDuration={700}
                  animationEasing="ease-in"
                >
                  <Label value={total} position="center" style={{ fontSize: '56px' }} />
                </Pie>
                <Legend
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={style}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            'Error loading Data'
          )}
        </div>
      </CardBody>
    </Card>
  );
};

PieChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.any,
  dataKey: PropTypes.string.isRequired,
  total: PropTypes.number,
  handleClick: PropTypes.func,
};

PieChartCard.defaultProps = {
  title: '',
  subheading: '',
  loading: false,
  data: [],
  dataKey: '',
  total: 0,
  handleClick: () => {},
};

export default PieChartCard;

const PieChartLegend = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  li {
    margin-top: 5px;
    color: ${colorText};
  }
`;

import {
  GET_VIN_LOADING,
  GET_VIN_SUCCESS,
  GET_VIN_FAIL,
  GET_VIN_RESTORE_INITIAL_VALUES,
} from '../../shared/constants/getVinConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getVinReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIN_LOADING:
      return { ...state, loading: true, error: '' };
    case GET_VIN_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
      };
    case GET_VIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_VIN_RESTORE_INITIAL_VALUES:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default getVinReducer;

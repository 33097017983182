import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardBody } from '@/shared/components/Card';
import { paddingLeft, right, left } from '@/utils/directions';
import SimpleLoader from '@/shared/components/SimpleLoader';
import { getVin } from '../../../../redux/actions/getVinActions';
import data from '../../../App/Router/data';

const RegisteredVehicleDetailsImage = ({ link, hasLink, assetType }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;
  const dispatch = useDispatch();
  const [items, setItems] = useState({});
  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userRole = userDetails.user_role;

  useEffect(() => {
    const newVin = localStorage.getItem('NewVin');
    dispatch(getVin(newVin));
    setTimeout(() => {
      const vehicleDetails = JSON.parse(localStorage.getItem('vehicleDetails'));
      if (vehicleDetails) {
        setItems(vehicleDetails);
      }
    }, 1000);
  }, []);

  return (
    <Col xs={12} sm={12} md={6}>
      <Card>
        <CardBody>
          {!items || items === undefined || Object.keys(items).length === 0 ? (
            <SimpleLoader />
          ) : (
            <ProfileInformation as={hasLink && Link} to={link}>
              <ProfileAvatar>
                <img src={Ava} alt="avatar" />
              </ProfileAvatar>
              <ProfileData>
                <ProfileName>VIN {items.vin}</ProfileName>
                <ProfileWork>Body# {items.body_number}</ProfileWork>
                <ProfileContact>{items.brand_name}</ProfileContact>
                <ProfileContact dir="ltr">{items.model}</ProfileContact>
                <ProfileContact dir="ltr">{items.engine_type + ' engine'}</ProfileContact>
              </ProfileData>
              {hasLink && assetType === 'Vehicles' && !viewRole.includes(userRole) && (
                <EditVehicleLink>
                  <span className="lnr lnr-chevron-right" />
                </EditVehicleLink>
              )}
            </ProfileInformation>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

RegisteredVehicleDetailsImage.propTypes = {
  link: PropTypes.string,
};

export default RegisteredVehicleDetailsImage;

// region STYLES

const ProfileInformation = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: ${left};
  padding: 20px;

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileAvatar = styled.div`
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
  }
`;

const ProfileData = styled.div`
  width: calc(100% - 140px);
  ${paddingLeft}: 20px;

  @media screen and (max-width: 920px) {
    padding-left: 0px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const EditVehicleLink = styled.div`
  margin-top: 3px;
  top: 32%;
  position: absolute;
  ${right}: 0;
  padding: 5px 10px;
  line-height: 16px;
  color: #1368b1;
`;
// endregion

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_NOTIFICATION_LIST_LOADING,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAIL,
  GET_NOTIFICATION_LIST_RESET,
} from '@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { sortingFunc } from '@/utils/helpers.js';

const getNotificationList = (pageNumber, numberofRecords, sortData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATION_LIST_LOADING,
    });

    const params = {
      p: pageNumber ? pageNumber : null,
      page_size: numberofRecords ? numberofRecords : null,
      sort: sortData ? sortingFunc(sortData) : null,
    };

    const { data } = await axios.get(ApiURL.GET_NOTIFICATION_LIST, { params }, getConfig());

    dispatch({
      type: GET_NOTIFICATION_LIST_SUCCESS,
      payload: { data: data.data.notification_counts, pagination: data.pagination },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_NOTIFICATION_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_NOTIFICATION_LIST_FAIL,
        // payload: error.response.data.data.message,
        payload: error,
      });
    }
  }
};

export const resetNotificationList = () => async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATION_LIST_RESET,
  });
};

export default getNotificationList;

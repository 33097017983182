import axios from "axios";
import { ApiURL } from "@/config/GlobalUrl";
import {
  GET_USERS_LIST_LOADING,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_RESET,
} from "@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants";
import { getConfig } from "@/shared/helpers/apiConfig.js";

const getUsersList = (company_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_LIST_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_USER_LIST_NOTI + company_id + "/get_company_user_list",
      getConfig()
    );

    const getUsersList = data?.data?.users?.map(({ id, username, email }) => ({
      id,
      value: username,
      label: `${username}, ${email}`,
    }));

    dispatch({
      type: GET_USERS_LIST_SUCCESS,
      payload: getUsersList,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_USERS_LIST_FAIL,
        payload: "network error",
      });
    } else {
      dispatch({
        type: GET_USERS_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export const resetUserListNotifications = () => async (dispatch) => {
  dispatch({
    type: GET_USERS_LIST_RESET,
  });
};

export default getUsersList;

import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import options from './dummyJSON.json';

const SelectionForm = ({
  handleCompanyChange,
  handleDepotChange,
  handleAlarmType,
  renderCompany,
  initialFormValues,
}) => {
  const alarmType = useSelector((state) => state.getDepotNotificationTypesReducer.info);
  const getDepotListLoading = useSelector((state) => state.getDepotListReducer.loading);
  const getDepotListInfo = useSelector((state) => state.getDepotListReducer.info);
  const getCompanyListReducerLoading = useSelector((state) => state.getCompanyListReducer.loading);
  const getCompanyListReducerInfo = useSelector((state) => state.getCompanyListReducer.info);

  const initialValues = initialFormValues
    ? {
        company: {
          id: initialFormValues.company_id,
          value: initialFormValues.company_name,
          label: initialFormValues.company_name,
        },
        depot_name: {
          id: initialFormValues.depot_id,
          value: initialFormValues.depot_name,
          label: initialFormValues.depot_name,
        },
        alarm_type: {
          id: initialFormValues.distribution_type,
          value: initialFormValues.distribution_type,
          label: initialFormValues.distribution_type,
        },
      }
    : {};

  const companyOptions =
    getCompanyListReducerInfo && Object.entries(getCompanyListReducerInfo.company_list).length !== 0
      ? getCompanyListReducerInfo.company_list.map(({ company_id, company_name, role }) => ({
          id: company_id,
          value: company_name,
          label: company_name + ', ' + role,
        }))
      : [];

  const handleSubmit = () => {};

  const newOptions = options.alarms.map((item) => {
    const isDisabled = alarmType?.types?.distribution_types.includes(item.id);
    const newLabel = isDisabled
      ? `${item.label} - List with the selected depot already exists`
      : item.label;

    return {
      ...item,
      isDisabled,
      label: newLabel,
    };
  });

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <FormContainer onSubmit={handleSubmit}>
          {renderCompany && (
            <FormGroup>
              <FormGroupLabel>Select Company</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="company"
                  component={renderSelectField}
                  type="text"
                  options={companyOptions}
                  placeholder="Select company"
                  parse={(e) => {
                    handleCompanyChange(e);
                    form.change('depot_name', '');
                    return e;
                  }}
                  isLoading={getCompanyListReducerLoading}
                  isDisabled={initialFormValues && initialFormValues.company_id}
                />
              </FormGroupField>
            </FormGroup>
          )}
          <FormGroup>
            <FormGroupLabel>Select Depot</FormGroupLabel>
            <FormGroupField>
              <Field
                name="depot_name"
                component={renderSelectField}
                options={getDepotListInfo ? getDepotListInfo : []}
                placeholder="Select depot"
                isClearable={true}
                isLoading={getDepotListLoading}
                parse={(e) => {
                  handleDepotChange(e);
                  form.change('alarm_type');
                  return e;
                }}
                isDisabled={
                  initialFormValues && initialFormValues.depot_id
                    ? true
                    : renderCompany
                    ? !values.company
                    : false
                }
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Alarm Type</FormGroupLabel>
            <FormGroupField>
              <Field
                name="alarm_type"
                component={renderSelectField}
                options={newOptions}
                placeholder="Alarm Type"
                parse={(e) => {
                  handleAlarmType(e);
                  return e;
                }}
                isDisabled={initialFormValues && initialFormValues.distribution_type ? true : false}
              />
            </FormGroupField>
          </FormGroup>
        </FormContainer>
      )}
    </Form>
  );
};

export default SelectionForm;

import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_SUPPLIERS_LOADING,
  GET_SUPPLIERS_SUCCESS,
  GET_SUPPLIERS_FAIL,
} from '../../shared/constants/getSuppliersConstants';

const getSupplierComps =
  (vehicleEngineType, supplierName) => async dispatch => {
    try {
      dispatch({
        type: GET_SUPPLIERS_LOADING,
      });

      // setting up the config as headers content-type
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        ApiURL.GET_SUPPLIER_COMP_LIST +
          '?engine_type=' +
          vehicleEngineType +
          '&name=' +
          supplierName,
        config
      );

      dispatch({
        type: GET_SUPPLIERS_SUCCESS,
        payload: data.data.suppliers,
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_SUPPLIERS_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_SUPPLIERS_FAIL,
          payload: error.response.data.data.message,
        });
      }
    }
  };

export default getSupplierComps;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CreateTableData from './components/CreateData';
import GatewayMasterForm from './components/GatewayMasterForm';
import GatewayMasterList from './components/GatewayMasterList';

const GatewayMaster = () => {
  const reactTableData = CreateTableData();

  return (
    <Container>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <GatewayMasterForm />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <GatewayMasterList reactTableData={reactTableData} />
        </Col>
      </Row>
    </Container>
  );
};

export default GatewayMaster;

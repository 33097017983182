import {
  GET_USERS_LIST_LOADING,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_RESET,
} from "@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants";

const initialState = {
  loading: false,
  info: [],
  error: "",
};

const getUsersListNotiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_LIST_LOADING:
      return { ...state, loading: true };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USERS_LIST_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getUsersListNotiReducer;

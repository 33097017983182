import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_HVAC_MODELS_LOADING,
  GET_HVAC_MODELS_SUCCESS,
  GET_HVAC_MODELS_FAIL,
  GET_HVAC_MODELS_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getHvacModels = (depotId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HVAC_MODELS_LOADING,
    });

    const response = await axios.get(ApiURL.GET_HVAC_MODELS + depotId + '/hvac_components_models/list', getConfig());

    dispatch({
      type: GET_HVAC_MODELS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_HVAC_MODELS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_HVAC_MODELS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getHvacModelsReset = () => async (dispatch) => {
  dispatch({
    type: GET_HVAC_MODELS_RESET,
  });
};

export default getHvacModels;

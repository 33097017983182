import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder, colorBackground, colorHover } from '@/utils/palette';
import { left } from '@/utils/directions';
import Path from '@/utils/routes/routePathNames';
import SidebarLink, { SidebarLinkTitle } from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import data from '../../../App/Router/data';
import { companyNameCheck } from '../../../../shared/helpers/storage';
import updateOtaRowReset from '@/redux/actions/OtaUpdateActions/OtaDashboardActions/updateOtaRowAction';

const SidebarContent = ({ onClick, changeToLight, changeToDark, collapse }) => {
  const [{ adminRole }, { viewRole }, { all }, { companyRole }, { companyAdmin }] = data;

  const dispatch = useDispatch();
  const [getCompanyRole, setGetCompanyRole] = useState('');
  const [getUserRole, setGetUserRole] = useState('');
  const [getCompanyName, setGetCompanyName] = useState('');

  useEffect(() => {
    const getUserDetails = JSON.parse(localStorage.getItem('user'));
    if (getUserDetails) {
      setGetCompanyRole(getUserDetails.company_role_name);
      setGetUserRole(getUserDetails.user_role);
      setGetCompanyName(getUserDetails.company_name);
    }
  }, []);

  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse} style={{ textTransform: 'uppercase' }}>
        <SidebarLink title="Global Dashboard" icon="home" route="/global" onClick={onClick} />
        <SidebarLink title="Depot View" icon="store" route="/global/depot" onClick={onClick} />
        <SidebarLink
          title="Health Status"
          icon="heart-pulse"
          route="/healthstatus"
          onClick={onClick}
        />
        <SidebarLink title="Manage Assets" icon="apartment" route="/app" onClick={onClick} />
        {(getCompanyRole === adminRole[1] ||
          getCompanyRole === adminRole[0] ||
          companyRole.includes(getCompanyRole)) &&
          !viewRole.includes(getUserRole) && (
            <SidebarLink
              title="System Settings"
              icon="cog"
              route={`${
                getUserRole === companyAdmin[0] && !companyRole.includes(getCompanyRole)
                  ? '/settings'
                  : '/company'
              }`}
              onClick={onClick}
            />
          )}
        {/* OEM comapny type check */}
        {(adminRole.includes(getCompanyRole) || companyNameCheck(getCompanyName)) &&
        !viewRole.includes(getUserRole) ? (
          <SidebarCategory title="Retrieve Data" icon="layers" collapse={collapse}>
            <SidebarLink title="Signal Data" route="/diagnostic" />
            <SidebarLink title="CAN Data" route="/data-collection" />
            <SidebarLink title="Gateway Diagnostics" exact="false" route="/gate-diagnostics" />
          </SidebarCategory>
        ) : (
          ''
        )}
        {adminRole.includes(getCompanyRole) ? (
          <SidebarCategory title="Gateway Master" icon="tablet" collapse={collapse}>
            <SidebarLink title="Gateway Details" route="/cpu" />
            <SidebarLink title="Sim Details" route="/sim" />
          </SidebarCategory>
        ) : (
          ''
        )}
        {adminRole.includes(getCompanyRole) ? (
          <SidebarCategory title="Ota" icon="cloud-sync" collapse={collapse}>
            <SidebarLink
              title="Group Mgt"
              route={Path.OTA_DASHBOARD}
              style={{ textTransform: 'uppercase' }}
              onClick={() => {
                dispatch(updateOtaRowReset());
              }}
            />

            <SidebarCategory title="Package Mgt" collapse={collapse}>
              <SidebarLink title="Creation" route={Path.PACKAGE_CREATION} />
              <SidebarLink title="Listing" route={Path.PACKAGE_LISTING} />
            </SidebarCategory>

            <SidebarCategory title="Campaign Mgt" collapse={collapse}>
              <SidebarLink title="Creation" route={Path.CAMPAIGN_CREATION} />
              <SidebarLink
                title="Listing"
                route={Path.CAMPAIGN_LISTING}
                onClick={() => {
                  dispatch(updateOtaRowReset());
                }}
              />
            </SidebarCategory>

            {/* <SidebarCategory title="Group MGT" collapse={collapse}>
              <SidebarLink
                title="Creation"
                route={Path.GROUP_CREATION}
                style={{ textTransform: "uppercase" }}
              />
              <SidebarLink
                title="Listing"
                route={Path.GROUP_LISTING}
                style={{ textTransform: "uppercase" }}
              />
            </SidebarCategory> */}
          </SidebarCategory>
        ) : (
          ''
        )}
        {companyAdmin.includes(getUserRole) ? (
          <SidebarLink title="Manage Notifications" icon="alarm" route={Path.NOTIFICATION} />
        ) : (
          ''
        )}
      </SidebarBlock>
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => {},
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: 0.3s;

    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

// endregion

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_TOTAL_COMPANY_VEHICLES_LOADING,
  GET_TOTAL_COMPANY_VEHICLES_SUCCESS,
  GET_TOTAL_COMPANY_VEHICLES_FAIL,
} from '@/shared/constants/globalDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getTotalCompanyVehicles = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_COMPANY_VEHICLES_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const { data } = await axios.get(
      ApiURL.GET_TOTAL_COMPANY_VEHICLES + companyId + '/counts/vehicles',
      getConfig()
    );

    const total_vehicles = data.data.global_total_vehicles[0].total_vehicle;
    const green_vehicles = data.data.global_total_vehicles[1]['006B38'];
    const orange_vehicles = data.data.global_total_vehicles[1]['F57A08'];
    const red_vehicles = data.data.global_total_vehicles[1]['D21D26'];
    const grey_vehicles = data.data.global_total_vehicles[1]['A7AEB4'];

    const total_company_vehicles_count = {
      total_vehicles,
      green_vehicles,
      orange_vehicles,
      red_vehicles,
      grey_vehicles,
    };

    dispatch({
      type: GET_TOTAL_COMPANY_VEHICLES_SUCCESS,
      payload: total_company_vehicles_count,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_TOTAL_COMPANY_VEHICLES_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_TOTAL_COMPANY_VEHICLES_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getTotalCompanyVehicles;

import {
  GET_SELECTED_USER_VEHICLES_LOADING,
  GET_SELECTED_USER_VEHICLES_SUCCESS,
  GET_SELECTED_USER_VEHICLES_FAIL,
  GET_SELECTED_USER_VEHICLES_RESET,
} from "@/shared/constants/SystemSettingsConstants/getSystemSettingsConstants";

const initialState = {
  loading: false,
  info: [],
  error: "",
};

const getSelectedUserVehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTED_USER_VEHICLES_LOADING:
      return { ...state, loading: true };
    case GET_SELECTED_USER_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_SELECTED_USER_VEHICLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SELECTED_USER_VEHICLES_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getSelectedUserVehiclesReducer;

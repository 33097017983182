import axios from 'axios';
import { ApiURL } from '../../../config/GlobalUrl';
import {
  GET_COMPANY_LIST_LOADING,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_FAIL,
} from '../../../shared/constants/CompanyConstants/getCompanyListConstants';
import { getConfig } from '../../../shared/helpers/apiConfig.js';

const getCompanyList = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_LIST_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_COMPANY_LIST + companyId + '/get_companies',
      getConfig()
    );

    dispatch({
      type: GET_COMPANY_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPANY_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMPANY_LIST_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getCompanyList;

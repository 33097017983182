import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import CameraIcon from "mdi-react/CameraIcon";
import { renderComponentField } from "@/shared/components/form/FormField";
import { colorBorder, colorText, colorFieldsBorder } from "@/utils/palette";
import getUserProfile from "@/redux/actions/UserProfileActions/getUserProfile";
import updateUserProfile from "@/redux/actions/UserProfileActions/updateUserProfile";

const Ava = `${process.env.PUBLIC_URL}/img/UserAvatar.jpg`;

export const ImageUpload = React.forwardRef(
  ({ onChange, name, fileType }, ref) => {
    const dispatch = useDispatch();

    const profileDetails = JSON.parse(localStorage.getItem("userProfile"));

    const [data, setData] = useState(
      profileDetails?.profile_photo ? profileDetails.profile_photo : ""
    );

    const handleChange = (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file) {
        convertToBase64(file);
      }
    };

    const convertToBase64 = (file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setData(base64String);
        onChange({ base64String: base64String, fileSize: file.size });
        if (file.size <= 700000) {
          dispatch(
            updateUserProfile({
              notify: profileDetails?.is_notification_active,
              profile_photo: base64String,
            })
          ).then((res) => {
            if (res?.data.status === "success") {
              dispatch(getUserProfile());
            }
          });
        }
      };
      reader.readAsDataURL(file);
    };

    const handleImageClick = () => {
      document.getElementById(name).click();
    };

    return (
      <AvatarContainer onClick={handleImageClick}>
        <input
          type="file"
          // accept=".jpeg" //Add support for only jpeg, jpg, and png
          accept={fileType || undefined}
          name={name}
          id={name}
          onChange={handleChange}
          ref={ref}
          hidden={true}
          size={5}
        />
        <AvatarImage src={data || Ava} alt="User Avatar" />
        <IconWrapper>
          <CameraDiv style={{ zIndex: "1" }} />
        </IconWrapper>
        <OverlayDiv />
      </AvatarContainer>
    );
  }
);

ImageUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

ImageUpload.defaultProps = {
  value: null,
};

export default renderComponentField(ImageUpload);

// region STYLES
const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
`;

const CameraDiv = styled(CameraIcon)`
  color: ${colorText};
}`;

const IconWrapper = styled.div`
  color: ${colorText};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorFieldsBorder};

  &:hover ${OverlayDiv} {
    opacity: 1;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  // border: 1px solid ${colorText};

  &:hover ${OverlayDiv} {
    opacity: 1;
  }
`;

const AvatarImage = styled.img`
  height: 100%;
  object-fit: cover;

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;

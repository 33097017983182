import {
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_IN_SUCCESS_FIRST_TIME,
  SIGN_IN_FAIL,
  SIGN_IN_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';

const initialState = {
  loading: false,
  info: '',
  info_first_time: '',
  error: '',
};

const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_LOADING:
      return { ...state, loading: true };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
        info_first_time: '',
      };
    case SIGN_IN_SUCCESS_FIRST_TIME:
      return {
        ...state,
        loading: false,
        info_first_time: action.payload,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SIGN_IN_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default signInReducer;

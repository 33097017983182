import {
  CLOSE_ALARM_LOADING,
  CLOSE_ALARM_SUCCESS,
  CLOSE_ALARM_FAIL,
} from '../../../shared/constants/HealthStatusDashboardConstants/closeAlarmConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const closeAlarmReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_ALARM_LOADING:
      return { ...state, loading: true };
    case CLOSE_ALARM_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case CLOSE_ALARM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default closeAlarmReducer;

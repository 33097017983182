import React, { useState } from 'react';
import { components } from 'react-select';

const InputOption = React.memo(
  ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, data, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    const isOptionDisabled = data.econtrol_version ? data.econtrol_version === data.svn : false;

    let bg = 'transparent';

    const style = {
      backgroundColor: isOptionDisabled ? '#F2F4F7' : bg,
      color: 'inherit',
      display: 'flex ',
      cursor: isOptionDisabled ? 'auto' : 'pointer',
    };

    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const props = isOptionDisabled
      ? {
          ...innerProps,
          onMouseDown,
          onMouseUp,
          onMouseLeave,
          style,
          onClick: handleClick,
        }
      : {
          ...innerProps,
          onMouseDown,
          onMouseUp,
          onMouseLeave,
          style,
        };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
        data={data}
      >
        <span style={{ marginRight: '12px' }}>
          <input
            type="checkbox"
            name={data.value}
            id={data.value}
            checked={isOptionDisabled ? true : isSelected}
            disabled={isOptionDisabled ? true : false}
            onChange={() => {}}
          />
        </span>
        {children}
      </components.Option>
    );
  }
);

export default InputOption;

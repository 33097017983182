import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import BusMultipleIcon from 'mdi-react/BusMultipleIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { selectDepotValidate } from '@/utils/validate.js';
import DepotForm from './DepotForm';
import getFleetList from '../../../../redux/actions/FleetActions/getFleetListActions';
import getDepotList from '../../../../redux/actions/DepotActions/getDepotListActions';

const DepotSelectForm = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const [showDepotEditForm, setShowDepotEditForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [fleetId, setFleetId] = useState();
  const [depotId, setDepotId] = useState();

  const companyId = localStorage.getItem('companyId');

  const getFleetListReducerLoading = useSelector((state) => state.getFleetListReducer.loading);

  const getFleetListReducerInfo = useSelector((state) => state.getFleetListReducer.info);

  const getFleetListReducerError = useSelector((state) => state.getFleetListReducer.error);

  const getDepotListReducerLoading = useSelector((state) => state.getDepotListReducer.loading);

  const getDepotListReducerInfo = useSelector((state) => state.getDepotListReducer.info);

  const getDepotListReducerError = useSelector((state) => state.getDepotListReducer.error);

  useEffect(() => {
    if (companyId) {
      dispatch(getFleetList(companyId));
    }
  }, []);

  const handleSubmit = (values) => {
    if (values.fleet && values.depot) {
      setFleetId(values.fleet.id);
      setDepotId(values.depot.id);
      setShowDepotEditForm(true);
    }
  };

  const handleFleetChange = (values) => {
    if (values.fleet_id) {
      dispatch(getDepotList(companyId, values.fleet_id));
    }
  };

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <>
      {showDepotEditForm === true ? (
        <DepotForm
          editMode={editMode}
          setShowDepotEditForm={setShowDepotEditForm}
          fleetId={fleetId}
          depotId={depotId}
        />
      ) : (
        <Card height="auto">
          <CardBody>
            <Form onSubmit={handleSubmit} validate={selectDepotValidate}>
              {({ handleSubmit, form }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>Select Fleet</FormGroupLabel>

                    {getFleetListReducerLoading ? (
                      <DotDotLoader loadingState={getFleetListReducerLoading} />
                    ) : (
                      <FormGroupField>
                        <FormGroupIcon>
                          <BusMultipleIcon />
                        </FormGroupIcon>
                        <Field
                          name="fleet"
                          component={renderSelectField}
                          type="text"
                          options={
                            getFleetListReducerInfo
                              ? getFleetListReducerInfo.fleet_list.map(({ id, name }) => ({
                                  id: id,
                                  value: name,
                                  label: name,
                                }))
                              : []
                          }
                          placeholder="Select fleet"
                          parse={(e) => {
                            handleFleetChange({
                              fleet_id: e.id,
                            });
                            form.change('depot', '');
                            return e;
                          }}
                        />
                      </FormGroupField>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Select Depot</FormGroupLabel>

                    {getDepotListReducerLoading ? (
                      <DotDotLoader loadingState={getDepotListReducerLoading} />
                    ) : (
                      <FormGroupField>
                        <FormGroupIcon>
                          <DomainIcon />
                        </FormGroupIcon>
                        <Field
                          name="depot"
                          component={renderSelectField}
                          type="text"
                          options={
                            getDepotListReducerInfo
                              ? getDepotListReducerInfo.depot_list.map(({ id, name }) => ({
                                  id: id,
                                  value: name,
                                  label: name,
                                }))
                              : []
                          }
                          placeholder="Select depot"
                        />
                      </FormGroupField>
                    )}
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="secondary" type="button" onClick={() => handleCancel()}>
                      Back
                    </Button>
                    <Button variant="primary" type="submit">
                      Next
                    </Button>
                  </FormButtonToolbar>
                </FormContainer>
              )}
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default DepotSelectForm;

import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';
import {
  GET_ASSETS_COUNT_LOADING,
  GET_ASSETS_COUNT_SUCCESS,
  GET_ASSETS_COUNT_FAIL,
} from '../../shared/constants/getAssetsCountConstants';
import { getConfig } from '../../shared/helpers/apiConfig';

const getAssetsCount = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSETS_COUNT_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const response = await axios.get(ApiURL.GET_ASSETS_COUNT + companyId, getConfig());

    dispatch({
      type: GET_ASSETS_COUNT_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_ASSETS_COUNT_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_ASSETS_COUNT_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export default getAssetsCount;

import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ column, isSortable }) => (
  <span
    className="react-table__column-header"
    style={{ pointerEvents: isSortable && column.canSort ? 'auto' : 'none' }}
  >
    <span className={isSortable ? 'react-table__column-header sortable' : ''}>
      {column.render('Header')}
    </span>
    {isSortable && column.canSort && <Sorting column={column} />}
  </span>
);

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.any,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
};

export const Sorting = ({ column }) => (
  <span>
    {/* //  <span className="react-table__column-header sortable" style={{ border: '1px solid green' }}>
  //   {column.isSortedDesc === undefined ? (
  //     <SortIcon />
  //   ) : (
  //     <span>{column.isSortedDesc ? <SortDescendingIcon /> : <SortAscendingIcon />}</span>
  //   )}
  //  </span> */}
  </span>
);

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.any,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
};

const ReactTableHeader = ({ headerGroups, isResizable, isSortable }) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps({ title: undefined });
    }
    return [props];
  };

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()} className="react-table thead tr">
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(headerPropsSortable)}
              {...column.getHeaderProps()}
              style={{ pointerEvents: 'none' }}
            >
              <Header column={column} isSortable={isSortable} />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    })
  ).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
};

export default ReactTableHeader;

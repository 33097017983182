import React from "react";
import CompanyDetailsContainer from "../../ManageCompany/CompanyDetailsContainer";
import DotDotLoader from "@/shared/components/DotDotLoader";

const CompanyDetails = ({ info, loading, getCompanyListReducerLoading }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;

  return (
    <>
      {info ? (
        <CompanyDetailsContainer
          img={Ava}
          name={info.company.name}
          website={info.company.website}
          role={info.company.company_type}
          city={info.company.city}
          country={info.company.country}
          onClick={() => {}}
          showLink={false}
        />
      ) : loading || getCompanyListReducerLoading ? (
        <DotDotLoader loadingState={loading || getCompanyListReducerLoading} />
      ) : (
        ""
      )}
    </>
  );
};

export default CompanyDetails;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getSimMasterList from '@/redux/actions/SimMasterActions/getSimMasterList';

const SimMasterList = ({ reactTableData }) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10, 20, 30, 40],
  };

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(getSimMasterList(pageIndex + 1, pageSize, reactTableData.sort));
  };

  useEffect(() => {
    dispatch(getSimMasterList(pageNumber, numberofRecords, reactTableData.sort));
  }, [reactTableData.sort]);

  const { loading, info, pagination, error } = useSelector(
    (state) => state.getSimMasterListReducer
  );

  return (
    <Card height="auto">
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Sim List</CardTitle>
        </CardTitleWrap>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={info}
          tableConfig={tableConfig}
          paginationDetail={pagination}
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Diagnostics'}
        />
        {loading ? '' : info && info.length > 0 ? '' : <TableNoData />}
      </CardBody>
    </Card>
  );
};

SimMasterList.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default SimMasterList;

import axios from 'axios';
import { ApiURL } from '../../config/GlobalUrl';

import {
  GET_HVAC_COMP_LOADING,
  GET_HVAC_COMP_SUCCESS,
  GET_HVAC_FILTERED_COMP_SUCCESS,
  GET_HVAC_COMP_FAIL,
} from '../../shared/constants/getHvacCountConstants';

const getHvacCompCount = hvacId => async dispatch => {
  try {
    dispatch({
      type: GET_HVAC_COMP_LOADING,
    });

    // setting up the config as headers content-type
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      ApiURL.GET_HVAC_COMPONENT_COUNT +
        hvacId +
        '/components/get_count',
      config
    );

    dispatch({
      type: GET_HVAC_COMP_SUCCESS,
      payload: data.data.count.category,
    });
    dispatch({
      type: GET_HVAC_FILTERED_COMP_SUCCESS,
      payload: data.data.count.component_type,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_HVAC_COMP_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_HVAC_COMP_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getHvacCompCount;

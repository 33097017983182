import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GATEWAY_MASTER_LIST_LOADING,
  GET_GATEWAY_MASTER_LIST_SUCCESS,
  GET_GATEWAY_MASTER_LIST_FAIL,
  GET_CPUID_MASTER_LIST_LOADING,
  GET_CPUID_MASTER_LIST_SUCCESS,
  GET_CPUID_MASTER_LIST_FAIL,
} from '@/shared/constants/GatewayMasterConstants/gatewayMasterConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { sortingFunc } from '@/utils/helpers.js';

const getGatewayMasterList =
  (pageNumber, numberofRecords, sortData, cpu_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GATEWAY_MASTER_LIST_LOADING,
      });

      const params = {
        p: pageNumber ? pageNumber : null,
        page_size: numberofRecords ? numberofRecords : null,
        sort: sortData ? sortingFunc(sortData) : null,
        'filter-cpu_number': cpu_id ? cpu_id : null,
      };

      const { data } = await axios.get(ApiURL.GET_CPU_NUMBER_LIST, { params }, getConfig());

      dispatch({
        type: GET_GATEWAY_MASTER_LIST_SUCCESS,
        payload: { data: data.data.master_gateway_list, pagination: data.pagination },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_GATEWAY_MASTER_LIST_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_GATEWAY_MASTER_LIST_FAIL,
          payload: error.response,
        });
      }
    }
  };

export const getCpuIdMasterList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CPUID_MASTER_LIST_LOADING,
    });

    const params = {
      p: 1,
      page_size: 999,
    };

    const { data } = await axios.get(ApiURL.GET_CPU_NUMBER_LIST, { params }, getConfig());

    const cpuIdList = data?.data?.master_gateway_list.map(({ cpu_number }) => ({
      id: cpu_number,
      value: cpu_number,
      label: cpu_number,
    }));

    dispatch({
      type: GET_CPUID_MASTER_LIST_SUCCESS,
      payload: cpuIdList,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_CPUID_MASTER_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_CPUID_MASTER_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getGatewayMasterList;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GLOBAL_HEALTH_SUMMARY_LOADING,
  GET_GLOBAL_HEALTH_SUMMARY_SUCCESS,
  GET_GLOBAL_HEALTH_SUMMARY_FAIL,
} from '@/shared/constants/globalDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';

const getGlobalHealthSummary = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_GLOBAL_HEALTH_SUMMARY_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const { data } = await axios.get(
      ApiURL.GET_GLOBAL_HEALTH_SUMMARY + companyId + '/healthstatusvehicle',
      getConfig()
    );

    const alarmData = data.data.health_status_summary.alarms;
    const totalAlarms = data.data.health_status_summary.total_count;

    const filteredData = Object.values(alarmData).map(({ alarm_type, color_code, count }) => ({
      name: alarm_type,
      fill: `#${color_code}`,
      value: count,
    }));

    dispatch({
      type: GET_GLOBAL_HEALTH_SUMMARY_SUCCESS,
      payload: { alarmData: filteredData, total: totalAlarms },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GLOBAL_HEALTH_SUMMARY_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GLOBAL_HEALTH_SUMMARY_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export default getGlobalHealthSummary;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap, CardTitle } from '@/shared/components/Card';
import CreateTableData from './CreateData';
import FullDiagnosticForm from './FullDiagnosticForm';
import DiagnosticTable from './DiagnosticTable';

const D2 = () => {
  const reactTableData = CreateTableData();

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card style={{ height: 'auto' }}>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>{'Initiate Full CAN Data Collection'}</CardTitle>
              </CardTitleWrap>
              <FullDiagnosticForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card style={{ height: 'auto' }}>
            <CardBody>
              <CardTitleWrap>
                <CardTitle></CardTitle>
              </CardTitleWrap>
              <DiagnosticTable reactTableData={reactTableData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default D2;

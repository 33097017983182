import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import getSupplierComps from '../../../../redux/actions/getSuppliersAction';
import data from './data';
import SimpleLoader from '../../../../shared/components/SimpleLoader';

const ComponentAddForm = ({ onSubmit, setAddComp }) => {
  const dispatch = useDispatch();

  const getSuppliersReducer = useSelector((state) => state.getSuppliersReducer.info);

  const getSuppliersReducerLoading = useSelector((state) => state.getSuppliersReducer.loading);

  const getCompSerialNumberReducer = useSelector((state) => state.getCompSerialNumberReducer);

  const getCompSerialNumberReducerError = useSelector(
    (state) => state.getCompSerialNumberReducer.error
  );

  const getCompSerialNumberReducerLoading = useSelector(
    (state) => state.getCompSerialNumberReducer.loading
  );

  const getVehicleInfo = JSON.parse(localStorage.getItem('vehicleDetails'));
  const getVehicleEngineType = getVehicleInfo.engine_type;
  const [dropdownValue, setDropdownValue] = useState();

  const handleChange = (values) => {
    if (Object.keys(values).length > 0 && values.manufacturer_id !== 1) {
      setDropdownValue(values);
      dispatch(getSupplierComps(getVehicleEngineType, values.value));
    }
    if (values.manufacturer_id === 1) {
      setDropdownValue(values);
    }
    setMessage('');
  };
  const [message, setMessage] = useState('');

  const validateComponentsEdit2 = (values) => {
    const errors = {};

    if (!values.select) {
      errors.select = 'Manufacterer shouldn’t be empty';
    }

    if (!values.selectcomponents) {
      errors.selectcomponents = 'Device shouldn’t be empty';
    }

    if (!values.input) {
      errors.input = 'Serial Number shouldn’t be empty';
    } else if (!/^[0-9]+$/g.test(values.input)) {
      errors.input = 'Invalid Serial Number';
    }

    if (values.select && values.selectcomponents && errors.input) {
      delete errors.input;
    }
    if (values.select && values.input && errors.selectcomponents) {
      delete errors.selectcomponents;
    }
    return errors;
  };

  const handleChangeMaxLength = (v) => {
    if (v.value.length < 4) {
      setMessage('Serial number is too short. Must be at least 4 digits.');
    } else if (v.value.length > 9) {
      setMessage('Serial number is too long.');
    } else {
      setMessage('');
    }
  };

  return (
    <Card height="auto">
      <CardBody>
        <Form onSubmit={onSubmit} validate={validateComponentsEdit2}>
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>Manufacturer</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="select"
                    //change name to select_manufacture
                    component={renderSelectField}
                    placeholder="Please select a manufacturer"
                    type="text"
                    options={data.map(({ id, name }) => ({
                      id: id,
                      value: name,
                      label: name,
                    }))}
                    parse={(e) => {
                      handleChange({
                        value: e.value,
                        manufacturer_id: e.id,
                      });
                      form.reset();
                      return e;
                    }}
                  />
                </FormGroupField>
              </FormGroup>

              {!dropdownValue ||
              dropdownValue === undefined ||
              dropdownValue.manufacturer_id === 1 ? (
                <FormGroup>
                  <FormGroupLabel>Serial Number</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="input"
                      component={FormField}
                      type="text"
                      placeholder="Please enter a serial number"
                      parse={(e) => {
                        handleChangeMaxLength({ value: e });
                        return e;
                      }}
                    />
                  </FormGroupField>
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormGroupLabel>Device</FormGroupLabel>
                  <FormGroupField>
                    {getSuppliersReducerLoading === true ? (
                      <SimpleLoader />
                    ) : (
                      <Field
                        name="selectcomponents"
                        //change name to select_supplier_devices
                        component={renderSelectField}
                        type="text"
                        placeholder="Please select a device"
                        options={
                          getSuppliersReducer &&
                          getSuppliersReducer !== undefined &&
                          Object(getSuppliersReducer).length > 0
                            ? getSuppliersReducer.map(({ id, component, unit_type }) => ({
                                id: id,
                                value: unit_type,
                                label: unit_type + component,
                              }))
                            : ''
                        }
                        parse={(e) => {
                          form.change('input', '');
                          return e;
                        }}
                      />
                    )}
                  </FormGroupField>
                </FormGroup>
              )}

              <FormButtonToolbar
                style={{
                  width: '50%',
                  paddingRight: '10px',
                }}
              >
                <Button
                  variant="secondary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    setAddComp(false);
                  }}
                >
                  Back
                </Button>
              </FormButtonToolbar>
              <FormButtonToolbar
                style={{
                  width: '50%',
                  marginBottom: '10px',
                  paddingLeft: '10px',
                }}
              >
                <Button variant="primary" type="submit" style={{ width: '100%' }}>
                  Next
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

ComponentAddForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ComponentAddForm;

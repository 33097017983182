import {
  GET_GROUP_DEVICE_LIST_BY_OTHERS_LOADING,
  GET_GROUP_DEVICE_LIST_BY_OTHERS_SUCCESS,
  GET_GROUP_DEVICE_LIST_BY_OTHERS_FAIL,
  GET_GROUP_DEVICE_LIST_BY_OTHERS_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const getGroupDeviceListByOthersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_DEVICE_LIST_BY_OTHERS_LOADING:
      return { ...state, loading: true };
    case GET_GROUP_DEVICE_LIST_BY_OTHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_GROUP_DEVICE_LIST_BY_OTHERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GROUP_DEVICE_LIST_BY_OTHERS_RESET:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default getGroupDeviceListByOthersReducer;

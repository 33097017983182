import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colorIcon } from '@/utils/palette';
import { right } from '@/utils/directions';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { colorBackground, colorText, colorDarkText } from '@/utils/palette';
import { Button } from '@/shared/components/Button';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { Table } from '@/shared/components/TableElements';
import BasicTableData from './BasicData';
import closeAlarm from '../../../../redux/actions/HealthStatusDashboardActions/closeAlarmActions';
import { Form, Field } from 'react-final-form';
import { FormContainer, FormGroup, FormGroupField } from '@/shared/components/form/FormElements';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import CheckCircleOutline from 'mdi-react/CheckCircleOutlineIcon';
import CloseIconOutline from 'mdi-react/CloseCircleOutlineIcon';
import MinusCircleOutlineIcon from 'mdi-react/MinusCircleOutlineIcon';
import { alarmModalTableValidate } from '../../../../utils/validate';

const AlarmModalTable = ({
  openModalState,
  closeModalState,
  refreshTable,
  setRefreshTable,
  alarmDetails,
  showInput,
  disableInput,
}) => {
  const dispatch = useDispatch();

  const closeVehicleAlarmReducerLoading = useSelector(
    (state) => state.closeVehicleAlarmReducer.loading
  );

  const getAlarmChecklistReducerLoading = useSelector(
    (state) => state.getAlarmChecklistReducer.loading
  );

  const getAlarmChecklistReducerInfo = useSelector((state) => state.getAlarmChecklistReducer.info);
  const getVehicleAlarmsReducerLoading = useSelector(
    (state) => state.getVehicleAlarmsReducer.loading
  );
  const closeAlarmReducerLoading = useSelector((state) => state.closeAlarmReducer.loading);
  const [btnClicked, setBtnClicked] = useState(false);
  const [data, setData] = useState([]);
  const [isRadioChecked, setIsRadioChecked] = useState();
  const [bgColor, setBgColor] = useState(false);
  const [count, setCount] = useState(0);

  const { tableHeaderData, tableHeaderDataConditional } = BasicTableData();

  const handleClose = () => closeModalState(false);

  useEffect(() => {
    if (getAlarmChecklistReducerInfo) {
      setData(getAlarmChecklistReducerInfo.alarm_checklist);
    }
  }, [getAlarmChecklistReducerInfo]);

  const onChangeRadio = (e, row) => {
    setIsRadioChecked({
      radioId: e,
      rowId: row.id,
      alarmName: alarmDetails.type,
    });
  };

  const onChangeInputBox = (e, row) => {
    const count = 1000 - e.length;
    setCount(count);
    const editData = data.map((item) => (item.id === row.id ? { ...item, ['comment']: e } : item));
    setData(editData);
  };

  const onChangeButton = (e, row) => {
    const preventiveAlarmData = data.map((item) =>
      item.id === row.id
        ? {
            ...item,
            ['is_checked']: {
              done: e === `radioValue-done-${item.id}` ? true : false,
              not_done: e === `radioValue-not-done-${item.id}` ? true : false,
              not_applicable: e === `radioValue-na-${item.id}` ? true : false,
            },
          }
        : item
    );
    setIsRadioChecked({
      radioId: e,
      rowId: row.id,
      alarmName: alarmDetails.type,
    });
    setBgColor(true);
    setData(preventiveAlarmData);
  };

  const handleSubmit = () => {
    if (isRadioChecked && isRadioChecked.rowId !== undefined) {
      const mainComment = 'closed';
      const conditionalAlarmData = data.map((item) =>
        item.id === isRadioChecked.rowId
          ? { ...item, ['is_checked']: isRadioChecked.radioId ? true : false }
          : item
      );

      if (isRadioChecked.alarmName === 'Conditional') {
        dispatch(closeAlarm(alarmDetails.vin, alarmDetails.id, conditionalAlarmData, mainComment));
      }

      if (isRadioChecked.alarmName === 'Preventive') {
        dispatch(closeAlarm(alarmDetails.vin, alarmDetails.id, data, mainComment));
      }
      setBtnClicked(true);
    }
  };

  //   condition for refreshing comp and closing modal
  if (btnClicked === true && closeVehicleAlarmReducerLoading === true) {
    setRefreshTable(true);
    setBtnClicked(false);
  }
  if (getVehicleAlarmsReducerLoading === true && refreshTable === true) {
    handleClose();
    setRefreshTable(false);
  }

  return (
    <StyledModal
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalState}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <StyledModalHeader>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={handleClose}
        />
        <StyledModalTitle>
          {alarmDetails && alarmDetails.status === 'open' && alarmDetails.type !== 'Preventive'
            ? 'Alarm Open'
            : alarmDetails && alarmDetails.type === 'Preventive'
            ? `${alarmDetails.metadata.display_name} Preventive Maintenance Alert`
            : 'Alarm Closed'}
        </StyledModalTitle>
        {alarmDetails ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '12px' }}>
              <p>VIN: {alarmDetails.vin && alarmDetails.vin}</p>
              <p>Alarm Id: {alarmDetails.id && alarmDetails.id}</p>
              <p>Alarm type: {alarmDetails.type && alarmDetails.type}</p>
              <p>
                Alarm opening time:{' '}
                {alarmDetails.triggered_at &&
                  `${
                    alarmDetails.triggered_at.split('T')[1].split('.')[0] +
                    ', ' +
                    alarmDetails.triggered_at.split('T')[0]
                  }`}
              </p>
              {alarmDetails.status !== 'open' && (
                <>
                  <p>
                    Alarm closed at:{' '}
                    {alarmDetails.closed_at &&
                      `${
                        alarmDetails.closed_at.split('T')[1].split('.')[0] +
                        ', ' +
                        alarmDetails.closed_at.split('T')[0]
                      }`}
                  </p>
                  <p>Alarm closed by: {alarmDetails.closed_by && alarmDetails.closed_by}</p>
                </>
              )}
              {alarmDetails.type === 'Conditional' ? (
                <>
                  <p>Delta-T = Return temp - Supply temp</p>
                  <p>
                    Room Details:{' '}
                    {alarmDetails.metadata.display_name && alarmDetails.metadata.display_name}
                  </p>
                  <p>
                    CAN Active Timestamp:{' '}
                    {alarmDetails.metadata.can_0_active_timestamp &&
                      `${moment
                        .utc(alarmDetails.metadata.can_0_active_timestamp)
                        .format('DD-MMMM-YY, hh:mm:ss')}`}
                  </p>
                  <p>
                    CAN Active Value:{' '}
                    {alarmDetails.metadata.can_0_active_value &&
                      alarmDetails.metadata.can_0_active_value}
                  </p>
                  <p>
                    Compressor Value:{' '}
                    {alarmDetails.metadata.compressor_value &&
                      alarmDetails.metadata.compressor_value}
                  </p>
                  <p>
                    Setpoint Timestamp:{' '}
                    {alarmDetails.metadata.setpoint_timestamp &&
                      `${moment
                        .utc(alarmDetails.metadata.setpoint_timestamp)
                        .format('DD-MMMM-YY, hh:mm:ss')}`}
                  </p>
                  <p>
                    Setpoint Value:{' '}
                    {`${
                      alarmDetails.metadata.setpoint_value && alarmDetails.metadata.setpoint_value
                    } C`}
                  </p>
                  <p>
                    Return air temp:{' '}
                    {alarmDetails.metadata.return_air_temp &&
                      `${alarmDetails.metadata.return_air_temp} C recorded at ${moment
                        .utc(alarmDetails.metadata.return_air_temp_timestamp)
                        .format('DD-MMMM-YY, hh:mm:ss')}`}
                  </p>
                  <p>
                    Supply air temp:{' '}
                    {alarmDetails.metadata.supply_air_temp &&
                      `${alarmDetails.metadata.supply_air_temp} C recorded at ${moment
                        .utc(alarmDetails.metadata.supply_air_temp_timestamp)
                        .format('DD-MMMM-YY, hh:mm:ss')}`}
                  </p>
                </>
              ) : (
                ''
              )}
            </div>
            {alarmDetails.type === 'Preventive' && showInput ? (
              <div style={{ fontSize: '12px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <CheckCircleOutline />
                    <p style={{ paddingLeft: '20px' }}>Done</p>
                  </div>
                  {/* <div style={{ display: 'flex' }}>
                    <CloseIconOutline />
                    <p style={{ paddingLeft: '20px' }}>Not Done</p>
                  </div> */}
                  <div style={{ display: 'flex' }}>
                    <MinusCircleOutlineIcon />
                    <p style={{ paddingLeft: '20px' }}>Not Applicable</p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          'Error loading alarm details'
        )}
        {closeVehicleAlarmReducerLoading || closeAlarmReducerLoading ? (
          <DotDotLoader
            loadingState={closeVehicleAlarmReducerLoading || closeAlarmReducerLoading}
          />
        ) : (
          ''
        )}
      </StyledModalHeader>
      <StyledModalBody>
        {getAlarmChecklistReducerLoading ? (
          <DotDotLoader loadingState={getAlarmChecklistReducerLoading} />
        ) : (
          <Form onSubmit={handleSubmit} validate={alarmModalTableValidate}>
            {({ handleSubmit, errors }) => (
              <FormContainer onSubmit={handleSubmit}>
                <Table responsive>
                  <thead className="alarm-modal">
                    {alarmDetails.type === 'Preventive' ? (
                      <tr>
                        {showInput === false ? (
                          <>
                            <th></th>
                            {tableHeaderData
                              .filter(
                                (newitem) =>
                                  newitem.title !== 'Comments' && newitem.title !== 'Choose Status'
                              )
                              .map((item) => (
                                <th key={item.id}>{item.title}</th>
                              ))}
                          </>
                        ) : (
                          tableHeaderData.map((item) => <th key={item.id}>{item.title}</th>)
                        )}
                      </tr>
                    ) : alarmDetails.type === 'Conditional' ? (
                      <tr>
                        {showInput === false ? (
                          <>
                            <th></th>
                            {tableHeaderDataConditional
                              .filter(
                                (newitem) =>
                                  newitem.title !== 'Comments' && newitem.title !== 'Choose Status'
                              )
                              .map((item) => (
                                <th key={item.id}>{item.title}</th>
                              ))}
                          </>
                        ) : (
                          tableHeaderDataConditional.map((item) => (
                            <th key={item.id}>{item.title}</th>
                          ))
                        )}
                      </tr>
                    ) : (
                      <tr>
                        <th></th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr
                        key={item.id}
                        style={{
                          borderBottom: '1px solid #D8D8D8',
                        }}
                      >
                        {showInput === true &&
                        disableInput === false &&
                        alarmDetails.type === 'Conditional' ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0' }}>
                              <FormGroupField>
                                <Field
                                  name="selectalarm"
                                  component={renderRadioButtonField}
                                  radioValue={`radioValue-${item.id}`}
                                  value={`radio-${item.id}`}
                                  parse={(e) => {
                                    onChangeRadio(e, item);
                                    return e;
                                  }}
                                  styleType={'button4'}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </td>
                        ) : showInput === true &&
                          disableInput === true &&
                          alarmDetails.type === 'Conditional' ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0' }}>
                              <FormGroupField>
                                <Field
                                  name="selectalarm"
                                  component={renderRadioButtonField}
                                  radioValue={item.id}
                                  val={item.is_checked ? item.id : ''}
                                  disabled
                                  styleType={'button4'}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </td>
                        ) : showInput === true &&
                          disableInput === false &&
                          alarmDetails.type === 'Preventive' ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0' }}>
                              <FormGroupField>
                                <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  radioValue={`radioValue-done-${item.id}`}
                                  value={`done-${item.id}`}
                                  styleType={'button'}
                                  parse={(e) => {
                                    onChangeButton(e, item);
                                    return e;
                                  }}
                                  bgcolor={bgColor}
                                />
                                {/* <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  radioValue={`radioValue-not-done-${item.id}`}
                                  value={`not-done-${item.id}`}
                                  styleType={'button2'}
                                  parse={(e) => {
                                    onChangeButton(e, item);
                                    return e;
                                  }}
                                  bgcolor={bgColor}
                                /> */}
                                <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  radioValue={`radioValue-na-${item.id}`}
                                  value={`n-a-${item.id}`}
                                  styleType={'button3'}
                                  parse={(e) => {
                                    onChangeButton(e, item);
                                    return e;
                                  }}
                                  bgcolor={bgColor}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </td>
                        ) : showInput === true &&
                          disableInput === true &&
                          alarmDetails.type === 'Preventive' ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0' }}>
                              <FormGroupField>
                                <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  styleType={'button'}
                                  radioValue={item.id}
                                  val={item.is_checked.done ? item.id : ''}
                                  disabled
                                />
                                {/* <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  styleType={'button2'}
                                  radioValue={item.id}
                                  val={item.is_checked.not_done ? item.id : ''}
                                  disabled
                                /> */}
                                <Field
                                  name={`Done-${item.id}`}
                                  component={renderRadioButtonField}
                                  styleType={'button3'}
                                  radioValue={item.id}
                                  val={item.is_checked.not_applicable ? item.id : ''}
                                  disabled
                                />
                              </FormGroupField>
                            </FormGroup>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {alarmDetails.type === 'Preventive' ? (
                          <>
                            <td>{item.task}</td>
                            <td>{item.sub_component_type}</td>
                            <td>{item.check_point}</td>
                            <td>{item.job_sheet}</td>
                            <td>{item.remarks}</td>
                          </>
                        ) : alarmDetails.type === 'Conditional' ? (
                          <>
                            <td>{item.task}</td>
                            <td>{item.condition}</td>
                            <td>{item.trigger_value}</td>
                            <td>{item.possible_reason}</td>
                          </>
                        ) : (
                          <td></td>
                        )}
                        {showInput === true && disableInput === false ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0', textAlign: 'right' }}>
                              <FormGroupField>
                                <Field
                                  name={`comment-${item.id}`}
                                  component="textarea"
                                  placeholder="Write comment"
                                  initialValue={
                                    item.comment && item.comment !== 'null' ? item.comment : ''
                                  }
                                  parse={(e) => {
                                    onChangeInputBox(e, item);
                                    return e;
                                  }}
                                  style={{
                                    resize: 'none',
                                    minHeight: '70px',
                                  }}
                                  maxLength={1000}
                                  //Fix the render prop error. It works even with the error
                                  render={(props) => {
                                    return (
                                      <>
                                        <input {...props.input} />
                                        <div>This is the footer.</div>
                                      </>
                                    );
                                  }}
                                />
                              </FormGroupField>
                              <p>
                                {item.comment !== 'null' &&
                                item.comment.length > 0 &&
                                item.comment.length < 1000
                                  ? `${1000 - item.comment.length} characters left`
                                  : ''}
                              </p>
                              <p style={{ color: '#ff4861', textAlign: 'left' }}>
                                {errors ? errors[`comment-${item.id}`] : ''}
                              </p>
                            </FormGroup>
                          </td>
                        ) : showInput === true && disableInput === true ? (
                          <td>
                            <FormGroup style={{ marginBottom: '0' }}>
                              <FormGroupField>
                                <Field
                                  name={`comment-${item.id}`}
                                  component="textarea"
                                  placeholder="Write comment"
                                  initialValue={
                                    item.comment && item.comment !== 'null'
                                      ? item.comment
                                      : 'No comment provided'
                                  }
                                  disabled
                                  style={{
                                    resize: 'none',
                                    minHeight: '70px',
                                  }}
                                  //Fix the render prop error. It works even with the error
                                  render={(props) => {
                                    return <input {...props.input} />;
                                  }}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FormContainer>
            )}
          </Form>
        )}
      </StyledModalBody>
      <StyledModalFooter>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{
            marginBottom: '0',
            textAlign: 'center',
            width: '145px',
            margin: '0',
          }}
        >
          Cancel
        </Button>
        {showInput === true && disableInput === false ? (
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            style={{
              marginBottom: '0',
              textAlign: 'center',
              width: '145px',
              margin: '0 0 0 10px',
            }}
            disabled={!(isRadioChecked && isRadioChecked.rowId !== undefined)}
          >
            Close Alarm
          </Button>
        ) : (
          ''
        )}
      </StyledModalFooter>
    </StyledModal>
  );
};

export default AlarmModalTable;

// region STYLES

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    min-width: 90vw;
    // margin-top: 8px
  }
  .modal-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: ${colorBackground};
    color: ${colorText};
  }
`;

const StyledModalHeader = styled(BootstrapModal.Header)`
  display: block;
  padding: 30px 25px 0 25px;
  font-size: 16px;
  border: 0;
  color: #646777;
`;

const StyledModalTitle = styled(BootstrapModal.Title)`
  font-size: 16px;
`;

const StyledModalBody = styled(BootstrapModal.Body)`
  padding-right: 25px;
  padding-left: 25px;
  min-height: 50vh;
  overflow: auto;
`;

const StyledModalFooter = styled(BootstrapModal.Footer)`
  border: 0;
  display: block;
  text-align: center;
  padding: 20px 0 30px 0;
  margin: 0;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};

  &:hover {
    color: ${colorDarkText};
  }
`;

// endregion

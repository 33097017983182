import { useState, useMemo } from 'react';
import Badge from '@/shared/components/Badge';
import { GetTableHeader } from '@/utils/helpers';

const CreateTableData = () => {
  const [sort, setSort] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot Status'}
            key={'Depot Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'color_code',
        disableGlobalFilter: true,
        Cell: (props) => {
          const bg = props.row.original.color_code;
          return <Badge bg={bg} />;
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Depot'}
            key={'Depot'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'name',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Operator'}
            key={'Operator'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'operator',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Fleet'}
            key={'Fleet'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'fleet',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Red Vehicles'}
            key={'Red Vehicles'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_related_count.color_code_counts.D21D26',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.vehicle_related_count.color_code_counts['D21D26']
                ? props.row.original.vehicle_related_count.color_code_counts['D21D26']
                : '0'}
            </>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Orange Vehicles'}
            key={'Orange Vehicles'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_related_count.color_code_counts.F57A08',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.vehicle_related_count.color_code_counts['F57A08']
                ? props.row.original.vehicle_related_count.color_code_counts['F57A08']
                : '0'}
            </>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Green Vehicles'}
            key={'Green Vehicles'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_related_count.color_code_counts.006B38',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.vehicle_related_count.color_code_counts['006B38']
                ? props.row.original.vehicle_related_count.color_code_counts['006B38']
                : '0'}
            </>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Grey Vehicles'}
            key={'Grey Vehicles'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_related_count.color_code_counts.A7AEB4',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.vehicle_related_count.color_code_counts['A7AEB4']
                ? props.row.original.vehicle_related_count.color_code_counts['A7AEB4']
                : '0'}
            </>
          );
        },
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Total Open Alarms'}
            key={'Total Open Alarms'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'vehicle_related_count.open_alarms_counts',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <>
              {props.row.original.vehicle_related_count.open_alarms_counts
                ? props.row.original.vehicle_related_count.open_alarms_counts
                : '0'}
            </>
          );
        },
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columns,
    sort: sort,
  };
  return reactTableData;
};

export default CreateTableData;

import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_GROUP_DEVICE_LIST_BY_MODAL_LOADING,
  GET_GROUP_DEVICE_LIST_BY_MODAL_SUCCESS,
  GET_GROUP_DEVICE_LIST_BY_MODAL_FAIL,
  GET_GROUP_DEVICE_LIST_BY_MODAL_RESET,
} from '@/shared/constants/OtaUpdateConstants/groupMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getGroupDeviceListByModal = (depotId, model) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GROUP_DEVICE_LIST_BY_MODAL_LOADING,
    });

    const body = {
      model_type: model,
    };

    const response = await axios.post(
      ApiURL.GET_GROUP_DEVICE_LIST_BY_MODAL + depotId + '/devices_for_model/list',
      body,
      getConfig()
    );

    dispatch({
      type: GET_GROUP_DEVICE_LIST_BY_MODAL_SUCCESS,
      payload: response.data.data.device_list,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GROUP_DEVICE_LIST_BY_MODAL_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GROUP_DEVICE_LIST_BY_MODAL_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGroupDeviceListByModalReset = () => async (dispatch) => {
  dispatch({
    type: GET_GROUP_DEVICE_LIST_BY_MODAL_RESET,
  });
};

export default getGroupDeviceListByModal;

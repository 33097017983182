import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { TabsWrap, NavLink, NavItem, BorderedBottomTabs } from '@/shared/components/Tabs';
import { Card, CardBody } from '@/shared/components/Card';
import ProfileUpdateForm from './ProfileUpdateForm';
import ProfileChangePassword from './ProfileChangePassword';

const ProfileTabs = () => {
  return (
    <Card>
      <CardBody>
        <BorderedBottomTabs>
          <Tab.Container defaultActiveKey="1">
            <TabsWrap>
              <Nav className="nav-tabs">
                <NavItem>
                  <NavLink eventKey="1">Change Password</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="2">Account Settings</NavLink>
                </NavItem>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <ProfileChangePassword />
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <ProfileUpdateForm />
                </Tab.Pane>
              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </BorderedBottomTabs>
      </CardBody>
    </Card>
  );
};

export default ProfileTabs;

import { ApiURL } from '../../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_FLEET_LIST_LOADING,
  GET_FLEET_LIST_SUCCESS,
  GET_FLEET_LIST_FAIL,
} from '../../../shared/constants/FleetConstants/getFleetListConstants';

const getFleetList = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FLEET_LIST_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.get(
      ApiURL.GET_FLEET_LIST + companyId + '/get_fleet_list',
      config
    );

    dispatch({
      type: GET_FLEET_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_FLEET_LIST_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_FLEET_LIST_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getFleetList;

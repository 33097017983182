import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  UPLOAD_PACKAGE_FILE_CHECK_LOADING,
  UPLOAD_PACKAGE_FILE_CHECK_SUCCESS,
  UPLOAD_PACKAGE_FILE_CHECK_FAIL,
  UPLOAD_PACKAGE_FILE_CHECK_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { errorFunction, successMessage } from '@/shared/helpers/alertMessages';

const uploadPackageFileCheck = (formValues, filedata) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_PACKAGE_FILE_CHECK_LOADING,
    });

    const formData = new FormData();

    formData.append('company_id', formValues.company.id);
    formData.append('fleet_id', formValues.fleet.id);
    formData.append('depot_id', formValues.depot.id);
    formData.append('group_name', formValues.group.value);
    formData.append('software_version', null);
    formData.append('file', filedata.file);
    formData.append('only_check_upload', true);

    const response = await axios.post(
      ApiURL.UPLOAD_PACKAGE_FILE_CHECK,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
      getConfig()
    );
    successMessage('File Verified Succesfully');
    dispatch({
      type: UPLOAD_PACKAGE_FILE_CHECK_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPLOAD_PACKAGE_FILE_CHECK_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPLOAD_PACKAGE_FILE_CHECK_FAIL,
        payload: error.response,
      });
    }
  }
};

export const uploadPackageFileCheckReset = () => (dispatch) => {
  dispatch({
    type: UPLOAD_PACKAGE_FILE_CHECK_RESET,
  });
};

export default uploadPackageFileCheck;

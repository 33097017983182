import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { Card, CardBody } from '@/shared/components/Card';
import CompanyDetails from './components/CompanyDetails';
import NotifcationForm from './components/NotifcationForm';
import { Button } from '@/shared/components/Button';
import getRegisteredCompany from '@/redux/actions/CompanyActions/getRegisteredCompanyActions';
import getDepotVehicles from '@/redux/actions/SystemSettingsActions/ManageNotifications/getDepotVehicles';
import getDepotList from '@/redux/actions/SystemSettingsActions/ManageNotifications/getDepotList';
import getUsersList from '@/redux/actions/SystemSettingsActions/ManageNotifications/getUserList';
import getCompanyList from '@/redux/actions/CompanyActions/getCompanyListActions';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { dataObject } from '../../App/Router/data';
import getSelectedUserVehicles, {
  resetSelectedUserVehicles,
  getDepotNotificationTypes,
  resetGetDepotNotificationTypes,
} from '@/redux/actions/SystemSettingsActions/ManageNotifications/getSelectedUserVehicles';
import SelectionForm from './components/SelectionForm';
import { resetDepotVehicles } from '@/redux/actions/SystemSettingsActions/ManageNotifications/getDepotVehicles';
import createNotification from '@/redux/actions/SystemSettingsActions/ManageNotifications/createNotification';
import { resetUserListNotifications } from '@/redux/actions/SystemSettingsActions/ManageNotifications/getUserList';
import getNotificationList from '@/redux/actions/SystemSettingsActions/ManageNotifications/getNotificationList';
import { resetRegisteredCompany } from '@/redux/actions/CompanyActions/getRegisteredCompanyActions';

const ManageNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { adminRole } = dataObject;
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [selectedDepot, setSelectedDepot] = useState();
  const [distributionType, setDistributionType] = useState();

  const getUserDetails = JSON.parse(localStorage.getItem('user'));
  const getLoggedInCompanyRole = getUserDetails.company_role_name;
  const getLoggedInCompanyId = getUserDetails.company_id;
  const isAdmin = adminRole.includes(getLoggedInCompanyRole);
  const initialFormValues = JSON.parse(localStorage.getItem('notificationDetails'));

  const { loading, info } = useSelector((state) => state.getRegisteredCompanyReducer);
  const getCompanyListReducerLoading = useSelector((state) => state.getCompanyListReducer.loading);
  const getSelectedUserVehiclesReducer = useSelector(
    (state) => state.getSelectedUserVehiclesReducer.info
  );
  const getDepotVehiclesReducer = useSelector((state) => state.getDepotVehiclesReducer);

  const onSubmit = (values) => {
    const comp_id = isAdmin ? selectedCompanyId : getLoggedInCompanyId;
    if (initialFormValues && Object.keys(initialFormValues).length > 0) {
      values['alarm_type'] = initialFormValues.distribution_type;
      dispatch(
        createNotification(
          values,
          initialFormValues.company_id,
          initialFormValues.depot_id,
          history
        )
      );
    } else {
      values['alarm_type'] = distributionType;
      dispatch(createNotification(values, comp_id, selectedDepot, history));
    }
  };

  const handleCompanyChange = (values) => {
    dispatch(resetSelectedUserVehicles());
    if (Object.keys(values).length > 0 && values.id) {
      dispatch(getRegisteredCompany(values.id));
      setSelectedCompanyId(values.id);
      dispatch(getDepotList(values.id));
      localStorage.setItem('companyId', values.id);
    }
    return values;
  };

  const handleDepotChange = (e) => {
    dispatch(resetDepotVehicles());
    if (e) {
      setSelectedDepot(e.id);
      if (isAdmin) {
        dispatch(getDepotNotificationTypes(selectedCompanyId, e.id));
      }
      if (!isAdmin) {
        dispatch(getDepotNotificationTypes(getLoggedInCompanyId, e.id));
      }
    }
    if (!e) {
      dispatch(resetDepotVehicles());
      dispatch(resetSelectedUserVehicles());
    }
  };

  const handleAlarmType = (e) => {
    if (e) {
      const comp_id = isAdmin ? selectedCompanyId : getLoggedInCompanyId;
      setDistributionType(e.id);
      dispatch(getDepotVehicles(comp_id, selectedDepot));
    }
  };

  const getUsersListNotiInfo = useSelector((state) => state.getUsersListNotiReducer.info);

  const usersOptions =
    getSelectedUserVehiclesReducer && getUsersListNotiInfo
      ? getUsersListNotiInfo.map((item) => {
          return {
            ...item,
            checked: !!(
              getSelectedUserVehiclesReducer &&
              getSelectedUserVehiclesReducer?.notification?.notification_user_id_list?.includes(
                item.id
              )
            ),
            type: 'user',
          };
        })
      : [];

  const vinOptions =
    getSelectedUserVehiclesReducer && getDepotVehiclesReducer.info
      ? getDepotVehiclesReducer.info.map((item) => {
          return {
            ...item,
            checked: !!(
              getSelectedUserVehiclesReducer &&
              getSelectedUserVehiclesReducer?.notification?.vin_list?.includes(item.id)
            ),
            type: 'vin',
          };
        })
      : [];

  const resetReducers = () => {
    dispatch(resetRegisteredCompany());
    dispatch(resetDepotVehicles());
    dispatch(resetSelectedUserVehicles());
    dispatch(resetGetDepotNotificationTypes());
    isAdmin && dispatch(resetUserListNotifications());
  };

  useEffect(() => {
    if (getLoggedInCompanyId) {
      if (!isAdmin) {
        dispatch(getDepotList(getLoggedInCompanyId));
        dispatch(getUsersList(getLoggedInCompanyId));
      }
    }
    if (selectedCompanyId) {
      if (isAdmin) {
        dispatch(getUsersList(selectedCompanyId));
      }
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    dispatch(getNotificationList());
    if (initialFormValues && Object.keys(initialFormValues).length > 0) {
      dispatch(getRegisteredCompany(initialFormValues.company_id));
      dispatch(getUsersList(initialFormValues.company_id));
      dispatch(getDepotVehicles(initialFormValues.company_id, initialFormValues.depot_id));
      dispatch(
        getSelectedUserVehicles(
          initialFormValues.company_id,
          initialFormValues.depot_id,
          initialFormValues.distribution_type
        )
      );
    } else {
      if (getLoggedInCompanyId) {
        if (isAdmin) {
          dispatch(getCompanyList(getLoggedInCompanyId));
        }
        if (!isAdmin) {
          dispatch(getRegisteredCompany(getLoggedInCompanyId));
        }
      }
    }
    return () => {
      resetReducers();
      localStorage.removeItem('notificationDetails');
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <SelectionForm
                handleCompanyChange={handleCompanyChange}
                handleDepotChange={handleDepotChange}
                handleAlarmType={handleAlarmType}
                renderCompany={isAdmin}
                initialFormValues={initialFormValues}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <CompanyDetails
                info={info}
                loading={loading}
                getCompanyListReducerLoading={getCompanyListReducerLoading}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              user_list: usersOptions.filter((item) => item.checked),
              vehicle_list: vinOptions.filter((item) => item.checked),
            }}
          >
            {({ handleSubmit, values }) => (
              <Card>
                <CardBody>
                  <NotifcationForm vinOptions={vinOptions} usersOptions={usersOptions} />
                  <FormButtonToolbar>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        history.push('/notifications');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={values.user_list.length === 0 || values.vehicle_list.length === 0}
                    >
                      Save
                    </Button>
                  </FormButtonToolbar>
                </CardBody>
              </Card>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageNotifications;

import {
  GET_DEPOT_LIST_BY_COMPANY_LOADING,
  GET_DEPOT_LIST_BY_COMPANY_SUCCESS,
  GET_DEPOT_LIST_BY_COMPANY_FAIL,
} from '../../../shared/constants/DepotConstants/getDepotListByCompanyConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const getDepotListByCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPOT_LIST_BY_COMPANY_LOADING:
      return { ...state, loading: true };
    case GET_DEPOT_LIST_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_DEPOT_LIST_BY_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getDepotListByCompanyReducer;

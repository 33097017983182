import { useState, useMemo } from 'react';
import ShowModalIcon from 'mdi-react/OpenInNewIcon';
import { GetTableHeader } from '@/utils/helpers';

const CreateDataDeviceStatus = () => {
  const [sort, setSort] = useState();
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState();

  const sortData = (props) => {
    setSort(props);
  };

  const handleClick = (rowData) => {
    setRowData(rowData);
    setShowModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Device id'}
            key={'Device id'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'device_id',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Version'}
            key={'Version'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'current_version',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Start time'}
            key={'Start time'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'start_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'End time'}
            key={'End time'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'end_time',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Status'}
            key={'Status'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'status',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => (
          <GetTableHeader
            item={props}
            headerName={'Remark'}
            key={'Remark'}
            onClick={() => sortData(props)}
          />
        ),
        accessor: 'remark',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => <GetTableHeader item={props} headerName={'Type'} key={'T'} />,
        accessor: 'type',
        disableGlobalFilter: true,
      },
      {
        Header: (props) => <GetTableHeader item={props} headerName={'Action'} key={'Action'} />,
        accessor: 'action',
        disableGlobalFilter: true,
        Cell: (props) => {
          return (
            <span style={{ cursor: 'pointer' }}>
              {props.row.original.last_reply_from_device && (
                <ShowModalIcon onClick={() => handleClick(props.row.original)} />
              )}
            </span>
          );
        },
      },
    ],
    []
  );
  const reactTableDataDeviceStatus = {
    tableHeaderData: columns,
    sort: sort,
    showModal: showModal,
    setShowModal: setShowModal,
    rowData: rowData,
  };
  return reactTableDataDeviceStatus;
};

export default CreateDataDeviceStatus;

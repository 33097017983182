import { ApiURL } from '../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_FLEETS_LOADING,
  GET_FLEETS_SUCCESS,
  GET_FLEETS_FAIL,
  GET_ALL_FLEETS_LOADING,
  GET_ALL_FLEETS_SUCCESS,
  GET_ALL_FLEETS_FAIL,
  GET_OP_FLEETS_LOADING,
  GET_OP_FLEETS_SUCCESS,
  GET_OP_FLEETS_FAIL,
  GET_OP_FLEETS_RESET,
} from '../../shared/constants/getAssociationsConstants';
import { getConfig } from '../../shared/helpers/apiConfig';

const getFleets = (companyId, companySelectedId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FLEETS_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_FLEETS + companyId + '/fleets?company_id=' + companySelectedId,
      getConfig()
    );

    dispatch({
      type: GET_FLEETS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_FLEETS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_FLEETS_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const getAllFleets = (selectedComp, serviceProvider) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FLEETS_LOADING,
    });

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const company_Id = userDetails.company_id;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // Global Dashboard Case
    const { data } = await axios.get(
      ApiURL.GET_FLEETS +
        company_Id +
        '/fleets?pto_id=' +
        selectedComp +
        '&service_provider_id=' +
        serviceProvider,
      config
    );

    dispatch({
      type: GET_ALL_FLEETS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_ALL_FLEETS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_ALL_FLEETS_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const getOpFleets = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OP_FLEETS_LOADING,
    });

    const { data } = await axios.get(
      ApiURL.GET_OP_FLEETS + companyId + '/operator_fleet_list',
      getConfig()
    );

    dispatch({
      type: GET_OP_FLEETS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_OP_FLEETS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_OP_FLEETS_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getOpFleetsReset = () => async (dispatch) => {
  dispatch({
    type: GET_OP_FLEETS_RESET,
  });
};

export default getFleets;

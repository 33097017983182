import axios from 'axios';
import {
  FORGOT_PWD_LOADING,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_FAIL,
  FORGOT_PWD_RESET,
} from '@/shared/constants/SignInConstants/signInConstants';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const forgotPwd = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PWD_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(ApiURL.FORGOT_PWD, { username: email }, config);
    successMessage('Temp password sent on email');

    dispatch({
      type: FORGOT_PWD_SUCCESS,
      payload: response.data.data.message,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: FORGOT_PWD_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: FORGOT_PWD_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export const forgotPwdReset = () => (dispatch) => {
  dispatch({
    type: FORGOT_PWD_RESET,
  });
};

export default forgotPwd;

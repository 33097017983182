import axios from 'axios';
import moment from 'moment';
import {
  GET_MAINTENANCE_LOADING,
  GET_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE_FAIL,
} from '@/shared/constants/MaintenanceConstants/maintenanceMetaConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '../../../config/GlobalUrl';

const getMaintenanceMeta = (vin) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_PREVENTIVE_MAINTENANCE + vin + '/metadata',
      getConfig()
    );

    const convertBackDate = (dateVal) => {
      const newDateVal = dateVal * 86400;
      return moment.unix(newDateVal).format('L');
    };

    let metadata = [];

    if (
      response.data.data.metadata.length > 0 &&
      response.data.data.metadata[0].mode === 'time_period_days'
    ) {
      metadata = [
        {
          mode: response.data.data.metadata[0].mode,
          level_a: convertBackDate(response.data.data.metadata[0].level_a),
          level_b: convertBackDate(response.data.data.metadata[0].level_b),
          level_c: convertBackDate(response.data.data.metadata[0].level_c),
          level_d: convertBackDate(response.data.data.metadata[0].level_d),
          level_e: convertBackDate(response.data.data.metadata[0].level_e),
        },
      ];
    }
    if (
      response.data.data.metadata.length > 0 &&
      response.data.data.metadata[0].mode === 'operating_hours'
    ) {
      metadata = [
        {
          mode: response.data.data.metadata[0].mode,
          level_a: response.data.data.metadata[0].level_a,
          level_b: response.data.data.metadata[0].level_b,
          level_c: response.data.data.metadata[0].level_c,
          level_d: response.data.data.metadata[0].level_d,
          level_e: response.data.data.metadata[0].level_e,
        },
      ];
    }

    dispatch({
      type: GET_MAINTENANCE_SUCCESS,
      payload: metadata,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_MAINTENANCE_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_MAINTENANCE_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getMaintenanceMeta;

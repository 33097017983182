export const GET_COMPANY_TAGS_LOADING = 'GET_COMPANY_TAGS_LOADING';
export const GET_COMPANY_TAGS_SUCCESS = 'GET_COMPANY_TAGS_SUCCESS';
export const GET_COMPANY_TAGS_FAIL = 'GET_COMPANY_TAGS_FAIL';

export const GET_DEPOT_TAGS_LOADING = 'GET_DEPOT_TAGS_LOADING';
export const GET_DEPOT_TAGS_SUCCESS = 'GET_DEPOT_TAGS_SUCCESS';
export const GET_DEPOT_TAGS_FAIL = 'GET_DEPOT_TAGS_FAIL';
export const GET_DEPOT_TAGS_RESET = 'GET_DEPOT_TAGS_RESET';

export const DOWNLOAD_INDEX_ERROR_CODE_LOADING = 'DOWNLOAD_INDEX_ERROR_CODE_LOADING';
export const DOWNLOAD_INDEX_ERROR_CODE_SUCCESS = 'DOWNLOAD_INDEX_ERROR_CODE_SUCCESS';
export const DOWNLOAD_INDEX_ERROR_CODE_FAIL = 'DOWNLOAD_INDEX_ERROR_CODE_FAIL';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import renderSelectField from '@/shared/components/form/Select';
import { Card, CardBody } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { selectUserValidate } from '@/utils/validate.js';
import DotDotLoader from '@/shared/components/DotDotLoader';
import UserForm from './UserForm';
import getUserList from '../../../../redux/actions/UserActions/getUserListActions';

const UserSelectForm = ({ setGoBack }) => {
  const dispatch = useDispatch();

  const [showUserEditForm, setShowUserEditForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [userId, setUserId] = useState();
  const [btnClicked, setBtnClicked] = useState(false);

  const companyId = localStorage.getItem('companyId');

  const getUserListReducerLoading = useSelector((state) => state.getUserListReducer.loading);

  const getUserListReducerInfo = useSelector((state) => state.getUserListReducer.info);

  const getUserListReducerError = useSelector((state) => state.getUserListReducer.error);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserList(companyId));
    }
  }, [companyId]);

  const handleSubmit = (values) => {
    if (Object.keys(values).length > 0 && values.user.id) {
      setUserId(values.user.id);
      setBtnClicked(true);
      setShowUserEditForm(true);
    }
  };

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <>
      {showUserEditForm === true ? (
        <UserForm editMode={editMode} setShowUserEditForm={setShowUserEditForm} userId={userId} />
      ) : (
        <Card height='auto'>
          <CardBody>
            <Form onSubmit={handleSubmit} validate={selectUserValidate}>
              {({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>Select User</FormGroupLabel>

                    {getUserListReducerLoading ? (
                      <DotDotLoader loadingState={getUserListReducerLoading} />
                    ) : (
                      <FormGroupField>
                        <Field
                          name='user'
                          component={renderSelectField}
                          type='text'
                          options={
                            getUserListReducerInfo
                              ? getUserListReducerInfo.users.map(({ id, username }) => ({
                                  id: id,
                                  value: username,
                                  label: username,
                                }))
                              : []
                          }
                          placeholder='Select user'
                        />
                      </FormGroupField>
                    )}
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant='secondary' type='button' onClick={() => handleCancel()}>
                      Back
                    </Button>
                    <Button variant='primary' type='submit'>
                      Next
                    </Button>
                  </FormButtonToolbar>
                </FormContainer>
              )}
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default UserSelectForm;

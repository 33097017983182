import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroupIcon,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import BusAlertIcon from 'mdi-react/BusAlertIcon';
import TagIcon from 'mdi-react/TagIcon';
import TagTextIcon from 'mdi-react/TagTextIcon';
import CogsIcon from 'mdi-react/CogsIcon';
import BusIcon from 'mdi-react/BusIcon';
import CardAccountDetailsIcon from 'mdi-react/CardAccountDetailsIcon';
import EngineIcon from 'mdi-react/EngineIcon';
import FormField from '../../../../shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import { Button } from '@/shared/components/Button';
import SimpleLoader from '@/shared/components/SimpleLoader';
import { vehicleRegisterationValidate } from '../../../../utils/validate';

const WizardFormOne = ({ onSubmit, initialValues, defaultValues, buttonState }) => {
  const history = useHistory();

  const loading = useSelector((state) => state.vehicleRegisterReducer.loading);

  const newVehicleVin = localStorage.getItem('NewVin');

  const data = [
    {
      id: 1,
      name: 'EV',
    },
    {
      id: 2,
      name: 'ICE',
    },
  ];

  const selectVal = initialValues.engine_type
    ? typeof initialValues.engine_type === 'string'
      ? {
          id: initialValues.engine_type,
          value: initialValues.engine_type,
          label: initialValues.engine_type,
        }
      : {
          id: initialValues.engine_type.value,
          value: initialValues.engine_type.value,
          label: initialValues.engine_type.value,
        }
    : null;

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={vehicleRegisterationValidate}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit} style={{ paddingTop: '25px' }} autoComplete="off">
          {loading ? <SimpleLoader /> : ''}
          <FormGroup>
            <FormGroupLabel>VIN Number</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <BusAlertIcon />
              </FormGroupIcon>
              <Field
                name="vin"
                component={FormField}
                type="text"
                required
                disabled={true}
                initialValue={newVehicleVin}
                placeholder={'VIN Number'}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Body Number</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <TagIcon />
              </FormGroupIcon>
              <Field
                name="body_number"
                component={FormField}
                type="text"
                placeholder={'Body Number'}
                maxLength={200}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Manufacturer</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <CogsIcon />
              </FormGroupIcon>
              <Field
                name="manufacturer"
                component={FormField}
                type="text"
                placeholder={'Manufacturer'}
                maxLength={200}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Model</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <TagTextIcon />
              </FormGroupIcon>
              <Field
                name="model"
                component={FormField}
                type="text"
                placeholder={'Model'}
                maxLength={200}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Bus Number</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <BusIcon />
              </FormGroupIcon>
              <Field
                name="bus_num"
                component={FormField}
                type="text"
                placeholder={'Bus Number'}
                maxLength={200}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Plate Number</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <CardAccountDetailsIcon />
              </FormGroupIcon>
              <Field
                name="plate_num"
                component={FormField}
                type="text"
                placeholder={'Plate Number'}
                maxLength={200}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Engine Type</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon style={{ minHeight: '38px' }}>
                <EngineIcon />
              </FormGroupIcon>
              <Field
                name="engine_type"
                component={renderSelectField}
                // type="text"
                options={data.map(({ id, name }) => ({
                  id: id,
                  value: name,
                  label: name,
                }))}
                initialValue={selectVal}
                placeholder={'Engine Type'}
                isSearchable={false}
              />
            </FormGroupField>
          </FormGroup>
          <FormButtonToolbar style={{ width: '100%', marginBottom: '0' }}>
            <Button
              variant="secondary"
              type="button"
              style={{ width: '100%' }}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </FormButtonToolbar>

          <FormButtonToolbar style={{ width: '100%', marginBottom: '0' }}>
            <Button variant="primary" type="submit" className="next" style={{ width: '100%' }}>
              Next
            </Button>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  );
};
WizardFormOne.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export default WizardFormOne;

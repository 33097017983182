import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import { WizardFormWrap, WizardWrap } from '@/shared/components/form/WizardFormElements';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import { vehicleRegisterAction } from '../../../../redux/actions/vehicleActions';
import editVehicleActions, {
  editVehicleActionsReset,
} from '../../../../redux/actions/editVehicleActions';

const WizardForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getVinReducer = useSelector((state) => state.getVinReducer.info);

  const [data, setData] = useState(getVinReducer ? getVinReducer.data.vehicle : {});
  const [page, setPage] = useState(1);

  const nextPage = (values) => {
    setPage(page + 1);
    setData(values);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const vehicleRegisterReducer = useSelector((state) => state.vehicleRegisterReducer);

  const [showForm, setShowForm] = useState(false);
  const [afterEdit, setAfterEdit] = useState(false);

  const handleSubmit = (values) => {
    if (
      Object.entries(values).length !== 0 &&
      values.fleet_name !== '' &&
      values.depot_name !== ''
    ) {
      setData(values);
      dispatch(vehicleRegisterAction(values));
      setShowForm(true);
    }
  };

  if (
    vehicleRegisterReducer.info !== '' &&
    vehicleRegisterReducer.info.status === 'success' &&
    showForm === true
  ) {
    setTimeout(() => {
      history.push('/app/hvac_dashboard');
    }, 2000);
  }

  const editVehicleSubmit = (values) => {
    if (Object.entries(values).length !== 0) {
      dispatch(editVehicleActions(values));
      setAfterEdit(true);
    }
  };

  const editVehicleReducer = useSelector((state) => state.editVehicleReducer);

  if (editVehicleReducer.info !== '' && afterEdit === true) {
    setTimeout(() => {
      dispatch(editVehicleActionsReset());
      setAfterEdit(false);
      history.push('/app/hvac_dashboard');
    }, 2000);
  }

  return (
    <Row>
      <Col md={6} lg={6}>
        <Card>
          <WizardWrap>
            {getVinReducer && getVinReducer.status === 'success' ? (
              <WizardFormWrap>
                {page === 1 && (
                  <WizardFormOne
                    onSubmit={nextPage}
                    initialValues={data}
                    defaultValues={getVinReducer ? getVinReducer.data.vehicle : ''}
                    buttonState={true}
                  />
                )}
                {page === 2 && (
                  <WizardFormTwo
                    previousPage={previousPage}
                    onSubmit={editVehicleSubmit}
                    initialValues={data}
                    defaultValues={getVinReducer ? getVinReducer.data.vehicle : ''}
                    buttonState={true}
                  />
                )}
              </WizardFormWrap>
            ) : (
              <WizardFormWrap>
                {page === 1 && <WizardFormOne onSubmit={nextPage} initialValues={data} />}
                {page === 2 && (
                  <WizardFormTwo
                    previousPage={previousPage}
                    onSubmit={handleSubmit}
                    initialValues={data}
                  />
                )}
              </WizardFormWrap>
            )}
          </WizardWrap>
        </Card>
      </Col>
    </Row>
  );
};

// WizardForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default WizardForm;

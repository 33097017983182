import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_DEPOT_VEHICLE_STATUS_LOADING,
  GET_DEPOT_VEHICLE_STATUS_SUCCESS,
  GET_DEPOT_VEHICLE_STATUS_FAIL,
} from '@/shared/constants/DepotDashboardConstants/depotDashboardConstants.js';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { sortingFunc } from '@/utils/helpers.js';

const getDepotVehicleStatus =
  (companyId, depotId, tag, pageNumber, numberofRecords, sortData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DEPOT_VEHICLE_STATUS_LOADING,
      });

      const params = {
        p: pageNumber ? pageNumber : null,
        page_size: numberofRecords ? numberofRecords : null,
        sort: sortData ? sortingFunc(sortData) : null,
        tag_id: tag ? tag : '',
      };
      const { data } = await axios.get(
        ApiURL.GET_DEPOT_VEHICLE_STATUS + companyId + '/depo_id/' + depotId,
        { params },
        getConfig()
      );

      dispatch({
        type: GET_DEPOT_VEHICLE_STATUS_SUCCESS,
        payload: { data: data.data.vehicles, pagination: data.pagination },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_DEPOT_VEHICLE_STATUS_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_DEPOT_VEHICLE_STATUS_FAIL,
          payload: error.response.data,
        });
      }
    }
  };

export default getDepotVehicleStatus;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormContainer,
  StyledPreTag,
} from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import getCompanyData from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getCompanyDataAction';
import getGroupList from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getGroupListAction';
import getmetadataAction, {
  getMetaDataResetAction,
} from '@/redux/actions/OtaUpdateActions/CampaignMgtActions/getmetadataAction';
import { WizardButtonToolbar } from '@/shared/components/form/WizardFormElements';
import { campaignCreationValidateStepOne } from '@/utils/validate';
import { Button } from '@/shared/components/Button';
import SharedRadio from '../../../SharedElements/SharedRadio';

const StepOne = ({ onSubmit, defaultValues, initialValues, prefilledFormOne }) => {
  const dispatch = useDispatch();

  const defaultCompanyValue = defaultValues.operator_name
    ? typeof defaultValues.operator_name === 'string'
      ? {
          id: defaultValues ? defaultValues.operator_id : null,
          label: defaultValues ? defaultValues.operator_name : null,
          value: defaultValues ? defaultValues.operator_name : null,
        }
      : {
          id: defaultValues ? defaultValues.operator_name.id : null,
          label: defaultValues ? defaultValues.operator_name.value : null,
          value: defaultValues ? defaultValues.operator_name.value : null,
        }
    : null;

  const defaultFleetValue = defaultValues.fleet_name
    ? typeof defaultValues.fleet_name === 'string'
      ? {
          id: defaultValues ? defaultValues.fleet_id : null,
          label: defaultValues ? defaultValues.fleet_name : null,
          value: defaultValues ? defaultValues.fleet_name : null,
        }
      : {
          id: defaultValues ? defaultValues.fleet_name.id : null,
          label: defaultValues ? defaultValues.fleet_name.value : null,
          value: defaultValues ? defaultValues.fleet_name.value : null,
        }
    : null;

  const defaultDepotValue = defaultValues.depot_name
    ? typeof defaultValues.depot_name === 'string'
      ? {
          id: defaultValues ? defaultValues.depot_id : null,
          label: defaultValues ? defaultValues.depot_name : null,
          value: defaultValues ? defaultValues.depot_name : null,
        }
      : {
          id: defaultValues ? defaultValues.depot_name.id : null,
          label: defaultValues ? defaultValues.depot_name.value : null,
          value: defaultValues ? defaultValues.depot_name.value : null,
        }
    : null;

  const defaultGroupValue = defaultValues.group_name
    ? typeof defaultValues.group_name === 'string'
      ? {
          label: defaultValues ? defaultValues.group_label : null,
          value: defaultValues ? defaultValues.group_name : null,
        }
      : {
          id: defaultValues ? defaultValues.group_name.id : null,
          label: defaultValues ? defaultValues.group_name.label : null,
          value: defaultValues ? defaultValues.group_name.value : null,
        }
    : null;

  // const defaultUnitName = defaultValues.unit_name
  //   ? typeof defaultValues.unit_name === 'string'
  //     ? {
  //         id: defaultValues ? defaultValues.unit_name : null,
  //         label: defaultValues ? defaultValues.unit_name : null,
  //         value: defaultValues ? defaultValues.unit_name : null,
  //       }
  //     : {
  //         id: defaultValues ? defaultValues.unit_name.id : null,
  //         label: defaultValues ? defaultValues.unit_name.value : null,
  //         value: defaultValues ? defaultValues.unit_name.value : null,
  //       }
  //   : null;

  const [companyData, setCompanyData] = useState('');
  const [fleetData, setFleetData] = useState('');
  const [resetDepot, setResetDepot] = useState(false);
  const [initialSofwareVersion, setinitialSofwareVersion] = useState(null);

  const getMetadataReducer = useSelector((state) => state.getMetadataReducer.info);
  const groupListInfo = useSelector((state) => state.getGroupListReducer.info);
  const { filterOptions } = useSelector((state) => state.getCompanyDataReducer);

  const radioValues = ['e-control', 'gateway'];

  const onCompanyChange = (e) => {
    if (e) {
      setCompanyData(`pto_id=${e.id}`);
    }
    if (!e) {
      setCompanyData('');
      setFleetData('');
      setResetDepot(true);
    }
  };

  const onFleetChange = (e) => {
    if (e) {
      setFleetData(`&fleet_id=${e.id}`);
      setResetDepot(false);
    }
    if (!e) {
      setFleetData('');
      setResetDepot(true);
    }
  };

  const onDepotChange = (e) => {
    if (e) {
      dispatch(getGroupList(e.id));
      setResetDepot(false);
    }
    if (!e) {
      setResetDepot(true);
    }
  };

  useEffect(() => {
    dispatch(getCompanyData(companyData, fleetData));
    if (
      defaultValues &&
      defaultValues.group_name &&
      defaultValues.group_name &&
      prefilledFormOne &&
      defaultGroupValue &&
      defaultDepotValue
    ) {
      dispatch(getmetadataAction(defaultDepotValue.id, defaultGroupValue.value));
    }
    if (defaultValues?.software_version) {
      setinitialSofwareVersion({
        id: defaultValues.software_version,
        value: defaultValues.software_version,
        label: defaultValues.software_version,
        metadata: defaultValues.metadata,
      });
    }
  }, [companyData, fleetData]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={campaignCreationValidateStepOne}
    >
      {({ handleSubmit, form, hasValidationErrors, values }) => (
        <FormContainer
          onSubmit={handleSubmit}
          style={{ maxWidth: '700px', width: '100%', marginTop: '25px' }}
          vertical
        >
          <FormGroup>
            <FormGroupLabel>Choose Device Type</FormGroupLabel>
            <FormGroupField>
              <Field
                name="deviceType"
                component={SharedRadio}
                label="HVAC ECUs"
                radioValue={radioValues[0]}
                value={radioValues[0]}
                initialValue={defaultValues && defaultValues.type}
                disabled={defaultValues && defaultValues.type && prefilledFormOne ? true : false}
                // disabled={true}
              />
              <Field
                name="deviceType"
                component={SharedRadio}
                label="Gateway"
                radioValue={radioValues[1]}
                value={radioValues[1]}
                initialValue={defaultValues && defaultValues.type}
                // disabled={defaultValues && defaultValues.type && prefilledFormOne ? true : false}
                disabled={true}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Company</FormGroupLabel>
            <FormGroupField>
              <Field
                name="operator_name"
                component={renderSelectField}
                options={filterOptions.operators}
                placeholder="Select company"
                isClearable
                parse={(e) => {
                  onCompanyChange(e);
                  form.change('fleet_name', '');
                  form.change('depot_name', '');
                  form.change('group_name', '');
                  form.change('software-version', '');
                  dispatch(getMetaDataResetAction());
                  return e;
                }}
                initialValue={defaultValues && defaultValues.operator_name && defaultCompanyValue}
                isDisabled={
                  defaultValues && defaultValues.operator_name && prefilledFormOne ? true : false
                }
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Fleet</FormGroupLabel>
            <FormGroupField>
              <Field
                name="fleet_name"
                component={renderSelectField}
                options={filterOptions && companyData ? filterOptions.fleets : []}
                placeholder="Select fleet"
                isClearable
                parse={(e) => {
                  onFleetChange(e);
                  form.change('depot_name', '');
                  form.change('group_name', '');
                  form.change('software-version', '');
                  dispatch(getMetaDataResetAction());
                  return e;
                }}
                initialValue={defaultValues && defaultValues.fleet_name && defaultFleetValue}
                isDisabled={
                  defaultValues && defaultValues.fleet_name && prefilledFormOne ? true : false
                }
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Depot</FormGroupLabel>
            <FormGroupField>
              <Field
                name="depot_name"
                component={renderSelectField}
                options={filterOptions && companyData && !resetDepot ? filterOptions.depots : []}
                placeholder="Select depot"
                isClearable
                parse={(e) => {
                  onDepotChange(e);
                  form.change('group_name', '');
                  form.change('software-version', '');
                  dispatch(getMetaDataResetAction());
                  return e;
                }}
                initialValue={defaultValues && defaultValues.depot_name && defaultDepotValue}
                isDisabled={
                  defaultValues && defaultValues.depot_name && prefilledFormOne ? true : false
                }
              />
            </FormGroupField>
          </FormGroup>
          {/* <FormGroup>
            <FormGroupLabel>Unit Name</FormGroupLabel>
            <FormGroupField>
              <Field
                name="unit_name"
                component={renderSelectField}
                options={[
                  { id: 'Master', value: 'Master', label: 'Master' },
                  { id: 'HMI', value: 'HMI', label: 'HMI' },
                  { id: 'Slave', value: 'Slave', label: 'Slave' },
                ]}
                placeholder="Select type"
                initialValue={defaultValues && defaultUnitName}
                isDisabled={defaultValues && defaultUnitName && prefilledFormOne ? true : false}
              />
            </FormGroupField>
          </FormGroup> */}
          <FormGroup>
            <FormGroupLabel>Group</FormGroupLabel>
            <FormGroupField>
              <Field
                name="group_name"
                component={renderSelectField}
                options={groupListInfo && !resetDepot ? groupListInfo : []}
                placeholder="Select group"
                initialValue={defaultValues && defaultValues.group_name && defaultGroupValue}
                isDisabled={
                  defaultValues && defaultValues.group_name && prefilledFormOne ? true : false
                }
                parse={(e) => {
                  form.change('software-version', '');
                  dispatch(getmetadataAction(values.depot_name.id, e.value));
                  return e;
                }}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Software Version</FormGroupLabel>
            <FormGroupField>
              <Field
                name="software-version"
                placeholder="Select software version number"
                component={renderSelectField}
                options={
                  getMetadataReducer
                    ? getMetadataReducer.packages.map((item) => {
                        return {
                          id: item.software_version,
                          value: item.software_version,
                          label: item.software_version,
                          metadata: item.metadata,
                        };
                      })
                    : []
                }
                isDisabled={
                  defaultValues && defaultValues.software_version && prefilledFormOne ? true : false
                }
                initialValue={
                  initialSofwareVersion
                    ? initialSofwareVersion
                    : defaultValues['software-version']
                    ? defaultValues['software-version']
                    : null
                }
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Metadata</FormGroupLabel>
            <FormGroupField>
              <StyledPreTag>
                {getMetadataReducer?.packages &&
                  values['software-version'] &&
                  JSON.stringify(values['software-version'].metadata, null, 2)}
                {(!getMetadataReducer || !values['software-version']) &&
                values.operator_name &&
                values.fleet &&
                values.depot &&
                values.group &&
                values['software-version'] &&
                defaultValues['software-version']?.metadata
                  ? JSON.stringify(defaultValues['software-version'].metadata, null, 2)
                  : (!getMetadataReducer || !values['software-version']) && 'Metadata'}
              </StyledPreTag>
            </FormGroupField>
          </FormGroup>
          <WizardButtonToolbar>
            <Button variant="primary" type="submit" className="next" disabled={hasValidationErrors}>
              Next
            </Button>
          </WizardButtonToolbar>
        </FormContainer>
      )}
    </Form>
  );
};

export default StepOne;

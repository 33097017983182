import {
  SET_OTA_ROW_ACTION,
  RESET_OTA_ROW_ACTION,
} from "@/shared/constants/OtaUpdateConstants/otaDashboardConstants";

const updateOtaRow = (rowdata) => async (dispatch) => {
  dispatch({
    type: SET_OTA_ROW_ACTION,
    payload: rowdata,
  });
};

export const updateOtaRowReset = (rowdata) => async (dispatch) => {
  dispatch({
    type: RESET_OTA_ROW_ACTION
  });
};

export default updateOtaRow;

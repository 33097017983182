import axios from 'axios';
import {
  GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING,
  GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS,
  GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL,
  GET_GATEWAY_DIAGNOSTICS_MONTH_RESET,
} from '@/shared/constants/GatewayDiagnosticsConstants/gatewayDiagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { ApiURL } from '../../../config/GlobalUrl';

const getGatewayDiagnosticsMonth = (cpuId, startDate, endDate) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GATEWAY_DIAGNOSTICS_MONTH_LOADING,
    });

    const response = await axios.get(
      ApiURL.GET_GATEWAY_DIAGNOSTICS_FILE_FOR_MONTH +
        `${cpuId}/filtered/files/list?start_date=${startDate}&end_date=${endDate}`,
      getConfig()
    );

    dispatch({
      type: GET_GATEWAY_DIAGNOSTICS_MONTH_SUCCESS,
      payload: { response: response.data.data, cpuId },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_MONTH_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getGatewayDiagnosticsMonthReset = () => (dispatch) => {
  dispatch({
    type: GET_GATEWAY_DIAGNOSTICS_MONTH_RESET,
  });
};

export default getGatewayDiagnosticsMonth;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import CompanyDetailsContainer from '../ManageCompany/CompanyDetailsContainer';
import ManageDepotContainer from './ManageDepotContainer';
import DepotForm from './components/DepotForm';
import DepotSelectForm from './components/DepotSelectForm';
import getRegisteredCompany from '../../../redux/actions/CompanyActions/getRegisteredCompanyActions';
import ViewDepot from './components/ViewDepot';
import { resetDepotList } from '../../../redux/actions/DepotActions/getDepotListActions';
import getDepotListByCompany from '../../../redux/actions/DepotActions/getDepotListByCompanyActions';
import data from '../../App/Router/data';

const ManageDepot = () => {
  const Ava = `${process.env.PUBLIC_URL}/img/Vehicle-4x.jpg`;
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, info, error } = useSelector((state) => state.getRegisteredCompanyReducer);

  const [
    { adminRole },
    { viewRole },
    { all },
    { companyRole },
    { companyAdmin },
    { componentNames },
    { canAccess },
  ] = data;

  const assets = ['Add Depots', 'Edit Depots', 'View Depots'];

  const addImage = `${process.env.PUBLIC_URL}/img/Add.jpg`;
  const editImage = `${process.env.PUBLIC_URL}/img/Edit.jpg`;
  const viewImage = `${process.env.PUBLIC_URL}/img/View.jpg`;

  const assetData = [
    {
      id: 1,
      img: addImage,
      assetType: assets[0],
    },
    {
      id: 2,
      img: editImage,
      assetType: assets[1],
    },
    {
      id: 3,
      img: viewImage,
      assetType: assets[2],
    },
  ];
  const [showDepotForm, setShowDepotForm] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSelectDepot, setShowSelectDepot] = useState(false);
  const [showDepotView, setShowDepotView] = useState(false);
  const companyId = localStorage.getItem('companyId');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const companyRoleName = userDetails.company_role_name;

  const toggleDepotForm = () => {
    setGoBack(false);
    setShowDepotForm(true);
    setShowSelectDepot(false);
  };

  const toggleDepotSelect = () => {
    dispatch(resetDepotList());
    setGoBack(false);
    setShowDepotForm(false);
    setShowSelectDepot(true);
  };
  const toggleDepotView = () => {
    if (companyId) {
      dispatch(getDepotListByCompany(companyId));
    }
    setGoBack(false);
    setShowDepotForm(false);
    setShowSelectDepot(false);
    setShowDepotView(true);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getRegisteredCompany(companyId));
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              {loading || !info || info === undefined ? (
                <DotDotLoader loadingState={loading} />
              ) : (
                <CompanyDetailsContainer
                  img={Ava}
                  name={info.company.name}
                  website={info.company.website}
                  role={info.company.company_type}
                  city={info.company.city}
                  country={info.company.country}
                  showLink={false}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {showDepotForm === true && goBack === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Depot Registration Section</h6>
            <Col xs={12} sm={12} md={6}>
              <DepotForm setGoBack={setGoBack} editMode={editMode} />
            </Col>
          </>
        ) : showSelectDepot === true && goBack === false && showDepotForm === false ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Edit Depot details</h6>
            <Col xs={12} sm={12} md={6}>
              <DepotSelectForm setGoBack={setGoBack} />
            </Col>
          </>
        ) : showSelectDepot === false &&
          goBack === false &&
          showDepotForm === false &&
          showDepotView === true ? (
          <>
            <h6 style={{ paddingBottom: '20px' }}>View Depot details</h6>
            <Col xs={12} sm={12} md={6}>
              <ViewDepot setGoBack={setGoBack} />
            </Col>
          </>
        ) : (
          <>
            <h6 style={{ paddingBottom: '20px' }}>Depot Settings</h6>
            <Col xs={12} sm={12} md={6}>
              <Card height="auto">
                <CardBody>
                  {info &&
                    assetData
                      .filter(({ assetType }) =>
                        adminRole.includes(companyRoleName) &&
                        !canAccess.includes(info.company.company_type)
                          ? assetType === assets[2]
                          : assetType
                      )
                      .map(({ id, assetType, img }) => (
                        <div
                          key={id}
                          onClick={() =>
                            assetType === assets[0]
                              ? toggleDepotForm()
                              : assetType === assets[1]
                              ? toggleDepotSelect()
                              : assetType === assets[2]
                              ? toggleDepotView()
                              : ''
                          }
                        >
                          <ManageDepotContainer img={img} assettype={assetType} />
                        </div>
                      ))}
                  <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{ width: '100%' }}
                    >
                      Back
                    </Button>
                  </FormButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageDepot;

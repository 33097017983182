import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_METADATA_LOADING,
  GET_METADATA_SUCCESS,
  GET_METADATA_FAIL,
  GET_METADATA_RESET,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';

const getmetadataAction = (depotid, groupName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_METADATA_LOADING,
    });
    const response = await axios.get(
      ApiURL.GET_PACHAKAGES_METADATA + `/${depotid}/groups/${groupName}/packages/list`,
      getConfig()
    );

    dispatch({
      type: GET_METADATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_METADATA_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_METADATA_FAIL,
        payload: error.response,
      });
    }
  }
};

export const getMetaDataResetAction = () => (dispatch) => {
  dispatch({
    type: GET_METADATA_RESET,
  });
};

export default getmetadataAction;

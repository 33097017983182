import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import TableNoData from '@/shared/components/TableNoData';
import getGatewayDiagnosticsListing from '@/redux/actions/GatewayDiagnosticsActions/getGatewayDiagnosticsListingAction';
import Filters from './Filters';
import { Col, Row } from 'react-bootstrap';
import VinSearch from '../../VinSearch';

const GatewayDiagnosticsListing = ({
  reactTableData,
  cpuIdValue,
  startDate,
  endDate,
  setIsFiltered,
  isFiltered,
  setCpuIdValue,
  setStartDate,
  setEndDate,
}) => {
  const dispatch = useDispatch();

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [numberofRecords, setNumberofRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [reset, setReset] = useState(false);
  const [vin, setVin] = useState('');

  const tableConfig = {
    isSortable,
    isResizable,
    withPagination,
    manualPageSize: [10],
  };

  const fetchApiData = (pageIndex, pageSize) => {
    setNumberofRecords(pageSize);
    dispatch(
      getGatewayDiagnosticsListing(
        cpuIdValue,
        pageIndex + 1,
        pageSize,
        startDate,
        endDate,
        reactTableData.sort
      )
    );
  };

  const { loading, info, error } = useSelector(
    (state) => state.getGatewayDiagnosticsListingReducer
  );

  useEffect(() => {
    dispatch(
      getGatewayDiagnosticsListing(
        cpuIdValue,
        pageNumber,
        numberofRecords,
        '',
        '',
        reactTableData.sort
      )
    );
  }, [reactTableData.sort, cpuIdValue]);

  return (
    <Card style={{ height: 'auto' }}>
      <CardBody>
        <Row>
          <Col md={12} lg={8} sm={12}>
            <CardTitleWrap>
              <CardTitle>List of Diagnostic Files</CardTitle>
              <CardSubhead>All diagnostic files sent by devices</CardSubhead>
            </CardTitleWrap>
          </Col>
          <Col md={12} lg={4} sm={12}>
            <VinSearch
              diagnostics={true}
              setVin={setVin}
              setCpuId={setCpuIdValue}
              isGateway={true}
              reset={false}
              setReset={setReset}
            />
          </Col>
        </Row>
        <ReactTableBase
          columns={reactTableData.tableHeaderData}
          data={info ? info.data : []}
          tableConfig={tableConfig}
          paginationDetail={
            info && info.pagination
              ? info.pagination
              : {
                  totalPages: 0,
                  totalRecords: 0,
                }
          }
          loading={loading}
          fetchData={fetchApiData}
          dashboardType={'Diagnostics'}
          isFiltered={isFiltered}
        />
        {loading ? '' : info ? info.data.length !== 0 ? '' : <TableNoData /> : <TableNoData />}
      </CardBody>
    </Card>
  );
};

GatewayDiagnosticsListing.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default GatewayDiagnosticsListing;

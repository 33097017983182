import {
  GET_COMP_SERIAL_NUM_LOADING,
  GET_COMP_SERIAL_NUM_SUCCESS,
  GET_COMP_SERIAL_NUM_FAIL,
  RESET_COMP_SERIAL_NUMBER,
} from '../../shared/constants/getCompSerialNumberConstants';

import { RESET_ERROR } from '../../shared/constants/errorResetConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};
const getCompSerialNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMP_SERIAL_NUM_LOADING:
      return { ...state, loading: true };
    case GET_COMP_SERIAL_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case GET_COMP_SERIAL_NUM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COMP_SERIAL_NUMBER:
      return {
        loading: false,
        info: '',
        error: '',
      };
    case RESET_ERROR:
      return {
        loading: false,
        info: '',
        error: '',
      };
    default:
      return state;
  }
};

export default getCompSerialNumberReducer;

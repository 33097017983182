import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl';
import {
  GET_COMPANY_DATA_LOADING,
  GET_COMPANY_DATA_SUCCESS,
  GET_COMPANY_DATA_FAIL,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { createArr } from '@/utils/helpers';

const getCompanyData = (operatorId, fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_DATA_LOADING,
    });

    const {
      data: { data },
    } = await axios.get(ApiURL.GET_MAP_FILTERS + '?' + operatorId + fleetId, getConfig());

    const operators = createArr(data.associations.operators);
    const fleets = createArr(data.associations.fleets);
    const depots = createArr(data.associations.depots);

    dispatch({
      type: GET_COMPANY_DATA_SUCCESS,
      payload: {
        data: data,
        filterOptions: { operators, fleets, depots },
      },
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_COMPANY_DATA_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_COMPANY_DATA_FAIL,
        payload: error.response,
      });
    }
  }
};

export default getCompanyData;

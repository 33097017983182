import {
  GET_GATEWAY_LISTING_LOADING,
  GET_GATEWAY_LISTING_SUCCESS,
  GET_GATEWAY_LISTING_FAIL,
} from "../../../../shared/constants/OtaUpdateConstants/otaDashboardConstants";

const initialState = {
  loading: false,
  info: "",
  error: "",
  pagination: {
    totalPages: 0,
    totalRecords: 0,
  },
  filters: {},
};
const getGatewayListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY_LISTING_LOADING:
      return { ...state, loading: true };
    case GET_GATEWAY_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        pagination: action.payload.pagination,
        filters: action.payload.filters,
        error: "",
      };
    case GET_GATEWAY_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getGatewayListingReducer;

import {
  DOWNLOAD_INDEX_ERROR_CODE_LOADING,
  DOWNLOAD_INDEX_ERROR_CODE_SUCCESS,
  DOWNLOAD_INDEX_ERROR_CODE_FAIL,
} from '@/shared/constants/VehicleDashboardConstants/getCompanyTagsConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
};

const downloadIndexErrorCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_INDEX_ERROR_CODE_LOADING:
      return { ...state, loading: true };
    case DOWNLOAD_INDEX_ERROR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload,
      };
    case DOWNLOAD_INDEX_ERROR_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default downloadIndexErrorCodeReducer;

//Get depot vehicle count
export const GET_TOTAL_VEHICLES_LOADING = 'GET_TOTAL_VEHICLES_LOADING';
export const GET_TOTAL_VEHICLES_SUCCESS = 'GET_TOTAL_VEHICLES_SUCCESS';
export const GET_TOTAL_VEHICLES_FAIL = 'GET_TOTAL_VEHICLES_FAIL';

//Get depot health status summary
export const GET_HEALTH_STATUS_SUMMARY_SUMMARY_LOADING = 'GET_HEALTH_STATUS_SUMMARY_LOADING';
export const GET_HEALTH_STATUS_SUMMARY_SUCCESS = 'GET_HEALTH_STATUS_SUMMARY_SUCCESS';
export const GET_HEALTH_STATUS_SUMMARY_FAIL = 'GET_HEALTH_STATUS_SUMMARY_FAIL';

//Get depot hvac by brand and type
export const GET_DEPOT_HVAC_LOADING = 'GET_DEPOT_HVAC_LOADING';
export const GET_DEPOT_HVAC_SUCCESS = 'GET_DEPOT_HVAC_SUCCESS';
export const GET_DEPOT_HVAC_FAIL = 'GET_DEPOT_HVAC_FAIL';

//Get depot vehicle status
export const GET_DEPOT_VEHICLE_STATUS_LOADING = 'GET_DEPOT_VEHICLE_STATUS_LOADING';
export const GET_DEPOT_VEHICLE_STATUS_SUCCESS = 'GET_DEPOT_VEHICLE_STATUS_SUCCESS';
export const GET_DEPOT_VEHICLE_STATUS_FAIL = 'GET_DEPOT_VEHICLE_STATUS_FAIL';

//Get depot vehicles with gateway
export const GET_VEHICLES_WITH_GATEWAY_LOADING = 'GET_VEHICLES_WITH_GATEWAY_LOADING';
export const GET_VEHICLES_WITH_GATEWAY_SUCCESS = 'GET_VEHICLES_WITH_GATEWAY_SUCCESS';
export const GET_VEHICLES_WITH_GATEWAY_FAIL = 'GET_VEHICLES_WITH_GATEWAY_FAIL';
import {
  GET_PACKAGE_LISTING_LOADING,
  GET_PACKAGE_LISTING_SUCCESS,
  GET_PACKAGE_LISTING_FAIL,
  GET_PACKAGE_LISTING_RESET,
} from '@/shared/constants/OtaUpdateConstants/packageMgtConstants';

const initialState = {
  loading: false,
  info: '',
  error: '',
  pagination: { totalPages: 0, totalRecords: 0 },
  filters: {},
};

const getPackageListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGE_LISTING_LOADING:
      return { ...state, loading: true };
    case GET_PACKAGE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.data,
        pagination: action.payload.pagination,
        filters: action.payload.filters,
      };
    case GET_PACKAGE_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PACKAGE_LISTING_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getPackageListingReducer;

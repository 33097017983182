import axios from 'axios';
import {
  UPDATE_COMPANY_LOADING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_RESET,
} from '@/shared/constants/CompanyConstants/updateCompanyConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import { system_settings } from '@/shared/helpers/successMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const updateCompany = (data, companyId) => async (dispatch) => {
  try {
    const { name, city, company_type, website, description, country } = data;

    dispatch({
      type: UPDATE_COMPANY_LOADING,
    });

    const response = await axios.post(
      ApiURL.UPDATE_COMPANY_DETAILS + companyId + '/update',
      {
        name: name,
        website: website,
        city: city,
        country: country,
        description: description,
        company_role: company_type.id,
      },
      getConfig()
    );

    successMessage(system_settings.update_company);

    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: UPDATE_COMPANY_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: UPDATE_COMPANY_FAIL,
        payload: error.response.data.data.message,
      });
    }
  }
};

export const updateCompanyReset = () => (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_RESET,
  });
};

export default updateCompany;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { right, marginRight, left } from '@/utils/directions';
import { Card, CardBody } from '@/shared/components/Card';
import SimpleLoader from '../../../../shared/components/SimpleLoader';
import { getGateway } from '../../../../redux/actions/getGatewayActions';

const RegisteredGatewayDetails = () => {
  const Ava3 = `${process.env.PUBLIC_URL}/img/Gateways.jpg`;

  const dispatch = useDispatch();
  const history = useHistory();
  const [items, setItems] = useState({});

  const getGatewayReducer = useSelector((state) => state.getGatewayReducer);

  useEffect(() => {
    const newVin = localStorage.getItem('NewVin');
    //Add a way to get latest values for the vin and gateway.
    // dispatch(getVin(newVin));

    setTimeout(() => {
      const vehicleDetails = JSON.parse(localStorage.getItem('vehicleDetails'));
      if (vehicleDetails) {
        setItems(vehicleDetails);
      }
      dispatch(getGateway(newVin));
    }, 1000);
  }, []);

  return (
    <>
      {!getGatewayReducer.info || getGatewayReducer.info === undefined ? (
        <Col xs={12} sm={12} md={6}>
          <h6 style={{ paddingBottom: '20px' }}>Gateway details for the vehicle</h6>
          <Card>
            <CardBody>
              <GatewayList>
                {!items || items === undefined || Object.keys(items).length === 0 ? (
                  <SimpleLoader />
                ) : (
                  <div
                    onClick={() => {
                      history.push('/gateway/');
                    }}
                    style={{ width: '100%' }}
                  >
                    <GatewayContainer>
                      <GatewayImage>
                        <img src={Ava3} alt="" />
                      </GatewayImage>
                      <GatewayAddContainer>
                        <GatewayAdd>Add a Gateway</GatewayAdd>
                        <GatewayLink>
                          <span className="lnr lnr-chevron-right" />
                        </GatewayLink>
                      </GatewayAddContainer>
                    </GatewayContainer>
                  </div>
                )}
              </GatewayList>
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Col xs={12} sm={12} md={6}>
          <h6 style={{ paddingBottom: '20px' }}>Gateway details for the vehicle</h6>
          <Card>
            <CardBody>
              <GatewayList>
                {!items || items === undefined || Object.keys(items).length === 0 ? (
                  <SimpleLoader />
                ) : (
                  <div
                    onClick={() => {
                      history.push('/gateway');
                    }}
                    style={{ width: '100%' }}
                  >
                    <GatewayContainer>
                      <GatewayImage>
                        <img src={Ava3} alt="" />
                      </GatewayImage>
                      <div>
                        <GatewayManufacture>
                          {getGatewayReducer.info.data.gateway_detail.manufacturer}
                          {' | '}
                          {getGatewayReducer.info.data.gateway_detail.company_name}
                        </GatewayManufacture>
                        <GatewaySerialNumber>
                          {getGatewayReducer.info.data.gateway_detail.serial_number}
                          {' | '}
                          {getGatewayReducer.info.data.gateway_detail.sim_number}
                        </GatewaySerialNumber>
                      </div>
                      <GatewayLink>
                        <span className="lnr lnr-chevron-right" />
                      </GatewayLink>
                    </GatewayContainer>
                  </div>
                )}
              </GatewayList>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};
export default RegisteredGatewayDetails;

// region STYLES

const GatewayContainer = styled.div`
  display: flex;
  position: relative;
  padding: 10px 10px;
  text-align: ${left};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const GatewayList = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
  text-align: ${left};

  &:last-child {
    border-bottom: none;
  }
`;

const GatewaySerialNumber = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
`;

const GatewayAddContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;
const GatewayAdd = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`;

const GatewayManufacture = styled.p`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  display: inline;
`;

const GatewayImage = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  ${marginRight}: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
`;

const GatewayLink = styled.div`
  margin-top: 3px;
  position: absolute;
  ${right}: 0;
  padding: 5px 20px;
  line-height: 16px;
  color: #1368b1;
`;

// endregion

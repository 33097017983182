import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from '@/shared/components/Card';
import DotDotLoader from '@/shared/components/DotDotLoader';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FleetListContainer from '../FleetListContainer';

const ViewFleet = ({ setGoBack }) => {
  const Ava = `${process.env.PUBLIC_URL}/img/Fleet.jpg`;

  const { loading, info, error } = useSelector((state) => state.getFleetListReducer);

  const handleCancel = () => {
    setGoBack(true);
  };

  return (
    <Card height="auto">
      <CardBody>
        {loading ? (
          <DotDotLoader loadingState={loading} />
        ) : info && info.fleet_list.length > 0 ? (
          info.fleet_list.map((item) => (
            <FleetListContainer
              img={Ava}
              fleetName={item.name}
              companyName={item.company_name}
              region={item.region}
              climateZone={item.climate_zone}
              showLink={false}
              key={`${item.name}_${item.id}`}
            />
          ))
        ) : info && info.fleet_list.length === 0 ? (
          <FleetListContainer error={'No fleet added'} />
        ) : (
          error && <FleetListContainer error={'Error getting fleet data'} />
        )}
        <FormButtonToolbar style={{ width: '100%', paddingTop: '20px' }}>
          <Button
            variant="secondary"
            type="button"
            onClick={() => handleCancel()}
            style={{ width: '100%' }}
          >
            Back
          </Button>
        </FormButtonToolbar>
      </CardBody>
    </Card>
  );
};

export default ViewFleet;

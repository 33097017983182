import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import SelectVehicleForm from './components/SelectVehicleForm';
import { Modal as BootstrapModal } from 'react-bootstrap';
import styled from 'styled-components';
import { colorBackground, colorText } from '@/utils/palette';
import { Button } from '@/shared/components/Button';
import { getVin, resetGetVinValues } from '../../../redux/actions/getVinActions';

const SelectVehicles = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getVinReducer = useSelector((state) => state.getVinReducer);
  const getAssetType = localStorage.getItem('asset');

  const [show, setShow] = useState(false);
  const [registerVehicle, setRegisterVehicle] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resetValues, setResetValues] = useState(false);

  //Modal btn functions
  const handleClose = () => setShow(false);
  const handleRegister = () => setRegisterVehicle(true);

  const handleSubmit = (values) => {
    if (values && values.vin) {
      dispatch(getVin(values.vin));
      localStorage.setItem('NewVin', values.vin);
      setShow(true);
      setShowForm(true);
      setResetValues(true);
    }
  };
  useEffect(() => {
    dispatch(resetGetVinValues());
  }, []);

  if (resetValues === true) {
    if (
      getVinReducer &&
      getVinReducer.info !== '' &&
      getVinReducer.info.status === 'success' &&
      getAssetType === 'Vehicles'
    ) {
      setTimeout(() => {
        history.push('/app/hvac_dashboard');
      }, 2000);
    }
  }
  if (registerVehicle === true) {
    if (
      getVinReducer.error !== '' &&
      getVinReducer.error !== 'network error' &&
      getVinReducer.error !== undefined
    ) {
      history.push('/wform');
    }
  }

  if (getVinReducer.info !== '' && getAssetType === 'HVACs' && resetValues === true) {
    setTimeout(() => {
      history.push('/app/hvac_dashboard');
    }, 2000);
  }

  if (getVinReducer.info !== '' && getAssetType === 'Gateways' && resetValues === true) {
    setTimeout(() => {
      history.push('/gateway');
    }, 2000);
  }
  if (getVinReducer.info !== '' && getAssetType === 'Maintenance Set Up' && resetValues === true) {
    setTimeout(() => {
      history.push('/maintenance');
    }, 2000);
  }
  return (
    <Container>
      <Row className="pb-4">
        <Col md={6}>
          <h3 className="page-title">{'Select Vehicles'}</h3>
          <h6>Input VIN number</h6>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <SelectVehicleForm onSubmit={handleSubmit} />
          <StyledModal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show && !!getVinReducer.error && getVinReducer.error !== 'network error'}
            backdrop="static"
            keyboard={false}
            onHide={handleClose}
          >
            {getVinReducer.error.status !== 403 ? (
              <>
                <StyledModalHeader>
                  <StyledModalTitle>No vehicle found with this VIN</StyledModalTitle>
                </StyledModalHeader>
                <StyledModalBody>
                  <StyledModalBodyText>
                    {getAssetType === 'Vehicles'
                      ? 'Please register a vehicle'
                      : `Register a vehicle first with this VIN and then register the ${getAssetType}`}
                  </StyledModalBodyText>
                </StyledModalBody>
                <StyledModalFooter>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{
                      marginBottom: '0',
                      textAlign: 'center',
                      width: '160px',
                      margin: '0',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleRegister}
                    style={{
                      marginBottom: '0',
                      textAlign: 'center',
                      width: '160px',
                      margin: '0 0 0 10px',
                    }}
                  >
                    Register Vehicle
                  </Button>
                </StyledModalFooter>
              </>
            ) : (
              <>
                <StyledModalHeader>
                  <StyledModalTitle>Access Denied!</StyledModalTitle>
                </StyledModalHeader>
                <StyledModalBody>
                  <StyledModalBodyText>
                    {`You don't have permission to access this vehicle!`}
                  </StyledModalBodyText>
                </StyledModalBody>
                <StyledModalFooter>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{
                      marginBottom: '0',
                      textAlign: 'center',
                      width: '160px',
                      margin: '0',
                    }}
                  >
                    Cancel
                  </Button>
                </StyledModalFooter>
              </>
            )}
          </StyledModal>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectVehicles;

const StyledModal = styled(BootstrapModal)`
  .modal-content {
    border-radius: 0;
    border: none;
    max-width: 350px;
    align-items: center;
    margin: 0 auto;
    background-color: ${colorBackground};
    color: ${colorText};
  }
`;

const StyledModalHeader = styled(BootstrapModal.Header)`
  display: block;
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  border: 0;
`;

const StyledModalTitle = styled(BootstrapModal.Title)`
  text-align: center;
  font-size: 16px;
`;

const StyledModalBody = styled(BootstrapModal.Body)`
  text-align: left;
  padding-right: 40px;
  padding-left: 40px;
`;

const StyledModalBodyText = styled.div`
  font-size: 14px;
`;

const StyledModalFooter = styled(BootstrapModal.Footer)`
  border: 0;
  display: block;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
`;

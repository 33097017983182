import axios from 'axios';
import moment from 'moment';
import { ApiURL } from '@/config/GlobalUrl';
import {
  CREATE_CAMPAIGN_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
} from '@/shared/constants/OtaUpdateConstants/campaignMgtConstants';
import { getConfig } from '@/shared/helpers/apiConfig';
import { successMessage, errorFunction } from '@/shared/helpers/alertMessages';
import Path from '@/utils/routes/routePathNames';

const createCampaign = (formValues, history) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CAMPAIGN_LOADING,
    });
    const response = await axios.post(
      ApiURL.CREATE_CAMPAIGN,
      {
        type: formValues.deviceType,
        name: formValues.campaign_name,
        package_id: formValues['package-list']?.id,
        strategy: 'INSTANT_UPDATE',
        // scheduled_at: formValues.time
        //   ? moment(formValues.time).format('YYYY-MM-DDTHH:mm:ss')
        //   : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        only_devices: formValues['hvac-list']?.map(({ id }) => id),
        deployment_type: formValues.deployment_type,
      },
      getConfig()
    );
    successMessage('Campaign created successfully!');
    dispatch({
      type: CREATE_CAMPAIGN_SUCCESS,
      // payload: response.data.data,
    });
    history.push(Path.CAMPAIGN_LISTING);
  } catch (error) {
    if (error.response === undefined) {
      errorFunction(error);
      dispatch({
        type: CREATE_CAMPAIGN_FAIL,
        payload: 'network error',
      });
    } else {
      errorFunction(error.response.data.data);
      dispatch({
        type: CREATE_CAMPAIGN_FAIL,
        payload: error.response,
      });
    }
  }
};

export default createCampaign;

import { ApiURL } from '../../config/GlobalUrl';
import axios from 'axios';
import {
  GET_VEHICLE_TAGS_LOADING,
  GET_VEHICLE_TAGS_SUCCESS,
  GET_VEHICLE_TAGS_FAIL,
} from '../../shared/constants/getVehicleTagsConstants';

const getVehicleTags = (vin) => async dispatch => {
  try {
    dispatch({
      type: GET_VEHICLE_TAGS_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const userDetails = JSON.parse(localStorage.getItem('user'));
    const companyId = userDetails.company_id;

    const response = await axios.get(
      ApiURL.GET_VEHICLE_TAGS +
        companyId +
        '/vehicles/' +
        vin +
        '/tags',
      config
    );
    dispatch({
      type: GET_VEHICLE_TAGS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: GET_VEHICLE_TAGS_FAIL,
        payload: 'network error',
      });
    } else {
      dispatch({
        type: GET_VEHICLE_TAGS_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

export default getVehicleTags;

import axios from 'axios';
import {
  GET_GATEWAY_DIAGNOSTICS_LISTING_LOADING,
  GET_GATEWAY_DIAGNOSTICS_LISTING_SUCCESS,
  GET_GATEWAY_DIAGNOSTICS_LISTING_FAIL,
  GET_GATEWAY_DIAGNOSTICS_LISTING_RESET,
} from '@/shared/constants/GatewayDiagnosticsConstants/gatewayDiagnosticsConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { errorFunction } from '@/shared/helpers/alertMessages';
import { ApiURL } from '../../../config/GlobalUrl';

const getGatewayDiagnosticsListing =
  (cpuId, pageIndex, pageSize, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_LISTING_LOADING,
      });

      const response = await axios.get(
        ApiURL.GET_GATEWAY_DIAGNOSTICS_LISTING +
          `/${cpuId}?sort=-creation_date&p=${pageIndex}&page_size=${pageSize}${
            startDate ? startDate : ''
          }${endDate ? endDate : ''}`,
        getConfig()
      );

      dispatch({
        type: GET_GATEWAY_DIAGNOSTICS_LISTING_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      if (error.response === undefined) {
        errorFunction(error);
        dispatch({
          type: GET_GATEWAY_DIAGNOSTICS_LISTING_FAIL,
          payload: 'network error',
        });
      } else {
        errorFunction(error.response.data.data);
        dispatch({
          type: GET_GATEWAY_DIAGNOSTICS_LISTING_FAIL,
          payload: error.response,
        });
      }
    }
  };

export const getGatewayDiagnosticsListingReset = () => (dispatch) => {
  dispatch({
    type: GET_GATEWAY_DIAGNOSTICS_LISTING_RESET,
  });
};
export default getGatewayDiagnosticsListing;

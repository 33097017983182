import axios from 'axios';
import { ApiURL } from '@/config/GlobalUrl.js';
import {
  GET_GATEWAY_LISTING_LOADING,
  GET_GATEWAY_LISTING_SUCCESS,
  GET_GATEWAY_LISTING_FAIL,
} from '@/shared/constants/OtaUpdateConstants/otaDashboardConstants';
import { getConfig } from '@/shared/helpers/apiConfig.js';
import { sortingFunc } from '@/utils/helpers';

const getGatewayListingAction =
  (operator, fleet, depot, group, type, pageNumber, numberofRecords, sortData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GATEWAY_LISTING_LOADING,
      });

      const params = {
        operator_id: operator ? operator : null,
        fleet_id: fleet ? fleet : null,
        depot_id: depot ? depot : null,
        name: group ? group : null,
        type: type ? type : null,
        p: pageNumber ? pageNumber : null,
        page_size: numberofRecords ? numberofRecords : null,
        sort: sortData ? sortingFunc(sortData) : '-updated_at',
      };

      const response = await axios.get(ApiURL.GET_GROUP_LISTING, { params: params }, getConfig());

      dispatch({
        type: GET_GATEWAY_LISTING_SUCCESS,
        payload: {
          data: response.data.data,
          pagination: response.data.pagination,
          filters: { operator, depot, fleet, group, type },
        },
      });
    } catch (error) {
      if (error.response === undefined) {
        dispatch({
          type: GET_GATEWAY_LISTING_FAIL,
          payload: 'network error',
        });
      } else {
        dispatch({
          type: GET_GATEWAY_LISTING_FAIL,
          payload: error.response.data,
        });
      }
    }
  };

export default getGatewayListingAction;

export const GET_SIGNALS_LOADING = 'GET_SIGNALS_LOADING';
export const GET_SIGNALS_SUCCESS = 'GET_SIGNALS_SUCCESS';
export const GET_SIGNALS_FAIL = 'GET_SIGNALS_FAIL';
export const GET_SIGNALS_RESET = 'GET_SIGNALS_RESET';

export const GET_DIAGNOSTICS_FILE_LOADING = 'GET_DIAGNOSTICS_FILE_LOADING';
export const GET_DIAGNOSTICS_FILE_SUCCESS = 'GET_DIAGNOSTICS_FILE_SUCCESS';
export const GET_DIAGNOSTICS_FILE_FAIL = 'GET_DIAGNOSTICS_FILE_FAIL';
export const GET_DIAGNOSTICS_FILE_RESET = 'GET_DIAGNOSTICS_FILE_RESET';

export const SEND_CAN_TIME_INTERVAL_LOADING = 'SEND_CAN_TIME_INTERVAL_LOADING';
export const SEND_CAN_TIME_INTERVAL_SUCCESS = 'SEND_CAN_TIME_INTERVAL_SUCCESS';
export const SEND_CAN_TIME_INTERVAL_FAIL = 'SEND_CAN_TIME_INTERVAL_FAIL';
export const SEND_CAN_TIME_INTERVAL_RESET = 'SEND_CAN_TIME_INTERVAL_RESET';

//Get can data lisitng
export const GET_CAN_DATA_LISTING_LOADING = 'GET_CAN_DATA_LISTING_LOADING';
export const GET_CAN_DATA_LISTING_SUCCESS = 'GET_CAN_DATA_LISTING_SUCCESS';
export const GET_CAN_DATA_LISTING_FAIL = 'GET_CAN_DATA_LISTING_FAIL';

export const DELETE_CAN_DUMP_LOADING = 'DELETE_CAN_DUMP_LOADING';
export const DELETE_CAN_DUMP_SUCCESS = 'DELETE_CAN_DUMP_SUCCESS';
export const DELETE_CAN_DUMP_FAIL = 'DELETE_CAN_DUMP_FAIL';
export const DELETE_CAN_DUMP_RESET = 'DELETE_CAN_DUMP_RESET';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, CardTitleWrap, CardTitle, CardSubhead } from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { WizardFormWrap } from '@/shared/components/form/WizardFormElements';
import renderSelectField from '@/shared/components/form/Select';
import { Button } from '@/shared/components/Button';
import { packageCreationValidate } from '@/utils/validate.js';
import getGroupList, {
  getGroupListReset,
} from '@/redux/actions/OtaUpdateActions/PackageMgtActions/getGroupListAction';
import createPackage from '@/redux/actions/OtaUpdateActions/PackageMgtActions/createPackageAction';
import { uploadPackageFileCheckReset } from '@/redux/actions/OtaUpdateActions/PackageMgtActions/uploadPackageFileCheckAction';
import getBaseFirmwareVersion, {
  getBaseFirmwareVersionReset,
} from '@/redux/actions/OtaUpdateActions/PackageMgtActions/GatewayPackageCreationActions/getBaseFirmwareVersion';
import createGatewayPackage from '@/redux/actions/OtaUpdateActions/PackageMgtActions/GatewayPackageCreationActions/createGatewayPackage';
import SharedRadio from '../../SharedElements/SharedRadio';
import SharedForm from '../../SharedElements/SharedForm';
import GatewayPackageCreationForm from './components/GatewayPackageCreationForm';
import EcontrolPackageCreationForm from './components/EcontrolPackageCreationForm';

const PackageCreationForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const radioValues = ['econtrol', 'gateway'];

  const [resetDepot, setResetDepot] = useState(false);
  const [deviceTypeValue, setDeviceTypeValue] = useState(radioValues[0]);

  const groupListLoading = useSelector((state) => state.getGroupListReducer.loading);
  const groupListInfo = useSelector((state) => state.getGroupListReducer.info);
  const createPackageLoading = useSelector((state) => state.createPackageReducer.loading);
  const createGatewayPackageLoading = useSelector(
    (state) => state.createGatewayPackageReducer.loading
  );
  const uploadPackageFileCheckLoading = useSelector(
    (state) => state.uploadPackageFileCheckReducer.loading
  );

  const handlePackageCreation = (values) => {
    if (Object.keys(values).length > 0 && deviceTypeValue === 'econtrol') {
      dispatch(createPackage(values, history));
    }
    if (Object.keys(values).length > 0 && deviceTypeValue === 'gateway') {
      dispatch(createGatewayPackage(values, history));
    }
  };

  const onDepotChange = (e) => {
    if (e) {
      dispatch(getGroupList(e.id, deviceTypeValue));
      setResetDepot(false);
    }
    if (!e) {
      dispatch(getGroupListReset());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(uploadPackageFileCheckReset());
    };
  }, []);

  const handleGroupChange = (e, form) => {
    if (e && deviceTypeValue === 'gateway') {
      dispatch(getBaseFirmwareVersion());
    }
    if (e && deviceTypeValue === 'econtrol') {
      // dispatch(getBaseFirmwareVersion(e.id));
    }
    if (!e) {
      const getAllRegisteredFields = form
        .getRegisteredFields()
        .filter((item) => !item.includes('company'))
        .filter((item) => !item.includes('fleet'))
        .filter((item) => !item.includes('depot'))
        .filter((item) => !item.includes('device_type'));
      getAllRegisteredFields.map((item) => form.change(item));
      dispatch(getBaseFirmwareVersionReset());
      if (deviceTypeValue === 'econtrol') {
        dispatch(uploadPackageFileCheckReset());
      }
    }
  };

  const handleRadioChange = (e, form) => {
    if (e) {
      setDeviceTypeValue(e);
      dispatch(getGroupListReset());
      form.change('group');
    }
    if (!e) {
      setDeviceTypeValue('');
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Package Management</CardTitle>
          <CardSubhead>Create a package</CardSubhead>
        </CardTitleWrap>
        <WizardFormWrap>
          <Form onSubmit={handlePackageCreation}>
            {({ handleSubmit, form, pristine, hasValidationErrors, values }) => (
              <FormContainer
                vertical
                onSubmit={handleSubmit}
                style={{ maxWidth: '750px', width: '100%' }}
              >
                <FormGroup>
                  <FormGroupLabel>Choose device type</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="device_type"
                      render={SharedRadio}
                      label="HVAC ECUs"
                      radioValue={radioValues[0]}
                      value={radioValues[0]}
                      parse={(e) => {
                        handleRadioChange(e, form);
                        return e;
                      }}
                      initialValue={deviceTypeValue}
                    />
                    <Field
                      name="device_type"
                      render={SharedRadio}
                      label="Gateway"
                      radioValue={radioValues[1]}
                      value={radioValues[1]}
                      parse={(e) => {
                        handleRadioChange(e, form);
                        return e;
                      }}
                    />
                  </FormGroupField>
                </FormGroup>
                <SharedForm form={form} onDepotChange={onDepotChange} />
                <FormGroup>
                  <FormGroupLabel>Group</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="group"
                      component={renderSelectField}
                      options={groupListInfo && !resetDepot ? groupListInfo : []}
                      placeholder="Select group"
                      parse={(e) => {
                        handleGroupChange(e, form);
                        return e;
                      }}
                      isClearable
                      isLoading={groupListLoading}
                    />
                  </FormGroupField>
                </FormGroup>
                {deviceTypeValue === 'gateway' ? (
                  <GatewayPackageCreationForm form={form} values={values} />
                ) : (
                  <EcontrolPackageCreationForm form={form} values={values} />
                )}
                <FormButtonToolbar>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      hasValidationErrors ||
                      createPackageLoading ||
                      uploadPackageFileCheckLoading ||
                      createGatewayPackageLoading
                    }
                  >
                    Create Package
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </WizardFormWrap>
      </CardBody>
    </Card>
  );
};

export default PackageCreationForm;
